import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: -15,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
    },
  }),
);



export const SetTimer: FunctionComponent<{ onChange?: (newTime) => void }> = props => {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        label=""
        type="datetime-local"
        // defaultValue="2020-07-24T10:30"
        defaultValue={new Date().getDate()+"-"+(new Date().getMonth()+1)+"-"+new Date().getFullYear()}
        className={classes.textField}
        onChange={(x) => {
          if (props.onChange) {
            props.onChange(Date.parse(x.target.value))
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}