import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid, Container, Typography, Paper, CircularProgress, Card, List, ListItem, Button, CardHeader, Avatar, IconButton, Divider, Link, CardContent, Collapse, } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { API } from "../Helpers";




const useStyles = makeStyles((theme: Theme) =>
  createStyles({




    imageSection: {

      height: 400,

      borderRadius: 0,
      boxShadow: 'none',
    },


    rounded: {
      color: '#fff',

    },


    otherColumns: {
      height: 400,
      padding: 25,

      borderRadius: 0,
      boxShadow: 'none',

    },

    documentGrid: {
      height: 200,

      borderRadius: 0,

      boxShadow: 'none',
      marginBottom: '2%',


    },


    card: {
      //   maxWidth: 345,
      display: 'flex',
      flexDirection: 'column',
      height: 330,
      alignItems: 'center',
      backgroundColor: '#f7f7f7',

    },


    SocialIcons: {

      color: '#b5adad',


    },

    SocialText: {


      color: '#969191',
      textAlign: 'left',
    },




    media: {
      height: 50,
      width: 50,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },


    headings: {

      opacity: '70%',
    },
    avatar: {
      //   backgroundColor: red[500],
      width: 150,
      height: 150,
    },






  }),
);





export const SingleBuyerComponent: FunctionComponent = () => {
  const classes = useStyles();
  const [user, setUser] = useState({});

  useEffect(() => {
    API.get('user/' + window.location.pathname.split('/')[3]).then(x => {
      setUser(x.data);
    })
  }, [])


  if (!user['id']) {
    return <Grid container justify="center" alignItems="center" style={{ paddingTop: '25%' }}>
      <CircularProgress style={{ color: '#f28f16' }} />
    </Grid>
  }

  return (
    <div style={{ paddingTop: '2%', marginBottom: '7%' }}>
      <Container maxWidth="xl">
        <Grid container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          style={{ backgroundColor: '#fff', padding: '2%' }}
        >
          <Grid item lg={12}>
            <Typography variant="h6">Buyer Profile</Typography>
          </Grid>
          <Grid item lg={4}>
            <Paper className={classes.imageSection}>
              <Card className={classes.card} elevation={0} >
                <List>
                  <ListItem alignItems="flex-start" >

                  </ListItem>
                </List>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      R
                    </Avatar>
                  }


                />

                {/* <Typography className={classes.headings} variant="h6" >   {user['firstName'] + ' ' + user['lastName']} </Typography>
    
           <Typography variant="body2" >Product Designer/UX Coordinator</Typography>
    <Typography variant="caption" >   {user['email']}</Typography><br />
        
      <CardContent>


        <Typography variant="body2" color="textSecondary" component="p">
         
        </Typography>
      </CardContent> */}

                {/* <Collapse in={expanded} timeout="auto" unmountOnExit> */}
                <CardContent>

                </CardContent>
                {/* </Collapse> */}
              </Card>
            </Paper>
          </Grid>

          <Grid item lg={4} style={{ marginTop: 75 }}>
            <Paper className={classes.otherColumns}>
              <Typography variant="body2">
                Name:

              </Typography>
              <Typography variant="subtitle2">
                {user['firstName'] + ' ' + user['lastName']}

              </Typography>
              <Divider style={{ marginTop: 4, marginBottom: 25 }} />
              <Typography variant="body2">
                Phone:

              </Typography>
              <Typography variant="subtitle2">
                {user['phone'] ?? 'Not Provided'}
              </Typography>
              <Divider style={{ marginTop: 4, marginBottom: 25 }} />
              <Typography variant="body2">
                Role:

              </Typography>
              <Typography variant="subtitle2">
                {user['role'] ?? 'Not Provided'}
              </Typography>

              <Divider style={{ marginTop: 4, marginBottom: 25 }} />

            </Paper>



          </Grid>

          <Grid item lg={4}>
            <List>
              <ListItem>

              </ListItem>

              <ListItem style={{ marginLeft: 230, marginTop: 30 }}>
                {/* <Button  variant="contained" size="small" style={{ backgroundColor: '#f28f16', boxShadow: 'none', color: '#ffffff', marginLeft: 15 }}>Approve</Button> */}
              </ListItem>
            </List>

            <Paper className={classes.otherColumns}>
              <Typography variant="body2">
                Email:

              </Typography>
              <Typography variant="subtitle2">
                {user['email']}
              </Typography>
              <Divider style={{ marginTop: 4, marginBottom: 25 }} />



              <Typography variant="body2">
                Country:

              </Typography>
              <Typography variant="subtitle2">
                {user['country'] ?? 'Not Provided'}
              </Typography>
              <Divider style={{ marginTop: 4, marginBottom: 25 }} />



            </Paper>



          </Grid>






        </Grid>

        <Grid container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          style={{ backgroundColor: '#fff', padding: '2%', marginTop: '2%' }}
        >
          <Grid item lg={12}>
            <Typography variant="h6">Documents</Typography>
            <br />
            <Paper className={classes.documentGrid}>

              <List style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #d6d4d4', }}>
                <ListItem>
                  <Typography variant="subtitle2">ID</Typography>

                </ListItem>

                <ListItem>
                  <Typography variant="subtitle2">Title</Typography>

                </ListItem>

                <ListItem>

                  <Typography variant="subtitle2">Image</Typography>

                </ListItem>

                <ListItem>
                  <Typography variant="subtitle2">Actions</Typography>


                </ListItem>



              </List>

              {[user['licenseURL'], user['certificateURL']].map((x, i) => {
                return <List style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#fff' }}>

                  <ListItem>


                    <Typography variant="body2">{i + 1}</Typography>
                  </ListItem>

                  <ListItem>

                    <Typography variant="body2">{i == 0 ? 'License' : 'Certificate'}</Typography>
                  </ListItem>

                  <ListItem>
                    <img src={x} height='50' width='50' />
                  </ListItem>

                  <ListItem>


                    <IconButton>
                      <DeleteIcon />
                    </IconButton>


                  </ListItem>



                </List>
              })
              }
            </Paper>
          </Grid>

        </Grid>





      </Container>


    </div>
  )
}


