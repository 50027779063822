import React, { useState, useEffect, FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import { ListItem, List, Typography, Grid, Paper, ListItemText, CircularProgress, ListItemSecondaryAction, Card, CardActionArea, CardMedia, CardContent, IconButton } from '@material-ui/core';
import { Cancel, Visibility } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Ways, API, Cook, getvalue } from '../../Helpers';
import { HoneyContributionPopup } from './HoneyContributionPopup';
import moment from 'moment';
import { sentenceCase } from "sentence-case";





const useStyles = makeStyles((theme) => ({


    Paper: {
        boxShadow: 'none',
        padding: 15,

    },

    listStyle: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',




        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },

    headingListStyle: {

        display: 'flex',
        flexDirection: 'row',
        width: '100%',



        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },


    },


    listheading: {

        fontSize: 16,
        fontWeight: 400,
    },

    leftItem: {

        backgroundColor: '#f28f14',
        color: '#fff',
        // borderBottom: '1px solid #fff',
    },

    rightItem: {

        border: '1px solid #efefef',
    },


    actionArea: {

        '&.MuiCardActionArea-root': {

            textAlign: 'center',


        },

    },

    media: {


    },

    mainHeading: {

        color: '#fff',
        fontSize: '1rem',
        fontWeight: 400,


    },


    descriptionStyle: {


        fontSize: '1rem',
        fontWeight: 500,
        color: '#fff',

    },

    Paper3: {
        backgroundColor: '#fff',
        height: '25vh',
        boxShadow: 'none',


        [theme.breakpoints.down('sm')]: {
            height: 200,
        },

    },

    card: {
        maxWidth: 400,

        boxShadow: 'none',
        backgroundColor: '#f28f16',
        paddingTop: 10,
        paddingBottom: 10,
        pointerEvents: 'none',




    },




}));




export const DetailsForSingleIBC: FunctionComponent<{ currentSingleIBC: any }> = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    // console.log(JSON.stringify(props))
    const handleClose = () => {
        setOpen(false);
    };



    const [data, setData] = useState([]);
    const [loading, setLoadin] = useState(false);


    useEffect(() => {

        // API.get('ibc', { ibcid: '0d5ade2c-1269-42d2-9da7-66ce8d111fc4' } ).then(x=>{
        //   console.log(x.data);
        //   setData(x.data);
        // })  
        // console.log(props.currentSingleIBC)


    }, [])

    // useEffect(() => {
    //     API.get('ibc', {ibcid: window.location.pathname.split('/')[3]}).then(x => {
    //         setData(x.data);
    //         console.log(x.data);
    //     })
    // }, [])


    //   if(!data['id']){
    //     return<Grid container justify="center" alignItems="center" style={{paddingTop: '25%'}}>
    // <CircularProgress style={{color: '#f28f16'}} />
    //     </Grid> 
    // }
    return (
        <div>




            <Grid container direction="row">


                <Grid item lg={12} xs={12}>
                    <Paper className={classes.Paper} >
                        {/* <List style={{display: 'flex', flexDirection: 'row'}}>
           <ListItem>
               <ListItemText>
               Down Payment (AUD) :
               </ListItemText>
               <ListItemSecondaryAction>
                  <b> {props.currentSingleIBC['initialPayment']}</b>
               </ListItemSecondaryAction>
           </ListItem>

          Honey Properties <ListItem>
               <ListItemText>
               Min Selling price (AUD/KG) :
               </ListItemText>
               <ListItemSecondaryAction>
                  <b> {props.currentSingleIBC['minSellingPrice']} </b>
               </ListItemSecondaryAction>
           </ListItem>

           <ListItem>
               <ListItemText>
               Pay Load (t) :
               </ListItemText>
               <ListItemSecondaryAction>
                  <b> {props.currentSingleIBC['payLoad']}</b>
               </ListItemSecondaryAction>
           </ListItem>

           <ListItem>
               <ListItemText>
               Total Sold (t) :
               </ListItemText>
               <ListItemSecondaryAction>
                  <b> {props.currentSingleIBC['payLoad']-props.currentSingleIBC['remaining']}</b>
               </ListItemSecondaryAction>
           </ListItem>

           <ListItem>
               <ListItemText>
               Available Qty (t) :
               </ListItemText>
               <ListItemSecondaryAction>
                  <b> {props.currentSingleIBC['remaining']}</b>
               </ListItemSecondaryAction>
           </ListItem>

       </List> */}

                        {/* <ListItem>
                            <ListItemText>
                                <Typography style={{marginLeft:'-16px'}} variant="h6">Honey Properties</Typography>
                            </ListItemText>
                            

                        </ListItem> */}

                        <div style={{marginBottom:'8px'}} >
                            <Typography  variant="h6">Honey Properties</Typography>
                        </div>

                        {/* first list */}


                        <List style={{paddingTop:'0px',paddingBottom:'0px'}} className={classes.listStyle}>
                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    Extraction Date

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">



                                    {new Date((Date.parse(String(props.currentSingleIBC['dateOfExtraction'])))).toLocaleString().split(',')[0]}

                                </Typography>

                            </ListItem>


                            {/* <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    Total Activity

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">
                                    {props.currentSingleIBC['activity']}

                                </Typography>

                            </ListItem> */}

                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    Sample Ref #

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">
                                    {props.currentSingleIBC['sampleRef']}

                                </Typography>

                            </ListItem>


                            {/* <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    Organic

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">

                                    {props.currentSingleIBC['organicNonOrganic'] == 'organic' ? 'Yes' : 'No'}

                                </Typography>

                            </ListItem> */}

                        </List>


                        {/* first list ends here */}







                        {/* second list */}


                        <List className={classes.listStyle} style={{ paddingTop:'0px',paddingBottom:'0px' }}>
                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    Dominant Variety

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">


                                    {props.currentSingleIBC['honeyType']}


                                </Typography>

                            </ListItem>




                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    TA Level

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">
                                    {props.currentSingleIBC['activity']}

                                </Typography>

                            </ListItem>




                        </List>


                        {/* second list ends here */}







                        {/* third list */}


                        <List className={classes.listStyle} style={{ paddingTop:'0px', paddingBottom:'0px' }}>


                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    Second Variety

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">
                                    {props.currentSingleIBC['secondaryVariety']}

                                </Typography>

                            </ListItem>


                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    MGO Level

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">

                                    {props.currentSingleIBC['mgo']}

                                </Typography>

                            </ListItem>




                        </List>


                        {/* third list ends here */}









                        {/* 4th list */}


                        <List className={classes.listStyle} style={{ paddingTop:'0px',paddingBottom:'0px' }}>

                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    Apiary Site 1

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography style={{ textTransform: 'capitalize' }} variant="body2">


                                    {props.currentSingleIBC['apiarySiteLocation']}

                                </Typography>

                            </ListItem>


                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    HMF

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">
                                    {props.currentSingleIBC['hmf']}

                                </Typography>

                            </ListItem>




                        </List>


                        {/* 4th list ends here */}







                        {/* 4th list */}


                        <List className={classes.listStyle} style={{ paddingTop:'0px',paddingBottom:'0px' }}>


                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    Apiary Site 2

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography style={{ textTransform: 'capitalize' }} variant="body2">


                                    {props.currentSingleIBC['apiarySiteLocation2']}

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.leftItem}>
                                <Typography className={classes.listheading} variant="subtitle1">
                                    Moisture

                                </Typography>

                            </ListItem>

                            <ListItem className={classes.rightItem}>
                                <Typography variant="body2">

                                    {props.currentSingleIBC['moisture']}

                                </Typography>

                            </ListItem>








                        </List>


                        {/* 4th list ends here */}



















                    </Paper>




                </Grid>
            </Grid>






        </div>
    );
}