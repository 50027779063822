import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { BarChart, MailOutline } from '@material-ui/icons';
import { Button, Dialog, DialogContent, ListItem, ListItemSecondaryAction, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { fade, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Search } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import GrainIcon from '@material-ui/icons/Grain';
import HomeIcon from '@material-ui/icons/Home';
import { Mail } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'typeface-roboto';
import black from './assets/black.jpg';
import logo from './assets/logo.png';
import men from './assets/men.png';
import { AllCategoriesTable } from './Categories/AllCategoriesTable';
import { ChatLayout } from './Chat/ChatLayout';
import { Menus } from './Components/Menus';
import { EmailLayout } from './Email/EmailLayout';
import { AllOffersTable } from './AllOffersTable';
// import { OfferLayout } from './Offer/OfferLayout';
import { AllOrdersTable } from './Orders/AllOrdersTable';
import { OrderLayout } from './Orders/OrderLayout';
import { AllProductsTable } from './Product/AllProductsTable';
import { ProductLayout } from './Product/ProductLayout';
import { Ways, API } from './Helpers';
import { createBrowserHistory } from 'history';
import { GlobalState } from './AppState';
import { observer } from 'mobx-react';
import { Quote } from './Quote/Quote';
import { AllBuyersTable } from './Components/AllBuyersTable';
import { EmailUpdated } from './Email/EmailUpdated';
import { AllBeekeepers } from './Tables/AllBeekeepers';
import { SellerProfileLayout } from './SellerProfile/SellerProfileLayout';
import { HoneyLayout } from './Honey/HoneyLayout';
import { SingleHoneyLayout } from './Honey/SingleHoneyPage/SingleHoneyLayout';
import { IBCSingleDetails } from './Honey/SingleHoneyPage/IBCSingleDetails';
import { BeekeeperHoneyLayout } from './BeekeeperHoneyDetails/BeekeeperHoneyLayout';
import { BeekeeperHoneyDetails } from './BeekeeperHoneyDetails/BeekeeperHoneyDetails';
import { WithdrawlsLayout } from './AllWithdrawls/WithdrawlsLayout';
import MainPieChart from './Charts/MainPieChart';
import { DashboardContent } from './Components/DashboardContent';
import { NotificationsComponent } from './Components/NotificationsComponent';
import { BeekeeperRequests } from './Components/BeekeeperRequests';
import { BreadCrumbsBar } from './Components/BreadCrumbsBar';
import { HoneyIbcs } from './Honey/IBC'
import { HoneyRates } from './HoneyRates/HoneyRates';

function Copyright() {
  return (

    <Typography variant="body2" color="textSecondary" align="center"  >
      {'Copyright © '}
      <Link color="inherit" href="https://admin.australianhoneyventures.com.au/">
        Australian Honey Ventures
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}-v1.1
    </Typography>
  );
}

const drawerWidth = 280;

const useButtonStyle = makeStyles((theme) => ({
  btnStyle: {
    backgroundColor: 'transparent',
    color: '#fff',
    '&.MuiIcocnButton-root': {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  shortDescription: {

    marginLeft: '6%',
    marginTop: '-4%',
  },


  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed

  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '20 8px 50px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    backgroundColor: '#F28F16',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,

    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    borderRight: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,

    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },



  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    marginTop: '-10%',
    overflow: 'auto',


    [theme.breakpoints.down('sm')]: {
      marginTop: '-45%',
    },






  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 580,
    boxShadow: 'none',
  },

  fixedHeightPaperProduct: {
    height: 650,
    boxShadow: 'none',
    padding: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {


      height: 2200,
    },

  },

  fixedHeightPaperProductGallery: {
    height: 600,
    boxShadow: 'none',
    padding: theme.spacing(4),

  },

  fixedHeightPaperOffer: {
    height: 520,
    boxShadow: 'none',
    padding: theme.spacing(4),

  },

  fixedHeightPaperProductItemDetail: {
    height: 1000,
    boxShadow: 'none',
    padding: theme.spacing(4),
  },


  fixedHeightPaperProductsTable: {
    height: 700,
    boxShadow: 'none',
    padding: theme.spacing(4),
  },



  fixedHeightPaperNewCategory: {
    height: 300,
    boxShadow: 'none',
    padding: theme.spacing(4),
  },

  search: {
    position: 'relative',
    marginRight: '2%',
    // borderRadius: theme.shape.borderRadius,
    borderRadius: 20,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },


  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '14ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },

  menuTab: {
    marginTop: 40,
  },

  breadcrumbs: {
    color: '#303030',
    position: 'relative',

    '& .MuiBreadcrumbs-separator': {
      color: '#303030',
    },

  },
  link: {
    display: 'flex',
    color: '#303030',
    position: 'relative',
    fontSize: 12,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: '#303030',
    position: 'relative',
  },

  topGrid: {
    overflow: 'hidden',




  },

  backgroundImg: {
    overflow: 'hidden',
    maxHeight: 250,
    minWidth: '100%',
  },


  deleteBtn: {

    color: '#ffffff',
    backgroundColor: '#F28F16',
    boxShadow: 'none',
    marginTop: '10%',
  },

  iconStyle: {

    '&:hover': {
      backgroundColor: 'transparent',
    }
  },

  headerStyle: {
    paddingTop: '227px',
    height: '300px',
    
  },

  secondHeaderStyle: {
    position: 'fixed',
    top: 64,
    backgroundColor: '#fafafa',
    height: '150px',
    width: '82%',
    zIndex: 20,
    paddingRight:'6px',
    [theme.breakpoints.down(1800)]: {
      width: '80.5%',
      
    },
    [theme.breakpoints.down(1700)]: {
      width: '79.5%',
      
    },
    [theme.breakpoints.down(1600)]: {
      width: '78%',
      
    },
    [theme.breakpoints.down(1500)]: {
      width: '76.5%',
      
    },
    [theme.breakpoints.down('md')]: {
      width: '73%',
     
     
      
    }

    
  }


}));

// const UnreadMessageIcon = observer((props: { onClick: (x) => void }) => {
//   const classes = useButtonStyle();
//   return <IconButton  color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={props.onClick}>
//     <Badge badgeContent={GlobalState.unread.length} color="secondary" style={{marginLeft: -20}}>


//       <Button variant='contained' className={classes.btnStyle} disableRipple={true} style={{ color: '#fff', backgroundColor: 'transparent', boxShadow: 'none' }}

//         startIcon={<MailOutline style={{fontSize: 25, pointerEvents: 'none'}} />}
//       >

//       </Button>
//     </Badge>

//   </IconButton>
// })


// const UnreadMessageList = observer(() => {
//   const classes = useStyles();
//   const [open, setOpen] = React.useState(false);


//   const anchorRef = React.useRef<HTMLButtonElement>(null);

//   const handleClose = (event: React.MouseEvent<EventTarget>) => {
//     if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
//       return;
//     }

//     setOpen(false);
//   };
//   return <div>
//     {
//       GlobalState.unread.length == 0 && <div>

// <Grid
//   container
//   direction="row"
//   justify="center"
//   alignItems="center"
//   style={{padding: 30}}
// >

//   <Grid item lg={12}>
//     <Typography align="center">
//   <Mail style={{fontSize: 50, color: '#efefef'}} />
//   </Typography>

//   </Grid>

//   <Grid item lg={12}>
//   <Typography align="center" variant="body2">No new messages!</Typography>

//     </Grid>

//   </Grid>


//       </div>
//     }
//     {GlobalState.unread.map((x, i) => {
//       return <div key={i}>
//         <MenuItem onClick={handleClose}>
//           <List dense={true} style={{
//             width: '100%',
//             minWidth: 360,
//           }}>


//             <ListItem>
//               <ListItemAvatar>
//                 <Avatar>
//                   <img src={men} alt="user-image" height="35" width="35" />
//                 </Avatar>
//               </ListItemAvatar>

//               <ListItemText primary="Mark" secondary={x['text']} />

//               <ListItemSecondaryAction><Typography variant="caption">12:45</Typography></ListItemSecondaryAction>

//             </ListItem>

//           </List>


//         </MenuItem>
//         <Divider />
//         <ListItem>
//           <ListItemText>
//             <Link href="#">
//               <Typography variant="body2">View All</Typography>
//             </Link>
//           </ListItemText>
//           <ListItemSecondaryAction>
//             <Link href="#">
//               <Typography variant="body2">Mark As Read</Typography>
//             </Link>
//           </ListItemSecondaryAction>

//         </ListItem>
//       </div>
//     })}

//   </div>
// })


export const Dashboard = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [pOpen, setPOpen] = useState(false)
  const [path, setPath] = useState('')







  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const breadClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  };


  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const p = window.location.pathname.split('/')[2]

  // useEffect(()=> {
  //   if(p != 'singlehoneypage'){
  //     setPath('')
  //   }else{
  //     setPath(p)
  //   }

  // },[])




  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            AHV Inventory Management System

          </Typography>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Search />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <div>

            {/* <UnreadMessageIcon onClick={handleClick} /> */}
            {/* notification bell */}
            {/* <NotificationsComponent /> */}

            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}p
              className={classes.menuTab}
            >

              <UnreadMessageList />

            </Menu> */}
          </div>

          <div style={{ marginLeft: '1%' }}>
            <img src={men} alt="profile" width="30" height="30" />
          </div>







        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={logo} alt="logo image" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List><Menus /></List>
        <Divider />
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>







      <main className={classes.content}   >
        <div className={classes.appBarSpacer} />
        {/* <Grid container  >
          <Grid item xs={12} md={12} lg={12} className={classes.topGrid} style={{ marginBottom: '14%', }} >

          </Grid>
        </Grid> */}
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}

            <Grid item xs={12}  >
              {/* <Typography variant="h5" className={classes.breadcrumbs}>Dashboard</Typography>
              <br />
              <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                <Link color="inherit" href="/" onClick={breadClick} className={classes.link}>
                  <HomeIcon className={classes.icon} />
                  Dashboard
                </Link>
                <Link
                  color="inherit"
                  href="/getting-started/installation/"
                  onClick={breadClick}
                  className={classes.link}
                >
                  <WhatshotIcon className={classes.icon} />

                  Products

                </Link>
                <Typography color="textPrimary" className={classes.link}>
                  <GrainIcon className={classes.icon} />
                  Add Product
                </Typography>
              </Breadcrumbs> */}
              <div className={p == 'singlehoneypage' || p == 'ibc' ? classes.secondHeaderStyle : classes.headerStyle} >
                <div style={{marginTop:p == 'singlehoneypage' || p == 'ibc' ? '65px' : '' }} >
                  <BreadCrumbsBar />
                </div>
              </div>
            </Grid>
            
            <div style={{marginTop:p == 'ibc' ? '5.5%' : ''}} >

            </div>

            <Router>
              <Grid item xs={12} style={{marginTop:p == 'singlehoneypage' || p == 'ibc' ? '330px' : '3px'}}>
                <Route path={Ways.beekeeperhoneydetailsPage} component={BeekeeperHoneyDetails} />
                <Route path={Ways.ibcPage} component={IBCSingleDetails} />
                <Route path={Ways.categoriesPage} component={AllCategoriesTable} />
                <Route path={Ways.productsPage} exact component={AllProductsTable} />
                <Route path={Ways.ordersPage} exact component={AllOrdersTable} />
                <Route path={Ways.quotePage} exact component={Quote} />
                <Route path={Ways.productEditPage} component={ProductLayout} />
                <Route path={Ways.productAddPage} component={ProductLayout} />
                <Route path={Ways.singleOrderPage} component={OrderLayout} />
                <Route path={Ways.singleOfferpage} component={Quote} />
                <Route path={Ways.offersPage} exact component={AllOffersTable} />
                <Route path={Ways.messagesPage} exact component={ChatLayout} />
                <Route path={Ways.allBuyersPage} component={AllBuyersTable} />
                <Route path={Ways.allbeekeepersPage} component={AllBeekeepers} />
                <Route path={Ways.beekeeperprofilePage} component={SellerProfileLayout} />
                <Route path={Ways.beekeeperhoneyPage} component={BeekeeperHoneyLayout} />
                <Route path={Ways.withdrawlsPage} component={WithdrawlsLayout} />
                <Route path={Ways.home} exact component={DashboardContent} />
                <Route path={Ways.honeyRates} exact component={HoneyRates} />

                <Route path={Ways.honeystockPage} exact component={HoneyLayout} />
                <Route path={Ways.honeysinglePage} component={SingleHoneyLayout} />
                <Route path={Ways.ibcs} component={HoneyIbcs} />
                <Route path={Ways.singleibcdetailsPage} exact component={HoneyLayout} />
                <Route path={Ways.beekeeperRequests} exact component={BeekeeperRequests} />

              </Grid>
            </Router>
          </Grid>
          <div  >
            <Box pt={4}>
              <Copyright />

            </Box>
          </div>
        </Container>
      </main>
    </div>
  );
}