import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import { Grid, FormControl, List, ListItem } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CircularProgress } from '@material-ui/core';
import { Ways, API, Cook } from '../Helpers';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import imgplaceholder from './../assets/imgplaceholder.jpg';
import docxImg from '../assets/fileDocx.png'
import pdfImg from '../assets/filePdf.png'
import firebase from 'firebase';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        width: '100%',
        // height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: 300,
        paddingTop: 10,
        paddingLeft: 15,

    },

    form: {
        width: '100%',
        '& > *': {
            margin: theme.spacing(1),
            minWidth: '100ch',
        },
    },
}));

export const Address = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const [certificate, setCertificate] = useState();

    const [Edit, setIsEdited] = useState(false);

    const [actualorganicCertificateUrl, SetActualorganicCertificateUrl] = useState('')

    const [actualtrademarkCertificate, setActualtrademarkCertificate] = useState('')


    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const [loading, setLoading] = useState(false);


    const [data, setData] = useState({});

    useEffect(() => {
        API.get('beekeeper', { beekeeper: window.location.pathname.split('/')[3] }).then(x => {
            setData(x.data);
            console.log(x.data)
            if (x.data['certifiedOrganicHoneyCertificate'] !== undefined) {
                // console.log(x.data['certifiedOrganicHoneyCertificate'])
                SetActualorganicCertificateUrl(x.data['certifiedOrganicHoneyCertificate'].split('?', 1).join('$').split('/').pop().split('.').pop())
            }
            if (x.data['trademarkCertificate'] !== undefined) {
                setActualtrademarkCertificate(x.data['trademarkCertificate'].split('?', 1).join('$').split('/').pop().split('.').pop())
            }

            console.log(x.data)
        })

    }, [])


    if (!data['id']) {
        return <Grid container justify="center" alignItems="center" style={{ paddingTop: '25%' }}>
            <CircularProgress style={{ color: '#f28f16' }} />
        </Grid>
    }





    return (
        <div className={classes.root}>


            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >

                <Tab label="Personal details" {...a11yProps(0)} />
                <Tab label="Address" {...a11yProps(1)} />
                <Tab label="Business Details" {...a11yProps(2)} />
                {/* <Tab label="Certificate" {...a11yProps(3)} /> */}

            </Tabs>



            <Grid container spacing={4} style={{ padding: 25, }}>
                <Grid item lg={12}>
                    <TabPanel value={value} index={0}>




                        <ValidatorForm onSubmit={async () => {
                            setLoading(true);

                        }}>


                            <div className={classes.root}>
                                <Grid container spacing={2} >

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['fullName']}
                                            id="amount"
                                            label="Name"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['phone']}
                                            id="amount"
                                            label="Phone"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            value={data['email']}
                                            // onChange={set}
                                            margin="dense"
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['Country is required']}
                                            label="Email"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            name={'country'}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            value={data['password']}
                                            // onChange={set}
                                            margin="dense"
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['Country is required']}
                                            label="Password"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            name={'country'}
                                            disabled
                                        />
                                    </Grid>



                                </Grid>







                            </div>
                        </ValidatorForm>

                    </TabPanel>



                    <TabPanel value={value} index={1}>




                        <ValidatorForm onSubmit={async () => {
                            setLoading(true);

                        }}>


                            <div className={classes.root}>
                                <Grid container spacing={2} >

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['street1']}
                                            id="amount"
                                            label="Street 1"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['street2']}
                                            id="amount"
                                            label="Street 2"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['city']}
                                            id="amount"
                                            label="City"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['state']}
                                            id="amount"
                                            label="State"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['zipcode']}
                                            id="amount"
                                            label="Zip Code"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>










                                </Grid>


                            </div>
                        </ValidatorForm>

                    </TabPanel>




                    <TabPanel value={value} index={2}>




                        <ValidatorForm onSubmit={async () => {
                            setLoading(true);

                        }}>


                            <div className={classes.root}>
                                <Grid container spacing={2} >

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'producesActiveHoney'}
                                            margin="dense"
                                            value={data['producesActiveHoney'] == true ? 'Yes' : 'No'}
                                            id="amount"
                                            label="I product active honey (TA or MGO) ?"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>


                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'registeredForGST'}
                                            margin="dense"
                                            value={data['registeredForGST'] == true ? 'Yes' : 'No'}
                                            id="amount"
                                            label="I am registered for GST (relevant for Australian users only)"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'producesPollinationServices'}
                                            margin="dense"
                                            value={data['producesPollinationServices'] == true ? 'Yes' : 'No'}
                                            id="amount"
                                            label="I provide pollination services"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>


                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'hospitalGradeHoney'}
                                            margin="dense"
                                            value={data['hospitalGradeHoney'] == true ? 'Yes' : 'No'}
                                            id="amount"
                                            label="I produce hospital grade active honey"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['bussinessTitle']}
                                            id="amount"
                                            label="What is your business name ?"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['numberOfHives']}
                                            id="amount"
                                            label="How many hives do you operate ?"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>




                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={data['honeyProductionVolume']}
                                            id="amount"
                                            label="What is your average honey production volume ?"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>



                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'honeySellingStatus'}
                                            margin="dense"
                                            // value={data['honeySellingStatus']}
                                            value={data['honeySellingStatus'] == true ? 'Yes' : 'No'}
                                            id="amount"
                                            label="Do you sell honey ?"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'certifiedOrganicHoneyCertificate'}

                                            margin="dense"
                                            // value={data['exportsInBulk']}
                                            value={data['certifiedOrganicHoneyCertificate'] == true ? 'Yes' : 'No'}
                                            id="amount"
                                            label="I have organic certification"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>



                                    <Grid item lg={12}>
                                        <TextValidator
                                            name={'hasTrademark'}
                                            margin="dense"
                                            // value={data['exportsInBulk']}
                                            value={data['hasTrademark'] == true ? 'Yes' : 'No'}
                                            id="amount"
                                            label="I have BQUAL or ISO 9001 certification"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>



                                    <Grid item xs={12} >
                                        <Typography variant='h6' >
                                            Documents:
                                        </Typography>
                                    </Grid>


                                    <Grid item xs={12} >
                                        {
                                            data['certifiedOrganicHoneyCertificate'] == "false" && data['trademarkCertificate'] == null ?
                                                <Typography style={{ color: 'gray' }}  >
                                                    No Documents
                                                </Typography>
                                                :

                                                <Grid container  >
                                                    {
                                                        data['certifiedOrganicHoneyCertificate'] != "false" &&
                                                        <Grid item xs={12} >
                                                            <div >

                                                                <Typography style={{ marginBottom: '10px' }} >
                                                                    Organic Honey Certificate
                                                                </Typography>

                                                                {

                                                                    actualorganicCertificateUrl === 'docx' ?
                                                                        <a href={data['certifiedOrganicHoneyCertificate']}>
                                                                            <img src={docxImg} style={{ width: 120 }} />
                                                                        </a>
                                                                        : actualorganicCertificateUrl === 'doc' ?
                                                                            <a href={data['certifiedOrganicHoneyCertificate']}>
                                                                                <img src={docxImg} style={{ width: 120 }} />
                                                                            </a>
                                                                            : actualorganicCertificateUrl === 'pdf' ?
                                                                                <a href={data['certifiedOrganicHoneyCertificate']}>
                                                                                    <img src={pdfImg} style={{ width: 88 }} />
                                                                                </a>
                                                                                :
                                                                                <a href={data['certifiedOrganicHoneyCertificate']}>
                                                                                    <img src={data['certifiedOrganicHoneyCertificate']} style={{ width: 120 }} />
                                                                                </a>

                                                                }

                                                            </div>
                                                        </Grid>
                                                    }

                                                    {
                                                        data['trademarkCertificate'] !== null &&
                                                        <Grid item xs={12} >
                                                            <div>
                                                                <Typography style={{ marginBottom: '10px', marginTop: '10px' }} >
                                                                    ISO 9001 Certificate
                                                                </Typography>
                                                                {
                                                                    actualtrademarkCertificate === 'docx' ?

                                                                        <a href={data['trademarkCertificate']}>
                                                                            
                                                                            <img  src={docxImg} style={{ width: 120 }} />
                                                                        </a>

                                                                        : actualtrademarkCertificate === 'doc' ?
                                                                        <a href={data['trademarkCertificate']}>

                                                                            <img src={docxImg} style={{ width: 120 }} />
                                                                        </a>

                                                                            :
                                                                            actualtrademarkCertificate === 'pdf' ?
                                                                            <a href={data['trademarkCertificate']}>

                                                                                <img src={pdfImg} style={{ width: 88 }} />
                                                                            </a>



                                                                                :
                                                                                <a href={data['trademarkCertificate']}>

                                                                                    <img src={data['trademarkCertificate']} style={{ width: 120 }} />
                                                                                </a>

                                                                }
                                                            </div>
                                                        </Grid>
                                                    }


                                                </Grid>

                                        }

                                    </Grid>


                                    {/* <Grid item lg={6}>
                                        <List style={{ display: 'flex', flexDirection: 'row' }}>
                                            <ListItem>
                                                Organic Honey Certificate
                                            </ListItem>
                                            <ListItem>
                                                ISO 9001 Certificate
                                            </ListItem>
                                        </List>
                                        <List style={{ display: 'flex', flexDirection: 'row' }}>
                                            <ListItem style={{ border: '1px solid #efefef' }}>

                                               
                                                {

                                                    actualorganicCertificateUrl === 'docx' ?
                                                        <img src={docxImg} style={{ height: 160, width: 184, }} />
                                                        : actualorganicCertificateUrl === 'doc' ?
                                                            <img src={docxImg} style={{ height: 160, width: 184, }} />
                                                            : actualorganicCertificateUrl === 'pdf' ?
                                                                <img src={pdfImg} style={{ height: 160, width: 184, }} />
                                                                :
                                                                <img src={data['certifiedOrganicHoneyCertificate']} style={{ height: 160, width: 184, }} />

                                                }

                                            </ListItem>
                                            <ListItem style={{ border: '1px solid #efefef' }}>


                                                

                                                {
                                                    actualtrademarkCertificate === 'docx' ?

                                                        <img src={docxImg} style={{ height: 160, width: 184, }} />

                                                        : actualtrademarkCertificate === 'doc' ?

                                                            <img src={docxImg} style={{ height: 160, width: 184, }} />

                                                            :
                                                            actualtrademarkCertificate === 'pdf' ?

                                                                <img src={pdfImg} style={{ height: 160, width: 184, }} />


                                                                :
                                                                <img src={data['trademarkCertificate']} style={{ height: 160, width: 184, }} />

                                                }

                                            </ListItem>
                                        </List>
                                    </Grid> */}





                                </Grid>


                            </div>
                        </ValidatorForm>

                    </TabPanel>










                    <TabPanel value={value} index={3}>




                        <ValidatorForm onSubmit={async () => {
                            setLoading(true);

                        }}>


                            <div className={classes.root}>
                                <Grid container spacing={3} >

                                    <Grid item lg={8}>


                                        {/* <TextValidator
                                        name={'zipcode'}
                                        margin="dense"
                                        // value={data['exportsInBulk']}
                                        value={data['exportsInBulk'] == true ? 'Yes' : 'No'}
                                        id="amount"
                                       
                                        // onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    /> */}

                                        <TextValidator
                                            name={'certifiedOrganicHoneyCertificate'}

                                            margin="dense"
                                            // value={data['exportsInBulk']}
                                            value={data['certifiedOrganicHoneyCertificate'] == true ? 'Yes' : 'No'}
                                            id="amount"
                                            label="I have organic certification"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                        <br /><br />


                                        <TextValidator
                                            name={'hasTrademark'}
                                            margin="dense"
                                            // value={data['exportsInBulk']}
                                            value={data['hasTrademark'] == true ? 'Yes' : 'No'}
                                            id="amount"
                                            label="I have BQUAL or ISO 9001 certification"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />

                                        <br /><br />


                                        <TextValidator
                                            name={'countries'}
                                            margin="dense"
                                            // value={data['exportsInBulk']}
                                            value={data['countries'] > 1 ? 'Yes' : 'No'}
                                            id="amount"
                                            label="Do you have experience supplying honey to export markets - if so what regions ?"
                                            // onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                        <br />
                                        <Typography variant="body2" >{data['countries'].length > 1 ? data['countries'] : <div></div>}</Typography>

                                        {/* { data['accreditationCertificate'] == true ? <div>Kamran </div> : <div>Kamran Asif</div>} */}







                                        {/* <br /><br />



<TextValidator
                                        name={'exportsCertifiedOrganicHoney'}
                                        margin="dense"
                                        // value={data['exportsInBulk']}
                                        value={data['exportsCertifiedOrganicHoney'] == true ? 'Yes' : 'No'}
                                        id="amount"
                                       label="Do you have any experience supplying honey to export markets - if so what regions ?"
                                        // onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    />
<br /><br /> */}
                                        {/* <Typography variant="h6">Do you have certificate for your business ?</Typography>

<TextValidator
                                        name={'honeyCertificationStatus'}
                                        margin="dense"
                                        value={data['honeyCertificationStatus'] == true ? 'Yes' : 'No'}
                                        id="amount"
                                       
                                        // onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    /> */}

                                    </Grid>
                                    <Grid item lg={4}>
                                        <Typography variant="h6">Certificates</Typography>
                                        {data['hasTrademark'] == true ?
                                            <img src={data['trademarkCertificate']} style={{ height: 120, width: 120, marginRight: 10 }} />
                                            :
                                            <div>

                                            </div>
                                        }


                                        {data['certifiedOrganicHoneyCertificate'] == true ?

                                            <img src={data['certifiedOrganicHoneyCertificate']} style={{ height: 150, width: 150, }} />
                                            :
                                            <div></div>
                                        }


                                    </Grid>

                                    <Grid item lg={6}>



                                    </Grid>


                                </Grid>


                            </div>
                        </ValidatorForm>

                    </TabPanel>



                </Grid>
            </Grid>





        </div>
    );
}
