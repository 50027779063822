import React, { useEffect, useState, FunctionComponent } from 'react';
import { Grid, Button, FormControl, MenuItem, Select, InputLabel, Typography, CircularProgress, Card, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import { Storage } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { API, Cook } from './../Helpers';
import purse from './../assets/purse.png';
import dollar from './../assets/dollar.png';
import { IBCForSingleBeekeeperTable } from '../Tables/IBCForSingleBeekeeperTable';
import { useSnackbar } from 'notistack';
import { BeekeeperHoneyFeatures } from './BeekeeperHoneyFeatures';




const useStyles = makeStyles({

  actionArea: {

    '&.MuiCardActionArea-root': {

      textAlign: 'center',


    },

  },

  btnStyle: {

    padding: 15,
  },


  root: {
    maxWidth: 530,
    boxShadow: 'none',
    backgroundColor: '#fff',
    paddingTop: 50,
    paddingBottom: 50,
    pointerEvents: 'none',


  },
  media: {


  },

  imgStyle: {

    maxWidth: 120,


  },

  mainHeading: {

    color: '#303030',
    fontSize: 38,
    fontWeight: 600,


  },


  descriptionStyle: {


    fontSize: '1rem',
    fontWeight: 500,
    color: '#726f6f',

  },


});



export const BeekeeperHoneyLayout: FunctionComponent<{ currentBEEKEEPER: any }> = props => {
  const classes = useStyles();




  const [loading, setLoadin] = useState(false);

  const [data, setData] = useState<any[]>([])
  const [totalPayment  , setTotalPayment] = useState(0);

  const [holding, setHolding] = useState<any[]>([])

  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    API.get('beekeeper/myibcs', { beekeeper: window.location.pathname.split('/')[3] }).then(x => {
      setData(x.data['ibcs']);
      setTotalPayment(x.data['payment'])
      // console.log(x.data);
    })

    //   API.get('ibcs', {beekeeper: window.location.pathname.split('/')[3]}).then(z => {
    //     setHolding(z.data);
    //     console.log(z.data);
    // })



  }, [])



  let id: number = 1
  const getID = () => {
    return id++;
  }












  return (
    <div style={{ marginTop: '-6%' }}>


      <br />
      <IBCForSingleBeekeeperTable currentBEEKEEPER={data} totalData={{
        totalRemaining: data.map(x => x.remaining != null ? x.remaining : x.payLoad).reduce((a, b) => a + b, 0), totalPayload: data.map(x => x.payLoad).reduce((a, b) => a + b, 0), totalPayment: totalPayment,
        totalSold: data.map(x => x.payLoad).reduce((a, b) => a + b, 0) - data.map(x => x.remaining != null ? x.remaining : x.payLoad).reduce((a, b) => a + b, 0)
      }} />
      <br />
      {/* <BeekeeperHoneyFeatures /> */}



      


    </div>
  )
}