
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Ways, API, Cook, getvalue, colorset, currentQuarter } from '../Helpers';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Grid, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { QuarterSelect } from "../Components/QuarterSelect";
import { QuarterSelector } from "./Quarter";

// Resolves charts dependancy
charts(FusionCharts);






export const StackedChart: FunctionComponent<{ currentData: any }> = props => {

  const [quarter, setQuarter] = useState<any>(moment().subtract(6, 'months').quarter());
  const [year, setYear] = useState<number>(currentQuarter().y)
  const [chartData, setChartData] = useState<any[]>([])
  const [months, setMonths] = useState<any[]>([])
  // const [month, setMonth] = useState<number>()




  const { enqueueSnackbar } = useSnackbar();


  const getData = async (year: number, quarter: any) => {
    // setYear(year)
    // setQuarter(quarter)
    var res = await API.get('admin/charts/recent', { year: year, quarter: quarter });
    // var x = await API.get('admin/charts/recent', { year: year, quarter: quarter });

    if (res.status == 200) {
      // window.location.reload();
      var serverData = res.data;
      var totalVarieties: any[] = [];
      var allMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(ee => ee.trim().toLowerCase());

      var months = Object.keys(serverData)

      months = months.sort((a, b) => {
        return allMonths.indexOf(a) - allMonths.indexOf(b);
      })

      setMonths(months.map(aa => {
        return {
          label: aa

        }
      }))

      // console.log(serverData)
      // console.log(months)
      months.forEach(singleMonth => {
        var catsForThisMonth = Object.keys(serverData[singleMonth]);
        totalVarieties = [...totalVarieties, ...catsForThisMonth]
      })

      var uniqueVarieties = totalVarieties.filter(onlyUnique)

      console.log(uniqueVarieties)
      var finalData: any[] = [];

      for (let i = 0; i < uniqueVarieties.length; i++) {


        const ver = uniqueVarieties[i];



        var obj: {
          data: any[],
          seriesName: string,
          color:any
        } = {
          seriesName: ver,
          data: [],
          color:colorset[i]
        };



        for (let j = 0; j < months.length; j++) {
          const mon = months[j];
          obj.data = [...obj.data, { value: (serverData[mon][ver] / 1000), color: colorset[i] }]
        }




        finalData.push(obj)

      }

      // console.log(finalData)
      // console.log(finalData)
      setChartData(finalData)

      return res.data;
    }
    else {
      enqueueSnackbar('data request failed!')
    }
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }


  useEffect(() => {

    getData(year, quarter)



  }, [])




  const dataSource = {

    // xAxis: {
    //   binning: {

    //     "month": [1], // Ex - [1,2,3,4,6]

    //   },
    // },


    chart: {
      // caption: "AHV Honey Stocks - varieties available by quarter (t)",
      // subcaption: "",
      //  numbersuffix: "(kg)",
      // formatNumber: true,

      // yAxisValueDecimals:0.100,
      // forceNumberScale:true,
      defaultNumberScale: 't',
      numberScaleValue: '0',
      numberScaleUnit: 't',
      // forceDecimals:true,




      showsum: "1",
      // yAxisName:'varieties in (t)',
      plottooltext:
        ` $label <b>$dataValue</b> of Honey added in $seriesName`,
      theme: "fusion",
      drawcrossline: "",
      bgColor: "#ffffff",
      bgAlpha: "50",
      borderRadius: "8",
      showBorder: "1",
      borderColor: "#ffffff",
      labelFormate: '$dataValue',

    },
    // categories : [],
    categories: [
      {
        category: months
      }
    ],
    dataset: true ? chartData : [
      {
        seriesName: 'Jarrah',
        data: [
          {
            value: 10,
          },
          {
            value: 20
          }
        ]
      }
    ]

  };

  return <div style={{ backgroundColor: '#fff', paddingTop: 30,paddingLeft:'10px',paddingRight:'10px' }}>

    <ValidatorForm onSubmit={async () => {
    }}>


      <QuarterSelector onChange={(data) => {
        getData(data.y, data.q);
      }} />

      {/* <Grid container direction="row" spacing={2} style={{ paddingRight: 30, }}>
        <Grid item lg={4}></Grid>
        <Grid item lg={4}>

          <SelectValidator name='year' variant="outlined" size="small" value={year} style={{ width: '100%' }}
            onChange={async (x) => {
              var y = Number(x.target['value'])
              var d = await getData(y, d)
            }}
            label="Select Financial Yearrr"



            validators={['required']}
            errorMessages={['this field is required']}

          >

            <MenuItem value={(new Date().getFullYear()) - 2}>{(new Date().getFullYear()) - 2}</MenuItem>
            <MenuItem value={(new Date().getFullYear()) - 1}>{(new Date().getFullYear()) - 1}</MenuItem>
            <MenuItem value={new Date().getFullYear()}  >{new Date().getFullYear()}</MenuItem>
            <MenuItem value={new Date().getFullYear()+1}  >{new Date().getFullYear()+1}</MenuItem>

          </SelectValidator>

        </Grid>

        <Grid item lg={4}>

          <SelectValidator name='quarterData' variant="outlined" size="small" value={quarter} style={{ width: '100%' }}

            onChange={async (x) => {
              var q = Number(x.target['value'])
              var d = await getData(year ?? 0, q)
            }}
            label="Select Quarter"




            validators={['required']}
            errorMessages={['this field is required']}

          >
            <MenuItem value={1}>Jul - Sep</MenuItem>
            <MenuItem value={2}>Oct - Dec</MenuItem>
            <MenuItem value={3}>Jan - Mar</MenuItem>
            <MenuItem value={4}>Apr - Jun</MenuItem>

          </SelectValidator>
        </Grid>
      </Grid> */}


    </ValidatorForm>
    <br />
    <p style={{fontSize:'18px',fontWeight:500,textAlign:'center'}} >
      AHV Honey Stocks - varieties available by quarter (t)
    </p>
    <ReactFusioncharts
      type="stackedcolumn2d"
      width="100%"
      height="40%"
      dataFormat="JSON"
      dataSource={dataSource}
      
    />
  </div>
}



export default StackedChart;