import React, { useState, FunctionComponent, useEffect } from 'react';
import { Grid, Button, FormControl, MenuItem, Select, InputLabel, Typography, Paper, List, ListItem, CircularProgress, Tabs, Tab, Box, ListItemSecondaryAction, IconButton, ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SelectValidator, TextValidator, ValidatorForm, } from "react-material-ui-form-validator";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { API, Ways, getvalue } from '../Helpers';
import { Edit } from '@material-ui/icons';
import { PhotoCamera } from '@material-ui/icons'
import { useSnackbar } from 'notistack';
import { createBrowserHistory } from 'history';
import firebase from 'firebase';
import imgplaceholder from './../assets/imgplaceholder.jpg';
import { ImgUpload } from './ImgUpload';
import ImageUploading, { ImageListType } from "react-images-uploading";
import docxImg from '../assets/fileDocx.png'
import pdfImg from '../assets/filePdf.png'

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    content: {

      maxHeight: 800,
    },

    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      width: '100%',
      // height: 224,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      width: '100%',
      paddingTop: 10,
      paddingLeft: 15,

    },

    form: {
      width: '100%',
      '& > *': {
        margin: theme.spacing(1),
        minWidth: '100ch',
      },
    },


  }),
);



export const EditableSellerProfile: FunctionComponent<{ onAdded?: () => void, currentProfile?: any }> = props => {




  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    setImages(imageList as never[]);
  };




  //   const [form, setForm] = useState<any>(props.currentProfile ?? {});
  const [fullWidth, setFullWidth] = React.useState(true);



  const [data, setData] = useState<any>({});
  const set = (e: React.FormEvent) => {
    var n = e.target['name'];
    var v = e.target['value'];
    data[n] = v;
    setData({ ...data })
  }


  useEffect(() => {

    if (props.currentProfile != null) {
      setData(props.currentProfile)
    }
  }, [])




  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullWidth(event.target.checked);
  };

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [adding, setAdding] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const [actualorganicCertificateUrl, SetActualorganicCertificateUrl] = useState('')

  const [actualtrademarkCertificate, setActualtrademarkCertificate] = useState('')


  useEffect(() => {
    API.get('beekeeper', { beekeeper: window.location.pathname.split('/')[3] }).then(x => {
      setData(x.data);
      if (x.data['certifiedOrganicHoneyCertificate'] !== undefined) {
        // console.log(x.data['certifiedOrganicHoneyCertificate'])
        SetActualorganicCertificateUrl(x.data['certifiedOrganicHoneyCertificate'].split('?', 1).join('$').split('/').pop().split('.').pop())
      }
      if (x.data['trademarkCertificate'] !== undefined) {
        setActualtrademarkCertificate(x.data['trademarkCertificate'].split('?', 1).join('$').split('/').pop().split('.').pop())
      }
    })
  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);


  function refreshPage() {
    window.location.reload();
  }

  const [value, setValue] = useState(0);

  const [Edit, setIsEdited] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };



  return (
    <div style={{ marginTop: '-4%' }}>

      <Grid container>
        <Grid item lg={12} xs={12}>


          <Button variant="contained" style={{ backgroundColor: '#f28f16', color: '#fff', margin: '0px' }} onClick={handleClickOpen}>
            Edit Profile
          </Button>



          <Dialog


            fullScreen={fullScreen}
            open={open}
            maxWidth="lg"
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"

          >
            <DialogTitle id="responsive-dialog-title">{"Edit Profile"}</DialogTitle>
            <DialogContent style={{ minWidth: 1000, maxWidth: 1000, maxHeight: 750 }}>



              <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
              >
                <Tab label="Personal Details" {...a11yProps(0)} />
                <Tab label="Address" {...a11yProps(1)} />
                <Tab label="Business Details" {...a11yProps(2)} />
                {/* <Tab label="Certificate" {...a11yProps(3)} /> */}

              </Tabs>



              <ValidatorForm onSubmit={async () => {
                //  setLoading(true);
                // API.delete('category', { categoryid: getvalue(ctd , 'id') })
                // API.put('beekeeper', data, { beekeeper: 'id'})

                // API.put('beekeeper' , data , {beekeeper : 'id'})


                if (props.currentProfile == null) {
                  API.post('beekeeper', data)
                }
                else {
                  API.put('beekeeper', data, { beekeeper: data['id'] }).then(x => {
                    if (x.status == 200) {
                      window.location.reload();
                      enqueueSnackbar('Beekeeper profile is updated');


                    }
                  }
                  )
                }





              }}>

                <TabPanel value={value} index={0}>



                  <Grid container spacing={2} >


                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        name={'fullName'}
                        margin="dense"
                        value={data['fullName']}
                        id="amount"
                        label="Full Name"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        name={'phone'}
                        margin="dense"
                        value={data['phone']}
                        id="amount"
                        label="Phone"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        value={data['email']}
                        onChange={set}
                        margin="dense"
                        id="amount"
                        // validators={['required']}
                        errorMessages={['Country is required']}
                        label="Email"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name={'email'}

                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        value={data['password']}
                        onChange={set}
                        margin="dense"
                        id="amount"
                        // validators={['required']}
                        errorMessages={['Password is required']}
                        label="Password"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name={'password'}

                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      {/* <TextValidator
                                        name={'summary'}
                                        maxlength='250'
                                        value= {data['summary']}
                                        id="amount"
                                        label="Summary"
                                        multiLine
                                        rows={12}
                                         onChange={set}
                                        // validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                       
                                    /> */}
                      <TextValidator
                        variant="outlined"
                        required
                        size="small"
                        fullWidth
                        multiline
                        maxlength='250'
                        rows={5}
                        value={data['summary']}

                        errorMessages={['this field is required']}
                        id="shortDescription"
                        label="Summary"
                        onChange={set}
                        name="summary"

                      />
                    </Grid>



                  </Grid>



                </TabPanel>
                <TabPanel value={value} index={1}>


                  <Grid container spacing={2} >

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        name={'street1'}
                        margin="dense"
                        value={data['street1']}
                        id="amount"
                        label="Street 1"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        name={'street2'}
                        margin="dense"
                        value={data['street2']}
                        id="amount"
                        label="Street 2"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        name={'zipcode'}
                        margin="dense"
                        value={data['zipcode']}
                        id="amount"
                        label="Zip Code"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        name={'state'}
                        margin="dense"
                        value={data['state']}
                        id="amount"
                        label="State"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        name={'city'}
                        margin="dense"
                        value={data['city']}
                        id="amount"
                        label="City"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>


                  </Grid>



                </TabPanel>
                <TabPanel value={value} index={2}>


                  <Grid container spacing={2} >

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        name={'bussinessTitle'}
                        margin="dense"
                        value={data['bussinessTitle']}
                        id="amount"
                        label="What is your business name ?"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextValidator
                        name={'numberOfHives'}
                        margin="dense"
                        value={data['numberOfHives']}
                        id="amount"
                        label="How many hives do you operate ?"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>




                    {/* <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'honeyProductionVolume'}
                                        margin="dense"
                                        value={data['honeyProductionVolume']}
                                        id="amount"
                                        label="What is your average honey production volume"
                                        onChange={set}
                                        // validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    
                                    />
                                </Grid> */}

                    <Grid item lg={12} xs={12}>


                      <SelectValidator name='honeyProductionVolume' value={data['honeyProductionVolume']} variant="outlined" size="small" style={{ width: '100%' }}

                        label="What is your average honey production volume"
                        onChange={set}
                        errorMessages={['this field is required']}

                      >
                        <MenuItem value={'~ Less than 500 KG'}>~ Less than 500 KG</MenuItem>
                        <MenuItem value={'~ 500 KG - 1.5 tonne than 500 KG'}>~ 500 KG - 1.5 tonne</MenuItem>
                        <MenuItem value={'~ 1.5 tonne -10 tonne'}>~ 1.5 tonne -10 tonne</MenuItem>
                        <MenuItem value={'~ 10 tonne - 20 tonne'}>~ 10 tonne - 20 tonne</MenuItem>
                        <MenuItem value={'~ 20 tonne PLUS'}>~ 20 tonne PLUS</MenuItem>



                      </SelectValidator>
                    </Grid>

                    <Grid item lg={12} xs={12}>


                      <SelectValidator name='producesActiveHoney' value={data['producesActiveHoney']} variant="outlined" size="small" style={{ width: '100%' }}

                        label="I produce active honey (TA or MGO)"
                        onChange={set}
                        errorMessages={['this field is required']}

                      >
                        <MenuItem value={'true'}>Yes</MenuItem>
                        <MenuItem value={'false'}>No</MenuItem>



                      </SelectValidator>
                    </Grid>

                    <Grid item lg={12} xs={12}>


                      <SelectValidator name='isBussinessAccreditated' value={data['isBussinessAccreditated']} variant="outlined" size="small" style={{ width: '100%' }}

                        label="Is your business accrediated to a national/international quality standard ?"
                        onChange={set}
                        errorMessages={['this field is required']}

                      >
                        <MenuItem value={'true'}>Yes</MenuItem>
                        <MenuItem value={'false'}>No</MenuItem>



                      </SelectValidator>
                    </Grid>


                    <Grid item lg={12} xs={12}>
                      <SelectValidator name='registeredForGST' value={data['registeredForGST']} variant="outlined" size="small" style={{ width: '100%' }}

                        label="I am registered for GST (relevant for Australian users only)"
                        onChange={set}
                        errorMessages={['this field is required']}

                      >
                        <MenuItem value={'true'}>Yes</MenuItem>
                        <MenuItem value={'false'}>No</MenuItem>



                      </SelectValidator>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <SelectValidator name='producesPollinationServices' value={data['producesPollinationServices']} variant="outlined" size="small" style={{ width: '100%' }}

                        label="I provide polination services"
                        onChange={set}
                        errorMessages={['this field is required']}

                      >
                        <MenuItem value={'true'}>Yes</MenuItem>
                        <MenuItem value={'false'}>No</MenuItem>



                      </SelectValidator>
                    </Grid>


                    <Grid item lg={12} xs={12}>
                      <SelectValidator name='hospitalGradeHoney' value={data['hospitalGradeHoney']} variant="outlined" size="small" style={{ width: '100%' }}

                        label="I produce hospital grade active honey"
                        onChange={set}
                        errorMessages={['this field is required']}

                      >
                        <MenuItem value={'true'}>Yes</MenuItem>
                        <MenuItem value={'false'}>No</MenuItem>



                      </SelectValidator>
                    </Grid>
                    <Grid item lg={12}>
                      <SelectValidator name='exportsCertifiedOrganicHoney' value={data['exportsCertifiedOrganicHoney']} variant="outlined" size="small" style={{ width: '100%' }}

                        label="I have organic certification"
                        disabled
                        onChange={set}
                        errorMessages={['this field is required']}

                      >
                        <MenuItem value={'true'}>Yes</MenuItem>
                        <MenuItem value={'false'}>No</MenuItem>



                      </SelectValidator>
                    </Grid>

                    <Grid item lg={12}>
                      <SelectValidator name='hasTrademark' value={data['hasTrademark']} variant="outlined" size="small" style={{ width: '100%' }}
                        disabled
                        label="I have BQUAL or ISO 9001 certification"
                        onChange={set}
                        errorMessages={['this field is required']}

                      >
                        <MenuItem value={'false'}>No</MenuItem>
                        <MenuItem value={'true'}>Yes</MenuItem>



                      </SelectValidator>
                    </Grid>

                    <Grid item lg={6}>
                      <List style={{ display: 'flex', flexDirection: 'row' }}>
                        <ListItem>
                          Organic Honey Certificate
                        </ListItem>
                        <ListItem>
                          ISO 9001 Certificate
                        </ListItem>
                      </List>
                      <List style={{ display: 'flex', flexDirection: 'row' }}>
                        <ListItem style={{ border: '1px solid #efefef' }}>

                          {/* {data['exportsCertifiedOrganicHoney'] == true ?

                            <img src={data['certifiedOrganicHoneyCertificate']} style={{ height: 160, width: 184, }} />
                            :
                            <img src={imgplaceholder} style={{ height: 160, width: 184, }} />
                          } */}

                          {

                            actualorganicCertificateUrl === 'docx' ?
                              <img src={docxImg} style={{ height: 160, width: 184, }} />
                              : actualorganicCertificateUrl === 'doc' ?
                                <img src={docxImg} style={{ height: 160, width: 184, }} />
                                : actualorganicCertificateUrl === 'pdf' ?
                                  <img src={pdfImg} style={{ height: 160, width: 184, }} />
                                  :
                                  <img src={data['certifiedOrganicHoneyCertificate']} style={{ height: 160, width: 184, }} />

                          }

                        </ListItem>
                        <ListItem style={{ border: '1px solid #efefef' }}>


                          {/* {data['hasTrademark'] == true ?

                            <img src={data['trademarkCertificate']} style={{ height: 160, width: 184, }} />
                            :
                            <img src={imgplaceholder} style={{ height: 160, width: 184, }} />
                          } */}

                          {
                            actualtrademarkCertificate === 'docx' ?

                              <img src={docxImg} style={{ height: 160, width: 184, }} />

                              : actualtrademarkCertificate === 'doc' ?

                                <img src={docxImg} style={{ height: 160, width: 184, }} />

                                :
                                actualtrademarkCertificate === 'pdf' ?

                                  <img src={pdfImg} style={{ height: 160, width: 184, }} />


                                  :
                                  <img src={data['trademarkCertificate']} style={{ height: 160, width: 184, }} />

                          }

                        </ListItem>
                      </List>
                    </Grid>





                    <Grid item lg={12} xs={12}>




                      {/* <TextValidator
                                        name={'honeySellingStatus'}
                                        margin="dense"
                                        value={data['honeySellingStatus']}
                                        id="amount"
                                        label="Do you sell honey ?"
                                        onChange={set}
                                         validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    
                                    /> */}
                      {/* <SelectValidator  name='honeySellingStatus' value={data['honeySellingStatus']} variant="outlined" size="small" style={{ width: '100%' }}
                                                        
                                                        label="Do you sell honey ?"
                                                      onChange={set}
                                                        errorMessages={['this field is required']}
                                  
                                                      >
                                                        <MenuItem value={'true'}>Yes</MenuItem>
                                                        <MenuItem value={'false'}>No</MenuItem>
                                  
                                  
                                  
                                                      </SelectValidator> */}
                    </Grid>

                  </Grid>



                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Grid container spacing={2} >






                    {/* <Grid item lg={6}>
 <Typography variant="h6">Is your business setup for bulk export ?</Typography>

 <SelectValidator  name='certifiedOrganicHoneyCertificate' value={data['certifiedOrganicHoneyCertificate']} variant="outlined" size="small" style={{ width: '100%' }}
                      
                      label="I have organic certification"
                    onChange={set}
                      errorMessages={['this field is required']}

                    >
                      <MenuItem value={'true'}>Yes</MenuItem>
                      <MenuItem value={'false'}>No</MenuItem>



                    </SelectValidator>
 <br /><br />


<SelectValidator  name='exportsInBulk' value={data['exportsInBulk']} variant="outlined" size="small" style={{ width: '100%' }}
                      
                      label="Yes/No"
                    onChange={set}
                      errorMessages={['this field is required']}

                    >
                      <MenuItem value={'true'}>Yes</MenuItem>
                      <MenuItem value={'false'}>No</MenuItem>



                    </SelectValidator>
 <br /><br />

<SelectValidator  name='hasTrademark' value={data['hasTrademark']} variant="outlined" size="small" style={{ width: '100%' }}
                      
                      label="I have BQUAL or ISO 9001 certification"
                    onChange={set}
                      errorMessages={['this field is required']}

                    >
                     <MenuItem value={'false'}>No</MenuItem>
                      <MenuItem value={'true'}>Yes</MenuItem>



                    </SelectValidator>

                    <br /><br />
 <Typography variant="subtitle2">Do you have experience supplying honey to export markets - if so what regions ?</Typography>
 <br />


<SelectValidator  name='countries'   value={data['countries'] > 1 ? true : false} variant="outlined" size="small" style={{ width: '100%' }}
                      
                      label="Yes/No"
                    onChange={set}
                      errorMessages={['this field is required']}

                    >
                     <MenuItem value={'true'}>Yes</MenuItem>
                      <MenuItem value={'false'}>No</MenuItem>



                    </SelectValidator>



              
 <br /><br />


 
 <Typography variant="h6">My business has the legal right to use the umg tradmark. My business is not approved to use the umg tradmark</Typography>
<SelectValidator  name='hasTrademark' value={data['hasTrademark']} variant="outlined" size="small" style={{ width: '100%' }}
                      
                      label=""
                    onChange={set}
                      errorMessages={['this field is required']}

                    >
                     <MenuItem value={'false'}>No</MenuItem>
                      <MenuItem value={'true'}>Yes</MenuItem>



                    </SelectValidator>


                                     
 
 </Grid> */}
                    <Grid item lg={6}>
                      <Typography variant="h6">Certificates</Typography>
                      {data['hasTrademark'] == true ?
                        <img src={data['trademarkCertificate']} style={{ height: 120, width: 120, marginRight: 10 }} />
                        :
                        <div>

                        </div>
                      }


                      {data['certifiedOrganicHoneyCertificate'] == true ?

                        <img src={data['certifiedOrganicHoneyCertificate']} style={{ height: 150, width: 150, }} />
                        :
                        <div></div>
                      }




                      {/* 
<ImageUploading
        multiple
        value={images}
        onChange={onChange}
       
        maxNumber={maxNumber}
      >
        {({
          imageList,
          onImageUpload,
        
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div>
            <Button
            variant="contained"
            size="small"
            //   style={isDragging ? { color: "red" } : undefined}
            style={{color: '#fff', backgroundColor: '#f28f16', marginBottom: 3}}
              onClick={onImageUpload}

              {...dragProps}
            >
             Upload
            </Button>
            
            &nbsp;
           
            {imageList.map((image, index) => (
              <div key={index}>
                <img onChange={set} src={image.dataURL} alt="" width="100" />
                <div >
               
                  <Button size="small" variant="contained" style={{color: '#fff', backgroundColor: '#ff0000'}} onClick={() => onImageRemove(index)}>Remove</Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading> */}





                    </Grid>

                    <Grid item lg={6}>



                    </Grid>

                  </Grid>


                </TabPanel>
                <Button autoFocus type="submit" variant="contained" style={{ color: '#fff', backgroundColor: '#f28f16', marginLeft: 20 }} color="primary">
                  Update
                </Button>
              </ValidatorForm>

            </DialogContent>
            <DialogActions>


            </DialogActions>
          </Dialog>

        </Grid>

      </Grid>





    </div>
  )
}






