import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';
import { Socket } from './Helpers';

import {SetupFirebase} from './FirebaseSetup';



ReactDOM.render(
  <SnackbarProvider>
    <App />

  </SnackbarProvider>,
  document.getElementById('root')
  );
  
  serviceWorker.unregister();
  
  Socket.initialize();
  SetupFirebase();