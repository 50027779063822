import React, { useState, useEffect, FunctionComponent } from 'react';
import { Grid, Paper, Typography, List, ListItem, Button } from '@material-ui/core';
import { Profile } from './Profile';
import { ProfileDescription } from './ProfileDescription';
import { Address } from './Address';
import { API } from './../Helpers';
import { EditableSellerProfile } from './EditableSellerProfile'




export const SellerProfileLayout: FunctionComponent = () => {
    const [eprofile, seteProfile] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [edata, seteData] = useState<any[]>([]);

    useEffect(() => {
        API.get('beekeepers').then(x => {
            setData(x.data);
        })
    }, [])

    return (
        <div  >
            <Grid container spacing={2}>
                <Grid item lg={10}></Grid>
                <Grid item lg={2}>
                    <EditableSellerProfile currentProfile={data} />
                </Grid>
                

                <Grid item lg={3} xs={12} style={{ marginTop: '0.5%' }}>

                    <Paper style={{ boxShadow: 'none', padding: '2%', marginTop: '2%', height: 400 }}>
                        <Profile />
                    </Paper>
                </Grid>

                <Grid item lg={9} xs={12}>
                    <Paper style={{ boxShadow: 'none', padding: '2%', marginTop: '2%', height: 400 }}>
                        <ProfileDescription />
                    </Paper>

                </Grid>

                <Grid item lg={12} xs={12} style={{ paddingLeft: '1.5%', paddingTop: '2%' }}>

                    <Typography variant="h6">Other Profile Settings</Typography>

                </Grid>

                <Grid item lg={12}>
                    <Paper style={{ boxShadow: 'none' }}>
                        <Address />
                    </Paper>
                </Grid>


            </Grid>
        </div>
    )
}