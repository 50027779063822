import React, { useState, useEffect, FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import { ListItem, List, Typography, Grid, Paper, ListItemText, CircularProgress, Container, ListItemSecondaryAction, Card, CardActionArea, CardMedia, CardContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Ways, API, Cook, getvalue } from './../../Helpers';
import { database } from 'firebase';
import { Storage } from '@material-ui/icons';
import dollar from './../../assets/dollar.png';
import purse from './../../assets/purse.png';
import { WithdrawPopup } from '../../Withdrawl/WithdrawPopup';
import funnel from './../../assets/funnel.png'
import { sentenceCase } from 'sentence-case';
import moment from 'moment';





const useStyles = makeStyles((theme) => ({


    Paper: {
        boxShadow: 'none',
        paddingTop: 0,
        paddingBottom:10,
        width: '100%',

    },

    listStyle: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',




        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },

    headingListStyle: {

        display: 'flex',
        flexDirection: 'row',
        width: '100%',



        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },


    },


    listheading: {

        fontSize: 16,
        fontWeight: 400,
    },

    leftItem: {

        backgroundColor: '#f28f14',
        color: '#fff',
        // borderBottom: '1px solid #fff',
    },

    rightItem: {

        border: '1px solid #efefef',
    },


    actionArea: {

        '&.MuiCardActionArea-root': {

            textAlign: 'center',


        },

    },

    media: {


    },

    mainHeading: {



        color: '#303030',
        fontSize: 38,
        fontWeight: 600,


    },


    descriptionStyle: {


        fontSize: '1rem',
        fontWeight: 500,
        color: '#303030',

    },

    Paper3: {
        backgroundColor: '#fff',
        height: '25vh',
        boxShadow: 'none',


        [theme.breakpoints.down('sm')]: {
            height: 200,
        },

    },

    card: {
        maxWidth: 400,

        boxShadow: 'none',
        backgroundColor: '#f28f16',
        paddingTop: 10,
        paddingBottom: 10,
        pointerEvents: 'none',




    },

    root: {
        maxWidth: 530,
        boxShadow: 'none',
        backgroundColor: '#fff',
        paddingTop: 50,
        paddingBottom: 50,
        pointerEvents: 'none',


    },




}));








export const SingleHoldingDetails: FunctionComponent<{ allBeekeerpers: any, currentHolding: any, totalData: { totalPayment?: string, totalPayload: any, totalRemaining?: any, totalSold?: any, totalContributor?: any } }> = props => {
    const classes = useStyles();

    // console.log(props.totalData)

    const [data, setData] = useState<any[]>([])

    const [loading, setLoadin] = useState(false);

    const [uniqueBK, setUniqueBK] = useState(0)

    const [holding, setHolding] = useState<any>([])
    //  var unique = require('array-unique');




    var unique = require('array-unique').immutable;
    var arr = ['a', 'b', 'c', 'c', 'd', 'd', 'e', 'd']
    //   console.log(unique(arr))







    var uni = new Set();
    useEffect(() => {

        // API.get('ibcs', { holdingid: window.location.pathname.split('/')[3] }).then(x => {
        //     setData(x.data);
        //     console.log(x.data);
        //     (x.data as any[]).map(c => c.beekeeper.fullName).forEach(q => uni.add(q))
        //     setUniqueBK(uni.size)
        //     // console.log(uni.size)



        // })


        API.get('holding', { holdingid: window.location.pathname.split('/')[3] }).then(x => {
            setData(x.data);
            // console.log(JSON.stringify(x.data))

        })

        API.get('holdings', { holdingid: window.location.pathname.split('/')[3] }).then(z => {
            // console.log(z.data)

            setHolding(z.data);

        });



    }, [])





    let id: number = 1
    const getID = () => {
        return id++;
    }


    return (
        <div style={{marginLeft:'-20px'}} >

            <Container>


                <Grid container justify="center" spacing={2}>

                    <Grid item lg={3} xs={12}>

                        <Card className={classes.root}>
                            <CardActionArea className={classes.actionArea}>
                                <CardMedia
                                    className={classes.media}>

                                    <Storage style={{ fontSize: 60, color: '#f28f16' }} />
                                </CardMedia>
                                <CardContent>


                                    <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                                        {/* {props.totalData.totalContributor} */}
                                        {
                                            holding.filter((x:any)=> x.id == window.location.pathname.split('/')[3]).map((x:any)=> x.contributors)
                                        }

                                    </Typography>
                                    <br />


                                    <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                                        Beekeepers Contributed
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                    </Grid>




                    <Grid item lg={3} xs={12}>

                        <Card className={classes.root}>
                            <CardActionArea className={classes.actionArea}>
                                <CardMedia
                                    className={classes.media}>


                                    <img src={dollar} width="60" height="60" />
                                </CardMedia>
                                <CardContent>


                                    <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                                        {/* {props.totalData.totalPayload.length > 0 ? props.totalData.totalPayload : 0  } (t) */}
                                        {Number(props.totalData.totalPayload).toFixed(2)}kg

                                    </Typography>
                                    <br />
                                    {/* <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                                    Pay Load
                                    </Typography> */}

                                    <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                                        Honey Delivered 
                                        {/* payload */}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                    </Grid>



                    <Grid item lg={3} xs={12}>

                        <Card className={classes.root}>
                            <CardActionArea className={classes.actionArea}>
                                <CardMedia
                                    className={classes.media}>

                                    <img src={purse} width="60" height="60" />
                                </CardMedia>
                                <CardContent>


                                    <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">

                                        {/* {props.totalData.totalSold == null ? 0 : props.totalData.totalSold} (t) */}
                                        {Number(props.totalData.totalSold).toFixed(2)}kg


                                    </Typography>
                                    <br />

                                    <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                                        Honey Used
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                    </Grid>

                    <Grid item lg={3} xs={12}>

                        <Card className={classes.root}>
                            <CardActionArea className={classes.actionArea}>
                                <CardMedia
                                    className={classes.media}>

                                    <Storage style={{ fontSize: 60, color: '#f28f16' }} />
                                </CardMedia>
                                <CardContent>


                                    <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                                        {Number(props.totalData.totalRemaining ).toFixed(2)}kg

                                    </Typography>
                                    <br />
                                    {/* <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
Remaining
</Typography> */}

                                    <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                                        Remaining Qty
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                    </Grid>





                </Grid>

                <br /><br />


                <Grid container direction="row" style={{ backgroundColor: '#fff', padding: 15, }}>


                    <Grid item lg={12}>

                        {/*                       
   <List className={classes.listStyle}>
       <ListItem>
           <Typography variant="subtitle2">
               Beekeepers Contributed:
              
            

           </Typography>

        

       </ListItem>

       <ListItem>
           <Typography variant="subtitle2">
      
    

<b>{uniqueBK}</b>
          
       

       

           </Typography>

       </ListItem>


       <ListItem>
           <Typography variant="subtitle2">
               Variety:

           </Typography>

       </ListItem>

       <ListItem>
           <Typography variant="subtitle2">
        
         <b>  {data['honeyType']}</b>

           </Typography>

       </ListItem>


       <ListItem>
           <Typography variant="subtitle2">
           Stock On Hand (t):

           </Typography>

       </ListItem>

       <ListItem>
           <Typography variant="subtitle2">

           <b>{props.totalData.totalRemaining == 0 ? 0 :  props.totalData.totalRemaining/1000 } (t)</b>

          

           </Typography>

       </ListItem>
       
   </List> */}
                    </Grid>




                    <Grid item lg={12} xs={12}>

                        <Paper className={classes.Paper} >

                            <Typography  variant="h6">Honey Characteristics</Typography>
                            <br />



                            <List style={{paddingTop:'0px',paddingBottom:'0px'}} className={classes.listStyle}>
                                <ListItem className={classes.leftItem}>
                                    <Typography className={classes.listheading} variant="subtitle1">
                                        Dominant Variety

                                    </Typography>

                                </ListItem>

                                <ListItem className={classes.rightItem}>
                                    <Typography variant="body2">

                                        {/* {props.currentHolding['floralType']} */}
                                        {/* {props.currentHolding.length > 0 ?  props.currentHolding['floralType'] : 0   } */}
                                        {data['honeyType']}

                                    </Typography>

                                </ListItem>


                                <ListItem className={classes.leftItem}>
                                    <Typography className={classes.listheading} variant="subtitle1">
                                        MGO

                                    </Typography>

                                </ListItem>

                                <ListItem className={classes.rightItem}>
                                    <Typography variant="body2">
                                        {/* {props.currentHolding.length > 0 ?  props.currentHolding[0]['holding']['organicnonorganic'] : 0   } */}
                                        {data['mgoFrom']} -- {data['mgoTO']}

                                    </Typography>

                                </ListItem>


                                {/* <ListItem className={classes.leftItem}>
                                    <Typography className={classes.listheading} variant="subtitle1">
                                        Organic

                                    </Typography>

                                </ListItem>

                                <ListItem className={classes.rightItem}>
                                    <Typography variant="body2">
                                        {data['organicNonOrganic'] == 'organic' ? 'Yes' : 'No'}
                                        {data['energycarbsfrom'] +"--"+ data['energycarbsto']} cal per 15ml


                                    </Typography>

                                </ListItem> */}

                            </List>






                            <List style={{paddingTop:'0px',paddingBottom:'0px'}} className={classes.listStyle}>
                                <ListItem className={classes.leftItem}>
                                    <Typography className={classes.listheading} variant="subtitle1">
                                        Second Variety

                                    </Typography>

                                </ListItem>

                                <ListItem className={classes.rightItem}>
                                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>


                                        {data['secondaryVariety'] == null ? 'not selected':data['secondaryVariety'] }

                                    </Typography>

                                </ListItem>


                                <ListItem className={classes.leftItem}>
                                    <Typography className={classes.listheading} variant="subtitle1">
                                        HMF

                                    </Typography>

                                </ListItem>

                                <ListItem className={classes.rightItem}>
                                    <Typography variant="body2">


                                        {data['hmffrom']} -- {data['hmfto']}

                                    </Typography>

                                </ListItem>

                                {/* <ListItem className={classes.leftItem}>
                                    <Typography className={classes.listheading} variant="subtitle1">
                                        Moisture

                                    </Typography>

                                </ListItem>

                                <ListItem className={classes.rightItem}>
                                    <Typography variant="body2">


                                        {data['moisturefrom'] + "--" + data['moistureto']}

                                    </Typography>

                                </ListItem> */}



                            </List>









                            <List style={{ paddingTop:'0px',paddingBottom:'0px' }} className={classes.listStyle}>
                                <ListItem className={classes.leftItem}>
                                    <Typography className={classes.listheading} variant="subtitle1">
                                        TA Range

                                    </Typography>

                                </ListItem>

                                <ListItem className={classes.rightItem}>
                                    <Typography variant="body2">

                                        {/* {props.currentHolding.length > 0 ?  props.currentHolding[0]['holding']['rawPastureized'] : 0   } */}
                                        {/* {data['dateOfExtraction']} */}
                                        {data['activityfrom']} -- {data['activityto']}

                                    </Typography>

                                </ListItem>


                                <ListItem className={classes.leftItem}>
                                    <Typography className={classes.listheading} variant="subtitle1">
                                        Moisture

                                    </Typography>

                                </ListItem>

                                <ListItem className={classes.rightItem}>
                                    <Typography variant="body2">
                                        {data['moisturefrom']} -- {data['moistureto']}


                                    </Typography>

                                </ListItem>



                            </List>









                        </Paper>



                    </Grid>
                </Grid>
                <br /><br />
                {/* <Typography variant="h6">Honey contributions to HON-HOL-{props.currentHolding.length > 0 ?  props.currentHolding[0]['holding']['rid'] : 0   }</Typography> */}
                <Typography  variant="h6">Honey Contributions To Holding HON-HOL-{data['rid']}</Typography>




            </Container  >

        </div>
    );
}