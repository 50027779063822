import { MenuItem, CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState, FunctionComponent, useEffect } from 'react';
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { API, Ways } from '../Helpers';
import { SelectImg } from './SelectImg';
import { useSnackbar } from 'notistack';
import { VariationsOfProduct } from './VariationsOfProduct';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  root: {
    display: 'flex',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  GroupButton: {
    color: '#ffffff',
    backgroundColor: '#F28F16',
    boxShadow: 'none',
    marginRight: 10,

    '& .MuiButtonGroup-groupedHorizontal': {
      '&:hover': {
        color: '#ffffff',
        backgroundColor: '#F28F16',
      },
    },
  },

  firstGrid: {

    [theme.breakpoints.down('sm')]: {


      marginLeft: '-2%',
    },
  },

  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },


  imgGrid: {

    marginLeft: '3%',
    


  },



}));





export const AddProduct: FunctionComponent<{ product?: any }> = () => {
  const classes = useStyles();

  const [product, setProduct] = useState({ category: 0, brand: 0 });

  const [categories, setCategories] = useState<any[]>([]);
  const [pics, setPics] = useState<any[]>([]);


  const set = (e) => {
    var p = { ...product };
    p[e.target.name] = e.target.value;
    setProduct(p);


  }

  const getData = async () => {

    var id = window.location.pathname.split('/')[4];
    var cats;
    await API.get('categories').then(x => {
      cats = x.data;
      setCategories(x.data);
    })
    if (id != null) {
      API.get('product', { productId: id }).then(x => {
        var p = x.data;
        // console.log(p);
        var images = (x.data['pictures'] as []).map(x => x['image']);
        setPics(images);

        if (p['category'] != null) {
          var c = cats.filter(cat => {
            return cat['id'] == p['category']['id']
          })
          p['category'] = c[0];
        }
        // setProduct(p)
        setProduct({ ...p })
      })
    }
  }
  useEffect(() => {
    getData();
  }, []);


  let a = 1;
  const val = (field: string) => {
    var ret: {} = product[field] || '';
    return ret;
  }



  const { enqueueSnackbar } = useSnackbar();


  return (
    <div className={classes.root}>

      <CssBaseline />
      <div className={classes.paper}>

        <ValidatorForm onSubmit={() => {

          let isEditMode: boolean = window.location.pathname.includes(Ways.productEditPage);
          if (!isEditMode) {
            API.post('product', product).then(x => {
              if (x.status == 200) {
                enqueueSnackbar('Product Saved');
              }
            });
          }
          if (isEditMode) {
            API.put('product', product, { productid: product['id'] }).then(xc => {
              if (xc.status == 200) {
                enqueueSnackbar('Product Updated', { color: 'green' })
              } else {
                alert('error')
              }
            })
          }
        }} className={classes.form}

          noValidate>

          <Grid container spacing={2} >
            <Grid item lg={12} xs={12} className={classes.firstGrid}>
              <Typography variant="h6">Product Category</Typography>
              <br />
              <SelectValidator id='category' name='category' value={product['category']} variant="outlined" size="small" style={{ width: '100%' }}

                onChange={x => {
                  set(x)
                }}

                label="Category"
                validators={[]}
                errorMessages={[]}>
                 {[<MenuItem key={-1} value={0}>None</MenuItem>].concat(categories.map((x, i) => <MenuItem key={i} value={x}>{x['name']}</MenuItem>))}
              </SelectValidator>
              <br />
              <br />



              <Typography variant="h6">Product Brand</Typography>
              <SelectValidator id='brand' name='brand' value={product['brand']} variant="outlined" size="small" style={{ width: '100%' }}

                onChange={x => {
                  set(x)
                }}

                label="Brand"
                validators={[]}
                errorMessages={[]}>
                {[<MenuItem key={-1} value={0}>None</MenuItem>].concat(['BEEGENIX', 'BEEWELL', 'HONEY YUMMIES', 'HONEY HIIT'].map((x, i) => <MenuItem key={i} value={x}>{x}</MenuItem>))}
              </SelectValidator>
              <br /> <br />

              <Typography variant="h6">Product Details</Typography>
              <br />
              <TextValidator
                variant="outlined"
                required
                size="small"
                fullWidth
                value={val('productTitle')}
                onChange={set}
                id="productTitle"
                label="Product Title"
                name="productTitle"
                validators={['required']}
                errorMessages={['this field is required', 'Job Title is in an invalid format']}

              />
              <br /> <br />

              <TextValidator
                variant="outlined"
                required
                size="small"
                fullWidth
                multiline
                maxlength='250'
                rows={5}
                value={val('shortDescription')}
                validators={['required']}
                errorMessages={['this field is required']}
                id="shortDescription"
                label="Product Short Description"
                onChange={set}
                name="shortDescription"
                autoComplete="email"
              />

              <br /> <br />

              {/* <TextValidator
                onChange={set}

                name="minPrice"
                variant="outlined"
                required
                size="small"
                value={val('minPrice')}
                validators={['required', 'isNumber', 'maxNumber:' + product['maxPrice'] ?? 10000]}
                errorMessages={['this field is required', 'Minimum Price is in an invalid format', 'Min Price cannot be higher than']}
                fullWidth

                id="minPrice"
                label="Min Price"
              />
              <br /> <br />
              <TextValidator
                value={val('maxPrice')}
                validators={['required', 'isNumber', 'minNumber:' + product['minPrice'] ?? 0]}
                errorMessages={['this field is required', 'Maximum is in an invalid format', 'Maximum price cannot be less than Minmum Price']}
                onChange={set}

                variant="outlined"
                required
                fullWidth
                size="small"
                id="maxPrice"
                label="Max Price"
                name="maxPrice"
                autoComplete="lname"
              />
              <br /> <br /> */}
              <TextValidator
                value={val('minQuantity')}
                validators={['required', 'isNumber',]}
                errorMessages={['this field is required', 'Quantity is in an invalid format']}
                onChange={set}

                variant="outlined"
                required
                fullWidth
                size="small"
                id="minQuantity"
                label="Min Quantity"
                name="minQuantity"
                autoComplete="lname"
              />
              <br /> <br />
              <TextValidator
                value={val('longDescription')}
                validators={['required']}
                errorMessages={['this field is required']}
                onChange={set}

                variant="outlined"
                required
                size="small"
                fullWidth
                multiline
                rows={14}
                id="longDescription"
                label="Product Long Description"
                name="longDescription"
                autoComplete="email"
              />


            </Grid>
            {/* <Grid item lg={4} xs={12} className={classes.imgGrid}>


              <br />
              <Typography variant="h6">Product Gallery</Typography>
              <br />
              {product['pictures'] &&
                <SelectImg pictures={pics}
                  disabled={false}
                  onAdded={async (c) => {
                    var res = await API.post('product/image', { product: product, image: c });
                    console.log(res);

                    setPics([...pics, c])
                    enqueueSnackbar("Image Uploaded");
                  }}
                  onChanged={(newListOfImages) => {
                    setPics(newListOfImages);
                  }}
                  onPrimaryChanged={async (newPrimary) => {
                    await API.post('product/setPrimary', { image: newPrimary, product: product['id'] });
                    var newProduct = product;
                    newProduct['primaryImage'] = newPrimary;
                    setProduct(newProduct);
                    enqueueSnackbar("New Primary Image Selected");
                  }}
                  primary={product['primaryImage']} />} */}

              {/* <ImageUploader /> */}

            {/* </Grid> */}




            <br /><br /><br /><br />

            <Grid container xs={12} lg={12} className={classes.firstGrid}>
              <br />
              <Typography variant="h6">Honey Characteristics</Typography>
              <Grid item lg={12} xs={12}>

                <br />

                <SelectValidator id='floralType' name='floralType' value={val('floralType')} variant="outlined" size="small" style={{ width: '100%' }}
                  onChange={set}
                  label="Floral Type"
                  validators={['required']}
                  errorMessages={['this field is required']}

                >
                  <MenuItem value={'Mono-Floral'}>Mono-Floral</MenuItem>
                  <MenuItem value={'Multi-Floral'}>Multi-Floral</MenuItem>

                  {/* </Select> */}
                </SelectValidator>
              </Grid>


              <Grid item lg={12} xs={12}>
                <br />
                <SelectValidator variant="outlined" size="small" style={{ width: '100%' }}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  value={val('rawness')}
                  name='rawness'
                  onChange={set}


                  label="Rawness"

                >
                  <MenuItem aria-label="None" value="" />
                  <MenuItem value={'Raw'}>Raw</MenuItem>
                  <MenuItem value={'Pastuerized'}>Pastuerized</MenuItem>

                </SelectValidator>
              </Grid>


              <Grid item lg={12} xs={12}>
                <br />
                <TextValidator
                  autoComplete="fname"
                  value={val('ta')}
                  validators={[ 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={[ 'TA is in an invalid format']}
                  onChange={set}
                  variant="outlined"
                  name='ta'
                  required
                  size="small"
                  fullWidth
                  id="firstName"
                  label="TA"

                />
              </Grid>

              <Grid item lg={12} xs={12}>
                <br />
                <TextValidator
                  value={val('mgp')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'mgp is in an invalid format']}
                  onChange={set}
                  name='mgp'
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  id="lastName"
                  label="MGP"

                  autoComplete="lname"
                />
              </Grid>

              <Grid item lg={12} xs={12}>
                <br />
                <SelectValidator variant="outlined" size="small" style={{ width: '100%' }}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  value={val('organicType')}
                  name='organicType'
                  label="Organic"
                  onChange={set}
                >
                  <MenuItem value={'Simple'}>Simple</MenuItem>
                  <MenuItem value={'Organic'}>Organic</MenuItem>
                  <MenuItem value={'Non-Organic'}>Non-Organic</MenuItem>
                </SelectValidator>

              </Grid>


              <Grid item lg={12} xs={12}>
                <br />
                <TextValidator
                  autoComplete="fname"
                  name="countryOfOrigin"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  id="firstName"
                  label="Country Of Origion"
                  value={val('countryOfOrigin')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'Country of Origin is in an invalid format']}
                  onChange={set}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <br />
                <TextValidator
                  variant="outlined"
                  value={val('countryPackaged')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'Country Packaged is in an invalid format']}
                  onChange={set}
                  name='countryPackaged'
                  required
                  fullWidth
                  size="small"
                  label="Country Packaged"
                  autoComplete="lname"
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <br />
                <TextValidator
                  value={val('hasImportedProducts')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'NO is in an invalid format']}
                  onChange={set}
                  name='hasImportedProducts'
                  required
                  fullWidth
                  size="small"
                  label="Contains Imported Products"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <br />
                <TextValidator
                  value={val('percentageImportedProducts')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'Origin Of Imported Products is in an invalid format']}
                  onChange={set}
                  name='percentageImportedProducts'
                  autoComplete="fname"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  id="firstName"
                  label="Percentage Of Impoted Products"

                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <br />
                <TextValidator
                  value={val('originOfImportedProducts')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'Origin Of Imported Products is in an invalid format']}
                  onChange={set}
                  name='originOfImportedProducts'
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  id="lastName"
                  label="Origin of Imported Products"

                  autoComplete="lname"
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <br />
                <TextValidator
                  value={val('moisture')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'moisture is in an invalid format']}
                  onChange={set}
                  name='moisture'
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  label="Moisture"

                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <br />
                <TextValidator
                  value={val('energyCarbs')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'Energy is in an invalid format']}
                  onChange={set}
                  name='energyCarbs'
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  id="lastName"
                  label="Energy/Carbs"

                  autoComplete="lname"
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <br />
                <TextValidator
                  value={val('antioxidents')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'Antioxidents is in an invalid format']}
                  onChange={set}
                  name='antioxidents'
                  autoComplete="fname"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  id="firstName"
                  label="Antioxidants"

                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <br />
                <TextValidator
                  value={val('glucoseIndexGi')}
                  validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,.]+$']}
                  errorMessages={['this field is required', 'Glucose Index is in an invalid format']}
                  onChange={set}
                  name='glucoseIndexGi'
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  id="lastName"
                  label="Glucose Index GI"

                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <br />
                <TextValidator
                  value={val('hmf')}
                  validators={['required', 'isNumber']}
                  errorMessages={['this field is required', 'HMF Index is in an invalid format']}
                  onChange={set}
                  name='hmf'
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  id="lastName"
                  label="HMF"

                  autoComplete="lname"
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <br />
                <SelectValidator variant="outlined" size="small" style={{ width: '100%' }}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  value={val('organicType')}
                  name='organicType'
                  label="Organic"
                  onChange={set}


                >
                  <MenuItem aria-label="None" value="" />
                  <MenuItem value={'Simple'}>Simple</MenuItem>
                  <MenuItem value={'Organic'}>Organic</MenuItem>
                  <MenuItem value={'Non-Organic'}>Non-Organic</MenuItem>
                </SelectValidator>
              </Grid>
            </Grid>
            {/* <Grid container lg={4} xs={12} className={classes.imgGrid}>

              <br />
              <Grid item lg={12}>
                <Typography variant="h6">Variations</Typography>
              </Grid>
              <br />
              <Grid item lg={12}>
                {product['id'] && <VariationsOfProduct product={product} />}

              </Grid>
            </Grid>
            <br /> */}
            <Grid item xs={12}>
              <Typography variant="h6">Nutritional Information</Typography>
              <Typography variant="subtitle2">(21g) 1 TBsp</Typography>

            </Grid>

            <Grid item xs={12} lg={12}>
              <br />
              <TextValidator
                value={val('energy')}
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Energy is in an invalid format']}
                onChange={set}
                name='energy'
                variant="outlined"
                required
                fullWidth
                size="small"
                id="lastName"
                label="Energy"

                autoComplete="lname"
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <br />
              <TextValidator
                value={val('protein')}
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Protein is in an invalid format']}
                onChange={set}
                name='protein'
                variant="outlined"
                required
                fullWidth
                size="small"
                id="lastName"
                label="Protein"

                autoComplete="lname"
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <br />
              <TextValidator
                value={val('fatTotal')}
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Fat Tota is in an invalid format']}
                onChange={set}
                name='fatTotal'
                variant="outlined"
                required
                fullWidth
                size="small"
                id="lastName"
                label="Fat Total"

                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <br />
              <TextValidator
                value={val('fatSaturated')}
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Fat Saturated is in an invalid format']}
                onChange={set}
                name='fatSaturated'
                variant="outlined"
                required
                fullWidth
                size="small"
                id="lastName"
                label="Fat Saturated"

                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <br />
              <TextValidator
                value={val('transFat')}
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Trans Fat is in an invalid format']}
                onChange={set}
                name='transFat'
                variant="outlined"
                required
                fullWidth
                size="small"
                id="lastName"
                label="Trans Fat"

                autoComplete="lname"
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <br />
              <TextValidator
                value={val('carbohydrates')}
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Carbohydrates is in an invalid format']}
                onChange={set}
                name='car'
                variant="outlined"
                required
                fullWidth
                size="small"
                id="lastName"
                label="carbohydrates"

                autoComplete="lname"
              />
            </Grid>


            <Grid item xs={12} lg={12}>
              <br />
              <TextValidator
                value={val('sugars')}
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Sugars is in an invalid format']}
                onChange={set}
                name='sugars'
                variant="outlined"
                required
                fullWidth
                size="small"
                id="lastName"
                label="Sugars"

                autoComplete="lname"
              />
            </Grid>


            <Grid item xs={12} lg={12}>
              <br />
              <TextValidator
                value={val('sodium')}
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Sodium is in an invalid format']}
                onChange={set}
                name='sodium'
                variant="outlined"
                required
                fullWidth
                size="small"
                id="lastName"
                label="Sodium"
                autoComplete="lname"
              />
            </Grid>
            <br /><br />

            <Grid item xs={12} lg={7} >
              <Button className={classes.btnStyle} variant="contained" onClick={() => {
              }} type='submit'>Save Product</Button>

            </Grid>



          </Grid>





          <Dialog style={{backgroundColor: 'transparent'}} open={window.location.pathname.includes(Ways.productEditPage) && product['id'] == null}>
            {/* <Dialog open={false}> */}
            <DialogContent style={{ padding: '20px', backgroundColor: 'transparent' }}>
              <CircularProgress style={{color: '#f28f16'}} />
            </DialogContent>
          </Dialog>
        </ValidatorForm>
      </div>
    </div>


  );
}

