import { Button, Chip, Grid, Link, Paper, List, ListItem, Dialog, DialogContent, Card, CardActionArea, CardMedia, CardContent, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Ways, API, Cook, getvalue } from '../Helpers';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { LocalMall, PanoramaFishEyeOutlined, Storage, Visibility } from '@material-ui/icons';
import purse from './../assets/purse.png';
import dollar from './../assets/dollar.png';
import { BeekeeperSingleHoneyDetailsPopup } from '../BeekeeperHoneyDetails/BeekeeperSingleHoneyDetailsPopup';
import moment from 'moment';
import { sentenceCase } from "sentence-case";




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    // padding: theme.spacing(),
    overflow: 'auto',



  },

  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },


  fixedHeightPaperEmail: {

    boxShadow: 'none',
    padding: '10px ',
  },



  actionArea: {

    '&.MuiCardActionArea-root': {

      textAlign: 'center',


    },

  },




  root2: {
    maxWidth: 530,
    boxShadow: 'none',
    backgroundColor: '#fff',
    paddingTop: 50,
    paddingBottom: 50,
    pointerEvents: 'none',


  },
  media: {


  },

  imgStyle: {

    maxWidth: 120,


  },

  mainHeading: {

    color: '#303030',
    fontSize: 38,
    fontWeight: 600,


  },


  descriptionStyle: {


    fontSize: '1rem',
    fontWeight: 500,
    color: '#726f6f',

  },

}));



export const IBCForSingleBeekeeperTable: FunctionComponent<{ currentBEEKEEPER: any, totalData: { totalRemaining: any, totalPayload: any, totalPayment: any, totalSold: any, } }> = props => {
  // :FunctionComponent<{currentHolding : any , totalData : {totalPayment? : string , totalPayload : any , totalRemaining?:any}}> = props => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([]);
  // const [ honeyType, sethoneyType ] = useState(['honeyType'])








  useEffect(() => {

    console.log(props.currentBEEKEEPER)


    API.get('beekeeper', { beekeeper: window.location.pathname.split('/')[3] }).then(x => {
      setData(x.data);
      console.log(x.data);

    })


  }, [])








  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item lg={12} >
        <Typography style={{ marginTop: 50, marginBottom: 10 }} variant="h4">{data['fullName']} </Typography>
        <Typography style={{ marginTop: 15, marginBottom: 10, textTransform: 'capitalize' }} variant="h6">{data['fullName']} Inventory Details </Typography>
      </Grid>




      <Grid item lg={3} xs={12}>

        <Card className={classes.root2}>
          <CardActionArea className={classes.actionArea}>
            <CardMedia
              className={classes.media}>

              {/* <Storage style={{fontSize: 70, color: '#f28f16'}} /> */}
              <img src={dollar} width="60" height="60" />
            </CardMedia>
            <CardContent>


              <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                {/* {data['payLoad']}t */}
                {Number(props.totalData.totalPayload).toFixed(2)}kg
              </Typography>
              <br />
              <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                Honey Delivered
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

      </Grid>


      <Grid item lg={3} xs={12}>

        <Card className={classes.root2}>
          <CardActionArea className={classes.actionArea}>
            <CardMedia
              className={classes.media}>

              <LocalMall style={{ fontSize: 60, color: '#f28f16' }} />
            </CardMedia>
            <CardContent>


              <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                {/* {props.currentBEEKEEPER.length > 0 ? props.currentBEEKEEPER[0]['remaining'] : ''} AUD */}
                {Number(props.totalData.totalSold).toFixed(2)}kg


              </Typography>
              <br />
              <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                Honey Used
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

      </Grid>


      <Grid item lg={3} xs={12}>

        <Card className={classes.root2}>
          <CardActionArea className={classes.actionArea}>
            <CardMedia
              className={classes.media}>

              <Storage style={{ fontSize: 60, color: '#f28f16' }} />
            </CardMedia>
            <CardContent>


              <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                {/* {props.currentBEEKEEPER.length > 0 ? props.currentBEEKEEPER[0]['remaining'] : ''} */}
                {Number(props.totalData.totalRemaining).toFixed(2)}kg
              </Typography>
              <br />
              <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                Honey Available 
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

      </Grid>





      <Grid item lg={3} xs={12}>

        <Card className={classes.root2}>
          <CardActionArea className={classes.actionArea}>
            <CardMedia
              className={classes.media}>

              {/* <Storage style={{fontSize: 70, color: '#f28f16'}} /> */}
              <img src={purse} width="60" height="60" />
            </CardMedia>
            <CardContent>


              <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                {/* {props.currentBEEKEEPER.length > 0 ? props.currentBEEKEEPER[0]['initialPayment'] : 'ajkshdf'} AUD */}
                ${(Number(props.totalData.totalPayment).toFixed(2)).toLocaleString()}

              </Typography>
              <br />
              <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                Total Earnings
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

      </Grid>











      {/* Table */}


      <Grid item lg={12} md={12} xs={12}>
        <Paper className={classes.fixedHeightPaperEmail}>
          <div style={{paddingLeft:'7px'}} >
            <Typography style={{ marginTop: 20, marginBottom: 10, textTransform: 'capitalize' }} variant="h6"  >Honey Receival History Of {data['fullName']} </Typography>
            <div style={{ padding: '1px 0px' }}  >
              <Button onClick={() => {
                createBrowserHistory().push(Ways.beekeeperprofilePage + '/' + data['id'])
                window.location.reload();
              }} variant="contained" size="medium"
                style={{ backgroundColor: '#f28f16', color: 'white' }}
                startIcon={<Visibility />}
              >View Profile</Button>
            </div>
          </div>
          <MaterialTable
            style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
            title=""

            columns={[
              { title: <p style={{ width: '70px', fontSize: '14px' }} >Delivery ID</p>, field: 'rid', render: (row) => <div style={{ padding: "0.5px 0px" }} >HON-IBC-{row['rid']}</div> },
              // { title: ' Holding Cat', render: (row) => <div>HON-HOL-{row['holding']['rid']}</div>},
              { title: <p style={{ width: '100px', fontSize: '14px' }} >Receival Date</p>, field: 'createdOn', render: (row) => <div style={{ padding: "0.5px 0px" }} >{moment(row['createdOn']).format('DD/MM/YYYY')}</div> },
              { title: <p style={{ width: '115px', fontSize: '14px' }} >Dominent Variety</p>, field: 'honeyType', cellStyle: { textTransform: 'capitalize' }, render: (row) => <div style={{ padding: "0.5px 0px" }} >{row['honeyType']}</div> },

              {
                title: <p style={{ width: '110px', fontSize: '14px', }} >Second Variety </p>, field: 'secondaryVariety',
                // cellStyle: { textTransform: 'capitalize' },
                render: (row) => <div style={{ textTransform: 'capitalize', padding: '0.5px 0px' }}>{row['secondaryVariety']}</div>
              },


              { title: <p style={{ width: '20px', fontSize: '14px' }} >TA</p>, field: 'activity', cellStyle: { textTransform: 'capitalize', }, render: (row) => <div style={{ padding: "0.5px 0px" }} >{row['activity'] != null ? row['activity'] : 0}</div> },
              { title: <p style={{ width: '120px', fontSize: '14px' }} >Holding Category</p>, field: 'rid', cellStyle: { textTransform: 'capitalize' }, render: (row) => <div style={{ padding: "0.5px 0px" }} >{row['holding'] != null ? `HON-HOL-${row['holding']['rid']}` : 'Not Assigned'}</div> },

              { title: <p style={{ width: '110px', fontSize: '14px' }}>Honey Delivered</p>, field: 'payLoad', render: (row) => <div style={{ padding: "0.5px 0px" }} >{Number((row['payLoad'] ?? 0)).toFixed(2)}kg</div> },

              // { title: 'Total Sold (t)', render: (row) => <div>{ row['remaining'] !== null ? row['payLoad'] - row['remaining'] : 0 } </div>},
              { title: <p style={{ width: '80px', fontSize: '14px' }}>Honey Used</p>,sorting:false, render: (row) => <div style={{ padding: "0.5px 0px" }} >{row['remaining'] == null ? '0.00' : Number((row['payLoad'] - row['remaining'])).toFixed(2)}kg</div> },

              { title: <p style={{ width: '110px', fontSize: '14px' }}>Honey Available</p>, sorting:false, render: (row) => <div style={{ padding: "0.5px 0px" }} >{row['remaining'] == null ? Number(row['remaining'] + row['payLoad']).toFixed(2) : Number(row['remaining'] ?? 0).toFixed(2)}kg</div> },



              { title: <p style={{ width: '120px', fontSize: '14px' }}> Delivery Payment</p>, field: 'initialPayment', render: (row) => <div style={{ padding: "0.5px 0px" }} >${Number(row['initialPayment'] ?? 0).toFixed(2)}</div> },
              // { title: ' Remaining (t)', render: (row) => <div>{row['remaining'] == null ? 0 : row['remaining']}</div>},
              // { title: ' Initial Payment', render: (row) => <div>{row['initialPayment']}</div>},



              {
                title: <p style={{ fontSize: '14px' }} >Actions</p>, sorting: false, render: (row) =>
                  <div style={{ padding: '0.5px 0px ' }} >
                    <Button onClick={() => {
                      createBrowserHistory().push(Ways.ibcPage + '/' + row['id'])
                      window.location.reload();
                    }} variant="contained" size="small"
                      style={{ backgroundColor: '#f28f16', color: 'white', height: '30px' }}>Details</Button>
                  </div>


              },
            ]}
            data={props.currentBEEKEEPER}



          />

          {/* <Dialog open={cCat != null} onClose={() => setcCat(undefined)}>
        <DialogContent style={{padding:'20px'}}>
      {data['initialPayment']}t
        </DialogContent>
      </Dialog> */}


        </Paper>


      </Grid>

    </Grid>
  )
}













