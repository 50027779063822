import { Box, Breadcrumbs, capitalize, Container, createStyles, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { containerNumbers } from "./../Helpers";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        breadcrumbBox: {
            // paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '0.5%',
            paddingBottom: '1%',
            // backgroundColor: '#f4f4f4',
        },
        mainHeading: {
            fontFamily: 'sophia',
            color: '#303030',
        },
    }),
);

export const BreadCrumbsBar: FunctionComponent = () => {
    var classes = useStyles();
    var pathlist = window.location.pathname.split('/');
    var url = pathlist.filter(x=>x.length > 0).map((x,i)=> { return {url : pathlist.filter((p,index)=>index <= i+1).join('/') , title : capitalize(x.split('-').join(' ')).replace('/' , '')}});
    url.splice(0 , 0 ,);

    if(containerNumbers(url[url.length-1].title)){
        url.splice(url.length -1, 1);
    }
    var last=  url[url.length-1];

    if(last.title =='Quote'){
        url[url.length-1] = {title : 'Single Quote' , url : window.location.pathname};
    }
    if(last.title =='Order'){
        url[url.length-1] = {title : 'Single Order' , url : window.location.pathname};
    }
    if(last.title =='Product'){
        url[url.length-1] = {title : 'Single Product' , url : window.location.pathname};
    }
    


    return <Box className={classes.breadcrumbBox}  >
           <Typography variant="h5" >Dashboard</Typography>
           
        
            <Breadcrumbs aria-label="breadcrumb">
                {url.map((x, i) => {
                    return <Link color="inherit" href={x.url}>
                    <Typography variant="body2">{x.title}</Typography>
                </Link>
                })}
            </Breadcrumbs>
        
    </Box>
}