import { Divider, ListItemText, Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import userplace from './../assets/userplace.png';
import { Chip } from '@material-ui/core';
import { API, Bbc, AppEvent, Cook } from '../Helpers';
import { observer } from 'mobx-react';
import { GlobalState } from './../AppState';


const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge);





const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
    },

    Divider: {

      border: '0.1px solid #fbfbfb',
    },

    profile: {

      borderRadius: 5,

    },

    text: {
      marginLeft: 20,
    },

    listText: {

      marginTop: '-2%',

    },

    listStyle: {
      paddingTop: 5,
      paddingBottom: 5,



    },
  }),
);



const MsgBadge = observer(() => {
  return <Badge style={{marginTop: -20}} color="secondary" badgeContent={GlobalState.unreadMessages.length} >
      
  </Badge>
})



export const ChatList = () => {
  const classes = useStyles();
  const [chats, setChats] = useState([]);

  useEffect(() => {
    API.get('adminChats', {userid: Cook.getUserData()['user']['id']}).then(x => {
        setChats(x.data);
        // console.log(x.data)
        var list = window.location.pathname.split('/');
        if(list.length < 4){
            return;
        }
        var c = (x.data as []).filter(e=>e['id'] == list[3]);
        console.log(c[0]);
        Bbc.fire(AppEvent.newChatSelected, c[0]);

    })

   
}, [])


  useEffect(() => {
    if (Cook.getUserData() == null) return
    API.get('notifications', { userid: Cook.getUserData()['user']['id'] }).then(x => {
        GlobalState.unreadMessages = x.data['messages'];
        // console.log(x.data);
    })
}, [])

  return (
    <div>
      <List dense className={classes.root}>
        <ListItem>
          <Typography variant="h6">All Quotes</Typography>

        </ListItem>

        {chats.map((chat , i) => {
          return <div key={i}>
            <Divider className={classes.Divider} />
            <ListItem  button onClick={e=>{
              Bbc.fire(AppEvent.newChatSelected , chat);
            }} className={classes.listStyle}>
              <ListItemAvatar>
                 <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                
                   {/* <MsgBadge /> */}
                
                  <img src={userplace} alt="placeholder-image" width="50" height="50" className={classes.profile} />
                </StyledBadge>
              </ListItemAvatar>
              <React.Fragment>
                <List dense>
                  <ListItem style={{maxWidth: 70}}>
                <Typography variant="body2">{chat['partner']['firstName'] +  " " + chat['partner']['lastName']}</Typography>
                  </ListItem>
              
                  <ListItem className={classes.listText}>
                <Typography variant="caption">{ 'HON-QUO-' + chat['offer']['rid']}</Typography>
                  </ListItem>
                </List>
              </React.Fragment>
              <ListItemSecondaryAction>
                <Chip label={chat['offer']['status']} />
              </ListItemSecondaryAction>
              <ListItemSecondaryAction>
              </ListItemSecondaryAction>
            </ListItem>

           <List>
             <ListItem>
               <ListItemText>
                 <Typography variant="h2">
                   Hello World!
                 </Typography>
               </ListItemText>
             </ListItem>
           </List>
          </div>
        })}



      </List>
    </div>
  );
}