import React, { useEffect, useState, FunctionComponent } from 'react';
import MaterialTable from 'material-table';
import { Grid, Chip, List, ListItem, Button, CircularProgress, Dialog, DialogContent, Typography } from '@material-ui/core';
import { Ways, API, Cook, getvalue } from './../Helpers';
import moment from 'moment';
import { useSnackbar } from 'notistack';


export const TransactionTable: FunctionComponent<{currentSingleIBC: any }> = props => {

  // const [data , setData] = useState<any[]>([])
  const [loading, setLoadin] = useState(false);
  const [ctd, setCTD] = useState()
  const { enqueueSnackbar } = useSnackbar();

  // console.log(window.location.pathname.split('/')[3])


    




  // }, [])




  // const getdata = () => {
  //   API.get('honeytransactions ', { userid: Cook.getUserData()['user']['id'] }).then(x => {
  //     // console.log(x.data);
  //     setData(x.data);
  //   });
  // }



  // let id: number = 1
  // const getID = () => {
  //   return id++;
  // }


  //   if(!data['id']){
  //     return<Grid container justify="center" alignItems="center" style={{paddingTop: '25%'}}>
  // <CircularProgress style={{color: '#f28f16'}} />
  //     </Grid> 
  // }
  return (
    <div>
      <MaterialTable
          style={{ boxShadow: 'none', backgroundColor: '#fff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8,padding:'10px 10px 10px 10px' }}
        // style={{ boxShadow: 'none',padding:'30px 0px' }}
        title="All Transactions"
        columns={[

          
          { title:<p style={{fontSize:'14px',}} > Transaction ID</p>, field:'rid', render: (row) => <div style={{padding:'5.5px 0px'}} >HON-TRA-{row['rid']}</div> },
          { title: <p style={{fontSize:'14px',}} >Date </p> , field:'createdOn' , render: (row) => <div style={{padding:'5.5px 0px'}} >{moment(row['createdOn']).format('DD/MM/YYYY')}</div> },
          { title:<p style={{fontSize:'14px',}} >Type</p> ,sorting:false ,render: (row) => <div style={{padding:'5.5px 0px'}} >Outgoing</div> },
          { title:<p style={{fontSize:'14px'}} > Quantity </p> , field:'weight', render: (row) => <div style={{padding:'5.5px 0px'}} >{Number(row['weight'] ?? 0).toFixed(2)}kg </div> },
          { title:<p style={{fontSize:'14px',}} >Destination</p>  , field:'country', render: (row) => <div   style={{textTransform:'capitalize',padding:'5.5px 0px'}} >{row['country']}</div> },
         
        
        ]}
        data={props.currentSingleIBC}
       
      />


      <Dialog open={ctd != undefined} onClose={() => setCTD(undefined)}>
        <DialogContent>
          <Typography variant='body2'>Are you sure you want to delete this IBC ?</Typography>
          <br />
          <List>

            <Button style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
              onClick={() => {
                API.delete('transaction', { transactionid: getvalue(ctd, 'id') }).then(x => {
                  if (x.status == 200) {
                    // getdata();
                    window.location.reload();
                    enqueueSnackbar('IBC deleted');
                  } else {
                    enqueueSnackbar('Could noe deleted')
                  }
                  setCTD(undefined);
                }
                )
              }

              }>Delete</Button>
            <Button style={{ backgroundColor: '#f7f7f7' }} onClick={() => {
              setCTD(undefined);
            }}>Cancel</Button>
          </List>
        </DialogContent>
      </Dialog>
    </div>


  )



}