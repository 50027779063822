import { Button, Chip, Grid, Paper, Dialog, DialogContent, Typography, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { API, Ways, getvalue } from "./Helpers";
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { GlobalState } from './AppState';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(2),
        overflow: 'auto',
    },


    btnStyle: {

        backgroundColor: '#f28f16',
        boxShadow: 'none',
        color: '#ffffff',


        '&.MuiButton-contained': {


            '&:hover': {


                backgroundColor: '#FECF51',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },


    fixedHeightPaperEmail: {

        boxShadow: 'none',
        padding: theme.spacing(4),
    },

}));


export const AllOffersTable = () => {
    const [data, setData] = useState([])
    const [co, setCo] = useState({});
    const classes = useStyles();
    let id: number = 1

    const { enqueueSnackbar } = useSnackbar();

    const getID = () => {
        return id++;
    }


    const getOffers = () => {
        API.get('offers').then(x => {
            GlobalState.unreadQuotes = x.data;

            setData((x.data))
        })
    }

    useEffect(() => {
        getOffers()
    }
        , [])
    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
                <Paper className={classes.fixedHeightPaperEmail}>

                    <MaterialTable


                        style={{
                            boxShadow: 'none',
                            backgroundColor: '#ffffff',
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                        }}
                        title="All Quotes"
                        columns={[
                            { title: 'Quote Id', render: (row) => <div>HON-OFF-{row['rid']}</div> },
                            {
                                title: ' Buyer Name', render: (row) => {
                                    return <div>
                                        <p>{row['user']['firstName']}  {row['user']['lastName']}</p>
                                    </div>
                                }
                            },
                            { title: 'Products', render: (row) => <div>{(row['products'] ?? []).length}</div> },
                            
                            
                            {
                                title: 'Status', render: (row) =>
                                    <Grid item lg={2}>
                                        <Chip label={row['status']}
                                            style={{ backgroundColor: '#f28f16', color: '#ffffff', }} />
                                    </Grid>
                            },
                            {
                                title: 'Actions', render: (row) =>

                                    <Grid container style={{ display: 'flex', flexDirection: 'row' }} >
                                        <Grid item lg={5} >
                                            <Button onClick={() => {
                                                createBrowserHistory().push(Ways.singleOfferpage + row['id'])
                                                window.location.reload();
                                            }} variant="contained" size="small"
                                                className={classes.btnStyle}>View</Button>
                                        </Grid>
                                        <Grid lg={2} item >
                                        </Grid>
                                        <Grid item lg={5}>
                                            <Button onClick={() => {
                                                setCo(row)
                                            }} variant="contained" size="small" style={{ backgroundColor: 'red', boxShadow: 'none', color: '#ffffff', }}>Delete</Button></Grid>
                                    </Grid>
                            }

                        ]}
                        data={data}
                    />
                </Paper>
            </Grid>

            <Dialog open={co['id'] != null} onClose={() => setCo({})}>
                <DialogContent>
                    <Typography variant='body2'>Are you sure you want to delete this Offer ?</Typography>
                    <br />
                    {/* <List style={{display: 'flex', flexDirection: 'row'}}>
                        <ListItem > */}
                            <Button variant="contained" style={{ backgroundColor: 'red', color: '#fff', boxShadow: 'none', marginLeft: 20 }} onClick={() => {
                                API.delete('offer', { offerid: getvalue(co, 'id') }).then(x => {
                                    setCo({})
                                    getOffers()
                                    enqueueSnackbar('Quote Deleted');
                                })
                            }
                            }>Delete</Button>
                        {/* </ListItem>
                        <ListItem > */}
                            <Button variant="outlined" style={{ backgroundColor: '#f7f7f7', boxShadow: 'none', marginLeft: '2%' }} onClick={() => {
                                setCo({});
                            }}>Cancel</Button>
                        {/* </ListItem>
                    </List> */}
                </DialogContent>
            </Dialog>
        </Grid>
    )
}


/*
[
        { name: 'BeeWell Honey Jar', product: 'Manuka', weight: '3500 Jars', value: '2500', birthCity: 63 },
        { name: 'Honey HI Box', product: 'Jarah', weight: '100 Jars', value: '3700', birthCity: 34 },
        { name: 'BeeWell Honey Jar', product: 'Manuka', weight: '3500 Jars', value: '10500', birthCity: 63 },
        { name: 'Honey HI Box', product: 'Jarah', weight: '100 Jars', value: '20,000', birthCity: 34 },
        { name: 'BeeWell Honey Jar', product: 'Manuka', weight: '3500 Jars', value: '50,000', birthCity: 63 },


      ]



*/