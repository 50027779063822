import { Button, Chip, Grid, Link, Paper, List, ListItem, Dialog, DialogContent, Typography, CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Ways, API, Cook, getvalue } from './../../Helpers';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Visibility, Delete } from '@material-ui/icons';
import { PopupHoneyEditableForm } from './../PopupHoneyEditableForm';
import { sentenceCase } from 'sentence-case';


import moment from 'moment'







const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    // margin: theme.spacing(2),
    overflowX: 'hidden',
    backgroundColor: '#fff',
    marginTop: '-2%',

  },

  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },


  fixedHeightPaperEmail: {

    boxShadow: 'none',
    padding: '10px'


  },

}));





function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



export const HoneyHoldingsTable: FunctionComponent<{ currentHolding: any }> = props => {

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [cp, setCp] = useState(undefined)
  const [ctd, setCTD] = useState()
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoadin] = useState(false);



  const getdata = () => {
    setLoadin(true);

    API.get('holdings').then(x => {

      console.log(x.data);
      setData(x.data)
      setLoadin(false);

    });



  }
  useEffect(() => {
    getdata();
    // console.log(JSON.stringify(data))
  }, []);


  let id: number = 1
  const getID = () => {
    return id++;
  }

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div style={{marginTop:'20px'}} >
      <Grid container className={classes.root} spacing={0}>
        <Grid item lg={12} md={12} xs={12}>

          <Paper className={classes.fixedHeightPaperEmail} >

            <MaterialTable



              style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
              title="Holding Categories"
              columns={[

                { title: <p style={{ width: '72px', fontSize: '14px' }} >Holding ID</p>, field: 'rid', render: (row) => <div style={{padding:'0px'}} >HON-HOL-{row['rid']}</div> },
                { title: <p style={{ width: '120px', fontSize: '14px' }} > Dominent Variety </p>,  cellStyle: { textTransform: 'capitalize' }, field: 'honeyType', render: (row) => <div>  {row['honeyType'] ?? 'undefined'}</div> },
                { title: <p style={{ width: '120px', fontSize: '14px' }} > Secondary Variety </p>, cellStyle: { textTransform: 'capitalize' }, field: 'secondaryVariety', render: (row) => <div>  {row['secondaryVariety'] ?? 'undefined'}</div> },
                { title: <p style={{ width: '50px', fontSize: '14px' }} > TA </p>, sorting: false, render: (row) => <div style={{padding:'0px'}} >{row['activityfrom']}-{row['activityto']} </div> },
                { title: <p style={{ width: '35px', fontSize: '14px' }} > MGO</p>, render: (row) => <div style={{padding:'0px'}} >{row['mgoFrom']}-{row['mgoTO']} </div> },
                {
                  title: <p style={{ width: '110px', fontSize: '14px' }} >BK Contributors </p>, cellStyle: {

                     textAlign: 'left',
                  }, field: 'contributors', render: (row) => <div style={{padding:'0px'}} >{row['contributors']}</div>
                },
                // { title: 'MGO', render: (row) => <div>{row['methylglyoxal']}</div> },
                { title: <p style={{ width: '110px', fontSize: '14px', }} >Honey Available </p>, sorting:false, render: (row) => <div>{Number(row['availableStock']??0).toFixed(2)}kg</div> },




                {
                  title: <p style={{ width: '130px', fontSize: '14px', }} >  Actions </p>, sorting: false, render: (row) =>

                    <div style={{ display: 'flex', alignItems: 'center',padding:'0px' }}>
                      <div style={{ marginRight: '10px', }} >
                        <Button onClick={() => {
                          createBrowserHistory().push(Ways.honeysinglePage + '/' + row['id'])
                          window.location.reload();
                        }} fullWidth variant="contained" size="small" style={{ backgroundColor: '#f28f16', color: '#fff',padding:'3.9px',height:'30px'  }} >
                          view
                        </Button>
                      </div>

                      <div style={{ marginRight: '10px', }} >
                        <PopupHoneyEditableForm currentHolding={row} />
                      </div>


                      <div  >
                        <Button onClick={() => {
                          setCTD(row);
                        }} fullWidth variant="contained" size="small" style={{ backgroundColor: 'red', color: '#fff',padding:'3.9px',height:'30px' }} >
                          delete
                        </Button>
                      </div>






                    </div>


                },
              ]}
              data={data}




            />


          </Paper>







        </Grid>

       


        <Dialog open={ctd != undefined} onClose={() => setCTD(undefined)}>
          <DialogContent>
            <Typography variant='body2'>Are you sure you want to delete this Holding Category ?</Typography>
            <br />
            <List>

              <Button style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
                onClick={() => {
                  API.delete('holding', { holdingid: getvalue(ctd, 'id') }).then(x => {
                    if (x.status == 200) {
                      getdata();
                      window.location.reload();
                      enqueueSnackbar('Holding category deleted');
                    } else {
                      enqueueSnackbar('Holding category could not deleted, Category is assigned to IBC')
                    }
                    setCTD(undefined);
                  }
                  )
                }

                }>Delete</Button>
              <Button style={{ backgroundColor: '#f7f7f7' }} onClick={() => {
                setCTD(undefined);
              }}>Cancel</Button>
            </List>
          </DialogContent>
        </Dialog>


      </Grid>

      <div style={{marginTop:'13%'}} >

      </div>
    </div>
  )
}
