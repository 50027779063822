import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Grid, Button, Paper, Link, Dialog, DialogContent, Typography, List, CircularProgress } from '@material-ui/core';
import honey from './../assets/honey.jpg';
import { makeStyles } from '@material-ui/core/styles';
import { API, Ways, getvalue } from '../Helpers';
import { createBrowserHistory } from 'history';





const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: 'auto',



  },


  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },


  fixedHeightPaperEmail: {

    boxShadow: 'none',
    padding: theme.spacing(4),
  },

}));


export const AllProductsTable = () => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false)
  const [cp, setCp] = useState(undefined)
  let id: number = 1;


  const getdata = () => {
    setLoading(true);
    API.get('products').then(x => {
      

      setData(x.data['products']);
      setLoading(false);


    })
  }

  useEffect(() => {
    getdata();
  }, []);

  if(loading) return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress style={{color: '#f28f16'}}/>
  </div>);


  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item lg={4} md={4} xs={12}>
        {/* {data.length} alsdjf */}
        <Paper className={classes.fixedHeightPaperEmail}>
          <Link href={Ways.productAddPage} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="secondary" fullWidth={true} className={classes.btnStyle}>

              Add Product
                    </Button>
          </Link>
        </Paper>
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <Paper className={classes.fixedHeightPaperEmail}>
          <MaterialTable
            style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
            title="All Products"
            columns={[
              { title: 'Product Id', render: row => <div>{'HON-PRO-' + row['id']}</div> },
            
              {
                title: 'Image', cellStyle: { maxWidth: 70}, render: (row) => (
                
                    <img src={row['primaryImage']} style={{ height: 35, width: 35, }} />
                
                )
              },
              { title: 'Name', field: 'productTitle', headerStyle: { marginLeft: -30} },
              // { title: 'Category', render: (row) => <p>{row['category'] ? row['category']['name'] : ''}</p> },
            { title: 'Brand', render: (row) => <p>{row['brand']}</p>},
              { title: 'Min Qty', headerStyle: { textAlign: 'left'},  render: row => <Typography align="center">{row['minQuantity']}cartons</Typography> , type: 'numeric' },
            // {title: 'Wholesale Price', render: row => <p>{row['variations'][0]['price']}</p> },
            // {title: 'RRP', render: row => <p>{row['variations'][0]['rrp']}</p> },
              // {
              //   title: 'Price Range(kg)',
              //   render: (cp) => {
              //     return <p>{cp['minPrice']} -  {cp['maxPrice']}</p>
              //   },
              // },
              {
                title: 'Actions', render: (cr) =>
                  <Grid container style={{ display: 'flex', flexDirection: 'row' }} >
                    <Grid item lg={5}>
                      <Button onClick={() => {
                        createBrowserHistory().push(Ways.productEditPage + '/' + cr['id']);
                        window.location.reload();
                      }} variant="contained" size="small" style={{ backgroundColor: '#f28f16', boxShadow: 'none', color: '#ffffff', }}>View</Button>
                    </Grid>
                    <Grid item lg={2}></Grid>
                    <Grid item lg={5}>
                      <Button onClick={() => {
                        setCp(cr);

                      }} variant="contained" size="small" style={{ backgroundColor: 'red', boxShadow: 'none', color: '#ffffff', marginLeft: 10 }}>Delete</Button></Grid>
                  </Grid>
              },
            ]}

            options={{
              // headerStyle: {
              //   textAlign: 'left',
                
              // }
            }}
            data={data}

            




          />
        </Paper>

        <Dialog open={cp != undefined} onClose={() => setCp(undefined)}>
          <DialogContent>
            <Typography variant='body2'>Are you sure you want to delete this product ?</Typography>
            <br />
            <List>
              <Button style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }} onClick={() => {
                API.delete('product', { productid: getvalue(cp, 'id') }).then(x => {
                  if (x.status == 200) {
                    getdata();
                    setCp(undefined)
                  }
                })
              }
              }>Delete</Button>
              <Button style={{ backgroundColor: '#f7f7f7' }} onClick={() => {
                setCp(undefined);
              }}>Cancel</Button>
            </List>
          </DialogContent>
        </Dialog>


      </Grid>
    </Grid>
  )
}