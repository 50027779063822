import { Paper, List, ListItem, Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, ListItemSecondaryAction, ListItemText, MenuItem, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { API, Ways } from '../Helpers';
import { createBrowserHistory } from 'history'
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Cancel } from '@material-ui/icons';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        
        padding: theme.spacing(0),
        overflow: 'auto',

    },

    btnStyle: {

        backgroundColor: '#f28f16',
        boxShadow: 'none',
        color: '#ffffff',


        '&.MuiButton-contained': {


            '&:hover': {


                backgroundColor: '#f28f16',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },


    fixedHeightPaperEmail: {

        boxShadow: 'none',
        padding: '10px',
    },

}));





export const HoneyRates = () => {
    const classes = useStyles();
    const [data, setData] = useState<any>([])
    const [addRates, setAddRates] = useState<any>({})
    const [open, setOpen] = useState(false)
    const [editRate, setEditRate] = useState(false)
    const [ctd, setCTD] = useState<any>({})
    const { enqueueSnackbar } = useSnackbar();
    const getData = async () => {
        var res = await API.get("ta")
        console.log(res.data)
        setData(res.data)
    }

    useEffect(() => {
        getData();
    }, [])

    const set = (e: React.FormEvent) => {
        var n = e.target['name'];
        var v = e.target['value'];
        addRates[n] = v;
        setAddRates({ ...addRates, })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAddRates({ addRates: null })
        setEditRate(false)
    };

    const dData = [
        {
            id: '1',
            ta: '20',
            variety: 'blackbutt',
            price: '400'
        },
    ]


    return (
        <>
            <div style={{ marginTop: '-0.7%' }} >
                <Grid container >
                    <Grid item xs={12} >
                        <div style={{ width: '20%', marginBottom: '29px', }} >
                            <Button  onClick={handleClickOpen} variant="contained" 
                                style={{ backgroundColor: '#f28f16', color: '#fff', }}
                                startIcon={<Add />}
                                >

                                Add New Rate
                            </Button>
                        </div>
                    </Grid>
                    <Grid item lg={9} md={10} xl={7} >
                        <Paper className={classes.fixedHeightPaperEmail} >
                            {/* <Typography variant="h6">Honey contribution to holding {data['holding'][2]['rid']}</Typography> */}
                            <MaterialTable


                                style={{ boxShadow: 'none', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
                                title="Honey Rates"
                                columns={[
                                    
                                    
                                    { title: <p style={{ textAlign: 'left',width:'90px',fontSize:'14px' }} > Variety </p>, field: 'variety', render: (row) => <div  >{row['variety']}</div> },
                                    { title: <p style={{ textAlign: 'left',width:'90px',fontSize:'14px' }} >  TA From</p>, field: 'taFrom', render: (row) => <div>{row['taFrom']}</div> },
                                    { title: <p style={{ textAlign: 'left',width:'90px',fontSize:'14px' }} >  TA To</p>, field: 'taTo', render: (row) => <div>{row['taTo']}</div> },
                                    { title: <p style={{ textAlign: 'left',width:'80px',fontSize:'14px' }} >  Price  </p>, field: 'price', render: (row) => <div>${Number(row['price']??0).toFixed(2)}</div> },
                                    // { title: <p style={{ textAlign: 'center', }} >  </p>, field: 'price', render: (row) => <div></div> },










                                    {
                                        title: <p style={{ textAlign: 'start',width:'110px',fontSize:'14px', }} > Actions </p>, sorting: false, render: (row) =>

                                            <div style={{ display:'flex', flexDirection: 'row', justifyContent:'flex-start' }}>

                                                <div style={{ marginRight:'10px' }} >


                                                    <Button onClick={() => {
                                                        setAddRates({ ...row })
                                                        handleClickOpen();
                                                        setEditRate(true);
                                                    }} variant="contained" size="small"
                                                        style={{backgroundColor:'#f28f16',color:'white'}}>Edit</Button>

                                                </div>

                                                <div style={{ marginRight:'10px' }} >
                                                    <Button
                                                        variant="contained" size="small"
                                                        style={{ backgroundColor: '#ff0000', color: '#fff' }}
                                                        onClick={async () => {
                                                            // setCTD(row);
                                                            var res = await API.delete('ta', { taid: row['id'] })
                                                            if (res.status == 200) {
                                                                enqueueSnackbar("Deleted Successfully!")
                                                                var index = (data as []).findIndex(x => x['id'] == row['id'])
                                                                var d = data as [];
                                                                if (index != -1) {
                                                                    d.splice(index, 1);
                                                                }

                                                                console.log(d);
                                                                setData([...d]);

                                                            }
                                                        }}
                                                    >
                                                        delete
                                                    </Button>
                                                </div>




                                            </div>


                                    },
                                    

                                ]}
                                data={data}

                            />


                        </Paper>
                    </Grid>
                </Grid>
            </div>

            <div style={{marginTop:'14%'}} >

            </div>

            <div>


                {/* dialog */}
                <Dialog
                    fullWidth
                    open={open}
                    maxWidth="sm"
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    style={{ height: 800 }}
                >
                    <ListItem>
                        <ListItemText>
                            <DialogTitle id="form-dialog-title"> {editRate == true ? 'Update Honey Rate:' : 'Honey Rate:'} </DialogTitle>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton>
                                <Cancel onClick={handleClose} />

                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>




                    <DialogContent >


                        <ValidatorForm onSubmit={async () => {
                            delete addRates['tableData']

                            if (editRate == true) {
                                var res = await API.put('ta', addRates, { taid: addRates['id'] })
                                window.location.reload();
                                enqueueSnackbar('Updated successfully!')


                            } else {
                                var res = await API.post('ta', addRates)
                                console.log(res.data)
                                window.location.reload();
                                enqueueSnackbar('New Rate Added!')
                            }

                            // else {
                            //   API.put('ibc', contribution, { ibcid: contribution['id'] }).then(x => {
                            //     if (x.status == 200) {
                            //       window.location.reload();
                            //       enqueueSnackbar('IBC is Updated');
                            //     }
                            //   }
                            //   )
                            // }


                        }}>






                            <Grid container direction="row" spacing={2}>
                                <Grid item lg={12}>
                                    <SelectValidator name={'variety'} variant="outlined" size="small" value={addRates['variety']} style={{ width: '100%' }}
                                        onChange={set}
                                        label="Select Product Label"


                                        errorMessages={['This field is required']}
                                        validators={['required']}



                                    >
                                        <MenuItem value={'TA0'}>TA0</MenuItem>
                                        <MenuItem value={'TA15+'}>TA15+</MenuItem>
                                        <MenuItem value={'TA25+'}>TA25+</MenuItem>
                                        <MenuItem value={'TA35+'}>TA35+</MenuItem>

                                    </SelectValidator>
                                </Grid>
                                <Grid item xs={8} style={{ border: '4px solid #fff' }}>
                                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">TA Range:</Typography>
                                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>
                                        <ListItem>
                                            <Typography variant="body2">From</Typography>
                                        </ListItem>

                                        <ListItem>


                                            <TextValidator
                                                name={'taFrom'}
                                                margin="dense"
                                                value={addRates['taFrom']}
                                                id="taFrom"
                                                label=""
                                                onChange={set}
                                                validators={['required', 'isNumber', 'minNumber:' + addRates['taFrom'] ?? 0]}
                                                errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}
                                                type="amount"
                                                fullWidth
                                                variant="outlined"

                                            />
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant="body2">To</Typography>
                                        </ListItem>

                                        <ListItem>


                                            <TextValidator
                                                name={'taTo'}
                                                margin="dense"
                                                value={addRates['taTo']}
                                                id="taTo"
                                                label=""
                                                onChange={set}
                                                validators={['required', 'isNumber', 'minNumber:' + addRates['taFrom'] ?? 0]}
                                                errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}

                                                type="amount"
                                                fullWidth
                                                variant="outlined"

                                            />
                                        </ListItem>


                                    </List>
                                </Grid>



                                <Grid item lg={12}>
                                    <TextValidator
                                        name={'price'}
                                        margin="dense"
                                        value={addRates['price']}
                                        id="price"
                                        label="Enter Rate per kg ($) "
                                        onChange={set}
                                        validators={['required',]}
                                        errorMessages={['This field is required']}
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={8} >

                                    <Button type='submit' variant="contained" size="large" style={{ backgroundColor: '#f28f16', color: '#fff', marginTop: 10, marginBottom: 15, paddingLeft: 30, paddingRight: 30, }} >
                                        {
                                            editRate == true ? 'Update' : 'Add Rate'
                                        }
                                    </Button>

                                </Grid>



                            </Grid>





                            {/* <Grid item lg={3}>
                                <Button onClick={async () => {
                                    // console.log(JSON.stringify(honeyTransaction))

                                    var remain = `${ibc['remaining'] == null ? ibc['payLoad'] : ibc['remaining']}`;
                                    if (honeyTransaction['amount'] > remain) {
                                        alert(`ammount ${honeyTransaction['amount']} is greater then total quantity! `)

                                    } else {
                                        var res = await API.post('takeHoney', {
                                            ibcid: ibc['id'],
                                            country: honeyTransaction['country'],
                                            weight: honeyTransaction['amount']
                                        });
                                        handleClose()
                                        window.location.reload()
                                    }

                                }} type='submit' fullWidth variant="contained" size="large" style={{ backgroundColor: '#f28f16', color: '#fff', marginTop: 75, marginBottom: 15, paddingLeft: 20, paddingRight: 20, }} autoFocus>
                                    withdraw
                                </Button>
                            </Grid> */}

                            <Grid item lg={9}></Grid>
                        </ValidatorForm>
                    </DialogContent>

                </Dialog>

            </div>

        </>
    )
}


