import React, { useState, FunctionComponent, useEffect } from 'react';
import { Grid, Button, FormControl, MenuItem, Select, InputLabel, Typography, List, ListItem, ListItemSecondaryAction, IconButton, ListItemText, InputBase, Paper, Link, CircularProgress } from '@material-ui/core';
import { alpha, createStyles, fade, makeStyles, styled, Theme } from '@material-ui/core/styles';
import { Cancel, ViewAgenda, EditOutlined, DeleteOutlined, Visibility } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Add, SearchOutlined } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Edit } from '@material-ui/icons';
import { API, Ways } from './../../Helpers';
import { useSnackbar } from 'notistack';
import { SetTimer } from './../../Quote/SetTimer';
import MaterialTable from 'material-table';
import { ImageUploader, SelectImg } from './../../Product/SelectImg'
import { createBrowserHistory } from 'history';
import { NewContributionPopup } from './NewContributionPopup';
import { ImgUpload } from '../../SellerProfile/ImgUpload';
import firebase from 'firebase';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    content: {

      maxHeight: 800,
    },
    root: {
      display: 'flex',
      overflow: 'auto',
    },
    fixedHeightPaperEmail: {

      boxShadow: 'none',
      padding: theme.spacing(4),
    },
    search: {
      position: 'relative',
      marginRight: '2%',
      // borderRadius: theme.shape.borderRadius,
      borderRadius: 20,
      border: '1px solid lighgray',
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },

    inputWrapper: {
      border: '1px solid lightgray',
      borderRadius: '15px',
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': {
        borderColor: 'orange'
      },
      '&:focus': {
        borderColor: 'orange'
      }
    },

    input: {
      border: 'none',
      outline: 'none',
      background: 'none',
      '&:focus': {
        outline: 'none'
      }
    },
    centerAlign: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btnStyle: {

      backgroundColor: '#f28f16',
      boxShadow: 'none',
      color: '#ffffff',


      '&.MuiButton-contained': {


        '&:hover': {


          backgroundColor: '#FECF51',
          color: '#ffffff',
          boxShadow: 'none',
        },
      },
    },
    hover: {
      '&:hover': {
        cursor: 'pointer'
      }
    }

  }),
);



export const HoneyContributionPopup: FunctionComponent<{ onAdded?: () => void, currentIBC?: any, currentVareity?: any, currentMethylglyoxal?: any, currentDate?: any, currentSiteLocation: any, currentBlossom: any, currentHolding?: any, currentAnti?: any, currentOrganic?: any }> = props => {


  const [contribution, setContribution] = useState<any>({});
  const [currentPriceForTa , setCurrentPriceForTa] = useState<any>();
  const [data, setData] = useState<any[]>([])
  const [cat, setCat] = useState<any[]>([])
  const [beekeeper, setBeekeeper] = useState<any[]>([])
  const [focused, setFocused] = useState(false);
  const [isAddIBCDialogOpen, setIsAddIBCDialogOpen] = React.useState(false);
  const [searchIbc, setSearchIbc] = React.useState<any>(null);
  const [isIBCSearchDialogOpen, setisIBCSearchDialogOpen] = React.useState(false);
  const [ibcID, setIbcID] = React.useState<any>('');
  const [honeyRates, setHoneyRates] = useState<any>([])
  const [taNum,setTaNum] = useState(0)
  const [allIbcs,setAllIbcs] = useState<any>()


  const [foundIbc, setFoundIbc] = React.useState<any>()
  const [searchLoading, setSearchLoading] = React.useState<boolean>(false)




  const set = (e: React.FormEvent) => {
    var n = e.target['name'];
    var v = e.target['value'];
    contribution[n] = v;
    setContribution({ ...contribution, })

  }

  const getAllIbcs = async () => {
    var res = await API.get("ibcs_all")
    setAllIbcs(res.data)
    // console.log(res.data)

  }

  useEffect(() => {

    getHoneyRates();

    if (props.currentIBC != null) {
      // console.log(JSON.stringify(props.currentIBC))
      setContribution(props.currentIBC)
    } else {

      setContribution({ holding: props.currentHolding, honeyType: props.currentVareity, methylglyoxal: props.currentMethylglyoxal, apiarySiteLocation: props.currentSiteLocation, dateOfExtraction: props.currentDate, flowersInBlossom: props.currentBlossom, antioxidents: props.currentAnti, organicNonOrganic: props.currentOrganic })


    }

    // console.log(JSON.stringify(contribution))
    API.get('holding', { holdingid: window.location.pathname.split('/')[3] }).then(x => {
      setCat(x.data);
    })



    // TODO : Uncomment this if something breaks in this page

    // API.get('beekeepers').then(x => {
    //   // console.log(x.data);
    //   setBeekeeper(x.data);


    // })

    getAllIbcs();

  }, [])




const suggestions = () => {
  if(allIbcs != null){
    let items = allIbcs.map((x)=> x.rid)
    // console.log(items.filter(word => word.length >= 0))
  }
  else{
    // console.log('false')
  }
}





  const classes = useStyles();


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setIsAddIBCDialogOpen(true);
  };

  const handleClose = () => {
    setIsAddIBCDialogOpen(false);
    setIbcID('');
    setSearchIbc(false);
  };

  const handleClickOpenNew = () => {
    setisIBCSearchDialogOpen(true);
  };

  const handleCloseNew = () => {
    setisIBCSearchDialogOpen(false);
    setIbcID('');
    setSearchIbc(false);
    setFoundIbc({})
  };

  const { enqueueSnackbar } = useSnackbar();

  const tryToFindIbc = async (id: string) => {
    try {

      setSearchLoading(true)
      var res = await API.post('ibcByRid', { rid: id })
      console.log(res.data)
      if (res.data['id'] != null) {
        console.log(res.data)
        setFoundIbc(res.data);
      } else {
        setFoundIbc({ error: "Nothing found. IBC number doesn’t exist yet. " });
      }
      setSearchLoading(false)

    } catch (error) {
      setSearchLoading(false)
      setFoundIbc({ error: "Nothing found. " });
    }
    // console.log(foundIbc)

  }


  const filterFunction = (res) => {

    if(res == null){
      return;
    }

    var ourTa = Number(res);

    for (let i = 0; i < honeyRates.length; i++) {
      const e = honeyRates[i];
      var lowerLimit = Number(e['taFrom'])
      var uppowerLimit = Number(e['taTo'])

      if(ourTa >=lowerLimit && ourTa <= uppowerLimit){
        setContribution({...contribution , initialPayment : e['price'] * contribution.payLoad , activity : res})
        setCurrentPriceForTa(e['price'])
        // console.log(e['price'])
        return;
      }

    }

// 
    // for(let i = 0 ;i < res.length ; i++){
      // if(taNum > res.data[i]['taFrom'] && taNum <= res.data[i]['taTo']){
        // setActualRate(res.data[i]['price'])
        // console.log(res.data[i]['taTo'])
    // }else {
      // console.log('nooo')
    // }
    // }
    // console.log(res.data[0]['taFrom'])
    // console.log(res.data[i])
    // console.log(actualRate)
  }



  const getHoneyRates = async () => {
    var res = await API.get("ta")
    setHoneyRates(res.data)
    // console.log(res.data)
  }


  const handleTaInput = (e) => {

    // setContribution({ ...contribution, activity: e.target.value })

    var c = parseInt(e.target.value);
    // console.log(c)
    if(c != NaN && c <= 100 && c > 0){
      filterFunction(e.target.value);    
    }else{
      // console.log("RES#ET")
      setContribution({...contribution , activity : c })
      setCurrentPriceForTa(null)
    }

  }

  return (


    <div >


      <div style={{marginBottom:'8px'}} > <Grid item lg={12} xs={12}>


        {/* <Button variant="contained" style={{ backgroundColor: '#f28f16', color: '#fff' }} onClick={handleClickOpen}>
        Honey Contribution
        </Button> */}


        {
          (props.currentIBC != null)

            ?

            //    <IconButton onClick={handleClickOpen}>
            //    <Edit />
            //  </IconButton>

            <Button size="small" style={{ color: '#fff', backgroundColor: '#f28f16', marginTop: 16, marginRight: -20 }} onClick={handleClickOpen} variant="contained">Edit</Button>


            :

            <Button
              variant="contained"
              style={{ backgroundColor: '#f28f16', color: '#fff',marginLeft:'3px' }} onClick={() => {

                handleClickOpenNew()
                // console.log(contribution)


              }}
              startIcon={<Add />}

            >
              Add IBC
            </Button>

        }

        {/*_____ assign IBC to holding category ____ */}

        {contribution != null && contribution['id'] != null && <Dialog
          fullScreen={fullScreen}
          open={isAddIBCDialogOpen}
          maxWidth="lg"
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          style={{ height: 800 }}

        >
          <ListItem>
            <ListItemText>
              <DialogTitle id="form-dialog-title">Assign IBC </DialogTitle>
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton>
                <Cancel onClick={handleClose} />

              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>




          <DialogContent className={classes.content}>


            <ValidatorForm onSubmit={async () => {
              //  setLoading(true);
              // console.log(contribution)
              //  API.post('ibc', contribution);
              // window.location.pathname.split('/')[3]


              if (props.currentIBC == null) {
                API.put('ibc', contribution, { ibcid: contribution['id'], holdingid: window.location.pathname.split('/')[3] }).then(x => {
                  if (x.status == 200) {
                    // console.log(contribution)
                    window.location.reload();
                    enqueueSnackbar('IBC added successfully');
                  }
                }
                )
              }


              else {
                API.post('ibc', contribution, { ibcid: contribution['id'] }).then(x => {
                  if (x.status == 200) {
                    window.location.reload();
                    enqueueSnackbar('IBC is Updated');
                  }
                }
                )
              }


            }}>





              <Grid container direction="row" spacing={2}>

                <Grid item lg={6}>
                  <TextValidator
                    name={'beekeeper'}
                    margin="dense"
                    value={contribution['beekeeper']['fullName']}
                    label="Beekeeper"
                    disabled
                    fullWidth
                    variant="outlined"
                  />
                </Grid>

                {/* <Grid item lg={6}>
                  <TextValidator
                    name={'beekeeper'}
                    margin="dense"
                    value={contribution['stage']} label="Select Stage"
                    disabled
                    fullWidth
                    variant="outlined"
                  />
                </Grid> */}
                <Grid item lg={6}>

                  <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                  <SelectValidator name={'stage'} variant="outlined" size="small" value={contribution['stage']} style={{ width: '100%', marginTop: '1%' }}
                    onChange={set}
                    label="Select Stage"


                    errorMessages={['This field is required']}
                    validators={['required']}



                  >
                    <MenuItem value={'delivery'}>Delivery</MenuItem>
                    <MenuItem value={'verifiedByLab'}>Verified by lab</MenuItem>

                  </SelectValidator>

                </Grid>




                {
                  contribution == null && contribution['id'] == null && <Grid item lg={6}>

                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>

                    <SelectValidator name={'beekeeper'} variant="outlined" size="small" value={contribution['beekeeper']} style={{ width: '100%' }}
                      onChange={set}
                      label="Select Beekeeper"
                      errorMessages={['This field is required']}
                      validators={['required']}
                    >

                      {[<MenuItem key={-1} value={0}>None</MenuItem>].concat(beekeeper.map((x, i) => <MenuItem key={x} value={x}>{x['fullName']}</MenuItem>))}


                    </SelectValidator>


                  </Grid>
                }


                {
                  contribution != null && contribution['id'] == null && <Grid item lg={6}>

                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                    <SelectValidator name={'stage'} variant="outlined" size="small" value={contribution['stage']} style={{ width: '100%' }}
                      onChange={set}
                      label="Select Stage"
                      errorMessages={['This field is required']}
                      validators={['required']}



                    >
                      <MenuItem value={'delivery'}>Delivery</MenuItem>
                      <MenuItem value={'verifiedByLab'}>Verified by lab</MenuItem>

                    </SelectValidator>

                  </Grid>
                }

                {/* <Grid item lg={4}>
<TextValidator
  variant="outlined"
 
  size="small"
  fullWidth
  value={contribution['quantity']}
  onChange={set}
  label="Quantity"
  name="quantity"
  errorMessages={['this field is required', 'Job Title is in an invalid format']}
>
</TextValidator>
</Grid> */}


                <Grid item lg={12}>
                  <Typography variant="subtitle2">Honey Chractristics:</Typography>
                </Grid>

                {/* <Grid item lg={4}>

               

                    <SelectValidator id='floralType' name='floralType' value={contribution['floralType']} variant="outlined" size="small" style={{ width: '100%' }}
                      onChange={set}
                      label="Mono Floral/Multi Floral"
                      disabled
                    
                      errorMessages={['this field is required']}

                    >
                     
                      <MenuItem value={'Mono-Floral'}>Mono Floral</MenuItem>
                      <MenuItem value={'Multi-Floral'}>Multi Floral</MenuItem>



                    </SelectValidator>
             
                </Grid> */}
                <Grid item lg={4}>
                  <SelectValidator id='honeyType' name={'honeyType'} value={contribution['honeyType']} variant="outlined" margin="dense" size="small" style={{ width: '100%' }}
                    onChange={set}
                    label="Select Dominent Variety"
                    errorMessages={['This field is required']}
                    validators={['required']}

                  >
                    <MenuItem value={'Blackbutt'}>Blackbutt</MenuItem>
                    <MenuItem value={'Red Gum'}>Red Gum</MenuItem>
                    <MenuItem value={'Jarrah'}>Jarrah</MenuItem>
                    <MenuItem value={'Red Bell'}>Red Bell </MenuItem>
                    <MenuItem value={'Wildflower'}>Wildflower</MenuItem>
                    <MenuItem value={'Whitegum'}>Whitegum</MenuItem>
                    <MenuItem value={'Banksia'}>Banksia</MenuItem>
                  </SelectValidator>
                </Grid>
                <Grid item lg={4}>
                  <SelectValidator id='secondaryVariety' name={'secondaryVariety'} value={contribution['secondaryVariety']} variant="outlined" margin="dense" size="small" style={{ width: '100%' }}
                    onChange={set}
                    label="Select Second Variety"
                    errorMessages={['This field is required']}
                    validators={['required']}

                  >
                    <MenuItem value={'Blackbutt'}>Blackbutt</MenuItem>
                    <MenuItem value={'Red Gum'}>Red Gum</MenuItem>
                    <MenuItem value={'Jarrah'}>Jarrah</MenuItem>
                    <MenuItem value={'Red Bell'}>Red Bell </MenuItem>
                    <MenuItem value={'Wildflower'}>Wildflower</MenuItem>
                    <MenuItem value={'Whitegum'}>Whitegum</MenuItem>
                    <MenuItem value={'Banksia'}>Banksia</MenuItem>
                  </SelectValidator>
                </Grid>

                <Grid item lg={4}>



                  <SelectValidator id='Organic' name={'organicNonOrganic'} value={contribution['organicNonOrganic']} variant="outlined" size="small" style={{ width: '100%' }}
                    onChange={set}
                    label="Organic/Non Organic"

                    errorMessages={['This field is required']}
                    validators={['required']}

                  >
                    <MenuItem value={'organic'}>organic</MenuItem>
                    <MenuItem value={'non-organic'}>non-organic</MenuItem>



                  </SelectValidator>


                </Grid>


                {/* 
                <Grid item lg={4}>

           
                    <TextValidator


                      variant="outlined"

                      required
                      size="small"
                      fullWidth
                      value={contribution['countryOfOrigin']}
                      onChange={set}
                      disabled
                      label="Country Of Origin"
                      name="countryOfOrigin"
                  
                      errorMessages={['this field is required', 'Job Title is in an invalid format']}
                    >

                    </TextValidator>
           
                </Grid> */}

                {/* 
                <Grid item lg={4}>

                    <TextValidator


                      variant="outlined"

               
                      required
                      size="small"
                      fullWidth
                      value={contribution['countryOfPackaged']}
                      onChange={set}
                      label="Country Of Packaged"
                      name="countryOfPackaged"
                      disabled
                 
                      errorMessages={['this field is required', 'Job Title is in an invalid format']}
                    >

                    </TextValidator>
              
                </Grid> */}



                {/* <Grid item lg={4}>


                    <SelectValidator id='floralType' name='containsImportedProducts' value={contribution['containsImportedProducts']} variant="outlined" size="small" style={{ width: '100%' }}
                      onChange={set}
                      label="Contains Imported Products"
                      disabled
                      errorMessages={['this field is required']}


                    >
                     <MenuItem value={'true'}>Yes</MenuItem>
                      <MenuItem value={'false'}>No</MenuItem>



                    </SelectValidator>
          
                </Grid> */}


                {/* <Grid item lg={4}>

            
                    <TextValidator
                      name={'originOfImportedProducts'}
                      margin="dense"
                      value={contribution['originOfImportedProducts']}
                      id="amount"
                      label="Origin Of Imported Products"
                      disabled
                      onChange={set}
                 
                      errorMessages={['This field is required']}
                      type="amount"
                      fullWidth
                      variant="outlined"
                     
                    />


            
                </Grid> */}






                {/* <Grid item lg={4}>
                    style={{marginTop: 15}}
                <SelectValidator id='holding' name='holdingCategories' value={data['rid']} variant="outlined" size="small" style={{ width: '100%' }}
                      onChange={set}
                      label="Holding Category"
                 
                      errorMessages={['this field is required']}
                    >
                      {[<MenuItem key={-1} value={0}>None</MenuItem>].concat(data.map((x, i) => <MenuItem key={i} value={x}>{x['rid']}</MenuItem>))}
                    </SelectValidator>
                    </Grid> */}





                <Grid item lg={4}>
                  <TextValidator
                    name={'apiarySiteLocation'}
                    margin="dense"
                    value={contribution['apiarySiteLocation']}
                    id="amount"
                    label="Apiary Site Location"
                    onChange={set}
                    errorMessages={['This field is required']}
                    type="amount"

                    fullWidth
                    variant="outlined"
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextValidator
                    name={'apiarySiteLocation2'}
                    margin="dense"
                    value={contribution['apiarySiteLocation2']}
                    id="amount"
                    label="Apiary Site Location 2"
                    onChange={set}
                    errorMessages={['This field is required']}
                    type="text"

                    fullWidth
                    variant="outlined"
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextValidator
                    name={'flowersInBlossom'}
                    margin="dense"
                    value={contribution['flowersInBlossom']}
                    id="amount"
                    label="Flowers In Blossom"
                    onChange={set}
                    errorMessages={['This field is required']}
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>


                <Grid item lg={4}>
                  <TextValidator
                    name={'dateOfExtraction'}
                    margin="dense"
                    value={contribution['dateOfExtraction']}
                    id="amount"
                    label="Date Of Extraction"
                    onChange={set}
                    errorMessages={['This field is required']}
                    type="amount"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>







                <Grid item lg={4}>




                  <TextValidator
                    name={'payLoad'}
                    margin="dense"
                    value={contribution['payLoad']}
                    id="amount"
                    label="Pay Load (kg)"
                    onChange={set}
                    errorMessages={['This field is required', 'pay load must be a number',]}
                    // validators={['required', 'isNumber', 'maxNumber:' + contribution['maxPrice'] ?? 1400]}
                    type="amount"
                    fullWidth
                    variant="outlined"

                  />

                </Grid>

                {/* <Grid item lg={4}>
               
<TextValidator
  name={'totalSold'}
  margin="dense"
  value={contribution['totalSold']}
  id="amount"
  label="Total Sold"
  onChange={set}
  errorMessages={['This field is required']}
  type="amount"
  fullWidth
  variant="outlined"
/>
</Grid> */}





                {/* <Grid item lg={4}>
<TextValidator
  name={'bonusPayment'}
  margin="dense"
  value={contribution['bonusPayment']}
  id="amount"
  label="Bonus Payment"
  onChange={set}
  errorMessages={['This field is required', 'initial payment must be a number']}
  validators={['required', 'isNumber']}
  type="amount"
  fullWidth
  variant="outlined"

/>

</Grid> */}










                {/* <Grid item lg={3}>
  {
    focused ?
    <ListItem style={{border: '1px solid #d1cfcf', borderRadius: 5, paddingTop: 0, marginTop: 8, paddingLeft: 10}}>
    <SetTimer  onChange={(x) => {
      var nobj = { ...contribution, dateOfExtraction: new Date(x) }
      setContribution(nobj);
      console.log(nobj)
      // console.log(x.offer)
  }} />
  </ListItem>
    :
    <TextValidator
    name={''}
    margin="dense"
    value={contribution['']}
    id="amount"
    label="Date Of Extraction" 
    onFocus={() => setFocused(true)}
    onChange={set}
    errorMessages={['This field is required']}
    type="amount"
    fullWidth
    variant="outlined"
  />

  }


   


</Grid> */}


              </Grid>


              <Grid container direction="row" spacing={6} style={{ backgroundColor: '#fbfbfb', marginTop: '3%' }}>





                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Total Activity:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>


                    <ListItem>



                      <TextValidator
                        name={'activity'}
                        margin="dense"
                        value={contribution['activity']}
                        id="number"
                        label=""
                        onChange={(e) => {
                          handleTaInput(e)
                        }}
                        // validators={['required']}
                        errorMessages={['This field is required', 'Total Activity must be a number']}
                        validators={['required', 'isNumber']}
                        type="number"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>


                  </List>
                </Grid>




                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">MGO:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>


                    <ListItem>
                      <TextValidator
                        name={'mgo'}
                        margin="dense"
                        value={contribution['mgo']}
                        id="amount"
                        label=""
                        onChange={set}

                        errorMessages={['This field is required', 'initial payment must be a number']}
                        validators={['required', 'isNumber']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>



                  </List>
                </Grid>


                {/* 
                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Percentage Of Imported Products:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>
               

                    <ListItem>
                      <TextValidator
                        name={'productsPercentage'}
                        margin="dense"
                        value={contribution['productsPercentage']}
                        id="amount"
                        label=""
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required', 'initial payment must be a number']}
                        validators={['required', 'isNumber']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>

                 


                  </List>
                </Grid> */}



                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Moisture:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>


                    <ListItem>
                      <TextValidator
                        name={'moisture'}
                        margin="dense"
                        value={contribution['moisture']}
                        id="amount"
                        label=""
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required', 'initial payment must be a number']}
                        validators={['required', 'isNumber']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>



                  </List>
                </Grid>


                {/* 
                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Energy/Carbs:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>
           
                    <ListItem>
                      <TextValidator
                        name={'energyCarbs'}
                        margin="dense"
                        value={contribution['energyCarbs']}
                        id="amount"
                        label=""
                        onChange={set}
                        errorMessages={['This field is required', 'initial payment must be a number']}
                        validators={['required', 'isNumber']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>

                

                  </List>
                </Grid> */}


                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Glucose Index GI:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>


                    <ListItem>
                      <TextValidator
                        name={'glucoseIndex'}
                        margin="dense"
                        value={contribution['glucoseIndex']}
                        id="amount"
                        label=""
                        onChange={set}
                        errorMessages={['This field is required', 'initial payment must be a number']}
                        validators={['required', 'isNumber']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>




                  </List>
                </Grid>


                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">HMF:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>

                    <ListItem>
                      <TextValidator
                        name={'hmf'}
                        margin="dense"
                        value={contribution['hmf']}
                        id="amount"
                        label=""
                        onChange={set}
                        errorMessages={['This field is required', 'initial payment must be a number']}
                        validators={['required', 'isNumber']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>




                  </List>
                </Grid>

                <Grid item lg={4} style={{ border: '4px solid #fff' }} >  </Grid>





              </Grid>


              <Grid container spacing={1} >


                <Grid item xs={12} lg={4} style={{ marginTop: '4%' }} >
                  <TextValidator
                    name={'initialPayment'}
                    margin="dense"
                    value={contribution['initialPayment']}

                    id="amount"
                    label="Delivery Payment ($)"
                    onChange={set}
                    errorMessages={['This field is required', 'initial payment must be a number']}
                    validators={['required', 'isNumber']}
                    type="amount"
                    fullWidth
                    variant="outlined"

                  />
                 {
                   currentPriceForTa !=null && <>
                    <p>Payload x Price for Current Total Activity</p>
                    <p>{contribution['payLoad']} x {currentPriceForTa}</p></>
                 }
                </Grid>

                <Grid item lg={12} sm={12}>

                  <div>
                    <div>
                      <p style={{ fontSize: '18px', fontWeight: '400' }} >
                        Lab Report:
                      </p>
                    </div>
                    {
                      contribution['labReport'] != null ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} >
                          <p style={{ padding: '10px' }} >
                            Lab Report:  {
                              contribution['labReport'].split('/')[7].split('%')[1].substring(0, 15)
                            }
                          </p>
                          <a target='_blank' href={contribution['labReport']}>
                            <IconButton style={{ color: '#f28f16', paddingLeft: 20, paddingRight: 20 }} >
                              <Visibility />
                            </IconButton>
                          </a>

                          <IconButton onClick={async () => {

                            await firebase.storage().refFromURL(contribution['labReport']).delete();
                            setContribution({ ...contribution, labReport: null })

                          }} style={{ color: 'orangered', paddingRight: 20 }} >
                            <DeleteOutlined />
                          </IconButton>
                        </div>
                        :
                        <ImageUploader onAdd={(x: any) => {
                          setContribution({ ...contribution, labReport: x });
                        }} />
                    }
                  </div>


                </Grid>
                <Grid item lg={3} >
                  {
                    (props.currentIBC == null)
                      ?


                      <Button type='submit' onClick={() => {
                        // console.log(contribution)
                      }} fullWidth variant="contained" size="large" style={{ backgroundColor: '#f28f16', color: '#fff', marginTop: 20, marginBottom: 15, paddingLeft: 20, paddingRight: 20, }} >
                        assign IBC
                      </Button>

                      :

                      <Button type='submit' fullWidth variant="contained" size="large" style={{ backgroundColor: '#f28f16', color: '#fff', marginTop: 75, marginBottom: 15, paddingLeft: 20, paddingRight: 20, }} >
                        Update
                      </Button>
                  }
                </Grid>
              </Grid>




              <Grid item lg={9}></Grid>


            </ValidatorForm>
          </DialogContent>




          <DialogActions>

            <Grid item lg={3} xs={12}>





            </Grid>

            <Grid item lg={8}>

            </Grid>

            <Grid item lg={1}>

            </Grid>


          </DialogActions>

        </Dialog>}




      </Grid>



        <br />
      </div>

      {/* ______ new dialog for asigning ibc ______ */}

      <Dialog fullWidth
        open={isIBCSearchDialogOpen}

        maxWidth="md"
        onClose={handleCloseNew}
        aria-labelledby="responsive-dialog-title"
        style={{ padding: '50px 20px', }} >
        <ListItem style={{ listStyle: 'none', paddingTop: '20px' }} >
          <ListItemText>
            <DialogTitle id="form-dialog-title">Add IBC to holding category</DialogTitle>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton>
              <Cancel onClick={handleCloseNew} />

            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <DialogContent>


          <div style={{ paddingBottom: '80px' }} >
            <Grid container spacing={2} >
              <Grid item xs={12} >

                <ValidatorForm onSubmit={async () => {

                }}>
                  <Grid container spacing={2} >
                    <Grid item xs={12} md={9} >
                      <TextValidator

                        margin="dense"
                        name={'searchText'}
                        value={ibcID}
                        id="amount"
                        label="Search..."
                        onChange={(e) => {
                          setIbcID(e.target['value'])
                          if(foundIbc !=null && foundIbc['id'] == null){
                            setFoundIbc({error:''})
                          }
                          suggestions();
                          // if (e.target['value'] != null && e.target['value'].length > 0) {
                          //   tryToFindIbc(e.target['value']);
                          // }
                        }}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        type="number"

                        fullWidth
                        variant="outlined"

                      />
                      {
                        foundIbc != null && foundIbc['id'] != null && foundIbc['holding'] != null ? <p style={{ color: 'red', fontSize: '10px', marginLeft: '6px' }} >IBC already added elsewhere</p> : ''
                      }

                      

                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div>
                        <Button onClick={async () => {

                          tryToFindIbc(ibcID)
                          
                          // This seems to be a post. But works like a GET


                          // var ibc = await API.post('ibcByRid', { rid: ibcID })
                          // if (ibc.data != null) {
                          //   console.log(ibc.data)
                          //   setData([ibc.data])
                          //   console.log(ibcID)
                          //   setSearchIbc(true);
                          //   checkIBCExist(ibcID)
                          // }
                        }} fullWidth variant="contained" style={{ backgroundColor: '#f28f16', color: '#fff', marginTop: '7px', height: '40px' }}>
                          {searchLoading ? <CircularProgress style={{ height: '20px', width: '20px', color: 'white' }} /> : "Search"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Grid>

            </Grid>
            {/* display data of ibcs */}


            {foundIbc !=null && foundIbc['id'] == null && <h3>{foundIbc['error']}</h3>}
            {
              <div>

                <Grid container>
                  <Grid item xs={12}  >
                    <div>



                      {foundIbc != null && foundIbc['id'] != null &&


                        (
                          <>

                            <div key={foundIbc.id} style={{ border: '1px solid #E5E5E5', borderRadius: '10px', marginTop: '20px' }} >
                              <Grid container className={classes.root} spacing={1}  >

                                <Grid item xs={12} md={3} lg={3} >
                                  <div className={classes.centerAlign} >
                                    <h3>
                                      ID
                                    </h3>
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3} >
                                  <div className={classes.centerAlign} >
                                    <h3>
                                      Beekeper Name
                                    </h3>
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3} >
                                  <div className={classes.centerAlign} >
                                    <h3>
                                      Honey Available (kg)
                                    </h3>
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3} >
                                  <div className={classes.centerAlign} >
                                    <h3>
                                      Action
                                    </h3>
                                  </div>
                                </Grid>

                              </Grid>
                              <div style={{ borderTop: '1px solid #E5E5E5' }} >
                                <Grid container spacing={1} className={classes.root}  >
                                  <Grid item xs={12} md={3} lg={3} >
                                    <div className={classes.centerAlign} >
                                      <p>
                                        HON-IBC-{foundIbc['rid']}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={3} lg={3} >
                                    <div className={classes.centerAlign} >
                                      <p>
                                        {foundIbc['beekeeper']['fullName']}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={3} lg={3} >
                                    <div className={classes.centerAlign} >
                                      <p>
                                        {foundIbc['remaining'] != null ? Number(Number(foundIbc['remaining']??0).toFixed(2)) : Number((Number(foundIbc['payLoad']??0)).toFixed(2))}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={3} lg={3} >
                                    <div className={classes.centerAlign} style={{ height: '100%' }} >
                                      <Button  style={{ marginTop: '1%' }} onClick={() => {
                                        if (cat['honeyType'] == foundIbc['honeyType']) {
                                          setisIBCSearchDialogOpen(false)
                                          setContribution(foundIbc);
                                          setIsAddIBCDialogOpen(true)


                                        } else {
                                          alert('This IBC can not be assigned to this Prticular holding category due to some properties mismatch!')

                                        }



                                      }} variant="contained" size="small"
                                        className={classes.btnStyle}> {foundIbc['holding'] != null ? 'reassign' : 'add'} </Button>



                                    </div>
                                  </Grid>

                                </Grid>
                              </div>
                            </div>

                          </>
                        )
                      }












                    </div>
                  </Grid>
                </Grid>
              </div>
            }

          </div>

        </DialogContent>
      </Dialog>



    </div >
  )
}
