import React, { useState } from 'react';
import moment from 'moment';
import { Grid, MenuItem } from '@material-ui/core';
import { SelectValidator } from 'react-material-ui-form-validator';
import { currentQuarter } from '../Helpers';



export const Quarter = () => {
 


    return (
       <div>{moment().subtract(6, 'months').quarter()}</div>
    )
}



// export const Quarter = (startMonth) => {
//     var obj = {};
//     if(startMonth=='january'){

//         obj.quarter1 = {start:moment().month(0).startOf('month'),end:moment().month(2).endOf('month')}
//         obj.quarter2 = {start:moment().month(3).startOf('month'),end:moment().month(5).endOf('month')}
//         obj.quarter3 = {start:moment().month(6).startOf('month'),end:moment().month(8).endOf('month')}
//         obj.quarter4 = {start:moment().month(9).startOf('month'),end:moment().month(11).endOf('month')}
// console.log(obj);
//         return obj;
//     }
//     else if(startMonth=='april'){

//         obj.quarter1 = {start:moment().month(3).startOf('month'),end:moment().month(5).endOf('month')}
//         obj.quarter2 = {start:moment().month(6).startOf('month'),end:moment().month(8).endOf('month')}
//         obj.quarter3 = {start:moment().month(9).startOf('month'),end:moment().month(11).endOf('month')}
//         obj.quarter4 = {start:moment().month(0).startOf('month').add('years',1),end:moment().month(2).endOf('month').add('years',1)}
//         console.log(obj);
//         return obj;
//     }
// }

// setQuarter('april');


export const QuarterSelector:React.FunctionComponent<{onChange : (data : {q:number , y:number})=>void}> = props=>{

    const [quarter, setQuarter] = useState<any>(moment().subtract(6, 'months').quarter());
    const [year, setYear] = useState<number>(currentQuarter().y)


    return    <Grid container direction="row" spacing={2} style={{ paddingRight: 30, }}>
    <Grid item lg={2} xs={2}></Grid>
    <Grid item  xs={5} >

      <SelectValidator name='year' variant="outlined" size="small" value={year} style={{ width: '100%' }}
        onChange={async (x) => {
          var y = Number(x.target['value'])
          setYear(y)
          props.onChange({q : quarter , y : y})

        }}
        label="Select Financial Year"



        validators={['required']}
        errorMessages={['this field is required']}

      >

        <MenuItem value={(new Date().getFullYear()) - 1}>{(new Date().getFullYear()) - 1}</MenuItem>
        <MenuItem value={new Date().getFullYear()}  >{new Date().getFullYear()}</MenuItem>
        <MenuItem value={new Date().getFullYear()+1}  >{new Date().getFullYear()+1}</MenuItem>

      </SelectValidator>

    </Grid>

    <Grid item  xs={5}>

      <SelectValidator name='quarterData' variant="outlined" size="small" value={quarter} style={{ width: '100%' }}

        onChange={async (x) => {
          var q = Number(x.target['value'])
          setQuarter(q)

          props.onChange({q : q , y : year})
        }}
        label="Select Quarter"




        validators={['required']}
        errorMessages={['this field is required']}

      >
        <MenuItem value={1}>Jul - Sep</MenuItem>
        <MenuItem value={2}>Oct - Dec</MenuItem>
        <MenuItem value={3}>Jan - Mar</MenuItem>
        <MenuItem value={4}>Apr - Jun</MenuItem>

      </SelectValidator>
    </Grid>
  </Grid>
}