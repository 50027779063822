// STEP 1 - Include Dependencies
// Include react
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Ways, API, Cook, getvalue, colorset, color, currentQuarter } from '../Helpers';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';

import ReactDOM from "react-dom";
import ReactFusioncharts from "react-fusioncharts";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Grid, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { kill } from 'process';
import { QuarterSelector } from './Quarter';

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);




export const BarChart: FunctionComponent<{ currentData: any }> = props => {


  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<any>()




  const getData = async (year: number, quarter: number) => {
    var res = await API.get('admin/charts/dividebyactivity', { year: year, quarter: quarter });

    if (res.status == 200) {
      // window.location.reload();
      // console.log(res)
      var x = res.data;
      var k = Object.keys(x).map((e, index) => {

        return {
          label: x[e]['label'],
          value: (x[e]['data'])/1000,
          color: color
        }

        
      })
      // setData(k)

      var c = k.map(cm => {
        return {
          label: cm.label,
          ta: Number(String(cm.label).replace(/[^0-9]/g, ''))
        }
      })


      c = c.sort((a, b) => {
        return a.ta == b.ta ? 0 : a.ta > b.ta ? 1 : -1;
      })



      try {
        var d = c.map(x=>{
          var obj = k.filter(e=>e.label == x.label)[0]
          // console.log(obj)
          return obj
          
        })
        
        setData(d)
      } catch (error) {
        // console.log(error)
      }

      // enqueueSnackbar('data request has been successfully sent!');
    }
    else {
      enqueueSnackbar('data request failed!')
    }
  }

  // var arr = [0-19,30-39,20-29,];
  // var sortingArray = 
  // sort((a,b)=>{
  //   // return a > b ? 1 : a==b ? 0 : -1
  // })



  // const onlyNumbers = (str: string) => str.replace(/\D/g, '');



  useEffect(() => {

    var c = currentQuarter();
    getData(c.y, c.q)





  }, [])

  // if (!data) {
  //   return <div></div>
  // }
  // STEP 2 - Chart Data

  // STEP 3 - Creating the JSON object to store the chart configurations
  const dataSource = {
    chart: {
      // caption: "AHV Honey Stocks - breakdown per TA level",
      // subcaption: "In MMbbl = One Million barrels",
      subcaption: "",
      xaxisname: "",
      // showsum: "1",
      // yaxisname: "Quantity of activity",
      // numbersuffix: "(t)",
      theme: "fusion",
      defaultNumberScale: 't',
      numberScaleValue: '0-10',
      // scaleRecursively:true,
      numberScaleUnit: 't',
      yAxisValuesStep: 2,

    },


    data: (data != null ? data : [])

    // [
    //   {
    //     label: "TA0",
    //     value: data['0-19']['data'],
    //   },

    //   {
    //     label: "TA15+",
    //     value: data['20-29']['data'],
    //     color: '#f28f16',
    //   },

    //   {
    //     label: "TA25+",
    //     value: data['30-39']['data'],
    //     color: '#f28f16',
    //   },
    //   {
    //     label: "TA35+",
    //     value: data[' 0']['data'],
    //     color: '#f28f16',
    //   },

    // ]
  };

  var colors = [

  ]



  //   data: [

  //        {
  //       label: "Total Activity",
  //        value: props.currentData.activityMgo['activity'],
  //       color: "#f28f16",
  //     },


  //     {
  //       label: "Total AHV Stock",
  //        value: props.currentData.total,
  //       color: "#c63131",
  //     },




  //   ]
  // };




  return <div style={{ backgroundColor: '#fff', paddingTop: 30 }}>

    <ValidatorForm onSubmit={async () => {
    }}>
  <QuarterSelector onChange={(data) => {
      getData(data.y, data.q);
    }} />


    </ValidatorForm>
    <br />
    <div>
      <p style={{ textAlign: 'center',fontSize:'18px',fontWeight:500 }} >
        AHV Honey Stocks - breakdown per TA level
      </p>
    </div>
    <ReactFusioncharts

      type="column2d"

      width="100%"
      // height="60%"
      height="40%"
      dataFormat="JSON"

      dataSource={dataSource}
    />
  </div>

}


export default BarChart;