import React, { useEffect, useState, FunctionComponent } from 'react';
import { Grid, Button, FormControl, MenuItem, Select, InputLabel, Typography, List, ListItem, CircularProgress, ListItemSecondaryAction, IconButton, ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PopupHoneyEditableForm } from './PopupHoneyEditableForm';
import { HoneyFeatures } from './SingleHoneyPage/HoneyFeatures'
import { HoneyExpansion } from './HoneyExpansion';
import { API } from '../Helpers';
import { AllIBCSTable, HoneyIbcTable } from '../Tables/HoneyIbcTable';
import { HoneyContributionPopup } from './SingleHoneyPage/HoneyContributionPopup';
import { SingleHoldingDetails } from './SingleHoneyPage/SingleHoldingDetails';
import { NewContributionPopup } from './SingleHoneyPage/NewContributionPopup';





const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    content: {

      maxHeight: 800,
    },
  }),
);



export const HoneyIbcs: FunctionComponent<{ currentHolding: any }> = props => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([])
  const [holding, setHolding] = useState<any>({})



  useEffect(() => {
    API.get('ibcs', { holdingid: window.location.pathname.split('/')[3] }).then(z => {
      // console.log(z.data);
      setData(z.data);


    })
    getholding()

    if (props.currentHolding != null) {
      setData(props.currentHolding)

    }
  }, [])

  const getholding = () => {

    API.get('holding', { holdingid: window.location.pathname.split('/')[3] }).then(z => {
      setHolding(z.data);

    });


  }



  let id: number = 1
  const getID = () => {
    return id++;
  }






  return (
    <>

      <div style={{ marginTop: '-3%', }}>

        
        <div style={{marginLeft:'-10px'}} >
          {holding['honeyType'] == null ? <NewContributionPopup currentVareity={holding['honeyType']} currentMethylglyoxal={holding['methylglyoxal']} currentDate={holding['dateOfExtraction']} currentBlossom={holding['flowersInBlossom']} currentSiteLocation={holding['apiarySiteLocation']} currentHolding={holding} currentAnti={holding['antioxidents']} currentOrganic={holding['organicnonorganic']} /> : <div></div>}
          

          <AllIBCSTable/>
        </div>
      </div>
    </>
  )
}