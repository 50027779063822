import { Box, Card, CardMedia, IconButton, Paper, Typography, CardActions, List, ListItem, Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add, CloudUpload } from '@material-ui/icons';
import { Cancel } from '@material-ui/icons';
import BackupIcon from '@material-ui/icons/Backup';
import StarsIcon from '@material-ui/icons/Stars';
import React, { FunctionComponent, useState } from 'react';
import ip from './../assets/imgplaceholder.jpg';
import firebase from 'firebase';
import { API } from '../Helpers';
import { useSnackbar } from 'notistack';
import { PropTypes } from 'mobx-react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        card: {
            maxWidth: 64,
            boxShadow: 'none',


        },


        cardStyle: {
            boxShadow: 'none',
            marginBottom: '-3%',

        },
        cancelStyle: {

            color: '#ffffff',
            marginTop: '-194%',



            '& .MuiIconButton-Base': {
                backgroundColor: 'transparent',

                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },

        imgCard: {
            maxWidth: 350,
            maxHeight: 300,
            boxShadow: 'none',
            marginBottom: 15,
            overflow: 'hidden',
        },
        media: {
            maxHeight: 310,
            maxWidth: 300,
            borderRadius: 8,
        },

        saveButton: {

            backgroundColor: '#f28f16',
            color: '#ffffff',
            boxShadow: 'none',
            marginTop: '2%',

            '&.MuiButton-contained': {


                '&:hover': {


                    backgroundColor: '#FECF51',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },
        },

        uploadStyle: {

            marginTop: '-30%',
            color: '#ffffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'

        },

        selectStyle: {

            marginTop: '-168%',
            marginLeft: '83%',
            color: '#ffffff',


        },
        btnStyle: {

            backgroundColor: '#f28f16',
            boxShadow: 'none',
            color: '#ffffff',


            '&.MuiButton-contained': {


                '&:hover': {


                    backgroundColor: '#FECF51',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },
        },



    }),
);


export const SelectImg: FunctionComponent<{ pictures: string[], onChanged: (x: string[]) => void, onAdded: (x: any) => void, primary: string, onPrimaryChanged?: (x: string) => void, disabled: boolean }> = props => {
    const [c, setC] = useState(props.primary);
    let fileInpuRef = React.createRef<HTMLInputElement>();
    const [complete, setComplete] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [pics, setPics] = useState(props.pictures);
    const classes = useStyles({});
    const [uploadProgress, setUploadProgress] = useState(0);

    const { enqueueSnackbar } = useSnackbar();
    return (
        <Box style={{ padding: '10px', backgroundColor: '#fbfbfb' }}>
            <Paper style={{ boxShadow: 'none', backgroundColor: '#fbfbfb', padding: 30 }}>
                <div>
                    <Typography variant="body1" style={{ paddingBottom: '15px' }}>
                        Upload pictures which best describe your product
                    </Typography>
                    <input id="ip" ref={fileInpuRef} onClick={x => { if (complete || uploading) { x.preventDefault() } }} type="file" style={{ display: 'none' }}
                        onChange={async e => {
                            // Null Checking of selected files
                            if (e.target.files) {
                                // this will get the first of the selected files
                                let file = e.target.files[0];
                                // check for the file size here
                                // the file size is represented in bytes

                                if (file.size > 2097152) {
                                    console.log(
                                        "File size " +
                                        file.size +
                                        " is larger than 1 MB.\nIt will not be uploaded"
                                    );
                                    return;
                                }
                                setUploading(true);
                                // This method will upload the file and return a proper url
                                var ref = firebase.app().storage().ref("Images/" + Date.now());
                                var task = ref.put(file);
                                task.on(firebase.storage.TaskEvent.STATE_CHANGED, function (snapshot) {
                                    var percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                                    console.log(percent + "% done");
                                    setUploadProgress(percent);
                                });
                                task.on(firebase.storage.TaskEvent.STATE_CHANGED, {
                                    'complete': async function () {
                                        console.log('upload complete!');
                                        // task.on('')
                                        let url = await ref.getDownloadURL();
                                        setUploading(false);
                                        setComplete(true);
                                        // below is just housekeeping stuff
                                        props.onAdded(url);
                                        // pics.push(url);
                                        // props.onChanged(pics);
                                        setPics(pics);
                                    }
                                });

                            }
                        }}
                    />

                    <Card className={classes.cardStyle}>

                        <CardMedia >
                            <img src={c && c.length > 0 ? c : ip} width={360} height={300} style={{ borderRadius: 3, }} />
                            {uploading ? <div>Uploading : {uploadProgress.toFixed(1)} %</div> : <div> </div>}

                            <CardActions>
                                {/* <IconButton className={classes.uploadStyle} onClick={() => {
                                    fileInpuRef.current?.click();
                                }}>
                                    <BackupIcon style={{ color: 'f28f16', fontSize: 35, }} />
                                </IconButton> */}
                            </CardActions>
                            <CardActions>
                                <IconButton className={classes.selectStyle} onClick={() => {
                                    if (props.onPrimaryChanged) {
                                        props.onPrimaryChanged(c);
                                    }
                                }}>
                                    <StarsIcon style={{ color: 'f28f16', fontSize: 35, }} />
                                </IconButton>

                            </CardActions>
                            <br />

                        </CardMedia>
                    </Card>
                    <label htmlFor="ip" style={{
                        paddingTop: '16px'
                    }}>
                        <div>
                            {/* <Button
                                variant="contained"
                                onClick={x => { if (!complete) { if (!uploading) fileInpuRef.current.click(); } }}
                                 type="button">
                                {!uploading && !complete && <CloudUpload className={classes.leftIcon} />}
                                {uploading && !complete && <CircularProgress color="secondary" className={classes.leftIcon} />}
                                {!uploading && complete && <Check className={classes.leftIcon} />}
                                Browse Picture
                            </Button> */}

                        </div>
                    </label>
                    {/* {c != props.primary && <Button variant='outlined' color='primary'
                        onClick={cc => {
                            props.onPrimaryChanged(c);
                        }}
                    >Mark As Primary</Button>} */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-2%', marginLeft: '-4%' }}>
                    {props.pictures.map((thumnmail, i) => {
                        return (
                            // <Grid key={i} item lg={12} style={{backgroundColor: '#ffffff', marginTop: 10, maxWidth: 345,}}>
                            <List key={i} style={{ maxWidth: 300, }}>
                                <ListItem>
                                    <img
                                        style={{ border: '2px solid #bcbcbc' }}
                                        src={thumnmail}
                                        width={58}
                                        height={58}
                                        onClick={() => setC(thumnmail)}
                                    />
                                </ListItem>
                                <ListItem>
                                    <IconButton
                                        className={classes.cancelStyle}
                                        onClick={async () => {
                                            //  await Helpers.DeleteFile(p);
                                            await API.delete('product/image', { imageurl: thumnmail })
                                            let npic = pics.filter((value) => {
                                                return value != thumnmail;
                                            })
                                            props.onChanged(npic);
                                            setPics(npic);
                                            enqueueSnackbar("Image Deleted");
                                        }}
                                    >
                                        <Cancel />
                                    </IconButton>
                                </ListItem>
                            </List>

                        )
                    })}
                </div>

                <Card style={{ width: 70, height: 70, boxShadow: 'none', border: '3px solid #cecece', backgroundColor: '#fbfbfb' }}>,
                    <CardMedia>

                        <IconButton
                            style={{ marginLeft: 6, marginTop: -12 }}
                            className={classes.uploadStyle} onClick={() => {
                                fileInpuRef.current?.click();
                            }}


                            component="label"
                        >
                            <Add style={{ color: '#9b9b9b' }} />
                            {/* <input
        type="file"
        style={{ display: "none" }}
    /> */}
                        </IconButton>
                        {/* <Typography  variant="subtitle2">Upload</Typography> */}

                    </CardMedia>




                </Card>
            </Paper >
        </Box>
    )
}


export const ImageUploader: FunctionComponent<{ onAdd: any }> = props => {

    let fileInpuRef = React.createRef<HTMLInputElement>();
    const [complete, setComplete] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const classes = useStyles({});



    return <div>
        <Grid container spacing={1} >
            <Grid item xs={3} >

                <Button onClick={() => {
                    fileInpuRef.current?.click();
                }}  variant="contained" size="large" style={{ backgroundColor: '#E5E5E5', color: 'black', marginTop: 20, marginBottom: 15, paddingLeft: 20, paddingRight: 20, height: '70px', }} >
                    <CloudUpload style={{ color: 'black' }} /> <p style={{ marginLeft: '20px' }} >Upload LabReport </p>
                </Button>


            </Grid>
        </Grid>
        {/* <Button
            style={{ marginLeft: 6, marginTop: -12 }}
            className={classes.btnStyle} onClick={() => {
                fileInpuRef.current?.click();
            }}


            component="label"
        >   <div  >
                <CloudUpload style={{ color: 'black' }} /> <p>Upload LabReport </p>
            </div>
        </Button> */}
        <input id="ip" ref={fileInpuRef} onClick={x => { if (complete || uploading) { x.preventDefault() } }} type="file" style={{ display: 'none' }}
            onChange={async e => {
                // Null Checking of selected files
                if (e.target.files) {
                    // this will get the first of the selected files
                    let file = e.target.files[0];
                    // check for the file size here
                    // the file size is represented in bytes

                    if (file.size > 2097152) {
                        console.log(
                            "File size " +
                            file.size +
                            " is larger than 1 MB.\nIt will not be uploaded"
                        );
                        return;
                    }
                    setUploading(true);
                    // This method will upload the file and return a proper url
                    var ref = firebase.app().storage().ref("Images/"+ file.name + Date.now());
                    var task = ref.put(file);
                    task.on(firebase.storage.TaskEvent.STATE_CHANGED, function (snapshot) {
                        var percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                        console.log(percent + "% done");
                        setUploadProgress(percent);
                    });
                    task.on(firebase.storage.TaskEvent.STATE_CHANGED, {
                        'complete': async function () {
                            console.log('upload complete!');
                            // task.on('')
                           let url = await ref.getDownloadURL();

                            setUploading(false);
                            setComplete(true);
                            console.log(url);
                            // below is just housekeeping stuff
                            // props.onAdded(url);
                            // pics.push(url);
                            // props.onChanged(pics);
                            // setPics(pics);
                            props.onAdd(url)
                        }
                    });

                }
            }}
        />
    </div>

}