import React, { FunctionComponent } from 'react'
import {List, Typography, ListItem, Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        card: {
            maxWidth: 50,
            maxHeight: 50,
            boxShadow: 'none',
        },

      

        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,

            backgroundColor: '#f28f16',


            '&.MuiButton-contained': {


                '&:hover': {


                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },


        },




        bottomListStyle: {

            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },

        listHeading: {

            fontSize: 17,
       
            marginTop: '-2%',
            marginBottom: '2%',
        },


        mainHeading: {

            
            fontSize: 32,
            marginBottom: '3%',
            
            marginLeft: '3%',
      

            [theme.breakpoints.down('sm')]: {
                fontSize: 20,
            },
        },

        listStyle: {

            [theme.breakpoints.down('sm')]: {
                maxWidth: 450,
            },

        },

        subHeading: {

            
            fontSize: 20,
            paddingTop: '2%',
            marginBottom: '0.5%',
            color: '#fff',
            paddingLeft: '3%',
        },

        textDescription:{
          
            fontSize: 18,
            color: '#fff',
            fontWeight: 400,
            paddingLeft: '3%',
            paddingBottom: '2%',

        } ,

        listsubHeading: {

            fontSize: 16,
         
            fontWeight: 800,


        },

        leftHeading: {
        
            fontSize: 14,

        },




    }),
);









export const OrderStatus: FunctionComponent<{ offer: any }> = props => {
    const classes = useStyles({});
    return(

        <div>
        <List className={classes.listStyle} style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <ListItem>
            <Typography className={classes.mainHeading} variant="h6">Order # HON-ORD-{props.offer['rid']} </Typography>
            </ListItem>
          
            <ListItem>
    <Typography variant="body2"> <b>Placed On :</b> {props.offer['lastUpdated']}</Typography>
            </ListItem>
        </List>
      
        

        <Grid container style={{borderTop: '4px solid #fec121', paddingLeft: 5, paddingRight: 5, backgroundColor: '#f28f16'}}>
        <Grid item lg={12} xs={12}>
          <Typography variant="h6" className={classes.subHeading}>Order Status</Typography>
    <Typography className={classes.textDescription} variant="body2">Order has been successfully accepted.</Typography>
          </Grid>
        </Grid>
        </div>
       

    )
}