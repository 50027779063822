import React, { FunctionComponent, useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import productthumn from './../assets/productthumn.jpg';
import { Grid } from '@material-ui/core';
import { Quantity } from './Quantity';
import { API, getval } from '../Helpers';

interface Row {
  name: string;
  surname: string;
  birthYear: string;
  totalprice: string;
  discount: string;
}

interface TableState {
  columns: Array<Column<Row>>;
  data: Row[];
}

export const QuoteCartTable: FunctionComponent<{ quote: any  , onUpdate?: (newData)=>void}> = props => {

  const [data, setData] = useState<any[]>(props.quote['products'].map((x, i) => {
    return { product: x, cartItem: props.quote['cartItems'][i] };
  }))

  // const [state, setState] = React.useState<TableState>({
  //   ,
  //   data: [
  //     props.quote['products']
  //   ],
  // });



  return (
    <MaterialTable
      style={{ boxShadow: 'none', textAlign: 'left', marginTop: '-3%' }}
      title=""
      columns={[
        { title: 'Product', field: 'product.productTitle', editable: 'never',  headerStyle: {
          
          minWidth: 170
        }, },
        
        // { title: 'Image', render: (row) => (<div style={{width: 30, height: 30}}>{row['products']['primaryImage']}</div>) },
        // { title: 'Brand', render: (row) => <p>{row['products']['primaryImage']}</p>},
        
        {
          title: 'Image', headerStyle: { maxWidth: 40}, render: row => (
            <Grid item lg={2}>
               <img src={row['product']['primaryImage']} style={{ height: 35, width: 35, }} />
            </Grid>
          )
        },
        { title: 'No. of Cartons', field: 'cartItem.quantity', type: 'numeric', editable: 'never', cellStyle: { maxWidth: 80, textAlign: 'center'} },

        { title: 'Starting Price $', field:  'cartItem.variation.price', type: 'numeric', editable: 'never', cellStyle: { maxWidth: 80, textAlign: 'center'} },
        { title: 'Offered Price $', field: 'cartItem.offeredPrice', type: 'numeric', cellStyle: { maxWidth: 80, textAlign: 'center'} },
        

        { title: 'Discount', field: 'cartItem.discount', type: 'numeric', cellStyle: { maxWidth: 80, textAlign: 'center'} },
        {
          title: 'Sub Total', render: (row) => {
            // if (!row['cartItem']['discount'] || !row['cartItem']['offeredPrice']) {
            //   return <div>-</div>
            // }
            var op = Number(row['cartItem']['offeredPrice']);
            var quantity = Number(row['cartItem']['quantity']);
            var discount = Number(row['cartItem']['discount']);
            var itemsPerCarton = Number(row['cartItem']['variation']['itemsPerCarton'])
            var oTotal = op * quantity * itemsPerCarton;
            return <div>
              {/* {op + ' * ' + quantity + ' * ' + itemsPerCarton + " = " + oTotal} */}
              
              {/* <br /> */}
              {oTotal - ((discount / 100) * oTotal)}
            </div>
          }, editable: 'never'
        },
      ]}
      data={data}
      options={{
           headerStyle: {
                textAlign: 'left',
                
               },
        paging: false,
        search: false,

        // headerStyle: {
        //   textAlign: 'center',
          
       
          
        // },
       
      }}
      editable={{
        // onRowAdd: (newData) =>
        //   new Promise((resolve) => {
        //     setTimeout(() => {
        //       resolve();
        //       setState((prevState) => {
        //         const data = [...prevState.data];
        //         data.push(newData);
        //         return { ...prevState, data };
        //       });
        //     }, 0);
        //   }),
        onRowUpdate: async (nd, oldData) => {
          var dataToUpdate = nd['cartItem'];
          await API.post('cart/ChangeItem', dataToUpdate, { itemid: nd['cartItem']['id'] })
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              var d = data;
              var f = d[data.indexOf(oldData)]
              f.cartItem = { ...d[data.indexOf(oldData)].cartItem, offeredPrice: nd['cartItem']['offeredPrice'], discount: nd['cartItem']['discount'] }
              d[data.indexOf(oldData)] = f;
              if (props.onUpdate){
               props.onUpdate(d);
             }
              setData(d);
            }, 0);
          })
        }
      }}
    />
  );
}