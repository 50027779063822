import React from 'react';
import './App.css';
import { Dashboard } from './Dashboard';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Ways} from './Helpers';
import { Signup } from './SinglePages/Signup';
import { SocketTest } from './Components/SocketTest';
import { LoginHandler } from './SinglePages/Login';
import { AllBeekeepers } from './Tables/AllBeekeepers';


const version= ()=>{
  return <div>1 March 2021</div>
}

function App() {
  return (
    // <Router>
    // <div>
    // <Dashboard />
    // <Switch>
    //  <Route path="/categories" exact component={AllCategoriesTable} />
    // <Route path="/products" exact component={AllProductsTable} /> 
    // </Switch>
    // </div>
    // </Router>
    <Router>
    <div>
      <Route path={Ways.home} component={Dashboard} />
      <Route path="/"  component={LoginHandler} />
      <Route path={Ways.signUp} exact component={Signup} />
      <Route path="/socket" component={SocketTest}/>
      <Route path="/version" component={version}/>


      {/* <Route path={Ways.productAddPage}  component={Dashboard}/>

      <Route exact path={Ways.productsPage}  component={()=>{
        return <Dashboard />
      }}/>

      <Route path={Ways.productEditPage}  component={Dashboard}/> */}
    </div>
    </Router>
  );
}


  


export default App;
