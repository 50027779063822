import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Ways, API, Cook, getvalue, currentQuarter } from '../Helpers';
import { MenuItem, Grid } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import moment from 'moment';
import { QuarterSelector } from "./Quarter";
// Resolves charts dependancy
charts(FusionCharts);



// class MainPieChart extends React.Component {

export const PieChart2: FunctionComponent<{ currentData: any }> = props => {


  const [data, setData] = useState<any>()
  // const [quarter, setQuarter] = useState<number>(moment().subtract(6, 'months').quarter())
  // const [year, setYear] = useState<number>(new Date().getFullYear())

  const { enqueueSnackbar } = useSnackbar();


  const getData = async (year: number, quarter: number) => {
    var x = await API.get('admin/charts/activitymgo', { year: year, quarter: quarter });
   
    if (x.status == 200) {
      // window.location.reload();
      // console.log(x.data)
      setData(x.data)
      
      return x.data;
    }
    else {
      enqueueSnackbar('data request failed!')
    }
  }


  useEffect(() => {


    getData(currentQuarter().y,currentQuarter().q)



  }, [])



  //   useEffect(() => {
  //     API.get('admin/charts/activitymgo', {year: 2021, quarter: 1}).then(x => {
  //         setData(x.data);
  //          console.log(x.data);
  //     })


  // }, [])


  let id: number = 1
  const getID = () => {
    return id++;
  }


  if (!data) {
    return <div>

    </div>
  }





  const dataSource = {
    chart: {
      // caption: "AHV Honey Stocks - ratio of TA & MGO (%)",
      plottooltext: "<b>$value k.g</b>  $label ",
      showlegend: "1",
      showpercentvalues: "1",
      legendposition: "bottom",
      usedataplotcolorforlabels: "1",
      enableMultiSlicing: "0",
      bgColor: "#ffffff",
      bgAlpha: "50",
      borderRadius: "8",
      showBorder: "1",
      borderColor: "#ffffff",
      decimals: "1",
      theme: "fusion",
      // exportEnabled: "1",
      // exportFileName: "export_file1",
    },



    data: [

      {
        label: "Total Activity",
        value: data['ta'],
        color: '#f28f16',
      },

      {
        label: "MGO",
        value: data['mgo'],
        color: "#c63131",
      },


    ]
  };





  // render() {
  return <div style={{ paddingTop: 30, backgroundColor: '#fff', }}>

    <ValidatorForm onSubmit={async () => {
    }}>

<QuarterSelector onChange={(data) => {
      getData(data.y, data.q);
    }} />   


    </ValidatorForm>
    <br />
    <div>
      <p style={{textAlign:'center',fontSize:'18px',fontWeight:500}} >
        AHV Honey Stocks - ratio of TA & MGO (%)
      </p>
    </div>
    <ReactFusioncharts

      type="pie2d"

      width="100%"
      
      height="40%"
      dataFormat="JSON"

      dataSource={dataSource}
    />
  </div>

}


export default PieChart2;