import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Grid, CircularProgress } from '@material-ui/core';
import { AccountBalance, Room } from '@material-ui/icons';
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import profileimg from './../assets/userplace.png';
import 'typeface-roboto';
import { API, Cook } from "./../Helpers";
// import { Bbc, AppEvent, API, getval } from './../Helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    card: {
      maxWidth: 430,
      boxShadow: 'none',
      pointerEvents: 'none',
    },
    media: {
      marginLeft: '30%',

    },

    headings: {

      fontSize: 14,
      fontWeight: 600,
    },

    mainHeading: {

      paddingTop: '5%',
      paddingBottom: '5%',
    },


    listStyle: {

      display: 'flex',
      flexDirection: 'row',
      maxWidth: 35,
      marginLeft: '25%',
      marginTop: '-4%',
    },

  }),
);

export const Profile = () => {
  const classes = useStyles({});

  const [data, setData] = useState({});

  useEffect(() => {
    API.get('beekeeper', { beekeeper: window.location.pathname.split('/')[3] }).then(x => {
      setData(x.data);
    })
  }, [])


  if (!data['id']) {
    return <Grid container justify="center" alignItems="center" style={{ paddingTop: '25%' }}>
      <CircularProgress style={{ color: '#f28f16' }} />
    </Grid>
  }
  return (

    <Card  className={classes.card}>
      <CardActionArea>
        <Typography className={classes.mainHeading} align="center" variant="h6">About Beekeeper</Typography>
        <CardMedia className={classes.media}>
          <img src={profileimg} style={{ height: 150, borderRadius: '50%' }} />
        </CardMedia>
        <CardContent>
          <Typography className={classes.headings} align="center" gutterBottom variant="subtitle2" component="h2">
            {data['fullName']}
          </Typography>
          <List dense className={classes.listStyle}>
            <ListItem>
              <Room style={{ fontSize: 20 }} />
            </ListItem>
            <ListItem style={{ minWidth: 80 }}>
              <Typography variant="body2">{data['city']}</Typography>
            </ListItem>

          </List>




        </CardContent>
      </CardActionArea>

    </Card>

  );
}