import React, { useState, useEffect, FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import { ListItem, List, Typography, Grid, Paper, ListItemText, ListItemSecondaryAction, Divider, TextField, Card, CardActionArea, CircularProgress, CardMedia, CardContent, IconButton } from '@material-ui/core';
import { Cancel, Visibility } from '@material-ui/icons';
import { Add } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Ways, API, Cook, getvalue } from './../Helpers';
import { sentenceCase } from "sentence-case";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import imgplaceholder from './../assets/imgplaceholder.png';
import docxImg from '../assets/fileDocx.png'
import pdfImg from '../assets/filePdf.png'
import firebase from 'firebase';
import { isPropertyAccessOrQualifiedName } from 'typescript';





const useStyles = makeStyles((theme) => ({


  Paper: {
    boxShadow: 'none',

  },

  listStyle: {
    border: '1px solid #d6d4d4',
  },

  headingListStyle: {

    display: 'flex',
    flexDirection: 'row',
    width: '100%',



    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },


  },


  listheading: {

    fontSize: '1.2rem',
    fontWeight: 400,
  },

  leftItem: {

    backgroundColor: '#f28f14',
    color: '#fff',
  },

  rightItem: {

    border: '1px solid #efefef',
  },


  actionArea: {

    '&.MuiCardActionArea-root': {

      textAlign: 'center',


    },

  },

  media: {


  },

  mainHeading: {

    color: '#fff',
    fontSize: '1rem',
    fontWeight: 400,


  },


  listItem: {


    // borderBottom: '1px solid #d6d4d4',
    paddingTop: 20,
    paddingBottom: 20,

  },



  card: {
    maxWidth: 400,
    minWidth: 180,


    minHeight: 100,
    maxHeight: 100,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingTop: 10,
    paddingBottom: 10,
    pointerEvents: 'none',




  },




}));

export const ImageCard: FunctionComponent<{ url: any, onDelete: () => void }> = props => {
  return <Card style={{ width: '100%', height: 300, backgroundColor: '#fbfbfb', boxShadow: 'none' }}>,
    <IconButton onClick={async () => {
      var ref = firebase.app().storage().refFromURL(props.url);
      await ref.delete();
      props.onDelete();
    }}>
      <Cancel style={{ paddingLeft: 5, }} />
    </IconButton>
    <CardMedia style={{
      textAlign: 'center',
      justifyContent: 'center',
      alignContent: 'center', paddingBottom: 50,
    }}>
      <img src={props.url != null ? props.url : imgplaceholder} style={{ height: '40%', width: '40%', objectFit: 'cover' }} />
    </CardMedia>


  </Card>
}




export const BeekeeperRequestPopup: FunctionComponent<{ currentBEEKEEPER: any }> = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  let id: number = 1

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const { enqueueSnackbar } = useSnackbar();

  const [uploadProgress, setUploadProgress] = useState(0);

  const [actualorganicCertificateUrl, SetActualorganicCertificateUrl] = useState('')

  const [actualtrademarkCertificate, setActualtrademarkCertificate] = useState('')

  const [beekeeperactualorganicCertificateUrl, SetbeekeeperActualorganicCertificateUrl] = useState('')

  const [beeKeeperactualtrademarkCertificate, setbeekeeperActualtrademarkCertificate] = useState('')


  // const organicCertificate =  props.currentBEEKEEPER['data']['certifiedOrganicHoneyCertificate']

  // const actualorganicCertificateUrl = rganicCertificate.split('?', 1).join('').split('/').pop().split('.').pop()

  const trademark = props.currentBEEKEEPER['data']['trademarkCertificate']
  // const actualtrademarkCertificate = trademark.split('?', 1).join('$').split('/').pop().split('.').pop()

  const [obj, setObj] = useState({});


  const [completeCert, setCompleteCert] = useState(false);
  const [completeLicense, setCompleteLicense] = useState(false);
  const [uploadingCert, setUploadingCert] = useState(false);

  const [certificate, setCertificate] = useState();


  const [data, setData] = useState<any>({});
  const set = (e: React.FormEvent) => {
    var n = e.target['name'];
    var v = e.target['value'];
    data[n] = v;
    setData({ ...data })
  }


  useEffect(() => {
    console.log(props.currentBEEKEEPER['beekeeper']['trademarkCertificate'])
    console.log(props.currentBEEKEEPER['data']['certifiedOrganicHoneyCertificate'])
    if (props.currentBEEKEEPER != null) {
      setData(props.currentBEEKEEPER)
    }
    if (props.currentBEEKEEPER['data']['certifiedOrganicHoneyCertificate'] != undefined || props.currentBEEKEEPER['data']['certifiedOrganicHoneyCertificate'] != null ) {

      SetActualorganicCertificateUrl(props.currentBEEKEEPER['data']['certifiedOrganicHoneyCertificate'].split('?', 1).join('$').split('/').pop().split('.').pop())
    }
    if (props.currentBEEKEEPER['data']['trademarkCertificate'] != undefined || props.currentBEEKEEPER['data']['trademarkCertificate'] != null) {
      setActualtrademarkCertificate(props.currentBEEKEEPER['data']['trademarkCertificate'].split('?', 1).join('$').split('/').pop().split('.').pop())
    }
    if (props.currentBEEKEEPER['beekeeper']['certifiedOrganicHoneyCertificate'] != undefined || props.currentBEEKEEPER['beekeeper']['certifiedOrganicHoneyCertificate'] != null ) {

      SetbeekeeperActualorganicCertificateUrl(props.currentBEEKEEPER['beekeeper']['certifiedOrganicHoneyCertificate'].split('?', 1).join('$').split('/').pop().split('.').pop())
    }
    if (props.currentBEEKEEPER['beekeeper']['trademarkCertificate'] != undefined || props.currentBEEKEEPER['beekeeper']['trademarkCertificate'] != null ) {
      setbeekeeperActualtrademarkCertificate(props.currentBEEKEEPER['beekeeper']['trademarkCertificate'].split('?', 1).join('$').split('/').pop().split('.').pop())
    }
  }, [])





  //   useEffect(() => {
  //       API.get('detailschangerequests', {beekeeper: window.location.pathname.split('/')[3]}).then(x => {
  //           setData(x.data);
  //           console.log(x.data);
  //       })
  //   }, [])


  if (!props.currentBEEKEEPER['id']) {
    return <Grid container justify="center" alignItems="center" style={{ paddingTop: '25%' }}>
      <CircularProgress style={{ color: '#f28f16' }} />
    </Grid>
  }



  const getID = () => {
    return id++;
  }



  return (
    <div>

      <Button size="small" style={{ color: '#fff', backgroundColor: '#f28f16' }} variant="contained" onClick={handleClickOpen}>Details</Button>
      <Dialog maxWidth="lg" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

        <ListItem>
          <ListItemText>
            <DialogTitle id="form-dialog-title">

              <Typography variant="h4">Request For Changes</Typography>

            </DialogTitle>

          </ListItemText>

          <ListItemSecondaryAction>
            <IconButton>
              <Cancel onClick={handleClose} />

            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <DialogContent>

          <ValidatorForm onSubmit={async () => {


            API.post('approveChangeRequest', { requestid: data['id'] }).then(x => {

              if (x.status == 200) {
                window.location.reload();
                enqueueSnackbar('Change Request has been sucessfully submitted!');
              }
              else {
                enqueueSnackbar('change request failed!')
              }
            })
          }}>

            <Grid container direction="row" spacing={4}>

              <Grid item lg={6}>

                <Typography variant="h5" className={classes.listheading}>Old Fields</Typography>
                <br />



                <List style={{ border: Object.keys(props.currentBEEKEEPER['data']).length != 0 ? '1px solid #d6d4d4' : '0px' }}>

                  {props.currentBEEKEEPER['data']['registeredForGST'] != null ?
                    <div>
                      <ListItem className={classes.listItem}>
                        <TextValidator
                          name={'registeredForGST'}
                          margin="dense"
                          value={props.currentBEEKEEPER['beekeeper']['registeredForGST'] == true ? 'Yes' : 'No'}
                          id="amount"
                          label="Registered For GST"

                          // validators={['required']}
                          errorMessages={['This field is required']}
                          type="amount"
                          fullWidth
                          variant="outlined"
                          disabled

                        />


                      </ListItem>
                      <Divider />

                    </div>

                    :

                    <div>

                    </div>

                  }



                  {props.currentBEEKEEPER['data']['bussinessTitle'] != null ?

                    <ListItem className={classes.listItem}>
                      <TextValidator
                        name={'bussinessTitle'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['bussinessTitle']}
                        id="amount"
                        label="Business Title"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>

                    :

                    <div></div>

                  }


                  {props.currentBEEKEEPER['data']['email'] != null ?

                    <ListItem className={classes.listItem}>
                      <TextValidator
                        value={props.currentBEEKEEPER['beekeeper']['email']}

                        margin="dense"
                        id="amount"
                        // validators={['required']}
                        errorMessages={['Country is required']}
                        label="Email Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name={'email'}
                        disabled

                      />


                    </ListItem>

                    :
                    <div>

                    </div>

                  }



                  {props.currentBEEKEEPER['data']['phone'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'phone'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['phone']}
                        id="amount"
                        label="Phone Number"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }



                  {props.currentBEEKEEPER['data']['hospitalGradeHoney'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'hospitalGradeHoney'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['hospitalGradeHoney']}
                        id="amount"
                        label="Hospital Grade Honey"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }







                  {props.currentBEEKEEPER['data']['city'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'city'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['city']}
                        id="amount"
                        label="City"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }





                  {props.currentBEEKEEPER['data']['exportsCertifiedOrganicHoney'] != null ?
                    <div>
                      <ListItem className={classes.listItem}>
                        <TextValidator
                          name={'exportsCertifiedOrganicHoney'}
                          margin="dense"
                          value={props.currentBEEKEEPER['beekeeper']['exportsCertifiedOrganicHoney'] === true ? 'Yes' : 'No'}
                          id="amount"
                          label="Exports Certified Organic Honey ?"

                          // validators={['required']}
                          errorMessages={['This field is required']}
                          type="amount"
                          fullWidth
                          variant="outlined"
                          disabled

                        />


                      </ListItem>


                    </div>

                    :

                    <div>

                    </div>

                  }

                  {props.currentBEEKEEPER['data']['certifiedOrganicHoneyCertificate'] || props.currentBEEKEEPER['data']['exportsCertifiedOrganicHoney'] != null ?

                    <div>

                      <ListItem style={{ paddingTop: 25 }}>
                        <br />
                        <Typography variant="subtitle2">Honey Organic  Certificate </Typography>



                      </ListItem>


                      <ListItem>

                        <Card style={{ boxShadow: 'none' }}>
                          <CardMedia>
                            {

                              beekeeperactualorganicCertificateUrl === 'docx' ?
                                < div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                  <img src={docxImg} width='200px' height='250px' />
                                </div>
                                : beekeeperactualorganicCertificateUrl === 'doc' ?
                                  < div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                    <img src={docxImg} width='200px' height='250px' />
                                  </div>
                                  : beekeeperactualorganicCertificateUrl === 'pdf' ?
                                    < div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                      <img src={pdfImg} width='200px' height='250px' />
                                    </div>

                                    :
                                    <img src={props.currentBEEKEEPER['beekeeper']['certifiedOrganicHoneyCertificate']} width="439.5" height="300" />

                            }

                          </CardMedia>
                        </Card>

                      </ListItem>


                    </div>


                    :

                    <div>

                    </div>

                  }


                  <Divider />




                  {props.currentBEEKEEPER['data']['exportsInBulk'] != null ?
                    <div>
                      <ListItem className={classes.listItem}>
                        <TextValidator
                          name={'exportsInBulk'}
                          margin="dense"
                          value={props.currentBEEKEEPER['beekeeper']['exportsInBulk'] == true ? 'Yes' : 'No'}
                          id="amount"
                          label="Exports In Bulk ?"

                          // validators={['required']}
                          errorMessages={['This field is required']}
                          type="amount"
                          fullWidth
                          variant="outlined"
                          disabled

                        />


                      </ListItem>
                      <Divider />

                    </div>

                    :

                    <div>

                    </div>

                  }







                  {props.currentBEEKEEPER['data']['fullName'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'fullName'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['fullName']}
                        id="amount"
                        label="Full Name"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }








                  {props.currentBEEKEEPER['data']['hasTrademark'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'hasTrademark'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['hasTrademark'] === true ? 'Yes' : 'No'}
                        id="amount"
                        label="Has Trademark ?"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }



                  {props.currentBEEKEEPER['data']['trademarkCertificate'] || props.currentBEEKEEPER['data']['hasTrademark'] != null ?

                    <div>

                      <ListItem style={{ paddingTop: 25 }}>
                        <br />
                        <Typography variant="subtitle2">Honey trademark Certificate   </Typography>



                      </ListItem>


                      <ListItem>

                        <Card style={{ boxShadow: 'none' }}>
                          <CardMedia>
                            {
                              beeKeeperactualtrademarkCertificate === 'docx' ?
                                < div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                  <img src={docxImg} width='200px' height='250px' />
                                </div>
                                : beeKeeperactualtrademarkCertificate === 'doc' ?
                                  < div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                    <img src={docxImg} width='200px' height='250px' />
                                  </div>
                                  :
                                  beeKeeperactualtrademarkCertificate === 'pdf' ?
                                    < div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                      <img src={pdfImg} width='200px' height='250px' />
                                    </div>

                                    :
                                    <img src={props.currentBEEKEEPER['beekeeper']['trademarkCertificate']} width="439.5" height="300" />

                            }

                          </CardMedia>
                        </Card>

                      </ListItem>


                    </div>


                    :

                    <div>

                    </div>

                  }

                  <Divider />


                  {props.currentBEEKEEPER['data']['honeyCertificationStatus'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'honeyCertificationStatus'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['honeyCertificationStatus']}
                        id="amount"
                        label="Honey Certification Status"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }







                  {props.currentBEEKEEPER['data']['honeyProductionVolume'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'city'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['honeyProductionVolume']}
                        id="amount"
                        label="Honey Product Volume"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }






                  {props.currentBEEKEEPER['data']['honeySellingStatus'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'honeySellingStatus'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['honeySellingStatus']}
                        id="amount"
                        label="Honey Selling Status"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }






                  {props.currentBEEKEEPER['data']['isBussinessAccreditated'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'isBussinessAccreditated'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['isBussinessAccreditated'] == true ? 'Yes' : 'No'}
                        id="amount"
                        label="Is Bussiness Accreditated ?"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }





                  {props.currentBEEKEEPER['data']['numberOfHives'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'numberOfHives'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['numberOfHives']}
                        id="amount"
                        label="Number Of Hives"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }








                  {props.currentBEEKEEPER['data']['producesActiveHoney'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'producesActiveHoney'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['producesActiveHoney'] == true ? 'Yes' : 'No'}
                        id="amount"
                        label="Produces Active Honey?"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }










                  {props.currentBEEKEEPER['data']['producesPollinationServices'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'producesPollinationServices'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['producesPollinationServices'] == true ? 'Yes' : 'No'}
                        id="amount"
                        label="Produces Pollination Services ?"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }





                  {props.currentBEEKEEPER['data']['state'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'state'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['state']}
                        id="amount"
                        label="State"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }




                  {props.currentBEEKEEPER['data']['street1'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'street1'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['street1']}
                        id="amount"
                        label="Street 1"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }






                  {props.currentBEEKEEPER['data']['street2'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'street2'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['street2']}
                        id="amount"
                        label="Street 2"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }




                  {props.currentBEEKEEPER['data']['summary'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'summary'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['summary']}
                        id="amount"
                        label="Summary"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }




                  {props.currentBEEKEEPER['data']['zipcode'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'zipcode'}
                        margin="dense"
                        value={props.currentBEEKEEPER['beekeeper']['zipcode']}
                        id="amount"
                        label="Zipcode"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"
                        disabled

                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }









                  {/* {props.currentBEEKEEPER['data']['certifiedOrganicHoneyCertificate'] || props.currentBEEKEEPER['data']['exportsCertifiedOrganicHoney'] != null  ? 
                
                    <div>

                      <ListItem style={{ paddingTop: 25 }}>
                        <br />
                        <Typography variant="subtitle2">Honey Certificate sdfdf </Typography>



                      </ListItem>


                      <ListItem>

                        <Card style={{ boxShadow: 'none' }}>
                          <CardMedia>
                            {

                              beekeeperactualorganicCertificateUrl === 'docx' ?
                                <img src={docxImg} width="439.5" height="300" />
                                : beekeeperactualorganicCertificateUrl === 'doc' ?
                                  <img src={docxImg} width="439.5" height="300" />
                                  : beekeeperactualorganicCertificateUrl === 'pdf' ?
                                    <img src={pdfImg} width="439.5" height="300" />

                                    :
                                    <img src={props.currentBEEKEEPER['beekeeper']['certifiedOrganicHoneyCertificate']} width="439.5" height="300" />

                            }

                          </CardMedia>
                        </Card>

                      </ListItem>


                    </div>


                    :

                    <div>

                    </div>

                  } */}


                  {/* {props.currentBEEKEEPER['data']['trademarkCertificate'] || props.currentBEEKEEPER['data']['hasTrademark'] != null ?

                    <div>

                      <ListItem style={{ paddingTop: 25 }}>
                        <br />
                        <Typography variant="subtitle2">Honey trademarkCertificate Certificate   </Typography>



                      </ListItem>


                      <ListItem>

                        <Card style={{ boxShadow: 'none' }}>
                          <CardMedia>
                            {
                              beeKeeperactualtrademarkCertificate === 'docx' ?
                                <img src={docxImg} width="439.5" height="300" />
                                : beeKeeperactualtrademarkCertificate === 'doc' ?
                                  <img src={docxImg} width="439.5" height="300" />
                                  :
                                  beeKeeperactualtrademarkCertificate === 'pdf' ?
                                    <img src={pdfImg} width="439.5" height="300" />

                                    :
                                    <img src={props.currentBEEKEEPER['beekeeper']['trademarkCertificate']} width="439.5" height="300" />

                            }

                          </CardMedia>
                        </Card>

                      </ListItem>


                    </div>


                    :

                    <div>

                    </div>

                  } */}


                </List>








              </Grid>
              <Grid item lg={6}>

                <Typography variant="h5" className={classes.listheading}>New Fields</Typography>
                <br />
                <List style={{ border: Object.keys(props.currentBEEKEEPER['data']).length != 0 ? '1px solid #d6d4d4' : '0px' }}>

                  {props.currentBEEKEEPER['data']['registeredForGST'] != null ?
                    <div>
                      <ListItem className={classes.listItem}>
                        <TextValidator
                          name={'registeredForGST'}
                          margin="dense"
                          value={props.currentBEEKEEPER['data']['registeredForGST'] == true ? 'Yes' : 'No'}
                          id="amount"
                          label="Registered For GST"
                          onChange={set}
                          // validators={['required']}
                          errorMessages={['This field is required']}
                          type="amount"
                          fullWidth
                          variant="outlined"


                        />


                      </ListItem>
                      <Divider />
                    </div>

                    :

                    <div>

                    </div>

                  }




                  {props.currentBEEKEEPER['data']['bussinessTitle'] != null ?
                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'bussinessTitle'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['bussinessTitle']}
                        id="amount"
                        label="New Business Title"
                        onChange={set}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="text"
                        fullWidth
                        variant="outlined"


                      />

                    </ListItem>

                    :

                    <div>

                    </div>

                  }



                  {props.currentBEEKEEPER['data']['email'] != null ?
                    <ListItem className={classes.listItem}>


                      <TextValidator
                        value={props.currentBEEKEEPER['data']['email']}
                        onChange={set}
                        margin="dense"
                        id="amount"
                        // validators={['required']}
                        errorMessages={['Country is required']}
                        label="New Email Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name={'email'}


                      />

                    </ListItem>

                    :

                    <div>

                    </div>


                  }



                  {props.currentBEEKEEPER['data']['phone'] != null ?

                    <ListItem className={classes.listItem}>

                      <TextValidator
                        value={props.currentBEEKEEPER['data']['phone']}
                        onChange={set}
                        margin="dense"
                        id="amount"
                        // validators={['required']}
                        errorMessages={['Country is required']}
                        label="New Phone Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name={'phone'}


                      />


                    </ListItem>



                    :

                    <div></div>


                  }




                  {props.currentBEEKEEPER['data']['hospitalGradeHoney'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'hospitalGradeHoney'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['hospitalGradeHoney']}
                        id="amount"
                        label="Hospital Grade Honey"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }








                  {props.currentBEEKEEPER['data']['city'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'city'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['city']}
                        id="amount"
                        label="City"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }





                  {props.currentBEEKEEPER['data']['exportsCertifiedOrganicHoney'] != null ?
                    <div>
                      <ListItem className={classes.listItem}>
                        <TextValidator
                          name={'exportsCertifiedOrganicHoney'}
                          margin="dense"
                          value={props.currentBEEKEEPER['data']['exportsCertifiedOrganicHoney'] === true ? 'Yes' : 'No'}
                          id="amount"
                          label="Exports Certified Organic Honey ?"

                          // validators={['required']}
                          errorMessages={['This field is required']}
                          type="amount"
                          fullWidth
                          variant="outlined"


                        />


                      </ListItem>


                    </div>

                    :

                    <div>

                    </div>

                  }


                  {props.currentBEEKEEPER['data']['certifiedOrganicHoneyCertificate'] || props.currentBEEKEEPER['data']['exportsCertifiedOrganicHoney'] != null ?

                    <div>

                      <ListItem style={{ paddingTop: 25 }}>
                        <br />
                        <Typography variant="subtitle2">Honey Organic Certificate  </Typography>



                      </ListItem>


                      <ListItem>

                        <Card style={{ boxShadow: 'none' }}>
                          <CardMedia>
                            {

                              actualorganicCertificateUrl === 'docx' ?
                                < div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                  <img src={docxImg} width='200px' height='250px' />
                                </div>
                                : actualorganicCertificateUrl === 'doc' ?
                                  < div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                    <img src={docxImg} width='200px' height='250px' />
                                  </div>
                                  : actualorganicCertificateUrl === 'pdf' ?
                                    < div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                      <img src={pdfImg} width='200px' height='250px' />
                                    </div>

                                    :
                                    <img src={props.currentBEEKEEPER['data']['certifiedOrganicHoneyCertificate']} width="439.5" height="300" />

                            }

                          </CardMedia>
                        </Card>

                      </ListItem>


                    </div>


                    :

                    <div>

                    </div>

                  }

                  <Divider />

                  {props.currentBEEKEEPER['data']['exportsInBulk'] != null ?
                    <div>
                      <ListItem className={classes.listItem}>
                        <TextValidator
                          name={'exportsInBulk'}
                          margin="dense"
                          value={props.currentBEEKEEPER['data']['exportsInBulk'] == true ? 'Yes' : 'No'}
                          id="amount"
                          label="Exports In Bulk ?"

                          // validators={['required']}
                          errorMessages={['This field is required']}
                          type="amount"
                          fullWidth
                          variant="outlined"


                        />


                      </ListItem>
                      <Divider />

                    </div>

                    :

                    <div>

                    </div>

                  }







                  {props.currentBEEKEEPER['data']['fullName'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'fullName'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['fullName']}
                        id="amount"
                        label="Full Name"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }








                  {props.currentBEEKEEPER['data']['hasTrademark'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'hasTrademark'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['hasTrademark'] === true ? 'Yes' : 'No'}
                        id="amount"
                        label="Has Trademark ?"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }

                  {props.currentBEEKEEPER['data']['trademarkCertificate'] || props.currentBEEKEEPER['data']['hasTrademark'] != null ?

                    <div>

                      <ListItem style={{ paddingTop: 25 }}>
                        <br />
                        <Typography variant="subtitle2">Honey Tradmark Certificate </Typography>



                      </ListItem>


                      <ListItem>

                        <Card style={{ boxShadow: 'none' }}>
                          <CardMedia>
                            {
                              actualtrademarkCertificate === 'docx' ?
                                <div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                  <img src={docxImg} width='200px' height='250px' />
                                </div>
                                : actualtrademarkCertificate === 'doc' ?
                                  <div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                    <img src={docxImg} width='200px' height='250px' />
                                  </div>
                                  :
                                  actualtrademarkCertificate === 'pdf' ?
                                    <div style={{ width: "439.5px", height: "300px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                      <img src={pdfImg} width='200px' height='250px' />
                                    </div>

                                    :
                                    <img src={props.currentBEEKEEPER['data']['trademarkCertificate']} width="439.5" height="300" />

                            }

                          </CardMedia>
                        </Card>

                      </ListItem>


                    </div>


                    :

                    <div>

                    </div>

                  }

                  <Divider />






                  {props.currentBEEKEEPER['data']['honeyCertificationStatus'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'honeyCertificationStatus'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['honeyCertificationStatus']}
                        id="amount"
                        label="Honey Certification Status"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }







                  {props.currentBEEKEEPER['data']['honeyProductionVolume'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'city'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['honeyProductionVolume']}
                        id="amount"
                        label="Honey Product Volume"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }






                  {props.currentBEEKEEPER['data']['honeySellingStatus'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'honeySellingStatus'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['honeySellingStatus']}
                        id="amount"
                        label="Honey Selling Status"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }






                  {props.currentBEEKEEPER['data']['isBussinessAccreditated'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'isBussinessAccreditated'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['isBussinessAccreditated'] == true ? 'Yes' : 'No'}
                        id="amount"
                        label="Is Bussiness Accreditated ?"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }





                  {props.currentBEEKEEPER['data']['numberOfHives'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'numberOfHives'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['numberOfHives']}
                        id="amount"
                        label="Number Of Hives"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }








                  {props.currentBEEKEEPER['data']['producesActiveHoney'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'producesActiveHoney'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['producesActiveHoney'] == true ? 'Yes' : 'No'}
                        id="amount"
                        label="Produces Active Honey?"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }










                  {props.currentBEEKEEPER['data']['producesPollinationServices'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'producesPollinationServices'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['producesPollinationServices'] == true ? 'Yes' : 'No'}
                        id="amount"
                        label="Produces Pollination Services ?"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }





                  {props.currentBEEKEEPER['data']['state'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'state'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['state']}
                        id="amount"
                        label="State"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }




                  {props.currentBEEKEEPER['data']['street1'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'street1'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['street1']}
                        id="amount"
                        label="Street 1"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }






                  {props.currentBEEKEEPER['data']['street2'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'street2'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['street2']}
                        id="amount"
                        label="Street 2"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }




                  {props.currentBEEKEEPER['data']['summary'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'summary'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['summary']}
                        id="amount"
                        label="Summary"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }




                  {props.currentBEEKEEPER['data']['zipcode'] != null ?


                    <ListItem className={classes.listItem}>

                      <TextValidator
                        name={'zipcode'}
                        margin="dense"
                        value={props.currentBEEKEEPER['data']['zipcode']}
                        id="amount"
                        label="Zipcode"

                        // validators={['required']}
                        errorMessages={['This field is required']}
                        type="amount"
                        fullWidth
                        variant="outlined"


                      />


                    </ListItem>


                    :

                    <div>

                    </div>

                  }




















                </List>



              </Grid>











              <Grid item xs={4}>
                <Button type="submit" style={{ color: '#fff', backgroundColor: '#f28f16', marginTop: 25 }} variant="contained">Approve</Button>
                <Button onClick={async () => {
                  
                  
                  API.delete('approveChangeRequest', { requestid: data['id'] }).then(x => {

                    if (x.status == 200) {
                      window.location.reload();
                      enqueueSnackbar('Change Request has been sucessfully submitted!');
                    }
                    else {
                      // console.log(x)

                      enqueueSnackbar('change request failed!')
                    }
                  })
                }} style={{ color: '#fff', backgroundColor: 'orangered', marginTop: 25, marginLeft: '20px' }} variant="contained">Reject</Button>
              </Grid>



            </Grid>

          </ValidatorForm>




        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}