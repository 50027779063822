import { Button, Chip, Grid, Paper, Dialog, DialogContent, Typography, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { API, Ways, getvalue } from "./../Helpers";
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { SingleBuyerComponent } from './SingleBuyerComponent';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(2),
        overflow: 'auto',
    },


    btnStyle: {

        backgroundColor: '#f28f16',
        boxShadow: 'none',
        color: '#ffffff',
        '&.MuiButton-contained': {
            '&:hover': {
                backgroundColor: '#FECF51',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },


    fixedHeightPaperEmail: {

        boxShadow: 'none',
        padding: theme.spacing(4),
    },

}));


export const AllBuyersTable = () => {
    const [data, setData] = useState<any[]>([])
    const [co, setCo] = useState({});
    const classes = useStyles();
    let id: number = 1

    const { enqueueSnackbar } = useSnackbar();

    const getID = () => {
        return id++;
    }


    const getOffers = () => {
        // console.log();
        if(window.location.pathname.split('/').length ==3){
            API.get('users').then(x => {
    
                setData((x.data))
            })
        }
    }

    useEffect(() => {
        getOffers()
    }
        , [])

    // console.log(window.location.pathname.split('/'))
    if(window.location.pathname.split('/').length >3){
        return <SingleBuyerComponent/>;
    } 

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
                <Paper className={classes.fixedHeightPaperEmail}>

                    <MaterialTable


                        style={{
                            boxShadow: 'none',
                            backgroundColor: '#ffffff',
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                        }}
                        title="All Buyers"
                        columns={[
                            {
                                title: 'Name', render: (row) => {
                                    return <div>
                                        <p>{row['firstName']} {row['lastName']}</p>
                                    </div>
                                }
                            },
                            {
                                title: 'Email', field : 'email'
                            },

                            { title: 'Status', render: (row) =>
                            <div>
                           
                           {row['approved'] == true ?  <Chip label='Approved' style={{backgroundColor: '#f28f16', color: '#ffffff',}} /> :  <Chip label='Pending' style={{backgroundColor: '#fbfbfb', color: '#303030',}} />}
                           
                            </div>
                               },
                            {
                                title: 'Actions', render: (row) =>

                                    <Grid container style={{ display: 'flex', flexDirection: 'row' }} >
                                        <Grid item lg={2} >
                                            <Button onClick={() => {
                                                createBrowserHistory().push(Ways.allBuyersPage + '/' + row['id'])
                                                window.location.reload();
                                            }} variant="contained" size="small"
                                                className={classes.btnStyle}>View</Button>
                                        </Grid>
                                        <Grid lg={2} item >

                                        </Grid>
                                        {!row['approved'] && <Grid item lg={5}>
                                            </Grid>
                                            ?
                                            <Button  variant="contained" size="small" 
                                            style={{ backgroundColor: '#f28f16', boxShadow: 'none', color: '#ffffff', }}
                                            onClick={()=>{
                                                API.post('approveUser' , {user : row['id']}).then(x=>{
                                                    enqueueSnackbar(x.data)
                                                    var ind = data.indexOf(row);
                                                    var e = row as  {};
                                                    e['approved'] = true;
                                                    data[ind] = e;
                                                    setData([...data])
                                                })
                                            }}
                                            >
                                                Approve
                                            </Button> 
                                            :
                                            <div>
                                            <Button  size="small" variant="contained" disabled>Approve</Button>
                                            </div>
                                        }
                                        </Grid>
                                
                                        
                            }

                        ]}
                        data={data}
                    />
                </Paper>
            </Grid>

            <Dialog open={co['id'] != null} onClose={() => setCo({})}>
                <DialogContent>
                    <Typography variant='body2'>Are you sure you want to delete this Offer ?</Typography>
                    <br />
                    <List>
                        <ListItem style={{ marginRight: 10, marginLeft: 20 }}>
                            <Button style={{ backgroundColor: 'red' }} onClick={() => {
                                API.delete('offer', { offerid: getvalue(co, 'id') }).then(x => {
                                    setCo({})
                                    getOffers()
                                    enqueueSnackbar('Quote Deleted');
                                })
                            }
                            }>Delete</Button>
                        </ListItem>
                        <ListItem >
                            <Button style={{ backgroundColor: '#f7f7f7' }} onClick={() => {
                                setCo({});
                            }}>Cancel</Button>
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
        </Grid>
    )
}


/*
[
        { name: 'BeeWell Honey Jar', product: 'Manuka', weight: '3500 Jars', value: '2500', birthCity: 63 },
        { name: 'Honey HI Box', product: 'Jarah', weight: '100 Jars', value: '3700', birthCity: 34 },
        { name: 'BeeWell Honey Jar', product: 'Manuka', weight: '3500 Jars', value: '10500', birthCity: 63 },
        { name: 'Honey HI Box', product: 'Jarah', weight: '100 Jars', value: '20,000', birthCity: 34 },
        { name: 'BeeWell Honey Jar', product: 'Manuka', weight: '3500 Jars', value: '50,000', birthCity: 63 },


      ]



*/