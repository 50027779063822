import * as axios from 'axios';
import faker from 'faker'
import { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { createBrowserHistory } from 'history';
import { EventEmitter } from 'events';
import socketIOClient from 'socket.io-client'
import { GlobalState } from './AppState';


export enum AppEvent {
    requestOfferDrawerOpen, requestOpenedWithLogin, requestOfferDrawerClose, loggedIn, productsFiltered, requestLoginDialog, newChatSelected, newQuoteRecieved, newMessageRecieved, messageAllSeen
}

export const colorset = [
    "#CC6600",
    "#097275",
    "#eb4c36",
    "#E17C4A",
    "#36b1b3",
    "#383938",
    "#a390b5",

]

export const color = '#f28f15';

export const getString = () =>{
    return 'SIM';
}


export const getQuarterMonth  = ( month : number) : number => {


    if (month >= 7 && month <= 9) {
      return 1;
    }
  
    if (month >= 10 && month <= 12) {
      return 2;
    }
    if (month >= 1 && month <= 3) {
      return 3;
    }
    if (month >= 4 && month <= 6) {
      return 4;
    }

    return -1;
  }
  
  export const currentQuarter =() => getQuarterData(new Date(Date.now()));
  
  export const getQuarterData = ( searchDate : Date) : {q:number , y:number} => {
    var q = 0;
    var y = 0;
    // var qdate = DateTime(2020, 2, 2);
    var date = searchDate;
  
    q = getQuarterMonth(date.getMonth()+1);
    if (q == 3 || q == 4) {
      y = date.getFullYear();
    } else {
      y = date.getFullYear() + 1;
    }
      const newLocal = { q: q, y: y };
      
    return newLocal;
  }
  

export class Bbc {
    static _emittor = new EventEmitter();


    static subscribe = (type: AppEvent, func: (data?: any) => void) => {
        Bbc._emittor.on(type.toString(), func);
    }

    static fire = (type: AppEvent, data?: any) => {
        Bbc._emittor.emit(type.toString(), data);
    }


    static removeLitseners = (event: AppEvent) => {
        Bbc._emittor.removeAllListeners(event.toString());
    }

}


export const getvalue = (obj, key) => {
    return key.split(".").reduce(function (o, x) {
        return (typeof o == "undefined" || o === null) ? o : o[x];
    }, obj);
}

export const getval = (object, path, even?) => {
    let res = path.split('.').reduce((o, x) => o == undefined ? o : o[x], object);
    if (!res) {
        res = even
    }
    // console.log('this is res' + res);
    // return res;
    return Math.round(res).toFixed(2);
}


export class API {
    static ngrok = 'https://13af28472811.ngrok.io/'
    static network = 'http://34.71.74.232:8080/';
    static local = 'http://localhost:3002/';
    static url = 'https://nothingrealjustchecking.tk/'
    static testURL = 'https://dev.australianhoneyventures.com.au/'

    static _apiHost = API.testURL;


    static checkSession = async () => {
        let s = Cook.getUserData('session');
        if (s === undefined) {
            console.log('session deleted');
            createBrowserHistory().push('/login');
            window.location.reload();
            return false;
        }

        let res = await API.get('sessionstatus');
        let val = Boolean(res.data);
        if (!val) {
            createBrowserHistory().push('/');
            window.location.reload();
        }
        return val;
    }



    static post = async (endpoint: string, body?: any, headers: any = {}): Promise<AxiosResponse> => {

        headers['session'] = Cook.getUserData('session');

        return await axios.default.post(API._apiHost + endpoint, body, { headers: headers, withCredentials: true })

    }


    static put = async (endpoint: string, body?: any, headers: any = {}): Promise<AxiosResponse> => {
        headers['session'] = Cook.getUserData('session');

        return await axios.default.put(API._apiHost + endpoint, body, { headers: headers, withCredentials: true })
    }

    static get = async (endpoint: string, headers: any = {}): Promise<AxiosResponse> => {
        headers['session'] = Cook.getUserData('session');

        return await axios.default.get(API._apiHost + endpoint, { headers: headers, withCredentials: true });
    }

    static delete = async (endpoint: string, headers: any = {}): Promise<AxiosResponse> => {
        headers['session'] = Cook.getUserData('session');

        return await axios.default.delete(API._apiHost + endpoint, { headers: headers, withCredentials: true });
    }



}


export const randomProduct = () => {
    return {
        antioxidents: faker.random.number(20),
        countryOfOrigin: faker.address.country(),
        countryPackaged: faker.address.country(),
        energy: faker.random.number(10),
        floralType: "Multi-Floral",
        glucoseIndexGi: faker.random.number(10),
        hmf: faker.random.number(10),
        longDescription: faker.lorem.lines(10),
        shortDescription: faker.lorem.lines(2),
        maxPrice: faker.random.number({ min: 900, max: 1000 }),
        minPrice: faker.random.number({ min: 400, max: 800 }),
        moisture: faker.random.number(50),
        no: faker.random.number(20).toString(),
        organicType: 'Organic',
        mgp: faker.random.number(10).toString(),
        originOfImportedProducts: faker.address.country(),
        percentageImportedProducts: faker.random.number(100),
        productTitle: faker.commerce.product(),
        rawness: 'Raw',
        ta: faker.random.number(10).toString(),
    };
}

export class Cook {

    static getUserData = (key?: string) => {
        if (key) {
            return (new Cookies().get('userData') || {})[key];
        }
        return new Cookies().get('userData');
    }

    static setUserData = (userData: any) => {
        new Cookies().set('userData', userData);

    }

    static setSession = (session: any) => {
        var c = Cook.getUserData() || {};
        c['session'] = session;
        new Cookies().set('userData', c);
    }

    static setUser = (user: any) => {
        var c = Cook.getUserData() || {};
        c['user'] = user;
        new Cookies().set('userData', c);
    }

    // static get = (name: string) => {
    //     return new Cookies().get(name) || undefined;
    // }

    // static set = (name: string, value: string) => {
    //     new Cookies().set(name, value , {path :'/'});
    // }

    static del = (name?: string) => {
        new Cookies().remove('userData', { domain: window.location.hostname });
    }
    // static deleteAll = () => {
    //     new Cookies().getAll()
    // }
}

export class LoginHelper {

    static isCookiePresent = (): boolean => Cook.getUserData() != null



    static login = async (email: string, password: string): Promise<string> => {
        var res = await API.post('login', { email: email, password: password })
        if (res.status == 200) {
            Cook.setUserData(res.data);
            var data = Cook.getUserData();
            Socket.emit('login', { email: data['user']['email'] })
            Bbc.fire(AppEvent.loggedIn);
            console.log(Cook.getUserData());
            return '';
        } else {
            return res.data;
        }
    }
}

export const createUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export class Socket {
    static socket: SocketIOClient.Socket = socketIOClient(API._apiHost, { forceNew: true });
    static initialize = () => {


        if (LoginHelper.isCookiePresent()) {
            Socket.socket.emit('cookieFound', Cook.getUserData());
        }



        Socket.socket.on('message', (x) => {
            Bbc.fire(AppEvent.newMessageRecieved, x);
            GlobalState.unread = [...GlobalState.unread, x];
            // Socket.socket.emit('messageDelivered', x);
        })

        Socket.socket.on('message', (x) => {
            Bbc.fire(AppEvent.newMessageRecieved, x);

            GlobalState.unreadMessages = [...GlobalState.unreadMessages.map(c => c), x];
        })

        Socket.socket.on('offers', (x) => {
            Bbc.fire(AppEvent.newQuoteRecieved, x);

            GlobalState.unreadQuotes = [...GlobalState.unreadQuotes.map(c => c), x];
        })


        Socket.socket.on('messageSent', message => {
            // console.log(message);

            GlobalState.messages[GlobalState.messages.findIndex(x => x['id'] == message['id'])] = message;
        })

        Socket.socket.on('messageSeen', (message) => {
            var index = GlobalState.messages.findIndex(x => {
                return x['id'] == message['id'];
            });
            GlobalState.messages[index] = message;
        })

        Socket.socket.on('messageAllSeen', message => {
            Bbc.fire(AppEvent.messageAllSeen, message);
        })
    }

    static emit = (event: string, data: any) => {
        Socket.socket.emit(event, data);
    }
}


export class Ways {
    static home = '/dashboard'
    static signUp = '/signup';
    static loginPage = '/'

    // Product
    static productsPage = Ways.home + '/products'
    static productEditPage = Ways.home + '/products/edit'
    static productAddPage = Ways.home + '/products/add'


    // Orders
    static ordersPage = Ways.home + '/orders'
    static singleOrderPage = Ways.ordersPage + "/order/"


    // AllBeekeepers
    static allbeekeepersPage = Ways.home + '/allbeekeepers'

    // honey rates

    static honeyRates = Ways.home + '/honeyrates'

    // BeeKeeperProfile

    static beekeeperprofilePage = Ways.home + '/beekeeperprofile'
    static beekeeperhoneyPage = Ways.home + '/beekeeperhoney'
    static beekeeperhoneydetailsPage = Ways.home + '/beekeeperhoneydetails'



    //Withdrawls

    static withdrawlsPage = Ways.home + '/withdrawls'
    static withdrawlsSinglePage = Ways.withdrawlsPage + '/withdrawl/'

    //Honey

    static honeystockPage = Ways.home + '/honeystock'
    static ibcs = Ways.home + '/ibcs'
    static honeysinglePage = Ways.home + '/singlehoneypage'
    static singleibcdetailsPage = Ways.home + '/singleibc'


    // Categories

    static categoriesPage = Ways.home + '/categories';


    // Offers
    static offersPage = Ways.home + '/quotes';
    static singleOfferpage = Ways.offersPage + '/quote/'




    // Messages
    static messagesPage = Ways.home + '/messages';


    // Email
    static ibcPage = Ways.home + '/ibc';

    //quote
    static quotePage = Ways.home + '/quote';


    // Buyers
    static allBuyersPage = Ways.home + '/buyers';


    //Beekeeper Requests
    static beekeeperRequests = Ways.home + '/beekeepersrequests'
}

export const containerNumbers = (s: string): boolean => {
    return /\d/.test(s)
}