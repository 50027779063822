import React, { useEffect, useState } from 'react';
import { Grid, Paper, CircularProgress, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { SingleOfferBottom } from './SingleOfferBottom';
import { OrderDetailUpdated } from './OrderDetailUpdated';
// import { RightColumn } from './RightColumn';
import { API } from './../Helpers';
import { OrderOtherDetails } from './OtherDetails';
import { ShippingDetailsDescription } from './ShippingDetailsDescription';
import { useSnackbar } from 'notistack';
import { OrderStatus } from './OrderStatus';
import { GlobalState } from '../AppState';
import { observer } from 'mobx-react';
import { QuoteForm } from './../Quote/QuoteForm';
import { TestingTable } from './TestingTable';
import { StatusChanger } from './StatusChanger';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: 'hidden',
  },


  fixedHeightPaperShipping: {
    height: 1200,
    boxShadow: 'none',
    padding: theme.spacing(4),


  },

  fixedHeightPaperfirst: {
    height: 430,
    backgroundColor: '#fff',
    boxShadow: 'none',
    padding: theme.spacing(4),


  },



  fixedHeightPapersecond: {
    height: 750,
    boxShadow: 'none',
    padding: theme.spacing(4),


  },

  fixedHeightPaperStatus: {
    height: 300,
    width: '100%',
    boxShadow: 'none',
    padding: theme.spacing(4),


  },

  fixedHeightPaperThird: {
    height: 1000,
    boxShadow: 'none',
    // padding: theme.spacing(4),
    paddingLeft: '3%',
    paddingRight: '3%',


  },



  fixedHeightPaperDetail: {
    height: 1200,
    boxShadow: 'none',
    padding: theme.spacing(4),
  },


  fixedHeightPaperForm: {

    boxShadow: 'none',
    padding: theme.spacing(4),
  },

  fixedHeightPaperGallery: {

    boxShadow: 'none',
    padding: theme.spacing(4),
  },

}));



export const OrderLayout = observer(() => {
  const classes = useStyles();

  const [co, setCo] = useState({});
  const [or, setOr] = useState({});
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  let changedOffer = {};


  useEffect(() => {

    var id = window.location.pathname.split('/')[4];
    API.get('offer', { offerid: id }).then(x => {
      setCo(x.data);
      console.log(x.data);
      GlobalState.currentOrder = x.data;
      
    })

    // API.get('order', { orderid: id }).then(x => {
    //   setOr(x.data);
    //   console.log(x.data);
    //   GlobalState.currentOrder = x.data;
      
    // })
  }, [])

  if (!co['cnf']) {
    return <Container maxWidth="xs" style={{padding: '30%'}}><Grid container justify="center" alignItems="center"><Grid item lg={12} ><CircularProgress style={{color: '#f28f16'}} /></Grid></Grid></Container>
  }

  if(GlobalState.currentOrder['id']){
    // this was just to test
    // alert("Order Changed Globally")
  }


  return (
    <Grid container className={classes.root} spacing={2}>

      <Grid item lg={12} xs={12} style={{ marginTop: '5%' }}>
        <OrderStatus offer={co} />
      </Grid>


      <Grid item lg={12} md={12} xs={12}>
        <Paper className={classes.fixedHeightPaperfirst}>
          {/* <OrderDetailUpdated quote={co} /> */}

          <TestingTable order={co} />
        </Paper>
      </Grid>

     



      <Grid item lg={12} md={12} xs={12}>
      <Typography style={{fontSize: 32, marginLeft: '3%', marginTop: '3%', marginBottom: '-1%'  }} variant="h6">Order Requirements</Typography>
        <Paper className={classes.fixedHeightPaperThird}>
          {/* <ShippingDetailsDescription offer={{}} /> */}
         
          <QuoteForm quote={co} />
        </Paper>

      </Grid>

      <Grid item lg={12} md={12} xs={12}>
      <Typography style={{fontSize: 32, marginLeft: '3%', marginTop: '3%', marginBottom: '2%'  }} variant="h6">Order Status</Typography>
        <Paper className={classes.fixedHeightPaperStatus}>
        <StatusChanger order={co} /> 
        </Paper>

      </Grid>






    </Grid>


  )
})