import React, { FunctionComponent, useEffect, useState } from "react";

import { Ways, API, Cook } from '../Helpers';
import { createBrowserHistory } from 'history';
import { Grid, Typography, Button, List, ListItem, CircularProgress, Dialog, DialogContent } from '@material-ui/core'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { Home } from '@material-ui/icons';




export const ProfileDescription: FunctionComponent = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [ edit, setEdit ] = useState(false);
  

 
    useEffect(() => {
        API.get('beekeeper', {beekeeper: window.location.pathname.split('/')[3]}).then(x => {
            setData(x.data);
            console.log(x.data);
        })
    }, [])

    if(!data['id']){
        return<Grid container justify="center" alignItems="center" style={{paddingTop: '25%'}}>
<CircularProgress style={{color: '#f28f16'}} />
        </Grid> 
    }

    return(
        <div style={{padding: 30}}>
            
            <Grid container>


    



                <Grid item lg={11}>
                


                    <Typography variant="h4">
                    {data['fullName']}
                    </Typography>
                 

                    <div>
                        
                    </div>
   
     


                 
                    <br />
                    {/* <List dense style={{display: 'flex', width: 200, flexDirection: 'row', marginLeft: '-2%'}}>
                        <ListItem >
                            <Home />
                        </ListItem>

                        <ListItem >
                            https://www.google.com
                        </ListItem>
                    </List> */}
                    </Grid>

                    <Grid item lg={1}>
                    
                        </Grid>


                    <Grid item lg={12}>
                    <Typography variant="body2">
                   {data['summary']}
                    </Typography>
                    <br />

                 
                    </Grid>


            
            </Grid>
        </div>
    )
}

