import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Ways, API, Cook, getvalue, currentQuarter, colorset } from '../Helpers';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { colors, Grid, MenuItem } from '@material-ui/core';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import moment from 'moment';
import { QuarterSelector } from "./Quarter";
import { isObjectLiteralExpression } from "typescript";
// Resolves charts dependancy
charts(FusionCharts);



// class MainPieChart extends React.Component {

export const MainPieChart: FunctionComponent<{ currentData: any }> = props => {

  const [data, setData] = useState<any>()
  const [tempData, setTempData] = useState<any>([])

  const { enqueueSnackbar } = useSnackbar();
  let finaldata: any[] =  []
  

  const getData = async (year: number, quarter: number) => {
    var x = await API.get('admin/charts/percentageOfVariety', { year: year, quarter: quarter });

    console.log(x.data)

    if (x.status == 200) {
      // window.location.reload();
      // setData(x.data)
     
      console.log(x.data)

     

      for(let i = 0 ; i < Object.keys(x.data).length ; i++){
        // setTempData([{...tempData,label:Object.keys(x.data)[i],value:Object.values(x.data)[i],color:colorset[i]}])
        finaldata.push({...tempData,label:Object.keys(x.data)[i],value:Object.values(x.data)[i],color:colorset[i]})

        

        console.log({label:Object.keys(x.data)[i],value:Object.values(x.data)[i],color:colorset[i]})
        
        
      }
      
      
      setData(finaldata)
      
    }
    else {
      enqueueSnackbar('data request failed!')
    }


  }


  console.log(data)

  useEffect(() => {
    getData(currentQuarter().y, currentQuarter().q)

  }, [])



  let id: number = 1
  const getID = () => {
    return id++;
  }


  if (!data) {
    return <div></div>
  }


  const dataSource = {
    chart: {
      // caption: "AHV Honey Stocks - breakdown per variety (%)",
      plottooltext: "<b>$valuet</b>  $label ",
      showlegend: "1",
      showpercentvalues: "1",
      legendposition: "bottom",
      usedataplotcolorforlabels: "1",
      enableMultiSlicing: "0",
      bgColor: "#ffffff",
      bgAlpha: "50",
      borderRadius: "8",
      showBorder: "1",
      borderColor: "#ffffff",
      decimals: "1",
      theme: "fusion",
      // exportEnabled: "1",
      // exportFileName: "export_file1",
    },

    
   data:data

    // data: [


    //   {
    //     label: "Red Gum",
    //     value: data['Red Gum'],
    //     color: "#CC6600",
    //   },
    //   {
    //     label: "Jarrah",
    //     value: data['Jarrah'],
    //     color: '#097275',
    //   },
    //   {
    //     label: 'Blackbutt',
    //     value: data['Blackbutt'],
    //     color: "#eb4c36",
    //   },
    //   {
    //     label: 'Banksia',
    //     value: data['Banksia'],
    //     color: "#c63131",
    //   },

    //   {
    //     label: "Red Bell",
    //     value: data['Red Bell'],
    //     color: "#097275",
    //   },


    //   {
    //     label: "Wildflower",
    //     value: data['Wildflower'],
    //     color: "#eb4c36",
    //   },

    //   {
    //     label: "Whitegum",
    //     value: data['Whitegum'],
    //     color: "#a390b5",
    //   },



    // ]

  };





  // render() {
  return <div style={{ backgroundColor: '#fff', paddingTop: 30 }}>

    <ValidatorForm onSubmit={() => { }}>
      <QuarterSelector onChange={(data) => {
        getData(data.y, data.q);
        console.log(data)
        
      }} />
    </ValidatorForm>
    <br />
    <div>
      <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: 500 }} >
        AHV Honey Stocks - breakdown per variety (%)
      </p>
    </div>
    
    <ReactFusioncharts

      type="pie2d"

      width="100%"
      // height="60%"
      height="40%"
      dataFormat="JSON"

      dataSource={dataSource}
    />
  </div>


}


export default MainPieChart;