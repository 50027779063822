import { Badge, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, TextField, Typography , List as MList } from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useState, FunctionComponent, useRef } from 'react';
import adminicon from './../assets/adminicon.png';
import userplace from './../assets/userplace.png';
import { Bbc, AppEvent, API, Socket, createUUID} from '../Helpers';
import { observer } from 'mobx-react';


import { Virtuoso, VirtuosoState } from 'react-virtuoso';
import { GlobalState } from '../AppState';
import { Chat } from '@material-ui/icons';





const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge);




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstGrid: {
      padding: 20,
      backgroundColor: '#f28f16',
      color: '#ffffff',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      marginLeft: 20,
    },

    msgNameText: {
      color: '#efefef',
      fontSize: 12,

      marginLeft: 70,

    },




    secondGrid: {
      padding: 20,
      backgroundColor: '#f28f16',
      color: '#ffffff',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      marginRight: 20,
    },

    thirdGrid: {
      marginTop: '2%',
    },

    sendButton: {

      color: '#ffffff',
      backgroundColor: '#f28f16',

    },
    msgBottomText: {

      marginLeft: 130,

      fontSize: 10,
      color: '#efefef',
    },

    msgText: {

      fontSize: 12,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      borderTopLeftRadius: 8,
      padding: 10,
      marginBottom: 2,
      marginTop: -10,
      backgroundColor: '#536883',
      color: '#ffffff',
      overflow: 'hidden',
      wordBreak: 'break-all',
      display: 'block',



    },

    
    selectText: {

      fontSize: 16,
      color: '#303030',
    },

    inline: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

    },

    msgText2: {

      fontSize: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      padding: 10,
      marginBottom: -10,
      marginTop: -10,
      backgroundColor: '#efefef',
      color: '#000000',
      overflow: 'hidden',
      wordBreak: 'break-all',
      display: 'block',



    },

    secondList: {
      marginTop: '-20%',

    },

    container: {
      minHeight: 480,
      maxHeight: 480,
    },



  }),
);


// let socket: SocketIOClient.Socket;


export const Messages = observer(() => {
  const classes = useStyles({});
  const [selectedChat, setSelectedChat] = useState({})
  const [cm, setCm] = useState('');

  const virt: React.RefObject<VirtuosoState> = useRef(null);

  const scrollToBottom = () => {
    if (virt != null) {
      if (virt.current != null) {
        virt.current.scrollToIndex(GlobalState.messages.length);
      }
    }
  }

  useEffect(() => {

    Bbc.subscribe(AppEvent.newChatSelected, async (chat) => {
      setSelectedChat(chat);
      await API.get('messages', { chatid: chat['id'] }).then(x => {
        GlobalState.messages = x.data;
        if (x.data.length > 0) {
          Socket.emit('messageAllSeen', { chat: chat['id'], sender: chat['partner'] });
        }
        setTimeout(() => {
          scrollToBottom();
        }, 1);
      })

      Bbc.subscribe(AppEvent.messageAllSeen, async (data) => {
        if (selectedChat['id'] == data['chat']) {
          var nList = GlobalState.messages;
          GlobalState.messages = await Promise.all(nList.map(x => {
            if (x['sender'] == data['sender']['id'] && x['status'] == 'Sent') {
              return { ...x, status: 'Seen' }
            } else {
              return x;
            }
          }));
        }
      })



      Bbc.removeLitseners(AppEvent.newMessageRecieved)
      Bbc.subscribe(AppEvent.newMessageRecieved, x => {
        if (x['chat'] == chat['id']) {
          GlobalState.messages.push(x);
          setTimeout(() => {
            scrollToBottom();
          }, 1)
        }
      })
    })
  }, []);

  const MessageRow: FunctionComponent<{ index: number }> = props => {
    var cm = GlobalState.messages[props.index];

    var isForeign = cm['sender'] == selectedChat['owner']['id'];

    if (!isForeign && cm['status'] == 'Sent') {
      Socket.emit('messageSeen', { ...cm, status: 'Seen' });
      setTimeout(() => {
        GlobalState.messages[props.index] = { ...cm, status: 'Seen' }

      }, 40)
    }

    return <Grid item lg={8} >
      <ListItem style={{ marginLeft: isForeign ? '40%' : 0, maxWidth: '100%', width: '100%', overflow: 'hidden' }} alignItems="flex-start">
        <ListItemAvatar style={{display: !isForeign ? 'inline': 'none', marginRight: -20, marginTop: -5}}>
          <img src={adminicon} alt="Remy Sharp" width="25" height="25" />
        </ListItemAvatar>
        <ListItemText
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
              </Typography>
              <Typography className={classes.msgText} style={{
                backgroundColor: isForeign ? '#536883' : '#EFEFEF', color: isForeign ? '#ffffff' : '#303030', 
                borderTopLeftRadius: isForeign ? 8 : 0,
                borderTopRightRadius: isForeign ? 0 : 8,
              }} variant="caption">{cm['text']}</Typography>
              <Typography style={{ width: 150 }} variant="caption">2:35 pm</Typography>
              {isForeign && <Typography style={{ width: 200, marginLeft: '75%' }} align="right" variant="caption">{cm['status']}</Typography>

              }
            </React.Fragment>
          }
        />
          <ListItemAvatar style={{ marginTop: '0%', paddingLeft: 22, display: !isForeign ? 'none' : 'inline', marginLeft: -10}}>
                    <img src={userplace} alt="Remy Sharp" width="30" height="30" />
                </ListItemAvatar>
      </ListItem>
    </Grid>
  }



  if (!selectedChat['id']) {
    return <Grid container justify="center" direction="row" alignItems="center" style={{ paddingTop: '25%' }}>
      <MList>


        <ListItem>
          <Chat style={{ fontSize: 170, color: '#edeaea' }} />
        </ListItem>
        <ListItem>
          <Typography align="center" className={classes.selectText} variant="subtitle2">Select a chat to continue</Typography>
        </ListItem>
      </MList>
    </Grid>
  }



  return (
    <div>
      <Grid container spacing={1} className={classes.container}>
        <Grid container spacing={1} className={classes.container}>
          <Grid item lg={12}>
            <Virtuoso ref={virt} style={{ height: '100%', width: '100%' }} totalCount={GlobalState.messages.length} item={(x) => <MessageRow index={x} />} />
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} className={classes.thirdGrid}>
          <ListItem alignItems="flex-start">
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              rows="6"
              value={cm}
              onChange={x => setCm(x['target']['value'])}
              onKeyDown={e => {
                var newid = createUUID();
                if (e.key == 'Enter') {
                  Socket.emit("messageup", {
                    id: newid,
                    text: cm,
                    chat: selectedChat['id'],
                    sender: selectedChat['owner']['id'],
                    senderEmail: selectedChat['owner']['email'],
                    reciever: selectedChat['partner']['id'],
                    recieverEmail: selectedChat['partner']['email'],
                    created: new Date(),
                    status: 'Sent'
                  });

                  GlobalState.messages.push({
                    id: newid,
                    text: cm,
                    chat: selectedChat['id'],
                    sender: selectedChat['owner']['id'],
                    senderEmail: selectedChat['owner']['email'],
                    reciever: selectedChat['partner']['id'],
                    recieverEmail: selectedChat['partner']['email'],
                    created: new Date(),

                    status: 'Sent'
                  })
                  setTimeout(() => {
                    scrollToBottom();

                  }, 100)
                  setCm('');
                }

              }}
              label="start typing here..."
              id="input-with-icon-textfield"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="send">
                      <SendIcon style={{ color: '#536883', }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            /><br />
          </ListItem>
        </Grid>
      </Grid>
    </div>
  )
})