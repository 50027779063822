
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddProduct } from './AddProduct';
import { SelectImg } from './SelectImg';
import { VariationsOfProduct } from './VariationsOfProduct';
import { API, Ways } from '../Helpers';
import { useSnackbar } from 'notistack';
import React, { useState, FunctionComponent, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: 'auto',



  },


  fixedHeightPaperForm: {

    boxShadow: 'none',
    padding: theme.spacing(4),


  },



  fixedHeightPaperGallery: {

    boxShadow: 'none',
    padding: theme.spacing(4),
  },

  
  fixedHeightProductGallery: {

    boxShadow: 'none',
    padding: theme.spacing(4),
  },

}));



export const ProductLayout = () => {
  const classes = useStyles();
  // const [product , setProudct] = useState();
  const [pics, setPics] = useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const [product, setProduct] = useState({ category: 0, brand: 0 });


  
  const set = (e) => {
    var p = { ...product };
    p[e.target.name] = e.target.value;
    setProduct(p);


  }

  const getData = async () => {

    var id = window.location.pathname.split('/')[4];
    
    if (id != null) {
      API.get('product', { productId: id }).then(x => {
        var p = x.data;
        var images = (x.data['pictures'] as []).map(x => x['image']);
        setPics(images);
        // setProduct(p)
        setProduct({ ...p })
      })
    }
  }
  useEffect(() => {
    getData();
  }, []);


  let a = 1;
  const val = (field: string) => {
    var ret: {} = product[field] || '';
    return ret;
  }


 
 
  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item lg={7} md={12} xs={12}>
        <Paper className={classes.fixedHeightPaperGallery}>

          <AddProduct  product={product}/>
        </Paper>
      </Grid>


      <Grid item lg={5} md={12} xs={12}>
        <Paper className={classes.fixedHeightProductGallery}>
      
        <Typography variant="h6">Product Gallery</Typography>
              <br />
              {product['pictures'] &&
                <SelectImg pictures={pics}
                  disabled={false}
                  onAdded={async (c) => {
                    var res = await API.post('product/image', { product: product, image: c });

                    setPics([...pics, c])
                    enqueueSnackbar("Image Uploaded");
                  }}
                  onChanged={(newListOfImages) => {
                    setPics(newListOfImages);
                  }}
                  onPrimaryChanged={async (newPrimary) => {
                    await API.post('product/setPrimary', { image: newPrimary, product: product['id'] });
                    var newProduct = product;
                    newProduct['primaryImage'] = newPrimary;
                    setProduct(newProduct);
                    enqueueSnackbar("New Primary Image Selected");
                  }}
                  primary={product['primaryImage']} />} 
        </Paper>

        <Paper className={classes.fixedHeightProductGallery}>
        <Typography variant="h6">Variations</Typography>
        <br />
                    <div style={{backgroundColor: '#fbfbfb', padding: 40}}>
               
           
           
                {product['id'] && <VariationsOfProduct product={product} />}
                </div>

            
        </Paper>
      </Grid>




      {/* <Grid item lg={4} md={4} xs={12}>
        <Paper className={classes.fixedHeightPaperForm}>
          <ProductGallery />


        </Paper>

        <Paper className={classes.fixedHeightPaperForm}>
          <SelectImg pictures={pics} disabled={false} onAdded={(x) => { }} onChanged={(x) => { }} primary={'123456789'} />
        </Paper>

      </Grid> */}
    </Grid>

  )
}