import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Card, CardActionArea, CardMedia, CardContent, ListItemText, Link, MenuItem, ListItemSecondaryAction, List, ListItem, Button, Dialog, DialogTitle, IconButton, DialogContent } from '@material-ui/core';
import { LocalShipping, Storage, Receipt, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import StackedChart from '../Charts/StackedChart';
// import { SellerTable } from '../Tables/SellerTable';
import PieChart3 from './../Charts/PieChart3';
import MainPieChart from '../Charts/MainPieChart';
import BarChart from '../Charts/BarChart';
import PieChart4 from '../Charts/PieChart4';
import PieChart2 from '../Charts/PieChart2';
import LineChart from '../Charts/LineChart';
import { Ways, API, Cook, getvalue } from '../Helpers';
import { createBrowserHistory } from 'history';
import TimeChart from './../Charts/TimeChart';
import { useSnackbar } from 'notistack';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { AllBeekeepers } from '../Tables/AllBeekeepers';
import { DashboardFeatures } from './DashboardFeatures';
import { Quarter } from './../Charts/Quarter';
import moment from 'moment';
import { HeatMap } from '../Charts/HeatMap';


const useStyles = makeStyles((theme) => ({


  root: {

    marginTop: '4%',
  },

  actionArea: {

    '&.MuiCardActionArea-root': {

      textAlign: 'center',


    },

  },

  media: {


  },

  mainHeading: {

    color: '#303030',
    fontSize: 36,
    fontWeight: 600,


  },


  descriptionStyle: {


    fontSize: '1rem',
    fontWeight: 500,
    color: '#726f6f',

  },

  Paper3: {
    backgroundColor: '#fff',
    height: '25vh',
    boxShadow: 'none',


    [theme.breakpoints.down('sm')]: {
      height: 200,
    },

  },

  card: {
    maxWidth: 400,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingTop: 30,
    paddingBottom: 30,
    pointerEvents: 'none',




  },




  Paper: {

    boxShadow: 'none',
  },


}));




export const DashboardContent = () => {

  const [data, setData] = useState<any[]>([])
  const [pro, setPro] = useState<any[]>([])
  const [total, setTotal] = useState<any[]>([])
  const [IsHeatMapDialogOpen, setIsHeatMapDialogOpen] = useState(false)
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();


  const [quarter, setQuarter] = useState<number>(1)



  const getData = async (year: number, quarter: number) => {
    var z = await API.get('admin/charts/totals', { year: year, quarter: quarter });

    if (z.status == 200) {
      // window.location.reload();
      console.log(z.data)
      return z.data;
      enqueueSnackbar('data request has been successfully sent!');
    }
    else {
      enqueueSnackbar('data request failed!')
    }
    console.log(z.data)
  }


  useEffect(() => {


    getData(2022, 4).then(x => {
      setTotal(x)


    })

    API.get('allorders').then(x => {

      setData(x.data);
    })


    // API.get('products').then(y => {


    //   setPro(y.data['products']);
    // })




  }, [])

  const handleHeatMapDialog = () => {
    setIsHeatMapDialogOpen(!IsHeatMapDialogOpen)
  }


  let id: number = 1
  const getID = () => {
    return id++;
  }


  if (!data) {
    return <div>


    </div>
  }




  return (

    <div className={classes.root}>

      {/* cards start here  */}


      <Grid container direction="row" spacing={2}>


        <Grid item lg={12}>
          <DashboardFeatures AllProducts={pro} allBeekeepers={data} currentData={total} totalData={{ totalOrderValue: total['sale'], }} />
        </Grid>

      </Grid>






      {/* cards ends here */}

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} lg={12}  >
          <div  onClick={() => {
            handleHeatMapDialog();
          }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',overflow:'hidden' }} >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', justifyContent: 'center', width: '100%', backgroundColor: 'white' }} >
              <p style={{ fontSize: '18px', fontWeight: '500' }} >
                AHV Sales Report Worldwide
              </p>
              <div onClick={(event) => {
                // event.stopPropagation();
              }} >
                <div  >

                  <HeatMap />
                </div>
              </div>
            </div>
          </div>
        </Grid>





      </Grid>



      <Grid container spacing={2}>

        <Grid item lg={6} xs={12}>

          <StackedChart currentData={data} />
        </Grid>



        <Grid item lg={6} xs={12}>
          <PieChart3 currentData={data} />
        </Grid>

        <Grid item lg={6} xs={12} sm={12}>


          <MainPieChart currentData={data} />
        </Grid>

        <Grid item lg={6} xs={12} sm={12}>
          <PieChart2 currentData={data} />

        </Grid>


        <Grid item lg={6} xs={12} sm={12}>
          <BarChart currentData={data} />
        </Grid>

        <Grid item lg={6} xs={12} sm={12}>
          <PieChart4 currentData={data} />
        </Grid>

        <Grid item lg={12} xs={12}>
          <LineChart currentData={data} />
        </Grid>




        <Grid item xs={12} >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }} >
            <Typography style={{ fontSize: '1.3rem' }} variant="h4">
              Recent Registered Beekeepers
            </Typography>
            <Link style={{ textDecoration: 'none' }} href="dashboard/allbeekeepers">
              <Button variant="contained" style={{ backgroundColor: '#f28f16', color: '#fff' }}>View All</Button>
            </Link>
          </div>
        </Grid>

        <Grid item xs={12} style={{ backgroundColor: 'transparent', }}>
          <AllBeekeepers />
        </Grid>

        <Grid item lg={12}>
          {/* <Quarter  /> */}
        </Grid>
      </Grid>

      {/* ______ HEAT MAP DIALOG _____  */}

      <Dialog fullScreen open={IsHeatMapDialogOpen} >
        <ListItem>
          <ListItemText>
            <DialogTitle id="form-dialog-title"> </DialogTitle>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton>
              <Cancel onClick={handleHeatMapDialog} />

            </IconButton>
          </ListItemSecondaryAction>

        </ListItem>
        <DialogContent style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }} >


          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', justifyContent: 'center', width: '100%', backgroundColor: 'white' }} >
            <p style={{ fontSize: '19px', fontWeight: '500' }} >
              AHV Sales Report Worldwide
            </p>
            <HeatMap />
          </div>
        </DialogContent>


      </Dialog>

    </div>
  )
}