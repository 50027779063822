import { Button, Grid, Paper, Dialog, DialogContent, List, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { NewCategory, CategoryEdi } from './NewCategory';
import { API, getvalue } from '../Helpers';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: 'auto',
  },

  fixedHeightPaperEmail: {

    boxShadow: 'none',
    padding: theme.spacing(4),
  },

}));



export const AllCategoriesTable: FunctionComponent = () => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([]);
  const [cCat, setcCat] = useState();
  const [ctd , setCTD] = useState()
  const [loading,setLoadin] = useState(false);

  let id: number = 1

  const { enqueueSnackbar } = useSnackbar();
  const getdata = () => {
    setLoadin(true);
    API.get('categories').then(x => {
      setData(x.data)
    setLoadin(false);

    });
  }
  useEffect(() => {
    getdata();
  }, []);

  if(loading) return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  <CircularProgress style={{color: '#f28f16'}} />
</div>);


  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item lg={12} md={12} xs={12}>
        <Paper className={classes.fixedHeightPaperEmail}>
          <NewCategory oldCategories={data} onCategoryAdded={ncat => {

            var d = [...data, ncat];
            var sortedData: any[] = [];
            d.forEach(a => {
              if (a.level == 0) {
                sortedData.push(a);
              }
              if (a.level > 0) {
                sortedData.splice(sortedData.indexOf(sortedData.filter(x => x['id'] == a.parent.id)[0]) + 1, 0, a)
              }
            })
            setData(sortedData)
          }} />
        </Paper>
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <Paper className={classes.fixedHeightPaperEmail}>
          <MaterialTable options={{ pageSize: 20 }}
            style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
            title="All Categories"
            columns={[
              { title: 'Category Id', render: row => <div>{'HON-CAT-'+row['id']}</div> },
              {
                title: 'Name', render: (row) => {
                  var name = String(row['name']);
                  for (let i = 0; i < row['level']; i++) {
                    name = '- ' + name;
                  }
                  return <div>{name}</div>
                }
              },
              {
                title: 'Actions', render: (row) =>
                  <Grid container spacing={2}>
                    <Grid item lg={3} xs={12}>
                      <Button onClick={() => {
                        setcCat(row);
                      }} variant="contained" size="small" style={{ backgroundColor: '#F28f16', boxShadow: 'none', color: '#ffffff', }}>Edit</Button>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <Button onClick={() => {
                       setCTD(row);
                      }} variant="contained" size="small" style={{ backgroundColor: '#FF0000', boxShadow: 'none', color: '#ffffff', }}>Delete</Button>
                    </Grid>
                  </Grid>
              },
            ]}
            data={data}
          />

          <Dialog open={cCat != null} onClose={() => setcCat(undefined)}>
            <DialogContent style={{padding:'20px'}}>
            <CategoryEdi oldCat={cCat} onUpdated={()=>{
              getdata();
              setcCat(undefined);
            }}/>
            </DialogContent>
          </Dialog>

          <Dialog open={ctd != undefined} onClose={() => setCTD(undefined)}>
          <DialogContent>
            <Typography variant='body2'>Are you sure you want to delete this Category ?</Typography>
            <br />
            <List>
              <Button style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }} onClick={() => {
                   API.delete('category', { categoryid: getvalue(ctd , 'id') }).then(x => {
                    if (x.status == 200) {
                      getdata();
                      enqueueSnackbar('Category deleted');
                    } else {
                      enqueueSnackbar("Could not delete")
                    }
                    setCTD(undefined);
                  })
                }
              }>Delete</Button>
              <Button style={{backgroundColor : '#f7f7f7'}} onClick={() => {
                setCTD(undefined);
              }}>Cancel</Button>
            </List>
          </DialogContent>
        </Dialog>
        </Paper>
      </Grid>
    </Grid>
  )
}