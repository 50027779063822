import React, { FunctionComponent, useState, useEffect } from "react";
import { Grid, Typography, Button, List, ListItem, ListItemSecondaryAction, ListItemText, Divider, Dialog, DialogContent } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { API } from "../Helpers";
import { useSnackbar } from "notistack";


export const AddProductVariation: FunctionComponent<{ product: any, onAdded?: (x) => void }> = props => {

    const [adding, setAdding] = useState(false);
    const [price, setPrice] = useState();
    const [items, setItems] = useState()
    const [weight, setWeight] = useState();
    const [RRP, setRRP] = useState();

    const { enqueueSnackbar } = useSnackbar();
    return <div style={{paddingTop: 10}}>
        {!adding && <Button variant="contained" style={{backgroundColor: '#f28f16', color: '#fff'}} fullWidth onClick={() => {
            setAdding(true);
        }}>Add New</Button>}

        {adding && <ValidatorForm onSubmit={async () => {
            var re = await API.post('product/addvariation', {
                productid: props.product['id'], variation: {
                    price: price, weight: weight, rrp: RRP, itemsPerCarton: items
                }
            });
            if (props.onAdded) {
                await props.onAdded({
                    productid: props.product['id'], variation: {
                        price: price, weight: weight, rrp: RRP, itemsPerCarton: items
                    }
                });
            }
            setAdding(false);
            setPrice(undefined);
            setItems(undefined);
            setWeight(undefined);
            setRRP(undefined);
            enqueueSnackbar("Variation Added")

        }}>

            <Grid container spacing={2}>
                <Grid item lg={12}>
                    <TextValidator 
                    // style={{margin :'10px'}}
                        name='proce'
fullWidth

label='Price'
                        value={price}
                        variant='outlined'
                        onChange={(x) => { setPrice(x.target['value']) }}
                        validators={['isNumber']}
                        errorMessages={['Price must be a number']}></TextValidator>
                </Grid>

                <Grid item lg={12}>
                    <TextValidator
                        name='weight'
                        //  style={{margin :'10px'}}
                        label='Weight in grams'
                        value={weight}
                        variant='outlined'
fullWidth
                        onChange={(x) => { setWeight(x.target['value']) }}
                        validators={['isNumber']}
                        errorMessages={['Weight must be a number']}></TextValidator>
                </Grid>

                <Grid item lg={12}>
                    <TextValidator
                        name='rrp'
                        //  style={{margin :'10px'}}
                        label='RRP $'
                        value={RRP}
                        variant='outlined'
fullWidth
                        onChange={(x) => { setRRP(x.target['value']) }}
                        validators={['isNumber']}
                        errorMessages={[' must be a number']}></TextValidator>
                </Grid>

                <Grid item lg={12}>
                    <TextValidator
                        name='items'
                        label='Jars Per Carton'
                        value={items}
                        variant='outlined' 
                        // style={{margin :'10px'}}
                        fullWidth

                        onChange={(x) => { setItems(x.target['value']) }}
                        validators={['isNumber']}
                        errorMessages={['This must be a number']}></TextValidator>
                </Grid>

                <Grid item lg={12}>
                    <Button variant="contained" style={{color: '#fff', backgroundColor: '#f28f16'}} fullWidth type='submit' onClick={async () => {

                    }}>Save</Button>
                </Grid>
            </Grid>

        </ValidatorForm>}
    </div>
}


export const EditProductVariation: FunctionComponent<{ variation: any, onSaved: (x) => void }> = props => {

    const [adding, setAdding] = useState(false);
    const [price, setPrice] = useState(props.variation.price);
    const [items, setItems] = useState(props.variation.itemsPerCarton)
    const [weight, setWeight] = useState(props.variation.weight);
    const [RRP, setRRP] = useState(props.variation.rrp);

    const { enqueueSnackbar } = useSnackbar();
    return <div style={{paddingTop: 10}}>
      

        {<ValidatorForm onSubmit={async () => {
          props.onSaved({
            price : price,
            itemsPerCarton : items,
            weight:weight,
            rrp : RRP
          });
        }}>

            <Grid container spacing={2}>
                <Grid item lg={12}>
                    <TextValidator 
                    // style={{margin :'10px'}}
                        name='proce'
fullWidth

label='Price'
                        value={price}
                        variant='outlined'
                        onChange={(x) => { setPrice(x.target['value']) }}
                        validators={['isNumber']}
                        errorMessages={['Price must be a number']}></TextValidator>
                </Grid>

                <Grid item lg={12}>
                    <TextValidator
                        name='weight'
                        //  style={{margin :'10px'}}
                        label='Weight in grams'
                        value={weight}
                        variant='outlined'
fullWidth
                        onChange={(x) => { setWeight(x.target['value']) }}
                        validators={['isNumber']}
                        errorMessages={['Weight must be a number']}></TextValidator>
                </Grid>

                <Grid item lg={12}>
                    <TextValidator
                        name='rrp'
                        //  style={{margin :'10px'}}
                        label='RRP $'
                        value={RRP}
                        variant='outlined'
fullWidth
                        onChange={(x) => { setRRP(x.target['value']) }}
                        validators={['isNumber']}
                        errorMessages={[' must be a number']}></TextValidator>
                </Grid>

                <Grid item lg={12}>
                    <TextValidator
                        name='items'
                        label='Jars Per Carton'
                        value={items}
                        variant='outlined' 
                        // style={{margin :'10px'}}
                        fullWidth

                        onChange={(x) => { setItems(x.target['value']) }}
                        validators={['isNumber']}
                        errorMessages={['This must be a number']}></TextValidator>
                </Grid>

                <Grid item lg={12}>
                    <Button variant="contained" style={{color: '#fff', backgroundColor: '#f28f16'}} fullWidth type='submit' onClick={async () => {

                    }}>Save</Button>
                </Grid>
            </Grid>

        </ValidatorForm>}
    </div>
}

export const VariationsOfProduct: FunctionComponent<{ product: any }> = props => {

    const [variations, setVariations] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    var [cv,setCv] = useState({});
    var [editing , setEditing] = useState(false)

 
    const getData = async () => {
        var res = await API.get('product/variations', { productid: props.product['id'] });
        setVariations(res.data['variations'])
    }

    useEffect(() => {
        getData();
    }, [])


    function SingleVariation(div) {
        return <div>

            <Grid container>
                <Grid lg={12}>

                    <List>
                        <ListItem>
                            <ListItemText>
                                Price:
                            </ListItemText>

                            <ListItemSecondaryAction>
                            <Typography>{div['price']}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>


                        <ListItem>
                            <ListItemText>
                                Weight:
                            </ListItemText>

                            <ListItemSecondaryAction>
                            <Typography>{div['weight']}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem>
                            <ListItemText>
                                RRP:
                            </ListItemText>

                            <ListItemSecondaryAction>
                            <Typography>{div['rrp']}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>


                        <ListItem>
                            <ListItemText>
                                Items Per Carton:
                            </ListItemText>

                            <ListItemSecondaryAction>
                            <Typography>{div['itemsPerCarton']}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>


                    </List>

                </Grid>
            </Grid>
           


            <List style={{display: 'flex', flexDirection: 'row'}}>

            <ListItem>
          
          <Button variant="contained" fullWidth style={{ backgroundColor: '#f28f16', color: '#fff' }} onClick={async () => {
             setCv(div);
             setEditing(true)
          }}>Edit</Button>

</ListItem>
                <ListItem>

              
            
                <Button variant="contained" fullWidth style={{ backgroundColor: '#FF0000', color: '#fff' }} onClick={async () => {
                    await API.delete('product/variation', { 'variationid': div['id'] })
                    await getData();
                    enqueueSnackbar("Variation Deleted");
                }}>Delete</Button>

</ListItem>



</List>
           
            <Divider style={{marginTop: 25, marginBottom: 5}} />
        </div>
    }

    return <div>
        {
            variations.map((x, i) => {
                return SingleVariation(x);
            })
        }
        <AddProductVariation product={props.product} onAdded={async () => { await getData() }} />


        <Dialog open={editing} onClose={()=>setEditing(false)}>
        <DialogContent>
            <EditProductVariation variation={cv} onSaved={async (x)=>{
               var res = await API.put('product/variation' ,x, {variationid : cv['id']} )
               console.log(variations)
               var index = variations.findIndex(x=>x['id'] == cv['id']);
                if(index==-1){
               enqueueSnackbar("Failed to save Changes");
               setEditing(false);
                }
               variations[index] = x;
                setVariations([...variations])
               enqueueSnackbar("Changes saved");
               setEditing(false);
            }}/>
        </DialogContent>
        </Dialog>
    </div>
}
