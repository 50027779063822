import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import avh from './../assets/honeylogo.png';
import { Ways, LoginHelper, API } from '../Helpers';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { createBrowserHistory } from 'history';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.avh.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}-v1.1
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',

    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },
}));


export const LoginHandler = () => {

  var path = window.location.pathname;

  useEffect(() => {
    if (path.length > 1) {
      console.log('Checking Status');
      API.checkSession().then(x => {
        console.log(x);
      })
    }
  }, []);



  if (path.length <= 1) {
    return <Login />
  }


  return <div></div>
}

export const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [v] = useState(false);
  const [] = useState('');
  const [errorText, setErrorText] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    ValidatorForm.addValidationRule('emailValid', () => {
      return v;
    });
    return function cleanup() {
      ValidatorForm.removeValidationRule('emailValid')
    }
  }, [])

  return (
    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <div className={classes.paper}>
        <img src={avh} alt="logo-image" />
        <br /><br />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <ValidatorForm onSubmit={() => {

          LoginHelper.login(email, password).then(x => {
            if (x == '') {
              setErrorText('')
              createBrowserHistory().push(Ways.home);
              window.location.reload();
            } else {
              enqueueSnackbar(x);
              setErrorText(x);
            }
          })
        }} className={classes.form} noValidate>

          <TextValidator
            value={email}
            onChange={e => setEmail(e.target['value'])}
            variant="outlined"
            margin="normal"
            validators={['required', 'isEmail',]}
            errorMessages={['Email is Required', 'Emails is Invalid',]}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
           
          />
          <TextValidator
            variant="outlined"
            value={password}
            onChange={e => setPassword(e.target['value'])}
            validators={['required']}
            errorMessages={['Password is Required']}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          {errorText.length > 0 && <p style={{ color: 'red' }}>{errorText}</p>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}

          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href={Ways.signUp} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}