import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent, useState } from 'react';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { API } from '../Helpers';



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // alignItems: 'center',
  },
  root: {
    display: 'flex',
  },
  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },




}));

export const NewCategory: FunctionComponent<{ oldCategories: any[], onCategoryAdded?: (cat: any) => void, oldCat?: any }> = props => {
  const classes = useStyles();
  const [selectedParent, setSelectedParent] = useState<any>(props.oldCat != null ? props.oldCat['parent'] != null ? props.oldCategories.filter(x => x['id'] == props.oldCat['parent']['id'])[0] : 0 : 0);
  const [name, setName] = useState<any>(props.oldCat != null ? props.oldCat['name'] : '');



  return (
    <div className={classes.root}>

      <CssBaseline />
      <div className={classes.paper}>
        <Typography variant="h6">
          {props.oldCat == null ? 'Add New Category' : 'Edit'}

        </Typography>
        <ValidatorForm onSubmit={() => {
          var body = { name: name };
          if (selectedParent !== null) {
            var id = selectedParent;
            if (id != null) {
              body['parent'] = id;
            }
          }
          // console.log(body);
          API.post('category', body).then(x => {
            if (x.status == 200) {
              if (props.onCategoryAdded != null) {
                props.onCategoryAdded(x.data);
              }
              setName('')
              setSelectedParent(0);
            }
          })

        }


        } className={classes.form} noValidate>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextValidator
                validators={['required']}
                errorMessages={['Name is required']}
                variant="outlined"
                required
                value={name}
                onChange={x => { setName(x.target['value']) }}
                fullWidth
                id="product-title"
                label="New Category"
                name="New Category"

              />
            </Grid>
            <Grid item xs={6}>
              <SelectValidator
                validators={[]}
                errorMessages={[]}
                fullWidth
                value={selectedParent}
                variant="outlined"
                required
                onChange={x => {

                  setSelectedParent(x.target['value']);
                }}
                placeholder="Parent Category"
                label="Parent Category"
                name="Parent Category"
              >
                {[<MenuItem value={0} key={-1}>None</MenuItem>].concat(props.oldCategories.map((x, i) => <MenuItem key={i} value={x}>{x['name']}</MenuItem>))}

              </SelectValidator>

            </Grid>

            <Grid item lg={3}>

              <Button
              
                variant="contained" color="secondary" type="submit" fullWidth={true} className={classes.btnStyle}>
                {props.oldCat == null ? 'Add New Category' : 'Save'}
              </Button>

            </Grid>





          </Grid>


        </ValidatorForm>
      </div>
    </div>


  );
}



export const CategoryEdi: FunctionComponent<{ oldCat: any  , onUpdated :()=>void}> = props => {

  const [cat, setCat] = useState(props.oldCat);
  return <div>
    {/* <Typography variant="body1">Edit</Typography> */}
    <br/>
    <ValidatorForm onSubmit={() => {

      API.put('category' , cat , {categoryid : cat['id']}).then(x=>{
        if(x.status == 200){
          props.onUpdated();
        }
      })
     }}>
      <TextValidator
        validators={['required']}
        errorMessages={['Name is required']}
        variant="outlined"
        required
        value={cat['name']}
        onChange={x => {

          var a = { ...cat };
          a['name'] = x.target['value'];
          setCat(a);
        }}
        fullWidth
        id="product-title"
        label="Category Name"
        name="New Category"

      />
      <br/>
      <Button style={{margin:'10px', color: '#ffffff', backgroundColor: '#41d18c'}} type='submit'>Save</Button>
     
    </ValidatorForm>
  </div>
}

