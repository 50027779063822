import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Paper, FormControl, InputLabel, Select, MenuItem, Button, Typography, ListItem, ListItemText, Divider, List, TextField, ListItemSecondaryAction, Chip, CircularProgress } from '@material-ui/core';
import { TimePicker } from "@material-ui/pickers";
import { QuoteCartTable } from './QuoteCartTable';
import { SetTimer } from "./SetTimer";
import { QuoteForm } from './QuoteForm';
import { API, getval } from "../Helpers";
import { useSnackbar } from "notistack";
import moment from 'moment';




const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(2),
        overflow: 'auto',

    },

    listStylesecond: {

        display: 'flex',
        flexDirection: 'row',
        width: '100%',


    },

    listStyle: {

        display: 'flex',
        width: '100%',
        paddingBottom: '3%',




    },

    bottomListStyle: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },

    formControl: {
        // margin: theme.spacing(0),

        minWidth: '100%',
    },

    btnStyle: {

        backgroundColor: '#f28f16',
        boxShadow: 'none',
        color: '#ffffff',


        '&.MuiButton-contained': {


            '&:hover': {


                backgroundColor: '#FECF51',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },


    fixedHeightPaper: {

        boxShadow: 'none',
        padding: theme.spacing(2),
    },

}));




export const Quote = () => {
    const [offer, setOffer] = useState({});
    const [selectedDate, handleDateChange] = useState(new Date());
    const classes = useStyles();
    const getData = async () => {
        var id = window.location.pathname.split('/')[4];
        var of = await API.get('offer', { offerid: id });
        setOffer(of.data);
        console.log(of.data)
    }


    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        getData();
    }, [])

    if (!offer['id']) {
        return <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{paddingLeft: '50%', paddingTop: '25%'}}
      >
            <Grid item lg={12} xs={12}>
            <CircularProgress style={{color: '#f28f16'}} />

            </Grid>
            </Grid>
    }

    var subtotal = 0;

    const getTotal = (): string => {
        var a  = getSubTotal(offer['cartItems']) + Number(getval(offer, 'shippingCost', 0));
        return Math.round(a).toFixed(2);
    }

    const getSubTotal = (cartItems: any[]): number => {

        subtotal = 0;

        cartItems.forEach(x => {
            var c = {
                quantity: x['quantity'],
                discount: x['discount'],
                price: x['offeredPrice'],
                itemsPerCarton: x['variation']['itemsPerCarton']
            }

            var total = (c.quantity * c.price * c.itemsPerCarton);
            var price = total - (c.discount / 100 * total);
            subtotal += price;
        });

        return Math.round((subtotal + Number.EPSILON) * 100) / 100
    }
    return (
        <Grid container className={classes.root} spacing={1}>
            <Grid item lg={9} md={9} xs={12}>
                <Paper className={classes.fixedHeightPaper}>
                    <List className={classes.listStyle} style={{ display: 'flex', flexDirection: 'column', paddingBottom: '-6%', marginLeft: '-1%' }}>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h6">Quote #
                        HON-QUO-{offer['rid']} </Typography>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Typography variant="body2"> <b>Submited On : </b>{moment(offer['createdOn']).format('MM/DD/YY')}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <QuoteCartTable quote={offer} onUpdate={(nd) => {
                        var d = offer;
                        d = { ...d, cartItems: nd.map(x => x['cartItem']) };
                        // console.log(nd.map(x => x['cartItem']).map(x => x['variation']));
                        setOffer(d);
                    }} />
                </Paper>
            </Grid>
            <Grid item lg={3} md={3} xs={12}>


                {/* Timer */}
                <Paper className={classes.fixedHeightPaper}>
                    <br />
                    <Typography variant="h6">Set Time</Typography>
                    <br />
                    <SetTimer onChange={(x) => {
                        var nobj = { ...offer, allowedDate: new Date(x) }
                        setOffer(nobj);
                        console.log(nobj)
                        // console.log(x.offer)
                    }} />
                </Paper>
                <Paper className={classes.fixedHeightPaper}>
                    <Typography variant="h6">Set Status</Typography>
                    <br />
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Status"
                            fullWidth
                            value={offer['status']}
                            onChange={(x)=>{
                                setOffer({...offer , status : x.target['value']})
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {/* <MenuItem value={'Active'}>Active</MenuItem> */}
                            <MenuItem value={'Pending'}>Pending</MenuItem>
                            <MenuItem value={'Expired'}>Expired</MenuItem>
                            <MenuItem value={'Quoted'}>Quoted</MenuItem>
                        </Select>
                    </FormControl>
                    <br /><br />
                    <Button fullWidth variant="contained" onClick={() => {
                        API.put('offer', { allowedDate: offer['allowedDate'] , totalBill: getTotal(), status: offer['status'], shippingCost: offer['shippingCost'] }, { offerid: offer['id'] }).then(x=>{
                            enqueueSnackbar("Quote Saved")
                        })
                    }} className={classes.btnStyle}>
                        Save
      </Button>
                </Paper>
            </Grid>
            {/* <Grid item lg={4} style={{backgroundColor: '#fff'}}>
                
            </Grid> */}
            <Grid item lg={9} xs={12} style={{ marginTop: '-2%' }}>
                <Paper className={classes.fixedHeightPaper} style={{paddingLeft: '40%'}}>
                    <List dense className={classes.bottomListStyle}>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="subtitle2">Sub Total</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {/* <Typography variant="body2"> 50 AUD</Typography> */}
                                {offer['cartItems'] && <TextField disabled id="outlined-basic" size="small" variant="outlined" value={getSubTotal(offer['cartItems'])} />}
                            </ListItemText>
                        </ListItem>
                    </List>
                    <Divider />
                    <List dense className={classes.bottomListStyle}>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="subtitle2">Shipping Costs</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {/* <Typography variant="body2"> 25 AUD</Typography> */}
                                <TextField id="outlined-basic" size="small" variant="outlined" value={offer['shippingCost']} onChange={(x) => {
                                    setOffer({ ...offer, shippingCost: x.target['value'] });
                                }} />
                            </ListItemText>
                        </ListItem>
                    </List>
                    <Divider />
                    <List dense className={classes.bottomListStyle}>

                        <ListItem>
                            <ListItemText>
                                <Typography variant="subtitle2">Total</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="body2">{getTotal()}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Paper>
            </Grid>
            <Grid item lg={3}></Grid>
            <Grid item lg={9}>
                <Paper className={classes.fixedHeightPaper}>

                    <QuoteForm quote={offer} />
                </Paper>
            </Grid>
            <Grid item lg={3}></Grid>
        </Grid>
    )
}