import React, { useEffect, useState, FunctionComponent } from 'react';
import MaterialTable from 'material-table';
import { Grid, Chip, List, ListItem, Button, CircularProgress, Dialog, DialogContent, Typography } from '@material-ui/core';
import { Ways, API, Cook, getvalue } from './../Helpers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Console } from 'console';


export const AddBonusTable: FunctionComponent<{ currentSingleIBC: any }> = props => {

    const [loading, setLoadin] = useState(false);
    const [ctd, setCTD] = useState('')
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState<any[]>([]);
    const [isIbcBonusDeletdOpen, setIsIbcBonusDeletdOpen] = useState(false)

    const getData = () => {
        API.get('getBonuses', { ibcid: window.location.pathname.split('/')[3] }).then(x => {
            setData(x.data);
        })
    }

    useEffect(() => {

        getData()

    }, [])

    const handleIbcBonusDeltedOpen = () => {
        setIsIbcBonusDeletdOpen(!isIbcBonusDeletdOpen)
    }

    return (
        <div>
            <MaterialTable
                style={{ boxShadow: 'none', backgroundColor: '#fff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, padding: '10px' }}

                title=" All Bonuses"
                columns={[


                    { title: <p style={{ fontSize: '14px', }} > Bonus ID</p>, field: 'rid', render: (row) => <div style={{ padding: '5px 0px' }} >HON-BNS-{row['rid']}</div> },
                    { title: ' Bonus Amount ', field: 'amount', render: (row) => <div style={{ padding: '5px 0px' }} >${Number(row['amount']??0).toFixed(2)}</div> },
                    // { title: 'Date', render: (row) => <div>{moment(row['date']).format('MMMM Do YYYY')}</div> },
                    { title: 'Date', field: 'createdAt', render: (row) => <div style={{ padding: '5px 0px' }} >{moment(row['createdAt']).format('DD/MM/YYYY')}</div> },
                    { title: 'Note', field: 'comment', render: (row) => <div style={{ padding: '5px 0px' }} >{row['comment']}</div> },
                    // { title: 'Amount (AUD)', render: (row) => <div>{row['amount']}</div>},



                    {
                        title: <p> Actions</p>, sorting: false, render: (row) =>
                            <List style={{ display: 'flex', flexDirection: 'row',padding:'0px 0px' }}>

                                
                                    <Button
                                        variant="contained" size="small"
                                        style={{ backgroundColor: '#ff0000', color: '#fff',height:'30px' }}
                                        onClick={() => {
                                            setCTD(`${row['id']}`)

                                            handleIbcBonusDeltedOpen();


                                        }}
                                    >
                                        delete
                                    </Button>
                                

                            </List>


                    },
                ]}
                data={data}

            />


            <Dialog open={isIbcBonusDeletdOpen}>
                <DialogContent>
                    <Typography variant='body2'>Are you sure you want to delete this Bonus ?</Typography>
                    <br />
                    <List>

                        <Button style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
                            onClick={() => {
                                API.delete('ibcBonus', { bonusid: ctd }).then(x => {
                                    if (x.status == 200) {
                                        // getdata();
                                        window.location.reload();
                                        enqueueSnackbar('Bonus deleted');
                                    } else {
                                        enqueueSnackbar('Could noe deleted')
                                    }
                                    setCTD('');
                                    console.log(ctd)

                                }
                                )
                            }

                            }>Delete</Button>
                        <Button style={{ backgroundColor: '#f7f7f7' }} onClick={() => {
                            handleIbcBonusDeltedOpen();
                            setCTD('');

                        }}>Cancel</Button>
                    </List>
                </DialogContent>
            </Dialog>
        </div>


    )



}