import React,{useState, FunctionComponent} from 'react';
import { Grid, Button, FormControl, MenuItem, Select, InputLabel, Typography, List, ListItem, ListItemSecondaryAction, IconButton,  ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Jarrah } from './Jarrah';
import { Redgum } from './Redgum';
import { RedgumBlend } from './RedgumBlend';
import { Cancel } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { HoneyPopupForm } from './HoneyPopupForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { HoneyExpansion } from './HoneyExpansion';
import { VariationsOfProduct } from '../Product/VariationsOfProduct';
import { AddHoneyCategory } from './TestingComponent';
import { RedgumTable } from './RedgumTable';
import { PopupHoneyEditableForm } from './PopupHoneyEditableForm';
import { useEffect } from 'react';
import { API, Cook } from './../Helpers'
import { ImgUpload } from './../SellerProfile/ImgUpload'
import { HoneyHoldingsTable } from './SingleHoneyPage/HoneyHoldingsTable';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    content: {

        maxHeight: 800,
    },
  }),
);



export const HoneyLayout = () => {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [holding, setHolding] = useState<any>({})
 
    useEffect(() => {
    API.get('ibcs').then(x => {
      // console.log(x.data);
        setData(x.data);
        // getholding();
        
    });
    

  }, [])

  // const getholding = () => {

  //   API.get('holding', { holdingid:window.location.pathname.split('/')[3] }).then(z => {
  //     console.log({dataforHolding : z.data})
  //     setHolding(z.data);

  //   });

    
  // }

  // let id: number = 1
  // const getId = () => {
  //   return id++;

  // }




  
  
  
 

    return(
        <div style={{marginTop: '-6%', overflowX: 'hidden'}}>
           <PopupHoneyEditableForm />
            <br /><br />
            {/* <HoneyExpansion  currentHolding={data} /> */}
            

            <HoneyHoldingsTable currentHolding={data} />
            <br />
           
        

           
  



         
               
        </div>
    )
}