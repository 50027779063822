import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { ChatList } from './ChatList';
import { Profile } from './Profile';
import { Messages } from './Messages';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(2),
        overflow: 'auto',
    },

    fixedHeightPaperEmail: {
        maxHeight: 640,
        overflow: 'auto',

        boxShadow: 'none',
        // padding: theme.spacing(4),
        paddingTop: 25,
    },


    fixedHeightPaperMsg: {
        height: 640,
        boxShadow: 'none',
        paddingTop: 25,
    },


    fixedHeightPaperProfile: {
        minHeight: 640,
        maxHeight: 640,
        overflow: 'auto',
        boxShadow: 'none',
        paddingTop: 25,
        overflowY: 'auto',
        backgroundColor: '#fff',
    },



    fixedHeightPaperOfferDetail: {
        height: 450,
        boxShadow: 'none',
        // padding: theme.spacing(4),
        paddingTop: 25,

        [theme.breakpoints.down('sm')]: {


            height: 800,
        },


    },


}));



export const ChatLayout = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root} spacing={1}>
            {/* <Grid item lg={12} xs={12}>
                <Paper className={classes.fixedHeightPaperOfferDetail}>
                <OfferDetail />
                </Paper>
            </Grid> */}
            <Grid item lg={3} md={5} xs={12}>
                <Paper className={classes.fixedHeightPaperEmail}>
                    <ChatList />
                </Paper>
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
                <Paper className={classes.fixedHeightPaperMsg}>

                    <Messages />


                </Paper>
            </Grid>

            <Grid item lg={3} md={3} xs={12} >
                <Paper className={classes.fixedHeightPaperProfile}>
                    <Profile />

                </Paper>

            </Grid>
        </Grid>

    )
}