import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React, { useEffect, useState, FunctionComponent } from 'react';
import { useSnackbar } from 'notistack';
import { Ways, API, Cook, getvalue, currentQuarter } from '../Helpers';
import { Grid, MenuItem } from '@material-ui/core';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import moment from 'moment';
import { QuarterSelector } from "./Quarter";

// Resolves charts dependancy
charts(FusionCharts);


export const LineChart: FunctionComponent<{ currentData: any }> = props => {

  const [data, setData] = useState<any>()
  const [selectedQuarter, setQuarter] = useState<number>(currentQuarter().q)
  const [selectedYear, setYear] = useState<number>(currentQuarter().y)
  const [beekeeper, setBeekeeper] = useState<number>()

  const { enqueueSnackbar } = useSnackbar();




  const getData = async (year: number, quarter: number) => {
    var x = await API.get('admin/charts/inputoutput', { beekeeperid: beekeeper, year: year, quarter: quarter });
    if (x.status == 200) {
      // window.location.reload();
      // console.log(x.data)
      setYear(year)
      setQuarter(quarter)
      setData(x.data)
      return
    }
    else {
      enqueueSnackbar('data request failed!')
    }
  }


  useEffect(() => {


    getData(selectedYear, selectedQuarter)



  }, [])



  if (!data) {
    return <div></div>
  }




  const dataSource = {
    chart: {
      // caption: "AHV Honey Stocks - deliveries vs sales",
      // captionAlignment: "center",
      Align: "left",
      // yaxisname: "Velocity (in mph)",
      // subcaption: "[2019-2020]",

      rotatelabels: "1",
      setadaptiveymin: "1",
      bgColor: "#ffffff",
      bgAlpha: "50",
      borderRadius: "8",
      showBorder: "1",
      borderColor: "#ffffff",
      theme: "fusion",
      yaxisname: "Quantity of AHV Stock",
      showhovereffect: "1",
      numbersuffix: " (t)",
      drawcrossline: "1",
      plottooltext: "<b>$dataValue</b> of AHV Stock on $seriesName",

    },


    categories: [
      {
        category: [


          {
            label: selectedQuarter == 4 ? 'April' : selectedQuarter == 3 ? 'January' : selectedQuarter == 2 ? 'October' : 'July',
          },
          {
            label: selectedQuarter == 4 ? 'May' : selectedQuarter == 3 ? 'February' : selectedQuarter == 2 ? 'November' : 'August'
          },
          {
            label: selectedQuarter == 4 ? 'June' : selectedQuarter == 3 ? 'March' : selectedQuarter == 2 ? 'December' : 'September',

          },


        ]
      }
    ],


    dataset: [




      {

        seriesname: "Deliveries",
        color: "#f28f16",
        data: [


          // {
          //   label: "Deliveries",
          //   value: data.input,

          //   color: "#c63131",
          // },



          {
            label: "Deliveries",
            value: data.deliveries[selectedQuarter == 4 ? 'April' : selectedQuarter == 3 ? 'January' : selectedQuarter == 2 ? 'October' : 'July'],

            color: "#f28f16",
          },


          {
            label: "Deliveries",
            value: data.deliveries[selectedQuarter == 4 ? 'May' : selectedQuarter == 3 ? 'February' : selectedQuarter == 2 ? 'November' : 'August'],

            color: "#f28f16",
          },

          {
            label: "Deliveries",
            value: data.deliveries[selectedQuarter == 4 ? 'June' : selectedQuarter == 3 ? 'March' : selectedQuarter == 2 ? 'December' : 'September'],

            color: "#f28f16",
          },



        ]
      },
      {
        seriesname: "Sale",
        color: "#c63131",
        data: [




          {
            label: "Sale",
            value: data.sales[selectedQuarter == 4 ? 'April' : selectedQuarter == 3 ? 'January' : selectedQuarter == 2 ? 'October' : 'July'],
            color: "#c63131",
          },


          {
            label: "Sale",
            value: data.sales[selectedQuarter == 4 ? 'May' : selectedQuarter == 3 ? 'February' : selectedQuarter == 2 ? 'November' : 'August'],
            color: "#c63131",
          },
          {
            label: "Sale",
            value: data.sales[selectedQuarter == 4 ? 'June' : selectedQuarter == 3 ? 'March' : selectedQuarter == 2 ? 'December' : 'September'],
            color: "#c63131",
          },


        ]
      }
    ]
  };




  return <div style={{ backgroundColor: '#fff', paddingTop: 30 }}>


    <ValidatorForm onSubmit={async () => {
    }}>

      <Grid container  >
        <Grid item xs={6} >

        </Grid>
        <Grid item xs={6} >
          <QuarterSelector onChange={(data) => {
            getData(data.y, data.q);
          }} />
        </Grid>
      </Grid>

    </ValidatorForm>

    <br />
    <p style={{ fontSize: '18px', fontWeight: 500, textAlign: 'center' }} >
      AHV Honey Stocks - deliveries vs sales
    </p>
    <ReactFusioncharts

      type="msline"
      width="100%"
      height="500"
      dataFormat="JSON"

      dataSource={dataSource}
    />
  </div>

}


export default LineChart;