import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Ways, API, Cook, getvalue, currentQuarter } from '../Helpers';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { Grid, MenuItem } from '@material-ui/core';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import moment from 'moment';
import { QuarterSelector } from "./Quarter";
// Resolves charts dependancy
charts(FusionCharts);



// class MainPieChart extends React.Component {

export const PieChart3: FunctionComponent<{ currentData: any }> = props => {

  const [data, setData] = useState<any>([])
  

  const { enqueueSnackbar } = useSnackbar();




  const getData = async (year: number, quarter: number) => {
    var x = await API.get('admin/charts/activeVsNonActive', { year: year, quarter: quarter });
    
    if (x.status == 200) {
      // window.location.reload();
      console.log(x.data)
      setData(x.data)
      

    }
    else {
      enqueueSnackbar('data request failed!')
      setData({})
    }
  }


  useEffect(() => {


    getData(currentQuarter().y, currentQuarter().q)



  }, [])

 


  const dataSource = {
    chart: {
      
      // caption: "AHV Honey Stocks - ratio of Active & Non-Active (%)",
      plottooltext: "<b>$percentValue</b> $label ",
      showlegend: "1",
      showpercentvalues: "1",
      legendposition: "bottom",
      usedataplotcolorforlabels: "1",
      enableMultiSlicing: "0",
      bgColor: "#ffffff",
      bgAlpha: "50",
      borderRadius: "8",
      showBorder: "1",
      borderColor: "#ffffff",
      decimals: "1",
      theme: "fusion",
      // exportEnabled: "1",
      // exportFileName: "export_file1",
    },



    data: [

      {
        label: "Active",
        value: data['active'],
        color: '#f28f16',
      },

      {
        label: "Non-Active",
        value: data['nonActive'],
        color: "#c63131",
      },


    ]
  };





  // render() {
  return <div style={{ backgroundColor: '#fff', paddingTop: 30 }}>

    <ValidatorForm onSubmit={async () => {
    }}>

      <QuarterSelector onChange={(data)=>{
        getData(data.y , data.q)
        console.log(data)
      }}/>

    </ValidatorForm>
    <br />
    <div style={{textAlign:'center'}} >
      <p style={{fontSize:'18px',fontWeight:500}} >
        AHV Honey Stocks - ratio of Active & Non-Active (%)
      </p>
    </div>
    <ReactFusioncharts
      type="pie2d"
      width="100%"
      // height="60%"
      height="40%"
      dataFormat="JSON"
      dataSource={dataSource}
    />
  </div>

}


export default PieChart3;