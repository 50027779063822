import { Button, Chip, Grid, Link, Paper, List, ListItem, Dialog, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Ways, API, Cook } from '../Helpers';
import moment from 'moment';







const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      flexGrow: 1,
      padding: theme.spacing(2),
      overflow: 'auto',

     
      
        
      
  },

  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
     color: '#ffffff',
 

   '&.MuiButton-contained': {
    

    '&:hover': {

    
     backgroundColor: '#FECF51',
     color: '#ffffff',
     boxShadow: 'none',
    },
   },
  },
  
     
        fixedHeightPaperEmail: {
        
          boxShadow: 'none',
          padding: theme.spacing(4),
        },
  
  }));






export const AllOrdersTable = () => {
  const classes = useStyles();
  const [data , setData] = useState<any[]>([])
  const [cp, setCp] = useState(undefined)

  // useEffect(()=>{
  //   API.get('products').then(y=>){
  //     cosole.log(y.data);
  //     setData(y.data);
  //   }
  // })


  useEffect(()=>{
    API.get('allorders' ).then(x=>{
      setData(x.data);
    })    

  
  },[])


  const getdata = () => {
    API.get('allorders', { userid: Cook.getUserData()['user']['id'] }).then(x => {
      setData(x.data);
    });
  }


    let id:number =1
    const getID = () => {
        return id++;
    }
    return (
      <Grid container className={classes.root} spacing={2}>
      <Grid item lg={12} md={12} xs={12}>
          <Paper className={classes.fixedHeightPaperEmail}>
          
      <MaterialTable

     
   
      style={{boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8,}}
        title="All Orders"
        columns={[
          { title: 'Order Id', render: (row) => <div>HON-ORD-{row['rid']}</div>},
          // { title: ' Buyer Name', field: 'user.firstName + user.lastName' },
          { title: 'Buyer Name', render: (row) => <div>{row['user']['firstName']} {row['user']['lastName']}</div>},
          // {
          //   title: 'Image', render: (row) => (
          //     <Grid item lg={2}>
          //       <img src={row['products'][0]['primaryImage']} style={{ height: 35, width: 35, }} />
          //     </Grid>
          //   )
          // },

        // {title: 'Date', render: (row) => { return <p>{row['lastUpdated']}</p>}},
        { title: ' Date', render: (row) => <div>{moment(row['lastUpdated']).format('MM/DD/YY')}</div>},
          // { title: 'Product', render:(row)=>{
          // return <Grid item lg={12}>{row['products'][0]['productTitle']}</Grid>
          // } },
          // { title: 'Size', field: 'weight', type: 'numeric' },

          // { title: 'Size(g)', render:(row)=> {
          //   return<Grid item lg={12}>{row['cartItems'][0]['variation']['weight']}</Grid>
          // }},
          
          // { title: 'Order Value', field: 'totalBill', type: 'numeric' },
           {title: 'Order Value', render: (row) =><div>${ (row['totalBill']).toLocaleString()}</div>},
        
          {title: 'Status', render: (row) =>
        <Grid item lg={2}>
            <Chip label={row['status']} style={{backgroundColor: '#f28f16', color: '#ffffff',}} />
        </Grid>},
          { title: 'Actions', render: (cr) => 

          <List style={{display: 'flex', flexDirection: 'row'}}>
            <ListItem>
            <Link href={Ways.singleOrderPage  + cr['id'] } style={{textDecoration: 'none'}}>
                <Button variant="contained" size="small" className={classes.btnStyle}>View</Button>
                </Link>
            </ListItem>

            <ListItem>
            
                <Button onClick={() => {
                        setCp(cr);

                      }} variant="contained" size="small" style={{backgroundColor: '#ff0000', color: '#fff'}}>Delete</Button>
             
              </ListItem>
          </List>
        

        },
        ]}
        data={data}

      
      
    
      />
     

      </Paper>

      <Dialog open={cp != undefined} onClose={x => setCp(undefined)}>
          <DialogContent style={{padding: 40}}>
            <Typography variant='body2'>Are you sure you want to remove this order from orders ?</Typography>
            <br />
          
              <Button variant="contained" style={{backgroundColor: '#f28f16', color: '#fff', marginRight: '30'}} onClick={() => {
                if (cp != undefined) {
                  var prod = {};
                  try {
                    prod = cp || {};
                  } catch (x) {

                  }
                  API.delete('allorders', { userid: Cook.getUserData()['user']['id'], orderid: prod['rid'] }).then(x => {
                    getdata();
                    setCp(undefined);
                  })
                }
              }} 
              >Delete</Button>
          
              <Button variant="outlined" style={{ backgroundColor: '#f7f7f7', marginLeft: '2%' }} onClick={() => {
                setCp(undefined);
              }}>Cancel</Button>
            
          </DialogContent>
        </Dialog>


      </Grid>
      </Grid>
    )
  }