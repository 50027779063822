import React, { useEffect, useState, FunctionComponent } from 'react';
import { Grid, Button, FormControl, MenuItem, Select, InputLabel, Typography, List, ListItem, CircularProgress, ListItemSecondaryAction, IconButton, ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme, ThemeProvider, useTheme } from '@material-ui/core/styles';
import { PopupHoneyEditableForm } from './../PopupHoneyEditableForm';
import { HoneyFeatures } from './HoneyFeatures'
import { HoneyExpansion } from './../HoneyExpansion';
import { HoneyContributionPopup } from './HoneyContributionPopup';
import { HoneyIbcTable } from './../../Tables/HoneyIbcTable';
import { API, Cook, Ways } from './../../Helpers';
import { HoneyContributionExpansion } from './HoneyContributionExpansion';
import { SingleHoldingDetails } from './SingleHoldingDetails';
import { createBrowserHistory, } from 'history'
import { KeyboardArrowDown, KeyboardBackspace, Translate } from '@material-ui/icons';
import '../../App.css'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    content: {

      maxHeight: 800,
    },

    goBackLink: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      '&:hover': {
        color: '#F28F15',
        cursor: 'pointer',

      },
    },

    widthStyle:{
     width:'81%',
     paddingRight:'6px',
      [theme.breakpoints.down(1800)]:{
        width:'80%'
      },
      [theme.breakpoints.down(1600)]: {
        width: '78%',
        
      },
      [theme.breakpoints.down(1500)]: {
        width: '76.5%',
        
      },
      [theme.breakpoints.down('md')]: {
        width: '73%',
       
       
        
      }

    }
  

  }),



  





);



export const SingleHoneyLayout: FunctionComponent<{ currentHolding: any }> = props => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([])
  const [holding, setHolding] = useState<any>({})

  const wid = useTheme().breakpoints.down('md')


  useEffect(() => {
    API.get('ibcs', { holdingid: window.location.pathname.split('/')[3] }).then(z => {
      setData(z.data);
      console.log(z.data)
      // console.log(wid)

    })
    getholding()

    if (props.currentHolding != null) {
      setData(props.currentHolding)


    }
  }, [])

  const getholding = () => {

    API.get('holding', { holdingid: window.location.pathname.split('/')[3] }).then(z => {
      setHolding(z.data);
      // console.log(z.data)

    });


  }



  let id: number = 1
  const getID = () => {
    return id++;
  }






  return (
    <>

      <Grid container spacing={2} style={{ position: 'relative' }}  >
        <Grid item xs={12} style={{ marginBottom: '0.2%', marginTop: '1.2%' }} >
          <div className={classes.widthStyle}  style={{ position: 'fixed', top: 193, backgroundColor: '#fafafa', zIndex: 20, overflow: 'hidden', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2 style={{ marginLeft: '3px' }} >
              Overview Of Holding Category HON-HOL-{holding['rid']}
            </h2>
            <div

              className={classes.goBackLink}
              onClick={() => {

                createBrowserHistory().push(Ways.honeystockPage);
                window.location.reload()


              }}


            >

              <KeyboardBackspace style={{ marginRight: '10px', }} />

              <p> back to holding categories</p>
            </div>
          </div>
        </Grid>
      </Grid >


      <div style={{ marginTop: '0%', }}>



        {/* <HoneyContributionPopup /> */}

        {holding['honeyType'] != null ? <HoneyContributionPopup currentVareity={holding['honeyType']} currentMethylglyoxal={holding['methylglyoxal']} currentDate={holding['dateOfExtraction']} currentBlossom={holding['flowersInBlossom']} currentSiteLocation={holding['apiarySiteLocation']} currentHolding={holding} currentAnti={holding['antioxidents']} currentOrganic={holding['organicnonorganic']} /> : <div></div>}

        {/* {holding['honeyType'] !=null ?  <HoneyContributionPopup currentVariety={holding['honeyType']} currentOrigin={holding['countryOfOrigin']} currentFloral={holding['floralType']} currentOrganic={holding['organicnonorganic']} currentPackaged={holding['countryOfPackaged']} currentPastuerized={holding['rawPastureized']} currentImported={holding['containsImportedProducts']} currentOriginPro={holding['originofimportedproducts']} currentAntioxidents={holding['antioxidents']} currentId={holding['rid']} /> :<div></div>}
          */}
        <SingleHoldingDetails allBeekeerpers={data} currentHolding={data} totalData={{
          totalPayload: data.map(x => x.payLoad).reduce((a, b) => a + b, 0), totalRemaining: data.map(x => x.remaining != null ? x.remaining : x.payLoad,).reduce((a, b) => a + b, 0), totalPayment: data.map(x => x.initialPayment).reduce((a, b) => a + b, 0),
          totalSold: data.map(x => x.payLoad).reduce((a, b) => a + b, 0) - data.map(x => x.remaining != null ? x.remaining : x.payLoad).reduce((a, b) => a + b, 0), totalContributor: data.length
        }

        }

        />


        {/* <HoneyContributionExpansion /> */}
        <div style={{ marginTop: '20px' }} >
          {holding['honeyType'] != null ? <HoneyContributionPopup currentVareity={holding['honeyType']} currentMethylglyoxal={holding['methylglyoxal']} currentDate={holding['dateOfExtraction']} currentBlossom={holding['flowersInBlossom']} currentSiteLocation={holding['apiarySiteLocation']} currentHolding={holding} currentAnti={holding['antioxidents']} currentOrganic={holding['organicnonorganic']} /> : <div></div>}
        </div>


        <HoneyIbcTable currentHolding={data} />






      </div>
    </>
  )
}