import React, { useState, FunctionComponent, useEffect, useRef } from 'react';
import { Grid, Button, FormControl, MenuItem, Select, InputLabel, Typography, List, ListItem, ListItemSecondaryAction, IconButton, ListItemText, Checkbox } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Cancel, CheckBox } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Add } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Edit } from '@material-ui/icons';
import { API, Ways } from './../../Helpers';
import { useSnackbar } from 'notistack';
import { SetTimer } from './../../Quote/SetTimer';




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: '100%',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },

        content: {

            maxHeight: 800,
        },
    }),
);



export const NewContributionPopup: FunctionComponent<{ onAdded?: () => void, currentIBC?: any, currentVareity?: any, currentMethylglyoxal?: any, currentDate?: any, currentSiteLocation?: any, currentBlossom?: any, currentHolding?: any, currentAnti?: any, currentOrganic?: any, }> = props => {
    const [contribution, setContribution] = useState<any>({});
    const [data, setData] = useState<any[]>([]);
    const [cat, setCat] = useState<any[]>([]);
    const [beekeeper, setBeekeeper] = useState<any[]>([]);
    const [focused, setFocused] = useState(false);
    const [honeyRates, setHoneyRates] = useState<any>([]);
    const [currentPriceForTa, setCurrentPriceForTa] = useState<any>();
    const [focus, setFocus] = useState<boolean>(false)




    const set = (e: React.FormEvent) => {
        var n = e.target['name'];
        var v = e.target['value'];
        contribution[n] = v;
        setContribution({ ...contribution, })

    }


    const getHoneyRates = async () => {
        var res = await API.get("ta")
        setHoneyRates(res.data)
        // console.log(res.data)
    }


    const filterFunction = (res) => {

        if (res == null) {
            return;
        }

        var ourTa = Number(res);

        for (let i = 0; i < honeyRates.length; i++) {
            const e = honeyRates[i];
            var lowerLimit = Number(e['taFrom'])
            var uppowerLimit = Number(e['taTo'])

            if (ourTa >= lowerLimit && ourTa <= uppowerLimit) {
                setContribution({ ...contribution, initialPayment: e['price'] * contribution.payLoad, activity: res })
                setCurrentPriceForTa(e['price'])
                // console.log(e['price'])
                return;
            }

        }
    }

    // const focus = () => {
    //     inputRef.current != undefined && inputRef.current.autoFocus(); 
    // }

    const handleTaInput = (e) => {

        // setContribution({ ...contribution, activity: e.target.value })

        var c = parseInt(e.target.value);
        // console.log(c)
        if (c != NaN && c <= 100 && c > 0) {
            filterFunction(e.target.value);

        } else {
            // console.log("RES#ET")
            setContribution({ ...contribution, activity: c })
            setCurrentPriceForTa(null)

        }

        console.log(focus)

    }


    useEffect(() => {

        if (props.currentIBC != null) {
            setContribution(props.currentIBC)
        } else {
            setContribution({ honeyType: props.currentVareity, methylglyoxal: props.currentMethylglyoxal, apiarySiteLocation: props.currentSiteLocation, dateOfExtraction: props.currentDate, flowersInBlossom: props.currentBlossom, antioxidents: props.currentAnti, organicNonOrganic: props.currentOrganic })

        }


        API.get('holdings').then(x => {
            // console.log(x.data);
            setCat(x.data);
        })


        API.get('beekeepers').then(x => {
            console.log(x.data);
            setBeekeeper(x.data);

        })
        getHoneyRates();

        console.log(contribution)

    }, [])










    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
        // console.log(contribution)
        setContribution({ ...contribution, labReportRequired: false })

    };

    const handleClose = () => {
        setOpen(false);
        setContribution({})
    };

    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();



    return (


        <div style={{ marginTop: '2.2%' }}>


            <div> <Grid item lg={12} xs={12}>


                {/* <Button variant="contained" style={{ backgroundColor: '#f28f16', color: '#fff' }} onClick={handleClickOpen}>
        Honey Contribution
        </Button> */}


                {
                    (props.currentIBC != null)

                        ?

                        //    <IconButton onClick={handleClickOpen}>
                        //    <Edit />
                        //  </IconButton>

                        <Button size="small" style={{ color: '#fff', backgroundColor: '#f28f16', marginTop: 16, marginLeft: 10, marginRight: -20 }} onClick={handleClickOpen} variant="contained">Edit</Button>


                        :

                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#f28f16', color: '#fff', marginLeft: 10 }} onClick={() => {
                                handleClickOpen()
                                setContribution({...contribution,initialPayment:0})
                            }}
                            startIcon={<Add />}

                        >
                            Add new contribution
                        </Button>

                }



                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    maxWidth="lg"
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    style={{ height: 800 }}
                >
                    <ListItem>
                        <ListItemText>
                            <DialogTitle id="form-dialog-title">Add New Contribution</DialogTitle>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton>
                                <Cancel onClick={handleClose} />

                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>




                    <DialogContent className={classes.content}>


                        <ValidatorForm onSubmit={async () => {
                            //  setLoading(true);
                            // console.log(contribution)
                            //  API.post('ibc', contribution);


                            if (props.currentIBC == null) {
                                API.post('ibc', contribution).then(x => {
                                    console.log(x.status)
                                    // console.log(contribution)
                                    if (x.status == 200) {
                                        window.location.reload();
                                        enqueueSnackbar('New IBC is added');

                                    }

                                }
                                )

                                // console.log({...contribution,isreq: contribution['labReportRequired'] == null ? false : contribution['labReportRequired'] })
                                // console.log(contribution)

                            }


                            else {
                                API.put('ibc', contribution, { ibcid: contribution['id'] }).then(x => {
                                    if (x.status == 200) {
                                        window.location.reload();
                                        enqueueSnackbar('IBC is Updated');

                                    }
                                }
                                )
                            }


                        }}>





                            <Grid container direction="row" spacing={2}>




                                <Grid item lg={6}>

                                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>

                                    <SelectValidator name='beekeeper' variant="outlined" size="small" value={beekeeper['beekeeper']} style={{ width: '100%' }}
                                        onChange={set}
                                        label="Select Beekeeper"
                                    // autoFocus
                                    // errorMessages={['This field is required']}
                                    // validators={['required']}
                                    >

                                        {[<MenuItem key={-1} value={0}>None</MenuItem>].concat(beekeeper.filter((x)=> x.approved == true).map((x, i) => <MenuItem key={i} value={x}>{x['fullName']}</MenuItem>))}


                                    </SelectValidator>


                                </Grid>


                                <Grid item lg={6}>



                                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>


                                    <SelectValidator name='stage' variant="outlined" size="small" value={contribution['stage']} style={{ width: '100%' }}
                                        onChange={set}
                                        label="Select Stage"

                                        errorMessages={['This field is required']}
                                        validators={['required']}



                                    >
                                        <MenuItem selected value={'delivery'}>Delivery</MenuItem>
                                        <MenuItem value={'verifiedByLab'}>Verified by lab</MenuItem>

                                    </SelectValidator>

                                </Grid>

                                {/* <Grid item lg={4}>
<TextValidator
  variant="outlined"
 
  size="small"
  fullWidth
  value={contribution['quantity']}
  onChange={set}
  label="Quantity"
  name="quantity"
  errorMessages={['this field is required', 'Job Title is in an invalid format']}
>
</TextValidator>
</Grid> */}


                                <Grid item lg={12}>
                                    <Typography variant="subtitle2">Honey Chractristics:</Typography>
                                </Grid>

                                {/* <Grid item lg={4}>

               

                    <SelectValidator id='floralType' name='floralType' value={contribution['floralType']} variant="outlined" size="small" style={{ width: '100%' }}
                      onChange={set}
                      label="Mono Floral/Multi Floral"
                      disabled
                    
                      errorMessages={['this field is required']}

                    >
                     
                      <MenuItem value={'Mono-Floral'}>Mono Floral</MenuItem>
                      <MenuItem value={'Multi-Floral'}>Multi Floral</MenuItem>



                    </SelectValidator>
             
                </Grid> */}
                                {/* <Grid item lg={4}>
                                    <TextValidator
                                        name={'dominentVariety'}
                                        margin="dense"
                                        value={contribution['dominentVariety']}
                                        id="amount"
                                        label="Dominent Variety"
                                        onChange={set}
                                        errorMessages={['This field is required']}
                                        validators={['required']}
                                        type="text"

                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid> */}

                                <Grid item lg={4}>
                                    <SelectValidator id='dominentVariety' name='honeyType' value={contribution['honeyType']} variant="outlined" margin="dense" size="small" style={{ width: '100%' }}
                                        onChange={set}
                                        label="Select Dominent Variety"
                                        errorMessages={['This field is required']}
                                        validators={['required']}

                                    >
                                        <MenuItem value={'Blackbutt'}>Blackbutt</MenuItem>
                                        <MenuItem value={'Red Gum'}>Red Gum</MenuItem>
                                        <MenuItem value={'Jarrah'}>Jarrah</MenuItem>
                                        <MenuItem value={'Red Bell'}>Red Bell </MenuItem>
                                        <MenuItem value={'Wildflower'}>Wildflower</MenuItem>
                                        <MenuItem value={'Whitegum'}>Whitegum</MenuItem>
                                        <MenuItem value={'Banksia'}>Banksia</MenuItem>
                                    </SelectValidator>
                                </Grid>
                                <Grid item lg={4}>
                                    <SelectValidator id='secondaryVariety' name='secondaryVariety' value={contribution['secondaryVariety']} variant="outlined" margin="dense" size="small" style={{ width: '100%' }}
                                        onChange={set}
                                        label="Select Second Variety"
                                        errorMessages={['This field is required']}
                                        validators={['required']}

                                    >
                                        <MenuItem value={'Blackbutt'}>Blackbutt</MenuItem>
                                        <MenuItem value={'Red Gum'}>Red Gum</MenuItem>
                                        <MenuItem value={'Jarrah'}>Jarrah</MenuItem>
                                        <MenuItem value={'Red Bell'}>Red Bell </MenuItem>
                                        <MenuItem value={'Wildflower'}>Wildflower</MenuItem>
                                        <MenuItem value={'Whitegum'}>Whitegum</MenuItem>
                                        <MenuItem value={'Banksia'}>Banksia</MenuItem>
                                    </SelectValidator>
                                </Grid>

                                <Grid item lg={4}>
                                    <SelectValidator id='Organic' name='organicNonOrganic' value={contribution['organicNonOrganic']} variant="outlined" margin="dense" size="small" style={{ width: '100%' }}
                                        onChange={set}
                                        label="Organic/Non Organic"
                                        errorMessages={['This field is required']}
                                        validators={['required']}

                                    >
                                        <MenuItem value={'organic'}>organic</MenuItem>
                                        <MenuItem value={'non-organic'}>non-organic</MenuItem>
                                    </SelectValidator>
                                </Grid>


                                {/* 
                <Grid item lg={4}>

           
                    <TextValidator


                      variant="outlined"

                      required
                      size="small"
                      fullWidth
                      value={contribution['countryOfOrigin']}
                      onChange={set}
                      disabled
                      label="Country Of Origin"
                      name="countryOfOrigin"
                  
                      errorMessages={['this field is required', 'Job Title is in an invalid format']}
                    >

                    </TextValidator>
           
                </Grid> */}

                                {/* 
                <Grid item lg={4}>

                    <TextValidator


                      variant="outlined"

               
                      required
                      size="small"
                      fullWidth
                      value={contribution['countryOfPackaged']}
                      onChange={set}
                      label="Country Of Packaged"
                      name="countryOfPackaged"
                      disabled
                 
                      errorMessages={['this field is required', 'Job Title is in an invalid format']}
                    >

                    </TextValidator>
              
                </Grid> */}



                                {/* <Grid item lg={4}>


                    <SelectValidator id='floralType' name='containsImportedProducts' value={contribution['containsImportedProducts']} variant="outlined" size="small" style={{ width: '100%' }}
                      onChange={set}
                      label="Contains Imported Products"
                      disabled
                      errorMessages={['this field is required']}


                    >
                     <MenuItem value={'true'}>Yes</MenuItem>
                      <MenuItem value={'false'}>No</MenuItem>



                    </SelectValidator>
          
                </Grid> */}


                                {/* <Grid item lg={4}>

            
                    <TextValidator
                      name={'originOfImportedProducts'}
                      margin="dense"
                      value={contribution['originOfImportedProducts']}
                      id="amount"
                      label="Origin Of Imported Products"
                      disabled
                      onChange={set}
                 
                      errorMessages={['This field is required']}
                      type="amount"
                      fullWidth
                      variant="outlined"
                     
                    />


            
                </Grid> */}










                                <Grid item lg={4}>
                                    <TextValidator
                                        name={'apiarySiteLocation'}
                                        margin="dense"
                                        value={contribution['apiarySiteLocation']}
                                        id="amount"
                                        label="Apiary Site Location 1"
                                        onChange={set}
                                        errorMessages={['This field is required']}
                                        validators={['required']}
                                        type="text"

                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={4}>
                                    <TextValidator
                                        name={'apiarySiteLocation2'}
                                        margin="dense"
                                        value={contribution['apiarySiteLocation2']}
                                        id="amount"
                                        label="Apiary Site Location 2"
                                        onChange={set}
                                        errorMessages={['This field is required']}
                                        validators={['required']}
                                        type="text"

                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={4}>
                                    <TextValidator
                                        name={'flowersInBlossom'}
                                        margin="dense"
                                        value={contribution['flowersInBlossom']}
                                        id="amount"
                                        label="Flowers In Blossom"
                                        onChange={set}
                                        errorMessages={['This field is required']}
                                        validators={['required']}
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>


                                <Grid item lg={4}>
                                    {
                                        focused ?
                                            <ListItem style={{ border: '1px solid #d1cfcf', borderRadius: 5, paddingTop: 0, marginTop: 8, paddingLeft: 10 }}>
                                                <SetTimer onChange={(x) => {
                                                    var nobj = { ...contribution, dateOfExtraction: new Date(x) }
                                                    setContribution(nobj);
                                                    // console.log(nobj)
                                                    // console.log(x.offer)
                                                }} />
                                            </ListItem>
                                            :
                                            <TextValidator
                                                name={''}
                                                margin="dense"
                                                value={contribution['']}
                                                id="amount"
                                                label="Date Of Extraction"
                                                onFocus={() => setFocused(true)}
                                                onChange={set}
                                                errorMessages={['This field is required']}
                                                validators={['required']}
                                                type="amount"
                                                fullWidth
                                                variant="outlined"
                                            />

                                    }

                                </Grid>







                                <Grid item lg={4}>




                                    <TextValidator
                                        name={'payLoad'}
                                        margin="dense"
                                        value={contribution['payLoad']}
                                        id="amount"
                                        label="Pay Load (kg)"
                                        onChange={set}
                                        errorMessages={['This field is required',]}
                                        // validators={['required', 'isNumber', 'maxNumber:' + contribution['maxPrice'] ?? 1400]}
                                        validators={['required', ]}
                                        type="number"
                                        fullWidth
                                        variant="outlined"

                                    />

                                </Grid>

                                {/* <Grid item lg={4}>
               
                                <TextValidator
                                    name={'totalSold'}
                                    margin="dense"
                                    value={contribution['totalSold']}
                                    id="amount"
                                    label="Total Sold"
                                     onChange={set}
                                    errorMessages={['This field is required']}
                                    type="amount"
                                    fullWidth
                                    variant="outlined"
                                    />
                                </Grid> */}

                                {/* <Grid item lg={4}>
                                    <TextValidator
                                        name={'initialPayment'}
                                        margin="dense"
                                        value={contribution['initialPayment']}
                                        id="amount"
                                        label="Delivery Payment ($)"
                                        onChange={set}
                                        errorMessages={['This field is required', 'initial payment must be a number']}
                                        validators={['required', 'isNumber']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                        onFocus={()=> setFocus(true)}
                                        
                                    />
                                    {
                                        currentPriceForTa != null && <>
                                            <p>Payload x Price for Current Total Activity</p>
                                            <p>{contribution['payLoad']} x {currentPriceForTa}</p></>
                                    }
                                </Grid> */}
                                <Grid item lg={4}>
                                    <TextValidator
                                        name={'sampleRef'}
                                        margin="dense"
                                        value={contribution['sampleRef']}
                                        id="amount"
                                        label="Sample Ref #"
                                        onChange={set}
                                        errorMessages={['This field is required',]}
                                        validators={['required',]}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"

                                    />

                                </Grid>

                                {/* __________--/ Confermation of Lab Report \--___________ */}

                                <Grid item lg={4}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} >
                                        <p style={{ fontSize: '17px', color: 'gray' }} >
                                            Require Lab Report :
                                        </p>
                                        <Checkbox aria-checked={contribution['labReportRequired']} onChange={(x) => {
                                            setContribution({ ...contribution, labReportRequired: x.target.checked })

                                        }}></Checkbox>

                                    </div>
                                    {/* <p>{JSON.stringify(contribution)}</p>
                                <SelectValidator id='labReportRequired' name={'labReportRequired'} value={contribution['labReportRequired'] == true? 1:2} variant="outlined" margin="dense" size="small" style={{ width: '100%' }}
                                        onChange={(x)=> {
                                            console.log(x.target)
                                            setContribution({labReportRequired : x.target['value'] == 1 ? true : false , ...contribution})
                                        }}
                                        label="Is Lab Report Require"
                                        errorMessages={['This field is required']}
                                        validators={['required']}
                                        type=''
                                       

                                    >
                                        <MenuItem  value={1}>Yes</MenuItem>
                                        <MenuItem value={2} >No</MenuItem>
                                    </SelectValidator> */}

                                </Grid>


                                {/* <Grid item lg={4}>
                                    <TextValidator
                                    name={'bonusPayment'}
                                    margin="dense"
                                    value={contribution['bonusPayment']}
                                    id="amount"
                                    label="Bonus Payment"
                                    onChange={set}
                                    errorMessages={['This field is required', 'initial payment must be a number']}
                                    validators={['required', 'isNumber']}
                                    type="amount"
                                    fullWidth
                                    variant="outlined"

                                    />

                                </Grid> */}










                                {/* <Grid item lg={3}>
                                        {
                                                focused ?
                                                <ListItem style={{border: '1px solid #d1cfcf', borderRadius: 5, paddingTop: 0, marginTop: 8, paddingLeft: 10}}>
                                                <SetTimer  onChange={(x) => {
                                                var nobj = { ...contribution, dateOfExtraction: new Date(x) }
                                                setContribution(nobj);
                                                console.log(nobj)
                                                // console.log(x.offer)
                                        }} />
                                        </ListItem>
                                                :
                                                <TextValidator
                                                name={''}
                                                margin="dense"
                                                value={contribution['']}
                                                id="amount"
                                                label="Date Of Extraction" 
                                                onFocus={() => setFocused(true)}
                                                onChange={set}
                                                errorMessages={['This field is required']}
                                                type="amount"
                                                fullWidth
                                                variant="outlined"
                                            />

                                        }


                                            


                                </Grid> */}


                            </Grid>


                            <Grid container direction="row" spacing={6} style={{ backgroundColor: '#fbfbfb', marginTop: '3%' }}>





                                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Total Activity:</Typography>
                                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>


                                        <ListItem>



                                            <TextValidator
                                                name={'activity'}
                                                margin="dense"
                                                value={contribution['activity']}
                                                id="amount"
                                                label=""
                                                onChange={(e) => {
                                                    handleTaInput(e)
                                                }}
                                                // validators={['required']}
                                                // errorMessages={['This field is required', 'initial payment must be a number']}
                                                // validators={['required', 'isNumber']}
                                                type="number"
                                                fullWidth
                                                variant="outlined"

                                            />
                                        </ListItem>


                                    </List>
                                </Grid>




                                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">MGO:</Typography>
                                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>


                                        <ListItem>
                                            <TextValidator
                                                name={'mgo'}
                                                margin="dense"
                                                value={contribution['mgo']}
                                                id="amount"
                                                label=""
                                                onChange={set}

                                                // errorMessages={['This field is required', 'initial payment must be a number']}
                                                // validators={['required', 'isNumber']}
                                                type="number"
                                                fullWidth
                                                variant="outlined"

                                            />
                                        </ListItem>



                                    </List>
                                </Grid>



                                {/* <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Percentage Of Imported Products:</Typography>
                                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>


                                        <ListItem>
                                            <TextValidator
                                                name={'productsPercentage'}
                                                margin="dense"
                                                value={contribution['productsPercentage']}
                                                id="amount"
                                                label=""
                                                onChange={set}
                                                // validators={['required']}
                                                errorMessages={['This field is required', 'initial payment must be a number']}
                                                validators={['required', 'isNumber']}
                                                type="amount"
                                                fullWidth
                                                variant="outlined"

                                            />
                                        </ListItem>




                                    </List>
                                </Grid> */}



                                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Moisture:</Typography>
                                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>


                                        <ListItem>
                                            <TextValidator
                                                name={'moisture'}
                                                margin="dense"
                                                value={contribution['moisture']}
                                                id="amount"
                                                label=""
                                                onChange={set}
                                                // errorMessages={['This field is required', 'initial payment must be a number']}
                                                // validators={['required', 'isNumber']}
                                                type="number"
                                                fullWidth
                                                variant="outlined"

                                            />
                                        </ListItem>



                                    </List>
                                </Grid>


                                {/* 
                                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Energy/Carbs:</Typography>
                                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>

                                        <ListItem>
                                            <TextValidator
                                                name={'energyCarbs'}
                                                margin="dense"
                                                value={contribution['energyCarbs']}
                                                id="amount"
                                                label=""
                                                onChange={set}
                                                errorMessages={['This field is required', 'initial payment must be a number']}
                                                validators={['required', 'isNumber']}
                                                type="amount"
                                                fullWidth
                                                variant="outlined"

                                            />
                                        </ListItem>



                                    </List>
                                </Grid> */}


                                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Glucose Index GI:</Typography>
                                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>


                                        <ListItem>
                                            <TextValidator
                                                name={'glucoseIndex'}
                                                margin="dense"
                                                value={contribution['glucoseIndex']}
                                                id="amount"
                                                label=""
                                                onChange={set}
                                                // errorMessages={['This field is required', 'initial payment must be a number']}
                                                // validators={['required', 'isNumber']}
                                                type="number"
                                                fullWidth
                                                variant="outlined"

                                            />
                                        </ListItem>




                                    </List>
                                </Grid>


                                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">HMF:</Typography>
                                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>

                                        <ListItem>
                                            <TextValidator
                                                name={'hmf'}
                                                margin="dense"
                                                value={contribution['hmf']}
                                                id="amount"
                                                label=""
                                                onChange={set}
                                                // errorMessages={['This field is required', 'initial payment must be a number']}
                                                // validators={['required', 'isNumber']}
                                                type="number"
                                                fullWidth
                                                variant="outlined"

                                            />
                                        </ListItem>




                                    </List>
                                </Grid>

                                

                                <Grid item lg={4}></Grid>


                            </Grid>

                            <Grid container spacing={2} >

                                <Grid item  xs={12} lg={4} style={{ marginTop: '4%' }}>
                                    <TextValidator
                                        name={'initialPayment'}
                                        margin="dense"
                                        value={contribution['initialPayment']}
                                        id="amount"
                                        label="Delivery Payment ($)"
                                        onChange={set}
                                        errorMessages={['This field is required', 'initial payment must be a number']}
                                        validators={['required', 'isNumber']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                        

                                    />
                                    {
                                        currentPriceForTa != null && <>
                                            <p>Payload x Price for Current Total Activity</p>
                                            <p>{contribution['payLoad']} x {currentPriceForTa}</p></>
                                    }
                                </Grid>

                            </Grid>


                            <Grid item lg={3} >
                                {
                                    (props.currentIBC == null)
                                        ?


                                        <Button type='submit' fullWidth variant="contained" size="large" style={{ backgroundColor: '#f28f16', color: '#fff', marginTop: 75, marginBottom: 15, paddingLeft: 20, paddingRight: 20, }} >
                                            Add
                                        </Button>

                                        :

                                        <Button type='submit' fullWidth variant="contained" size="large" style={{ backgroundColor: '#f28f16', color: '#fff', marginTop: 75, marginBottom: 15, paddingLeft: 20, paddingRight: 20, }} >
                                            Update
                                        </Button>
                                }
                            </Grid>

                            <Grid item lg={9}></Grid>


                        </ValidatorForm>
                    </DialogContent>




                    <DialogActions>

                        <Grid item lg={3} xs={12}>





                        </Grid>

                        <Grid item lg={8}>

                        </Grid>

                        <Grid item lg={1}>

                        </Grid>


                    </DialogActions>

                </Dialog>




            </Grid>



                <br />
            </div>

        </div>
    )
}
