import { Button, Chip, Grid, Link, Paper, List, ListItem, Dialog, DialogContent, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz } from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Ways, API, Cook, getvalue } from '../Helpers';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { GlobalState } from '../AppState';
import moment from 'moment';







const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(0),
    overflow: 'auto',





  },

  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },


  fixedHeightPaperEmail: {

    boxShadow: 'none',
    // padding: theme.spacing(4),
  },

}));






export const AllBeekeepers = () => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([])
  const [cp, setCp] = useState(undefined)
  const [ctd, setCTD] = useState()


  // Menu in table for profile and honeydetails

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  var sort = {}


  const open = Boolean(anchorEl);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };



  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // menu ends here 





  const { enqueueSnackbar } = useSnackbar();



  useEffect(() => {

    gd();





  }, [])


  var gd = async () => {
    await API.get('beekeepers', {
    }).then(x => {
      setData(x.data);
    });
  }


  let id: number = 1
  const getID = () => {
    return id++;
  }


  var keys = ['rid', 'fullName', 'bussinessTitle', 'numberOfHives', 'approved']
  return (
    <div>
      <Grid container className={classes.root} spacing={0}>
        <Grid item lg={12} md={12} xs={12}>
          <div className={classes.fixedHeightPaperEmail}>
            <Paper style={{ boxShadow: 'none', padding: '10px' }} className="beekeepertable" >

              <MaterialTable





                style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
                title="Beekeepers"

                columns={[
                  {
                    title: <p style={{ width: '95px', fontSize: '14px' }} >Beekeeper ID</p>, field: 'rid', render: (row) => <div>HON-BEE-{row['rid']}</div>
                  },
                  {
                    title: <p style={{ width: '70px', fontSize: '14px' }} >Name</p>, field: 'fullName', render: (row) => <div>{row['fullName']}</div>
                  },
                  {
                    title: <p style={{ width: '100px', fontSize: '14px' }} >Business Name</p>, field: 'bussinessTitle', render: (row) => <div>{row['bussinessTitle']}</div>
                  },
                  {
                    title: <p style={{ width: '90px', fontSize: '14px' }} >No. Of Hives</p>, field: 'numberOfHives', render: (row) => <div>{(row['numberOfHives'] ?? 0).toLocaleString()}</div>
                  },
                  {
                    title: <p style={{ width: '80px', fontSize: '14px' }} >Status</p>, sorting: false, render: (row) => <div>{row['approved'] == true ? <Chip label='Approved' style={{ backgroundColor: '#56A33B', color: '#ffffff', }} /> : <Chip label='Pending' style={{ backgroundColor: '#fbfbfb', color: '#303030', padding: '0px' }} />}</div>
                  },
                  // { title: ' Email', render: (row) => <div>{row['email']}</div>},
                  // { title: ' Phone', render: (row) => <div>{row['phone']}</div>},
                  // { title: ' Country', render: (row) => <div>{row['city']}</div>},

                  {
                    title: <p style={{ textAlign: 'start', width: '110px', fontSize: '14px' }} >Actions</p>,
                    sorting: false,
                    render: (row) =>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>


                        <Button onClick={() => {
                          createBrowserHistory().push(Ways.beekeeperprofilePage + '/' + row['id'])
                          window.location.reload();
                        }} variant="contained" size="small"
                          style={{ marginRight: '10px', backgroundColor: '#56A33B', color: 'white' }} >Profile</Button>

                        <Button onClick={() => {
                          createBrowserHistory().push(Ways.beekeeperhoneyPage + '/' + row['id'])
                          window.location.reload();
                        }} variant="contained" size="small"
                          style={{ backgroundColor: '#f28f16', color: '#fff', marginRight: '10px' }}>Honey </Button>



                        {!row['approved']
                          ?
                          <Button variant="contained" size="small"
                            style={{ backgroundColor: '#f28f16', boxShadow: 'none', color: '#ffffff', }}
                            onClick={async () => {
                              var x = await API.post('admin/approveBeekeeper', { beekeeperid: row['id'] });
                              if (x.status == 200) {
                                enqueueSnackbar(x.data)
                                var ind = data.indexOf(row);
                                var e = row as {};
                                e['approved'] = true;
                                data[ind] = e;
                                setData([...data])
                              }
                            }}
                          >
                            Approve
                          </Button>
                          :

                          <Button size="small" variant="contained" disabled>Approved</Button>

                        }





                      </div>



                  },
                ]}
                data={data}






              />


            </Paper>
          </div>




          {/* <Dialog open={cp != undefined} onClose={() => setCp(undefined)}>
          <DialogContent>
            <Typography variant='body2'>Are you sure you want to delete this Beekeeper ?</Typography>
            <br />
            <List>

              <Button style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }} 
              onClick={() => {
                API.delete('beekeeper', { beekeeperid: getvalue(cp, 'id') }).then(x => {
                  if (x.status == 200) {
                    // getdata();
                    enqueueSnackbar('Beekeeper deleted');
                    API.get('beekeepers' ).then(x=>{
                     
                      setData(x.data);
                    })   
                  } else {
                    enqueueSnackbar('Could noe deleted')
                  }
                    setCp(undefined);
                  }
                )}
              
              }>Delete</Button>
              <Button style={{ backgroundColor: '#f7f7f7' }} onClick={() => {
                setCp(undefined);
              }}>Cancel</Button>
            </List>
          </DialogContent>
        </Dialog> */}






        </Grid>
        
      </Grid>
      <div style={{ marginTop: '17.5%' }} >

      </div>
    </div>
  )
}