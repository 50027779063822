import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import { ListItem, List, Typography, Grid, Paper, ListItemText, CircularProgress, ListItemSecondaryAction, Card, CardActionArea, CardMedia, CardContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Ways, API, Cook, getvalue } from './../Helpers';





const useStyles = makeStyles((theme) => ({
    
    
    Paper: {
        boxShadow: 'none',
        padding: 25,
        
    },
    
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            
            
    
         
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
            },
        },
    
        headingListStyle: {
    
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            
    
         
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
            },
    
    
        },
    
    
     listheading: {
    
    fontSize: 16, 
    fontWeight: 400,
     },
    
     leftItem: {
    
        backgroundColor: '#f28f14',
        color: '#fff',
        // borderBottom: '1px solid #fff',
     },
    
     rightItem: {
    
        border: '1px solid #efefef',
     },


     actionArea: {
        
        '&.MuiCardActionArea-root': {
  
          textAlign: 'center',
  
  
        },
  
      },

      media: {


      },

      mainHeading: {
        
        color: '#fff',
        fontSize: '1rem',
        fontWeight: 400,
    
  
    },
  
  
    descriptionStyle: {
  
       
        fontSize: '1rem',
        fontWeight: 500,
        color: '#fff',
  
    },

      Paper3: {
        backgroundColor: '#fff',
        height: '25vh',
        boxShadow: 'none',
        
       
        [theme.breakpoints.down('sm')]: {
          height: 200,
        },
  
      },

      card: {
        maxWidth: 400,
      
        boxShadow: 'none',
        backgroundColor: '#f28f16',
        paddingTop: 10,
        paddingBottom: 10,
        pointerEvents: 'none',

       
  
        
      },



    
    }));




export const BeekeeperHoneyDetails = () => {
    const classes = useStyles();



  const [data , setData] = useState<any[]>([])
  const [loading,setLoadin] = useState(false);



  useEffect(() => {
    API.get('beekeeper/myibcs', {beekeeper: window.location.pathname.split('/')[3]}).then(x => {
        setData(x.data);
    })


}, [])


    let id:number =1
    const getID = () => {
        return id++;
    }

 
  return (
    <div>
    
     

          <Grid container direction="row">
   

   <Grid item lg={12} xs={12}>
   <Paper className={classes.Paper} >


   <ListItem>
             <ListItemText>
            <Typography variant="h6">Details IBC For Beekeeper</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
                {/* <HoneyContributionPopup /> */}
            </ListItemSecondaryAction>
  
          </ListItem>

   <List className={classes.listStyle}>
       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Mono Floral or Multi Floral

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
      
         {/* {data['honeyType']} */}
         {data.length > 0 ? data['honeyType'] : 'nothing'}t

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Organic or Non-Organic

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {data['organicNonOrganic']}

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
           Energy or Carbs

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
{/* {e['energycarbsfrom']{e['energycarbsto']} cal per 15ml */}

{/* {e['energycarbsfrom']} -- {e['energycarbsto']} cal per 15ml */}
{data['energyCarbs']} cal per 15ml

           </Typography>

       </ListItem>
       
   </List>

   <List style={{marginTop: '-1.3%'}} className={classes.listStyle}>
       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Variety

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {data['honeyType']}

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Country of Origin

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {data['countryOfOrigin']}

           </Typography>

       </ListItem>

       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Antioxidants

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {data['antioxidents']}

           </Typography>

       </ListItem>


   

    
       
   </List>




   <List style={{marginTop: '-1.2%'}} className={classes.listStyle}>
       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Purity

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {/* {e['purityfrom']} -- {e['purityto']} % */}
           {data['purity']} %

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Country Packaged

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
      {data['countryOfPackaged']}

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
              Glucose Index GI

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {/* {e['glucoseindexfrom']} -- {e['glucoseindexto']} % */}
           {data['glucoseIndex']} %

           </Typography>

       </ListItem>
       
   </List>





   <List style={{marginTop: '-1.3%'}} className={classes.listStyle}>
       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Raw or Pasterrised

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
               {data['rawPastureized']}

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Contains Imported Products

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {data['containsImportedProducts'] == true ? 'Yes' : 'No'}

           </Typography>

       </ListItem>

       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
              HMF

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {/* {e['hmffrom']} -- {e['hmfto']} */}
           {data['hmf']}

           </Typography>

       </ListItem>
       
   </List>




   <List style={{marginTop: '-1.3%'}} className={classes.listStyle}>
       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
           Total Activity

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {/* {e['activityfrom']} -- {e['activityto']} */}
           {data['activity']} %

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Percentage of Imported Products

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {/* {e['productspercentagefrom']} -- {e['productspercentageto']} % */}
           {data['productsPercentage']} %

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
              
               Moisture
           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {/* {e['moisturefrom']} -- {e['moistureto']} cal per 15ml */}
           {data['moisture']} cal per 15ml

           </Typography>

       </ListItem>
       
   </List>





   <List style={{marginTop: '-1.2%'}} className={classes.listStyle}>
       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               MGO

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {/* {e['mgofrom']} -- {e['mgoto']} cal per 15ml */}
           {data['mgo']} cal per 15ml

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1">
               Origin of Imported Products

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2">
           {data['countryOfOrigin']}

           </Typography>

       </ListItem>


       <ListItem className={classes.leftItem}>
           <Typography className={classes.listheading} variant="subtitle1" align="center">
              ---

           </Typography>

       </ListItem>

       <ListItem className={classes.rightItem}>
           <Typography variant="body2" align="center">
              ---

           </Typography>

       </ListItem>
       
   </List>

   </Paper>



       
       </Grid>
</Grid>




        
      
    </div>
  );
}