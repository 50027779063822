import { autorun, observable } from 'mobx';

export enum NotificationStatus {
      seen, new
  }
  
  export class Notification {
      status?: NotificationStatus;
  
      text?: string;
  
      header?: string;
  }


export class AppState {
      @observable
      userName: String = 'name';

        @observable
    unreadMessages: any[] = [];


      @observable
      currentChat?: any;

      @observable
      messages: any[] = [];

      @observable
      quotes: any[] = [];

      @observable
      unread: any[] = [];

      @observable
      notifications : any[] = [];

      @observable
      currentOffer?: any;

      @observable
      unreadQuotes: any[] = [];

      @observable
      currentOrder?: any ={};
}


autorun(() => {
      console.log(GlobalState);
});

export const GlobalState = new AppState();
window['store'] = GlobalState;



