import React, { FunctionComponent, useEffect, useState } from "react";
import socketIOClient, { } from "socket.io-client";
import { API } from "../Helpers";

let socket: SocketIOClient.Socket;

export const SocketTest: FunctionComponent = () => {
    const [response, setResponse] = useState("");
    const [text, setText] = useState('');
    const [messages, setMessages] = useState<any[]>([])


    useEffect(() => {
        socket = socketIOClient(API.network);
        socket.removeAllListeners();
        socket.on("fromServer", data => {
            setResponse(data);
            // console.log(data)
        });
    }, [])
    return <div>
        <h1>Socket Test</h1>
        <p>
            It's <time dateTime={response} />
        </p>

        <input value={text} type='text' onChange={x => setText(x.target['value'])} onKeyDown={x => {
            if (x.key == 'Enter') {
                socket.emit('fromAdmin', text);
                setText('');
            }
        }}></input>
        {messages.map((x, i) => <div>{x}</div>)}
    </div>
}