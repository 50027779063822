import React, { FunctionComponent, useEffect, useState } from 'react';
import { Hidden, Box, Link, Breadcrumbs, Container, Typography, Grid, List, ListItem, Divider, Card, CardMedia, MenuItem, Tooltip, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import productthumn from './../assets/productthumn.jpg';
import { API, AppEvent, Bbc, Cook, LoginHelper } from "./../Helpers";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
// import {Loader} from './../Components/LoaderButton';
import question from './../assets/question.png';
import { cnfMenuList, packagingMenuList, shippingStrategyMenuList, timingMenuList } from './../Constants';



const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#666666',
        //   color: 'rgba(0, 0, 0, 0.87)',
        color: '#fff',
        maxWidth: 360,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);



const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {

            // paddingTop: '2%',
            // paddingBottom: '5%',
            backgroundColor: '#fbfbfb',

        },
        productBtn: {
            color: '#ffffff',
            backgroundColor: '#f28f16',
            border: '1px solid #f28f16',
            boxShadow: 'none',
            borderRadius: 4,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
            fontFamily: 'sophia',
            width: '100%',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#FCB040',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },

        },

        productBtnoutlined: {

            backgroundColor: 'transparent',
            border: '1px solid #f28f16',
            marginLeft: '19%',
            color: '#f28f16',
            boxShadow: 'none',
            borderRadius: 4,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
            fontFamily: 'sophia',
            width: '100%',
            '&.MuiButton-outlined': {
                '&:hover': {
                    backgroundColor: '#F28F16',
                    color: '#fff',
                    boxShadow: 'none',
                },
            },


            [theme.breakpoints.down('sm')]: {
                marginLeft: '0%',
            },
        },

        media: {
            maxWith: 95,
            maxHeight: 95,
            marginTop: '5%',
        },

        heading: {

        },




        breadcrumbBox: {
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '1%',
            paddingBottom: '1%',
            backgroundColor: '#efeded',
        },


        tooltipItem: {
            marginLeft: '-2%',
            // marginRight: '-%',
            minWidth: 340,
            [theme.breakpoints.down('sm')]: {
                minWidth: 250,
            },

        },




    }),
);




export const QuoteForm: FunctionComponent<{ quote: any }> = props => {
    const classes = useStyles();
    const [product, SetProduct] = useState({})
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('editing');
    const [offer, setOffer] = useState(props.quote);

    useEffect(() => {
        Bbc.subscribe(AppEvent.loggedIn, async (data) => {
            setOffer({ ...offer, address: (Cook.getUserData() || {})['user']['address'] ?? {} })
        });
        if (Cook.getUserData() != null) {
            var address = (Cook.getUserData() || {})['user']['address'] ?? {};
            delete address['id']
            setOffer({ ...offer, ...address })
        }
    }, [])

    const set = (e: React.FormEvent) => {
        var n = e.target['name'];
        var v = e.target['value'];
        offer[n] = v;
        setOffer({ ...offer })
        console.log(offer);
    }

    return (
        <div>






            <ValidatorForm onSubmit={async () => {
                setLoading(true);
                // try {
                //     var req = await API.post('offer', {
                //         ...offer,
                //         product: product,
                //         user: Cook.getUserData()['user']
                //     }).then(async x => {
                //         console.log(x.data);
                //         if (offer['saveAddress'] && offer['saveAddress'] == true) {
                //             await LoginHelper.updateUserData();
                //         }
                //         setOffer({});
                //         setStatus('done');
                //     })
                // } catch (x) {
                //     setStatus('error')
                // }
            }}>


                <div className={classes.root}>






                    <Grid container style={{ paddingTop: '3%', paddingBottom: '3%', paddingLeft: '3%', paddingRight: '3%', marginTop: '4%', backgroundColor: '#fff' }}>
                        {/* <Grid item lg={12}>
               
               <Typography variant="h5" className={classes.mainheading}>Submit Your Quote Request</Typography>
          
       </Grid> */}



                        {/* <Grid item lg={12} xs={12}>
                        
                            <Typography variant="h6" className={classes.heading}>How Many Do I Want ?</Typography>
                        
                        </Grid> */}

                        {/* <Grid style={{paddingRight: '0.5%'}} item lg={8} xs={12}>
                            <br/>
                            <TextValidator
                                variant="outlined"
                                fullWidth
                                value={offer['items']}
                                validators={['required']}
                                errorMessages={['Quantity is required']}
                                label="Quantity"
                                onChange={set}
                                name={'items'}
                            />
                             
                        </Grid> */}

                        {/* <Grid item lg={4} xs={12}>
                            <br/>
                            <SelectValidator
                                variant="outlined"
                                fullWidth
                                value={offer['items']}
                                validators={['required']}
                                errorMessages={['Quantity is required']}
                                // label="Per Carton/Per Pallete"
                                onChange={set}
                                defaultValue={5}
                                name={'carton/palette'}
                                shrink={false}
                                
                            >
                               
                               
                                <MenuItem selected value={5}>Carton</MenuItem>
                                <MenuItem value={6}>Pallet</MenuItem>
                              
                            </SelectValidator>
                        </Grid> */}





                        <Grid item lg={6} xs={12}>

                            <List style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <ListItem className={classes.tooltipItem}><Typography variant="h6" className={classes.heading}>Buyer's Shipping Preference?</Typography></ListItem>
                                {/* <ListItem >
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>

                                                <Typography variant="caption">FOB - AHV arranges and pays for delivery to nearest port and I will handle the rest </Typography><br />
                                                <Typography variant="caption">CIF - AHV arranges and pays for delivery to the my country’s port and I will handle the rest  </Typography><br />
                                                <Typography variant="caption">DDP - AHV arranges and pays for the whole delivery direct to my warehouse  </Typography><br />


                                            </React.Fragment>
                                        }
                                    >
                                        <img src={question} />
                                    </HtmlTooltip>
                                </ListItem> */}
                            </List>


                        </Grid>

                        <Grid style={{ paddingBottom: '2%' }} item lg={12} xs={12}>

                            <SelectValidator
                                name='cnf'
                                fullWidth={true}
                                variant="outlined"
                                validators={['required']}
                                errorMessages={['CNF is required']}
                                onChange={set}
                                value={props.quote['cnf']}
                                label="I am Looking For"
                                disabled
                            >
                                {cnfMenuList}
                            </SelectValidator>
                        </Grid>


                        <Grid item lg={12} xs={12}>

                            <Typography variant="h6" className={classes.heading}>Delivery Schedule</Typography>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <br />
                            <SelectValidator
                                fullWidth={true}
                                variant="outlined"
                                name={'shippingStrategy'}
                                onChange={set}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                value={offer['shippingStrategy']}
                                label="How Do I Want This Order Sent?"
                                disabled
                            >
                                {shippingStrategyMenuList}
                            </SelectValidator>
                        </Grid>



                        <Grid item lg={12} xs={12}>
                            <br />
                            <SelectValidator
                                name={'timing'}
                                value={offer['timing']}
                                onChange={set}
                                fullWidth={true}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant='outlined'
                                label="How quickly do I need this item shipped ?"
                                disabled
                            >
                                {timingMenuList}
                            </SelectValidator>
                        </Grid>

                        {offer['cnf'] != undefined && offer['cnf'] == '1' ?  <div style={{ width: '100%' }}>

                        <Grid container>
                        <Grid item lg={12} xs={12}>
                                    <br />
                                    <Typography variant="h6" className={classes.heading}>Buyer’s my shipping address</Typography>
                                    <br />
                                </Grid>
                            <Grid item lg={12} xs={12} style={{marginTop: 20}}>
                                        <TextValidator
                                            name={'companyName'}
                                            onChange={set}
                                            value={offer['companyName']}
                                            
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            label="Name of Company"
                                            disabled
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12} style={{marginTop: 20}}>
                                    <TextValidator
                                        name={'street1'}
                                        onChange={set}
                                        value={offer['street1']}
                                        
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        id="amount"
                                        disabled
                                        label="Line Address 1"
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>

                                    <Grid item lg={12} xs={12} style={{marginTop: 20}}>
                                        <TextValidator
                                            name={'city'}
                                            onChange={set}
                                            value={offer['city']}
                                            
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            label="City"
                                            disabled
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                        <Grid item lg={12} xs={12} style={{marginTop: 20}}>

                                    <TextValidator
                                        name={'state'}
                                        onChange={set}
                                       
                                        value={offer['state']}
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['State is required']}
                                        label="State/Province"
                                        type="amount"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                
                                <Grid item lg={12} xs={12} style={{marginTop: 20}}>
                                    <TextValidator
                                        name={'zipcode'}
                                        
                                        value={offer['zipcode']}
                                        id="amount"
                                        label="Zip Code"
                                        onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                
                                </Grid>


                        </div>

                        :
                        <div></div>
                        }
                        {offer['cnf'] != undefined && offer['cnf'] == '2' ?
                            <div style={{ width: '100%' }}>
                                <Grid item lg={12} xs={12}>
                                    <br />
                                    <Typography variant="h6" className={classes.heading}>Buyer's Shipping Address</Typography>
                                    <br />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'airport'}
                                        onChange={set}
                                        margin="dense"
                                        value={offer['airport']}
                                        id="port-name"
                                        validators={['required']}
                                        errorMessages={['port is required']}
                                        label="Airport Name"
                                        type="name"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>


                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'state'}
                                        onChange={set}
                                        margin="dense"
                                        value={offer['state']}
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['State is required']}
                                        label="State/Province"
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'zipcode'}
                                        margin="dense"
                                        value={offer['zipcode']}
                                        id="amount"
                                        label="Zip Code"
                                        onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>

                                

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        value={offer['country']}
                                        onChange={set}
                                        margin="dense"
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['Country is required']}
                                        label="Country"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        name={'country'}
                                        disabled
                                    />
                                </Grid>
                                </div> : <div></div>}



                                {offer['cnf'] != undefined && offer['cnf'] == '3' ?
                            <div style={{ width: '100%' }}>
                                <Grid item lg={12} xs={12}>
                                    <br />
                                    <Typography variant="h6" className={classes.heading}>Buyer's Shipping Address</Typography>
                                    <br />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'portName'}
                                        onChange={set}
                                        margin="dense"
                                        value={offer['portName']}
                                        id="port-name"
                                        validators={['required']}
                                        errorMessages={['port is required']}
                                        label="Port Name"
                                        type="name"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>


                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'state'}
                                        onChange={set}
                                        margin="dense"
                                        value={offer['state']}
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['State is required']}
                                        label="State/Province"
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'zipcode'}
                                        margin="dense"
                                        value={offer['zipcode']}
                                        id="amount"
                                        label="Zip Code"
                                        onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>

                                

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        value={offer['country']}
                                        onChange={set}
                                        margin="dense"
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['Country is required']}
                                        label="Country"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        name={'country'}
                                        disabled
                                    />
                                </Grid>
                                </div> : <div></div>}


                        {offer['cnf'] != undefined && offer['cnf'] == '4' || offer['cnf'] == '5' || offer['cnf'] == '6' || offer['cnf'] == '7' ?
                            <div style={{ width: '100%' }}>
                                <Grid item lg={12} xs={12}>
                                    <br />
                                    <Typography variant="h6" className={classes.heading}>Buyer’s My Shipping Address</Typography>
                                    <br />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'companyName'}
                                            onChange={set}
                                            value={offer['companyName']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            disabled
                                            label="Name of Company/Warehouse"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'street1'}
                                        onChange={set}
                                        value={offer['street1']}
                                        margin="dense"
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        id="amount"
                                        disabled
                                        label="Street Address 1"
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>


                                <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'city'}
                                            onChange={set}
                                            value={offer['city']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            label="City"
                                            disabled
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'state'}
                                        onChange={set}
                                        margin="dense"
                                        disabled
                                        value={offer['state']}
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['State is required']}
                                        label="State/Province"
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'zipcode'}
                                        margin="dense"
                                        value={offer['zipcode']}
                                        id="amount"
                                        disabled
                                        label="Zip Code"
                                        onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        value={offer['country']}
                                        onChange={set}
                                        margin="dense"
                                        id="amount"
                                        disabled
                                        validators={['required']}
                                        errorMessages={['Country is required']}
                                        label="Country"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        name={'country'}
                                    />
                                </Grid>
                            </div>
                            : <div></div>}



{offer['cnf'] != undefined && offer['cnf'] == '8' ?
                            <div style={{ width: '100%' }}>
                                <Grid item lg={12} xs={12}>
                                    {/* <br />
                                    <Typography variant="h6" className={classes.heading}>Here’s My Shipping Address</Typography>
                                    <br /> */}
                                </Grid>
                               
                            </div>
                            : <div></div>}




                        <Grid item lg={12} xs={12}>
                            <br />
                            <Typography variant="h6" className={classes.heading}>Other Details</Typography>
                            <br />
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'description'}
                                margin="dense"
                                value={offer['description']}
                                id="offer-description"
                                label="Describe Your Offer in Words"
                                type="offer"
                                onChange={set}
                                multiline
                                rows={5}
                                fullWidth
                                variant="outlined"
                                disabled
                            />
                        </Grid>


                        {/*                         
                        <Grid item lg={3}>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                        <br />
                     
                            <Button variant="outlined" className={classes.productBtnoutlined}>
                                Negotiate With Admin
                            </Button>
                        </Grid>
                        <Grid item lg={1}></Grid>
                        <Grid item lg={4} xs={12}>
                       <br />
                       <Button variant="contained" className={classes.productBtn}>
                                Accept & Place Order
                            </Button>
                        </Grid> */}
                    </Grid>


                </div>
            </ValidatorForm>

        </div>
    )
}