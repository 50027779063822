import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, List, ListItem, Card, CircularProgress, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Storage } from '@material-ui/icons';
import dollar from './../../assets/dollar.png';
import purse from './../../assets/purse.png';
import { Ways, API, Cook, getvalue } from '../../Helpers';
import { MonetizationOn } from '@material-ui/icons'
import { useStaticState } from '@material-ui/pickers';




const useStyles = makeStyles({

  actionArea: {

    '&.MuiCardActionArea-root': {

      textAlign: 'center',


    },

  },

  btnStyle: {

    padding: 15,
  },


  root: {
    maxWidth: 530,
    boxShadow: 'none',
    backgroundColor: '#fff',
    paddingTop: 50,
    paddingBottom: 50,
    pointerEvents: 'none',


  },
  media: {


  },

  imgStyle: {

    maxWidth: 120,


  },

  mainHeading: {

    color: '#303030',
    fontSize: 38,
    fontWeight: 600,


  },


  descriptionStyle: {


    fontSize: '1rem',
    fontWeight: 500,
    color: '#726f6f',

  },


});



export const HoneyFeatures = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoadin] = useState(false);
  const [sum, setSum] = useState(0);
  // const [totalBonuses,setTotalBonuses] = useState<number>()

  // const totalBonuses = data['bonuses'].reduce((total, bonus) => {
  //   return total + bonus.amount
  // }, 0)

  // const getSumOfBonuses = () => {
  //   if(data['bonuses'] != null  ){
  //     let bonuses = data['bonuses'].reduce((total, bonus) => {
  //       return total + bonus.amount
  //     }, 0)
  //     setTotalBonuses(bonuses)
  //   }else{
  //     setTotalBonuses(0)
  //   }

  // }

  useEffect(() => {
    API.get('ibc', { ibcid: window.location.pathname.split('/')[3] }).then(x => {
      setData(x.data);
      // console.log(x.data)



    })
    // getSumOfBonuses();
    // console.log(totalBonuses)
  }, [])


  if (!data['id']) {
    return <Grid container justify="center" alignItems="center" style={{ paddingTop: '25%' }}>
      <CircularProgress style={{ color: '#f28f16' }} />
    </Grid>
  }


  return (
    <div>
      <Grid container justify="center" spacing={2}>
        <Grid item lg={3} xs={12}>

          <Card className={classes.root}>
            <CardActionArea className={classes.actionArea}>
              <CardMedia
                className={classes.media}>

                {/* <Storage style={{fontSize: 70, color: '#f28f16'}} /> */}
                <img src={dollar} width="60" height="60" />
              </CardMedia>
              <CardContent>


                <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                  {Number(data['payLoad']??0).toFixed(2)}kg
                </Typography>
                <br />
                <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                  Honey Delivered
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

        </Grid>

        <Grid item lg={3} xs={12}>

          <Card className={classes.root}>
            <CardActionArea className={classes.actionArea}>
              <CardMedia
                className={classes.media}>

                {/* <Storage style={{fontSize: 70, color: '#f28f16'}} /> */}
                {/* <img src={purse} width="60" height="60" /> */}
                <MonetizationOn style={{ color: '#f28f16', width: 60, height: 60 }} />
              </CardMedia>
              <CardContent>


                <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                  {/* {data['minSellingPrice']} /kg <br /> */}
                  {/* {data['payLoad'] - data['remaining']} (ttt) */}
                  {data['remaining'] == null  ?  Number(0).toFixed(2) : Number(data['payLoad'] - data['remaining']).toFixed(2)}kg
                </Typography>
                <br />
                <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                  Honey Used
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

        </Grid>

        <Grid item lg={3} xs={12}>

          <Card className={classes.root}>
            <CardActionArea className={classes.actionArea}>
              <CardMedia
                className={classes.media}>

              </CardMedia>
              <Storage style={{ fontSize: 60, color: '#f28f16' }} />
              <CardContent>


                <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                  {data['remaining'] == null ? Number(data['payLoad']).toFixed(2) : Number(data['remaining']).toFixed(2)}kg
                </Typography>
                <br />
                <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                  Honey Available
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

        </Grid>

        <Grid item lg={3} xs={12}>

          <Card className={classes.root}>
            <CardActionArea className={classes.actionArea}>
              <CardMedia
                className={classes.media}>

                {/* <Storage style={{fontSize: 70, color: '#f28f16'}} /> */}
                <img src={purse} width="60" height="60" />
              </CardMedia>
              <CardContent>


                <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                  {/* ${data['initialPayment'] + ( data['bonuses'] != null ? 100 : 0) } */}
                  ${
                    data['bonuses'] != null && data['bonuses'].length > 0 ?
                    
                    (data['initialPayment'] +  data['bonuses'].reduce((total, bonus) => {
                        return total + bonus.amount
                      }, 0)) .toFixed(2)
                      :
                      Number(data['initialPayment'].toFixed(2))
                    }

                </Typography>
                <br />
                <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                  IBC Earnings
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

        </Grid>







      </Grid>
    </div>
  )
}