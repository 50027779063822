import React, { useEffect, useState, FunctionComponent } from 'react';
import { Grid, Button, FormControl, MenuItem, Select, InputLabel, Typography, List, ListItem, Dialog, DialogContentText, Divider, Container, Card, CardActionArea, CardMedia, CardContent, DialogTitle, DialogContent, DialogActions, Chip, Paper, CircularProgress, ListItemSecondaryAction, IconButton, ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { Ways, API, Cook, getvalue } from './../Helpers';
import { useSnackbar } from 'notistack';
import { CheckCircleOutlineOutlined, Cancel } from '@material-ui/icons';
import moment from 'moment';
import { createBrowserHistory } from 'history';




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: 'auto',

  },

  actionArea: {

    '&.MuiCardActionArea-root': {

      textAlign: 'center',


    },

  },


  card: {
    maxWidth: 540,
    minWidth: 400,
    boxShadow: 'none',
    pointerEvents: 'none',
    marginTop: '-6%',


  },
  media: {
    height: 120,

  },

  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },


  fixedHeightPaperEmail: {

    boxShadow: 'none',
    // padding: theme.spacing(4),
  },


  captionStyle: {
    fontSize: 14,
    marginTop: '-2%',
  },
  headingStyle: {
    fontSize: '1.5rem',
    color: '#303030',
    paddingTop: '3%',
    paddingBottom: '2%',
    marginLeft: '2%'
  },

  headingStyle1: {
    fontSize: '1rem',
    color: '#303030',
    paddingTop: '3%',
    paddingBottom: '2%',
  },
  listItemStyle: {

    backgroundColor: '#fbfbfb',

  },
}));





export const WithdrawlsLayout = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<any[]>([])


  const [open, setOpen] = React.useState(false);
  const [ddata, setDdata] = React.useState<any>()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const getData = () => {
    API.get('withrawals').then(x => {
      setData(x.data);
      // console.log(x.data);
    })

    // API.get('beekeeper/myibcs', {beekeeper: window.location.pathname.split('/')[0]}).then(x => {
    //     // setData(x.data);
    //     console.log(x.data);
    // })



  }

  useEffect(() => {

    getData();



  }, [])



  return (
    <div style={{ marginTop: '0%' }}>


      <Grid container className={classes.root} spacing={2}>
        <Grid item lg={12} md={12} xs={12}>

          <Paper className={classes.fixedHeightPaperEmail}>

            <MaterialTable



              style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, padding: '30px 0PX' }}
              title="All Withdrawls"
              columns={[
                { title: ' Withdrawl ID', render: (row) => <div>HON-WID-{row['rid']}</div> },
                { title: ' Beekeeper Name', render: (row) => <div>{row['beekeeper']['fullName']}</div> },
                { title: ' Created On', render: (row) => <div>{moment(row['createdOn']).format('MM//DD/YY')}</div> },

                { title: ' Amount($)', render: (row) => <div>{row['amount']}</div> },
                { title: ' Selected Method', render: (row) => <div>{row['paymentMethod']}</div> },

                {
                  title: 'Status', render: (row) =>
                    <div>

                      {row['approved'] == true ? <Chip label='Approved' style={{ backgroundColor: '#f28f16', color: '#ffffff', }} /> : <Chip label='Pending' style={{ backgroundColor: '#fbfbfb', color: '#303030', }} />}

                    </div>
                },

                {
                  title: 'Actions', render: (row) =>
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }} >
                      {/* <Grid item lg={2} >
    
    </Grid>
    <Grid lg={2} item >

    </Grid> */}
                      {/* 
    {!row['approved'] && <Grid item lg={5}>
       
       </Grid>
       ?
       <Button  variant="contained" size="small" 
           style={{ backgroundColor: '#f28f16', boxShadow: 'none', color: '#ffffff', marginLeft: -55 }}
           onClick={()=>{
               API.post('approvewithdrawal' , {withdrawalid : row['id']}).then(x=>{
                   // enqueueSnackbar('Withdrawl Approved Successfully !')
                   // handleClickOpen();
                
                   var ind = row.indexOf(row);
                   var e = row as  {};
                   e['approved'] = true;
                   data[ind] = e;
                   setData([...data])
               })
           }}
           >
               Sent Payment
           </Button>
   
       :
   
       <div>
           <Button style={{marginLeft: -55}} size="small" variant="contained" disabled>Approve</Button>
       </div>
       }
  */}

                      <Grid item lg={2}>
                        {/* <Button variant="contained" onClick={handleClickOpen}>Open</Button> */}
                        <Button fullWidth onClick={() => {

                          setDdata(row);
                          handleClickOpen()
                          // window.location.reload();
                        }} style={{ color: '#fff', backgroundColor: '#f28f16', boxShadow: 'none', marginLeft: 8 }} size="small" variant="contained">Details</Button>
                      </Grid>
                    </Grid>

                },
              ]}
              data={data}


            />


          </Paper>

        </Grid>
      </Grid>


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Container maxWidth="sm">
            <Grid container spacing={0} justify="center" alignItems="center">

              <Grid item lg={11}></Grid><Grid item lg={1}><IconButton style={{ marginLeft: 15, }} onClick={handleClose}><Cancel /></IconButton></Grid>
              <Grid item lg={12} xs={12} sm={12} style={{ backgroundColor: '#fff', padding: '3%', }}>


                <Card className={classes.card}>
                  <CardActionArea className={classes.actionArea}>
                    {/* <CardMedia
          className={classes.media}>
              <CheckCircleOutlineOutlined style={{ fontSize: 150, color: '#f28f16'}} />
          </CardMedia> */}
                    <Typography className={classes.headingStyle} align="left" gutterBottom variant="h5" component="h2">
                      Withdrawl Details
                    </Typography>
                    <CardContent>





                      <List style={{ display: 'flex', flexDirection: 'column', border: '1px solid #efefef', }}>



                        <ListItem className={classes.listItemStyle}>

                          <ListItemText >
                            Withdrawl ID:
                          </ListItemText>

                          <ListItemSecondaryAction>

                            HON-WID-{ddata != null ? ddata['rid'] : ''}

                          </ListItemSecondaryAction>

                        </ListItem>
                        <Divider />
                        <ListItem>

                          <ListItemText>
                            Beekeeper:
                          </ListItemText>

                          <ListItemSecondaryAction>

                            {ddata != null ? ddata['beekeeper']['fullName'] : ''}

                          </ListItemSecondaryAction>

                        </ListItem>
                        <Divider />


                        <ListItem className={classes.listItemStyle}>
                          <ListItemText>
                            Created On:
                          </ListItemText>
                          <ListItemSecondaryAction>

                            {ddata != null ? <div>{moment(ddata['createdOn']).format('MM//DD/YY')}</div> : ''}

                          </ListItemSecondaryAction>
                        </ListItem>

                        <Divider />



                        <ListItem>
                          <ListItemText>
                            Amount:
                          </ListItemText>
                          <ListItemSecondaryAction>

                            ${ddata != null ? ddata['amount'] : ''}

                          </ListItemSecondaryAction>
                        </ListItem>

                        <Divider />


                        <ListItem className={classes.listItemStyle}>
                          <ListItemText>
                            payment Method:
                          </ListItemText>
                          <ListItemSecondaryAction>

                            {ddata != null ? ddata['paymentMethod'] : ''}

                          </ListItemSecondaryAction>

                        </ListItem>
                      </List>






                    </CardContent>
                  </CardActionArea>
                </Card>

              </Grid>

              <Grid item lg={4} style={{ marginBottom: '3%' }}>
                {ddata != null && ddata['approved'] == false
                  ?
                  <Button variant="contained" size="medium"
                    style={{ backgroundColor: '#f28f16', boxShadow: 'none', color: '#ffffff', marginLeft: -127, }}
                    onClick={() => {
                      API.post('approvewithdrawal', { withdrawalid: ddata['id'] }).then(x => {
                        enqueueSnackbar('Withdrawl Approved Successfully !')
                        // handleClickOpen();
                        window.location.reload();


                        var ind = ddata.indexOf(ddata);
                        var e = data as {};
                        e['approved'] = true;
                        data[ind] = e;
                        setDdata({ ...ddata })
                      })
                    }}
                  >
                    Sent Payment
                  </Button>

                  :

                  <Grid item lg={2}>

                    <Button style={{ marginLeft: -127 }} size="medium" variant="contained" disabled>Payment Sent</Button>

                  </Grid>
                }
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>


        </DialogActions>
      </Dialog>





    </div>
  )
}