import React, { useEffect, useState, FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, Typography, CircularProgress, ListItem, ListItemText, ListItemSecondaryAction, List, Tooltip } from '@material-ui/core';
// import { Quantity } from './../Pages/SingleProduct/Quantity';
// import productthumn from './../assets/productthumn.jpg';
import { API, LoginHelper, getval } from './../Helpers';
import { useSnackbar } from 'notistack';
// import { Loader, AutoLoader } from './LoaderButton';
import { GlobalState } from '../AppState';

const useStyles = makeStyles({
  table: {
    width: '100%',
    overflowX: 'hidden',

  },

  bottomListStyle: {

    display: 'flex',
    flexDirection: 'column',
    width: '83%',

  },

  mainheading: {
    fontFamily: 'sophia',
    paddingBottom: '5%',

  },

});






export const TestingTable: FunctionComponent<{ order: any }> = props => {




    const classes = useStyles();
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();


    var isQuoted = props.order['status'] == 'Quoted';

    const getCartItems = async () => {

    }
    useEffect(() => {
      getCartItems();
    }, [])




    const getSubTotalSingleCartItem = (row) => {
      if (!row['cartItem']['discount'] || !row['cartItem']['offeredPrice']) {
        return <div>-</div>
      }
      var op = Number(row['cartItem']['offeredPrice']);
      var quantity = Number(row['cartItem']['quantity']);
      var discount = Number(row['cartItem']['discount']);
      var itemsPerCarton = Number(row['cartItem']['variation']['itemsPerCarton'])
      var oTotal = op * quantity * itemsPerCarton;
      return <div>
        <div >{op + ' * ' + quantity + ' * ' + itemsPerCarton + " = "}<div style={{ textDecoration: 'line-through' }}> {oTotal}</div> </div>
        <br />
        {oTotal - ((discount / 100) * oTotal)}
      </div>
    }

    const getSubTotal = (cartItems: any[]): number => {

      var subtotal = 0;

      cartItems.forEach(x => {
        var c = {
          quantity: x['quantity'],
          discount: x['discount'],
          price: x['offeredPrice'],
          itemsPerCarton: x['variation']['itemsPerCarton']
        }

        var total = (c.quantity * c.price * c.itemsPerCarton);
        var price = total - (c.discount / 100 * total);
        subtotal += price;
      });

      return Math.round((subtotal + Number.EPSILON) * 100) / 100
    }




    return (
      <div>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Product Description</TableCell>
                <TableCell align="left">Image</TableCell>
                {/* <TableCell align="center">No. Of Items</TableCell> */}
                <TableCell align="right">Size(g)</TableCell>
                <TableCell align="right">No. Of Cartons</TableCell>
                <Tooltip title="Price of a unit  x  Items In A Carton">
                  <TableCell align="left">Price per carton</TableCell>
                </Tooltip>
                <TableCell align="right">Discount</TableCell>
                <TableCell align="right">Subtotal</TableCell>

              </TableRow>
            </TableHead>
            {
              props.order['cartItems'].map((x, i) => { return { product: props['order']['products'][i], cartItem: x } }).map((x, i) => {
                return <TableBody style={{ paddingTop: -15, paddingBottom: -15, }}>
                  <TableCell align="left">HON-PRO-{x['product']['id']}</TableCell>
                  <TableCell align="left">{x['product']['productTitle']}</TableCell>
                  <TableCell align="left"> <img src={x['product']['primaryImage']} width="35" height="35" /></TableCell>
              {/* <TableCell align="center">{getval(props.order, 'items')}</TableCell> */}
                  <TableCell align="right">{x['cartItem']['variation']['weight'] + ' g'}</TableCell>

                  <TableCell align="center">{x['cartItem']['quantity']}</TableCell>
                  <TableCell align="left">${(x['cartItem']['variation']['itemsPerCarton'] * x['cartItem']['variation']['price']).toLocaleString()}</TableCell>
                  {/* <TableCell align="right">{x['cartItem']['variation']['price'] + ' * ' + x['cartItem']['variation']['itemsPerCarton'] + ' = ' + x['cartItem']['variation']['itemsPerCarton'] * x['cartItem']['variation']['price']}</TableCell> */}
                  <TableCell align="right">{x['cartItem']['discount'] + ' %'}</TableCell>
                  {/* <TableCell align="right">{getSubTotalSingleCartItem(x)}</TableCell> */}
                  <TableCell align="right">{true ? getSubTotal(props.order['cartItems']) : '-'}</TableCell>
                  {/* <TableCell align="right">{isQuoted ? getSubTotalSingleCartItem(x) : '-'}</TableCell> */}
                  {/* <TableCell align="right"><AutoLoader
                    onClick={async () => {
                      await API.post('cart/removeitem', { item: x['id'] }).then(res => {
                        if (res.status == 200) {
                          enqueueSnackbar('Item Removed');
                          var nc = [...cart.filter(z => z['id'] != x['id'])];
                          GlobalState.localCart = {items : nc.length , data : nc}
                          console.log(nc);
                          setCart([...nc])
                        }
                      })
                    }}
                    variant="contained">Remove</AutoLoader></TableCell> */}
                </TableBody>
              })
            }
          </Table>
        </TableContainer>
        <Grid container style={{ backgroundColor: '#fff' }}>
          <Grid item lg={8}>
          </Grid>
          <Grid item lg={4}>
            <List dense className={classes.bottomListStyle}>
              <ListItem>
                <ListItemText>
                  <Typography variant="subtitle2">Sub Total</Typography>
                </ListItemText>

                <ListItemSecondaryAction>
                  <Typography variant="body2">{true ? getSubTotal(props.order['cartItems']) : '-'}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography variant="subtitle2">Shipping Costs</Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Typography variant="body2">{true ? getval(props.order, 'shippingCost', '') : '-'}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography variant="subtitle2">Total</Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Typography variant="body2">{true ? Number(props.order['shippingCost']) + Number(getSubTotal(props.order['cartItems'])) : '-'}</Typography>
                </ListItemSecondaryAction>
              </ListItem>



            </List>
          </Grid>

        </Grid>




      </div>
    )
  }