import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, CardMedia, Typography, } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import pngprofile from './../assets/pngprofile.png';
import 'typeface-roboto';
import { Bbc, AppEvent, API, getval, Ways } from '../Helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    card: {
      maxWidth: 430,
      boxShadow: 'none',
      marginTop: -25,
      backgroundColor: '#fff',

      
    },
    media: {
      marginLeft: '25%',

    },

    headings: {

      fontSize: 14,
      fontWeight: 600,
    },

    mainHeading: {

      paddingTop: '5%',
      paddingBottom: '5%',
    },


    listStyle: {

      display: 'flex',
      flexDirection: 'row',
      maxWidth: 35,
      marginLeft: '25%',
      marginTop: '-4%',
    },

  }),
);

export const Profile: FunctionComponent<{ candidate?: string }> = props => {
  const classes = useStyles({});
  const [userData, setUserData] = useState({});

  useEffect(() => {
    Bbc.subscribe(AppEvent.newChatSelected, x => {
      API.get('userData', { userEmail: x['partner']['email'] }).then(user => {
        setUserData(user.data)
        console.log(user.data)
      })

      API.get('userOrders', { userEmail: x['partner']['email'] }).then(user => {
        setUserData(user.data)
        console.log(user.data)
      })
    })
  }, []);


  if(!userData['id']){
    return <div></div>
  }


  return (

    <Card className={classes.card}>
    
      {/* <CardActionArea > */}
     
        <Typography className={classes.mainHeading} align="center" variant="h6">About Buyer</Typography>
        <CardMedia className={classes.media}>
          <img src={pngprofile} style={{ height: 150 }} />
        </CardMedia>
        <CardContent>
          <Typography className={classes.headings} align="center" gutterBottom variant="subtitle2" component="h2">
            {userData['firstName']} {userData['lastName']}
        </Typography>
          <List dense className={classes.listStyle}>
            <ListItem>
              <Room />
            </ListItem>
            <ListItem>
              <Typography variant="subtitle2">{userData['country']}</Typography>
            </ListItem>
          </List>


          <List>

            {/* <ListItem style={{marginTop: '-5%'}}>
              <ListItemText>
                <Typography className={classes.headings} variant="subtitle2">Average Response Time</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                1 Hrs
            </ListItemSecondaryAction>
            </ListItem> */}

            <ListItem style={{marginTop: '-5%'}}>
              <ListItemText>
                <Typography className={classes.headings} variant="subtitle2">Previous Orders</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                {userData['offers'].length == 0 ? '0' : userData['offers'].length - 1}
              </ListItemSecondaryAction>
            </ListItem>
            </List>
          

          
            <br />
            <List style={{maxHeight: 200, overflow: 'auto'}}>
            <ListItem>
              <Typography variant="h6">Quotes</Typography>
            </ListItem>
            {userData['offers'] != null && userData['offers'].map((x, i) => 
            <ListItem style={{marginTop: '-5%', overflowX: 'auto'}}>
              <ListItemText>
                {/* <Typography variant="subtitle2">HON-QUO-{x['enrid']}</Typography> */}
                <a style={{color: '#303030'}} href={Ways.singleOfferpage + x['id']}>HON-QUO-{x['enrid']}</a>
              </ListItemText>
              <ListItemSecondaryAction>
                {x['status']} 
            </ListItemSecondaryAction>
            </ListItem>)}
          </List>

        </CardContent>
      {/* </CardActionArea> */}

    </Card>

  );
}