import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { BarChart, Category, Dashboard, Email, Layers, Lock, Message, ShoppingCart, EmojiNature, MonetizationOn, MergeType, ViewHeadline, WorkOutlined } from '@material-ui/icons';
import React from 'react';
import 'typeface-roboto';
import { Ways, API, Cook, } from '../Helpers';
import { GlobalState } from '../AppState';
import { observer } from 'mobx-react';

import {createBrowserHistory} from 'history';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
     

        customBadge: {
            backgroundColor: "#BF3604",
            color: "white",
            marginTop: 7,

        },
      
    }),
);



const MsgBadge = observer(() => {
  const classes = useStyles()
  return <Badge classes={{ badge: classes.customBadge }}  badgeContent={GlobalState.unreadMessages.length} >
      
  </Badge>
})

const QuoteBadge = observer(() => {
  const classes = useStyles()
  return <Badge classes={{ badge: classes.customBadge }}  badgeContent={GlobalState.unreadQuotes.filter(x=>x.status == 'Pending').length} >
      
  </Badge>
})

const StyledMenuItem = withStyles(() => ({
  root: {


    // '&:focus': {
    //   // backgroundColor: theme.palette.primary.main,
    //   backgroundColor: '#f28f16',
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,

    //     '& .Mui-selected': {
    //       backgroundColor: '#ffffff',
    //     },
    //   },
    // },
  },

}))(MenuItem);

export const Menus = () => {


  

  const [] = React.useState(false);

  const menuItems: { path?: string, title: string, icon: JSX.Element, onClick?: () => void }[] = [
    { path: Ways.home, title: 'Dashboard', icon: <Dashboard fontSize="small" style={{ fontSize: 17, }} /> },
    { path: Ways.allbeekeepersPage, title: 'All Beekeepers', icon: <Layers fontSize="small" style={{ fontSize: 17}} /> },
    { path: Ways.beekeeperRequests, title: 'Beekeeper Requests', icon: <MergeType fontSize="small" style={{ fontSize: 17}} /> },
    {path: Ways.honeystockPage, title: 'Honey Stock', icon: <WorkOutlined fontSize="small" style={{ fontSize: 17}} />},
    {path: Ways.ibcs, title: "IBCs", icon: <EmojiNature fontSize="small" style={{ fontSize: 17}} />},
    // {path: Ways.withdrawlsPage, title: 'Withdrawls', icon: <MonetizationOn fontSize="small" style={{ fontSize: 17}} />},

    // { path: Ways.productsPage, title: 'Products', icon: <Layers fontSize="small" style={{ fontSize: 17, }} /> },
    // { path: Ways.ordersPage, title: 'Orders', icon: <ShoppingCart fontSize="small" style={{ fontSize: 17, }} /> },
    // { path: Ways.categoriesPage, title: 'Categories', icon: <Category fontSize="small" style={{ fontSize: 17, }} /> },
    // { path: Ways.offersPage, title: 'Quotes', icon: <BarChart fontSize="small" style={{ fontSize: 17, }} /> },
    // { path: Ways.allBuyersPage, title: 'Buyers', icon: <BarChart fontSize="small" style={{ fontSize: 17, }} /> },
    // { path: Ways.messagesPage, title: 'Messages', icon: <Message fontSize="small" style={{ fontSize: 17, }} /> },
    // { path: Ways.emailPage, title: 'Email', icon: <Email fontSize="small" style={{ fontSize: 17, }} /> },

    {path: Ways.honeyRates, title: 'Honey Rates', icon: <MonetizationOn fontSize="small" style={{ fontSize: 17}} />},
    {
      title: 'Logout', icon: <Lock fontSize="small" style={{ fontSize: 17, }} />, onClick: () => {
        API.post('logout').then(()=>{
          Cook.del();
          createBrowserHistory().push('/');
          window.location.reload();
        })
      }
    }

  ];

  return (
    <div>


      <br />

      {menuItems.map((x, i) => {
        let isSelected = window.location.pathname == (x.path);
        return <Link key={i} href={x.path} style={{ textDecoration: 'none', color: '#4c4a4a', }}>
          <StyledMenuItem onClick={()=>{
            if(x.onClick!=null){
              x.onClick();
            }
          }} style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: isSelected ? '#f28f16' : '4c4a4a', color: isSelected ? 'white' : '#4c4a4a' }}>
            <ListItemIcon style={{ marginLeft: 5, color: isSelected ? 'white' : '#4c4a4a' }}>
              {x.icon}
            </ListItemIcon>
            <ListItemText primary={x.title} />

          
                                    
                        { (x.path == Ways.messagesPage) && <MsgBadge />}
                        { (x.path == Ways.offersPage) && <QuoteBadge />}
          </StyledMenuItem>
        </Link>
      })}
      {/* 

      <Link href={Ways.home} style={{ textDecoration: 'none', color: '#4c4a4a', }}>
        <StyledMenuItem style={{ paddingTop: 5, paddingBottom: 5,}}>
          <ListItemIcon style={{ marginLeft: 5, }}>
            <Dashboard fontSize="small" style={{ fontSize: 17, }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </StyledMenuItem>
      </Link>


      <Link href={Ways.productsPage} style={{ textDecoration: 'none', color: '#4c4a4a', }}>
        <StyledMenuItem style={{ paddingTop: 5, paddingBottom: 5, }}>
          <ListItemIcon style={{ marginLeft: 5, }}>
            <Layers fontSize="small" style={{ fontSize: 17 }} />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </StyledMenuItem>
      </Link>

      <Link href={Ways.ordersPage} style={{ textDecoration: 'none', color: '#4c4a4a', }}>
        <StyledMenuItem style={{ paddingTop: 5, paddingBottom: 5, }}>
          <ListItemIcon style={{ marginLeft: 5, }}>
            <ShoppingCart fontSize="small" style={{ fontSize: 17 }} />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </StyledMenuItem>
      </Link>


      <Link href={Ways.categoriesPage} style={{ textDecoration: 'none', color: '#4c4a4a', }}>
        <StyledMenuItem style={{ paddingTop: 5, paddingBottom: 5, }}>
          <ListItemIcon style={{ marginLeft: 5, }}>
            <Category fontSize="small" style={{ fontSize: 17 }} />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </StyledMenuItem>
      </Link>



      <Link href="/offers" style={{ textDecoration: 'none', color: '#4c4a4a', }}>
        <StyledMenuItem style={{ paddingTop: 5, paddingBottom: 5, }}>
          <ListItemIcon style={{ marginLeft: 5, }}>
            <BarChart fontSize="small" style={{ fontSize: 17 }} />
          </ListItemIcon>
          <ListItemText primary="Offers" />
        </StyledMenuItem>
      </Link>

      <Link href="/chat" style={{ textDecoration: 'none', color: '#4c4a4a', }}>
        <StyledMenuItem style={{ paddingTop: 5, paddingBottom: 5, }}>
          <ListItemIcon style={{ marginLeft: 5, }}>
            <Message fontSize="small" style={{ fontSize: 17 }} />
          </ListItemIcon>
          <ListItemText primary="Messages" />
        </StyledMenuItem>
      </Link>



      <Link href="/email" style={{ textDecoration: 'none', color: '#4c4a4a', }}>
        <StyledMenuItem style={{ paddingTop: 5, paddingBottom: 5, }}>
          <ListItemIcon style={{ marginLeft: 5, }}>
            <Email fontSize="small" style={{ fontSize: 17 }} />
          </ListItemIcon>
          <ListItemText primary="Email" />
        </StyledMenuItem>
      </Link>

      <Link href="#" style={{ textDecoration: 'none', color: '#4c4a4a', }}>
        <StyledMenuItem style={{ paddingTop: 5, paddingBottom: 5, }}>
          <ListItemIcon style={{ marginLeft: 5, }}>
            <Lock fontSize="small" style={{ fontSize: 17 }} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </StyledMenuItem>
      </Link> */}





    </div>
  );
}