import React, {useState, FunctionComponent} from 'react';
import { Grid, Paper, Container, MenuItem, FormControl, Typography, Select, InputLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { API, getval } from "../Helpers";
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';



const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(2),
        overflow: 'auto',

    },

    listStylesecond: {

        display: 'flex',
        flexDirection: 'row',
        width: '100%',


    },

    listStyle: {

        display: 'flex',
        width: '100%',
        paddingBottom: '3%',




    },

    bottomListStyle: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },

    formControl: {
        // margin: theme.spacing(0),

        minWidth: '100%',
    },

    btnStyle: {

        backgroundColor: '#f28f16',
        boxShadow: 'none',
        color: '#ffffff',


        '&.MuiButton-contained': {


            '&:hover': {


                backgroundColor: '#FECF51',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },


    fixedHeightPaper: {

        boxShadow: 'none',
        padding: theme.spacing(2),
    },

}));



export const StatusChanger: FunctionComponent<{ order: any }> = props => {

    const classes = useStyles();
 
    const set = (e: React.FormEvent) => {
        var n = e.target['name'];
        var v = e.target['value'];
        data[n] = v;
        setData({ ...data })
        console.log(data);
      }
    
      const { enqueueSnackbar } = useSnackbar();
      const [data , setData] = useState<any[]>([])
      const [loading, setLoading] = useState(false);

    return(

 

<Grid item lg={12} md={12} xs={12}>

<Paper className={classes.fixedHeightPaper}>
<ValidatorForm onSubmit={async () => {
    // alert('Starting')
    setLoading(true);
    console.log( {...props.order , ...data  });
    await API.put('order', {...props.order , ...data  }).then(x => {
        console.log(x);
        if (x.status == 200) {
            enqueueSnackbar("Order saved")
           
            // window.location.reload();
        }
     
    })
    setLoading(false)
}}>




  
    

        <SelectValidator   name='orderStatus' value={data['orderStatus']} variant="outlined" size="small" style={{ width: '100%' }}
  onChange={set}
  
  label="Order Status"
  
  errorMessages={['this field is required']}


>
{/* <MenuItem value={props.order['orderStatus']}> */}
    
      {/* </MenuItem> */}
 <MenuItem value={'pending'}>pending</MenuItem>
  <MenuItem value={'processing'}>processing</MenuItem>
  <MenuItem value={'shipped'}>shipped</MenuItem>
  <MenuItem value={'complete'}>complete</MenuItem>
  <MenuItem value={'cancelled'}>cancelled</MenuItem>
 



</SelectValidator>
   
    <br /><br />
    <Button type='submit' variant="contained" fullWidth style={{color: '#fff', backgroundColor: '#f28f16'}}>
        Save
</Button>

</ValidatorForm>
</Paper>
</Grid>


)
}
