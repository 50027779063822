import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import avh from './../assets/avh.png';
import { Ways, API } from '../Helpers';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.avh.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}-v1.1
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },
}));

export const Signup = () => {
  const classes = useStyles();
  const [user, setUser] = useState({})

  const set = (e) => {
    var p = { ...user };
    p[e.target.name] = e.target.value;
    setUser(p);
    // console.log(p);
    
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={avh} alt="logo-image" />
        <br /><br />
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <ValidatorForm onSubmit={() => {

          API.post('signup' , user).then(x=>{
            console.log(x.status);
            if(x.status!=200){
            }
          })
          
         }} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextValidator
                value={user['firstName']}
                onChange={set}
                validators={['required', 'matchRegexp:^[a-zA-Z0-9]+$']}
                errorMessages={['this field is required', 'First Name is in an invalid format']}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                variant="outlined"
                value={user['lastName']}
                validators={['required', 'matchRegexp:^[a-zA-Z0-9]+$']}
                errorMessages={['this field is required', 'Last Name is in an invalid format']}
                onChange={set}
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                value={user['email']}
                onChange={set}
                required
                validators={['required', 'isEmail']}
                errorMessages={['this field is required', 'Email is in an invalid format']}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                required
                validators={['required', ]}
                errorMessages={['this field is required']}
                fullWidth
                value={user['password']}
                onChange={set}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                name="marketing" onChange={x=>{
                  var p = {...user};
                  p['marketing'] = x.target['checked'];
                  setUser(p);
                }}
                control={<Checkbox value={user['marketing']} color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href={Ways.loginPage} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}