import { Button, Chip, Grid, Link, Paper, List, ListItem, Dialog, DialogContent, Typography, CircularProgress, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Ways, API, Cook, getvalue } from '../Helpers';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { IBCSinglePopup } from '../Honey/SingleHoneyPage/IBCSinglePopup';
import { WithdrawPopup } from './../Withdrawl/WithdrawPopup';
import { HoneyContributionPopup } from '../Honey/SingleHoneyPage/HoneyContributionPopup';
import moment from 'moment'
import { FormatAlignCenter } from '@material-ui/icons';
import { set } from 'date-fns';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';







const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(1),
    overflow: 'auto',

  },

  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },


  fixedHeightPaperEmail: {

    boxShadow: 'none',
    padding: '8px',
  },

}));



export const HoneyIbcTable: FunctionComponent<{ currentHolding: any, }> = props => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([])
  const [ctd, setCTD] = useState<any>({})

  // console.log(JSON.stringify(props))
  const { enqueueSnackbar } = useSnackbar();







  //   const getdata = () => {
  //     API.get('ibc', { userid: Cook.getUserData()['user']['id'] }).then(x => {
  //       // console.log(x.data);
  //       setData(x.data);
  //     });
  //   }

  //   let id: number = 1
  //   const getID = () => {
  //     return id++;
  //   }

useEffect(()=> {
  console.log(props.currentHolding)
},[])



  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item lg={12} md={12} xs={12}>

        <Paper className={classes.fixedHeightPaperEmail}>
          {/* <Typography variant="h6">Honey contribution to holding {data['holding'][2]['rid']}</Typography> */}
          <MaterialTable



            style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
            title=""
            columns={[

              // { title: <p style={{ textAlign: 'center', }} > Delivery ID new</p>, render: (row) => <div>HON-IBC-84</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '75px', }} >Delivery ID</p>, field: 'rid', render: (row) => <div>HON-IBC-{JSON.stringify(row['rid'])}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '90px' }} > Receival Date</p>, field: 'createdOn', render: (row) => <div>{moment(row['createdOn']).format('DD/MM/YYYY')}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '110px' }} >  Beekeeper Name  </p>, field: 'beekeeper.fullName', render: (row) => <div>{row['beekeeper']['fullName']}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '95px' }} >  Beekeeper ID </p>, field: 'beekeeper.rid', render: (row) => <div>HON-BEE-{row['beekeeper']['rid']}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '110px' }} >  Honey Delivered </p>, field: 'payLoad', render: (row) => <div>{(Number(row['payLoad'] ?? 0)).toFixed(2)}kg</div> },



              // { title: ' Flowers Blossom', render: (row) => <div>{row['flowersInBlossom']}</div>},
              // { title: ' Date Of Extraction', render: (row) => <div>{row['dateOfExtraction']}</div>},
              // { title: ' Location', render: (row) => <div>{row['apiarySiteLocation']}</div>},

              // { title: ' Remaining (t)', render: (row) => <div>{row['remaining'] == null ? row['remaining'] + row['payLoad'] : row['remaining']}</div>},

              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '80px' }} >  Honey Used </p>, field: 'remaining', render: (row) => <div>{row['remaining'] == null ? Number(0).toFixed(2) : Number((row['payLoad'] - row['remaining'])).toFixed(2)}kg</div> },

              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '110px' }} >  Honey Available </p>, field: 'remaining', render: (row) => <div>{row['remaining'] == null ? Number(row['payLoad']).toFixed(2) : Number(row['remaining']??0).toFixed(2)}kg</div> },

              // { title: ' Total Sold (t)', render: (row) => <div>{row['payLoad'] - row['remaining']}</div>},
              // { title: ' Remaining (t)', render: (row) => <div>{row['remaining']}</div>},


              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '115px' }} >  Delivery Payment  </p>, field: 'initialPayment', render: (row) => <div>${Number(row['initialPayment'] ?? 0).toFixed(2)}</div> },

              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '105px' }} >  Bonus Payment</p>, field: 'total_bonus', render: (row) => <div>${Number(row['total_bonus'] ?? 0).toFixed(2) ?? Number(0).toFixed(2)  }</div> },





              // { title: ' Email', render: (row) => <div>{row['beekeeper']['email']}</div>},


              {
                title: <p style={{ textAlign: 'start' }} > Actions </p>, cellStyle: { textAlign: 'start' }, sorting: false, render: (row) =>

                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                  

                    <div style={{ marginRight:'10px',height:'30px' }} >


                      <Button onClick={() => {
                        createBrowserHistory().push(Ways.ibcPage + '/' + row['id'])
                        window.location.reload();
                      }} variant="contained" size="small"
                        style={{backgroundColor:'#f28f16',color:'white',height:'30px'}}>Details</Button>

                    </div>
                    <div style={{marginRight:'10px',height:'30px' }} >
                      <Button
                        variant="contained" size="small"
                        style={{ backgroundColor: '#ff0000', color: '#fff',height:'30px' }}
                        onClick={() => {
                          setCTD(row);
                        }}
                      >
                        delete
                      </Button>
                    </div>

                    {/* <div style={{height:'30px'}} >
                      <WithdrawPopup currentIBC={row} currentBeekeeper={row} />
                    </div> */}


                  </div>


              },
            ]} data={props.currentHolding}


          />


        </Paper>

        <Dialog open={ctd != undefined && ctd['id'] != null} onClose={() => setCTD(undefined)}>
          <DialogContent>
            <Typography variant='body2'>Are you sure you want to delete this IBC ?</Typography>
            <br />
            <List>

              <Button style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
                onClick={() => {
                  API.delete('ibc', { ibcid: getvalue(ctd, 'id') }).then(x => {
                    if (x.status == 200) {
                      // getdata();
                      window.location.reload();
                      enqueueSnackbar('IBC deleted');
                    } else {
                      enqueueSnackbar('Could noe deleted')
                    }
                    setCTD(undefined);
                  }
                  )
                }

                }>Delete</Button>
              <Button style={{ backgroundColor: '#f7f7f7' }} onClick={() => {
                setCTD(undefined);
              }}>Cancel</Button>
            </List>
          </DialogContent>
        </Dialog>








      </Grid>
    </Grid>
  )
}

// all IBCS TABLE

export const AllIBCSTable: FunctionComponent = () => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([])
  const [ctd, setCTD] = useState<any>({})
  const [filter, setFilter] = useState<any>({})
  const { enqueueSnackbar } = useSnackbar();

  const getData = async () => {
    var res = await API.get("ibcs_all")
    setData(res.data)
    console.log(res.data)
  }

  useEffect(() => {
    getData();
  }, [])

  const set = (e: React.FormEvent) => {
    var n = e.target['name'];
    var v = e.target['value'];
    filter[n] = v;
    setFilter({ ...filter, })

  }



  //   useEffect(() => {
  //     API.get('ibcs',  {holdingid: window.location.pathname.split('/')[3]}).then(x => {
  //       console.log(x.data);
  //         setData(x.data);

  //     })
  // }, [])

  //   const getdata = () => {
  //     API.get('ibc', { userid: Cook.getUserData()['user']['id'] }).then(x => {
  //       // console.log(x.data);
  //       setData(x.data);
  //     });
  //   }

  //   let id: number = 1
  //   const getID = () => {
  //     return id++;
  //   }





  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item lg={12} md={12} xs={12}>

        <Paper className={classes.fixedHeightPaperEmail}>
          {/* <Typography variant="h6">Honey contribution to holding {data['holding'][2]['rid']}</Typography> */}



          <MaterialTable
            options={{
              sorting: true,


            }}
            style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
            title="Overview of IBCs at delivery stage"
            columns={[

              // { title: <p style={{ textAlign: 'center', }} > Delivery ID </p>, render: (row) => <div>HON-IBC-{row['holding']['rid']}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '50px' }} > IBC ID</p>, field: 'rid', render: (row) => <div>HON-IBC-{row['rid']}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '90px' }} > Receival Date  </p>, field: 'createdOn', render: (row) => <div>{moment(row['createdOn']).format('DD/MM/YYYY')}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '85px' }} >  Beekeeper ID </p>, field: 'beekeeper.rid', render: (row) => <div>HON-BEE-{row['beekeeper']['rid']}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '110px' }} >  Beekeeper Name </p>, field: 'beekeeper.fullName', render: (row) => <div>{row['beekeeper']['fullName']}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '110px' }} >  Honey Delivered </p>, field: 'payLoad', render: (row) => <div>{Number(row['payLoad']).toFixed(2)}kg</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '120px' }} >  Holding Category </p>, field: 'holding.rid', render: (row) => <div>{row['holding'] != null ? `HON-HOL-${row['holding']['rid']}` : 'Not Assigned'}</div> },

              // { title: ' Flowers Blossom', render: (row) => <div>{row['flowersInBlossom']}</div>},
              // { title: ' Date Of Extraction', render: (row) => <div>{row['dateOfExtraction']}</div>},
              // { title: ' Location', render: (row) => <div>{row['apiarySiteLocation']}</div>},

              // { title: ' Remaining (t)', render: (row) => <div>{row['remaining'] == null ? row['remaining'] + row['payLoad'] : row['remaining']}</div>},
              // { title: ' Total Sold (t)', render: (row) => <div>{row['payLoad'] - row['remaining']}</div>},
              // { title: ' Remaining (t)', render: (row) => <div>{row['remaining']}</div>},
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '80px' }} >  Honey Used   </p>, sorting:false , render: (row) => <div>{row['remaining'] == null ? Number(0).toFixed(2) : Number((row['payLoad'] - row['remaining'])).toFixed(2)}kg</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '110px' }} >  Honey Available  </p>, sorting:false ,render: (row) => <div>{row['remaining'] == null ? Number(row['payLoad'] ?? 0).toFixed(2) : Number(row['remaining'] ?? 0).toFixed(2)}kg</div> },

              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '120px' }} >  Delivery Payment  </p>, field: 'initialPayment', render: (row) => <div>${Number(row['initialPayment']??0).toFixed(2)}</div> },
              { title: <p style={{ textAlign: 'start', fontSize: '14px', width: '105px' }} >  Bonus Payment </p>, field: 'total_bonus', render: (row) => <div>${Number(row['total_bonus'] ??0).toFixed(2) }</div> },






              // { title: ' Email', render: (row) => <div>{row['beekeeper']['email']}</div>},


              {
                title: <p style={{ textAlign: 'start', fontSize: '14px' }} > Actions </p>, sorting: false, render: (row) =>

                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>

                    <div style={{marginRight:'10px' }} >


                      <Button onClick={() => {
                        createBrowserHistory().push(Ways.ibcPage + '/' + row['id'])
                        window.location.reload();
                      }} variant="contained" size="small"
                        style={{backgroundColor:'#f28f16',color:'white'}}>Details</Button>

                    </div>
                    <div style={{marginRight:'10px' }} >
                      <Button
                        variant="contained" size="small"
                        style={{ backgroundColor: '#ff0000', color: '#fff' }}
                        onClick={() => {
                          setCTD(row);
                          console.log(JSON.stringify(ctd))
                        }}
                      >
                        delete
                      </Button>
                    </div>




                  </div>


              },

            ]}
            data={data}

          />


        </Paper>

        <Dialog open={ctd != undefined && ctd['id'] != null} onClose={() => setCTD(undefined)}>
          <DialogContent>
            <Typography variant='body2'>Are you sure you want to delete this IBC  ?</Typography>
            <br />
            <List>

              <Button style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
                onClick={() => {
                  API.delete('ibc', { ibcid: getvalue(ctd, 'id') }).then(x => {
                    if (x.status == 200) {
                      // getData();
                      enqueueSnackbar('IBC deleted');
                      window.location.reload();
                    } else {
                      enqueueSnackbar('Could noe deleted')
                    }
                    setCTD(undefined);
                  }
                  )

                }

                }>Delete</Button>
              <Button style={{ backgroundColor: '#f7f7f7' }} onClick={() => {
                setCTD(undefined);
              }}>Cancel</Button>
            </List>
          </DialogContent>
        </Dialog>








      </Grid>
      <Grid item xs={12} >
        <div style={{marginTop:'14%'}} >

        </div>
      </Grid>
    </Grid>
  )
}