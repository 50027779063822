import { Button, Chip, Grid, Link, Paper, List, ListItem, Dialog, DialogContent, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz } from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Ways, API, Cook, getvalue } from '../Helpers';
import { createBrowserHistory } from 'history';
import { useSnackbar } from 'notistack';
import { GlobalState } from '../AppState';
import moment from 'moment';
import { BeekeeperRequestPopup } from './../Components/BeekeeperRequestPopup';







const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(0),
    overflow: 'auto',





  },

  btnStyle: {

    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',


    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },


  fixedHeightPaperEmail: {

    boxShadow: 'none',
    // padding: theme.spacing(4),
  },

}));






export const BeekeeperRequests = () => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([])
  const [cp, setCp] = useState(undefined)
  const [ctd, setCTD] = useState()


  // Menu in table for profile and honeydetails

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };



  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // menu ends here 





  const { enqueueSnackbar } = useSnackbar();



  useEffect(() => {
    API.get('detailschangerequests').then(x => {
      // console.log(x.data);
      setData(x.data);
    })




    // API.delete('beekeeper' ).then(x=>{
    //   console.log(x.data);
    //   setData(x.data);
    // })  

  }, [])

  // let id:number =1
  // const getID = () => {
  //     return id++;
  // }



  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item lg={12} md={12} xs={12}>

        <div className={classes.fixedHeightPaperEmail}  >
          <Paper style={{ boxShadow: 'none',padding:'10px' }} className="beekeepertable" >

            <MaterialTable



              style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
              title="Beekeepers Requests For Change"
              columns={[
                { title: <p style={{ width: '90px' }} >Request ID</p>, field: 'rid', render: (row) => <div>BEE-REQ-{row['tableData']['id']}</div> },
                { title: <p style={{ width: '90px' }} >Beekeeper ID</p>, field: 'rid', render: (row) => <div>HON-BEE-{row['beekeeper']['rid']}</div> },
                { title: <p style={{ width: '120px' }} >Beekeeper Name</p>, field: 'fullName', render: (row) => <div>{row['beekeeper']['fullName']}</div> },
                // { title: ' No. Of Fields To Change',  headerStyle: { minWidth: 190 }, render: (row) => <div>{row['data'].length}</div>},
                { title: <p style={{ width: '180px' }} >No. Of Fields To Change</p>, field: 'data', render: (row) => <div>{Object.keys(row['data']).length}</div> },



                { title: <p style={{ width: '75px' }} >Date</p>, field: 'createdOn', render: (row) => <div>{moment(row['createdOn']).format('MM/DD/YY')}</div> },



                {
                  title: <p style={{ width: '80px' }} >Actions</p>, sorting: false, render: (row) =>

                    <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'flex-start' }}>
                      <div>

                        <BeekeeperRequestPopup currentBEEKEEPER={row} />
                      </div>


                    </div>



                },
              ]}
              data={data}






            />


          </Paper>
        </div>



      </Grid>
      <Grid item xs={12} >
          <div style={{marginTop:'17.4%'}} >
            
          </div>
      </Grid>
    </Grid>
  )
}