import React, { useState, useEffect, FunctionComponent } from 'react';
import { Paper, Grid, Typography, Card, CardActionArea, CardMedia, CardContent, ListItemText, Link, MenuItem, ListItemSecondaryAction, List, ListItem, Button } from '@material-ui/core';
import { LocalShipping, Storage, Receipt } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import StackedChart from '../Charts/StackedChart';
// import { SellerTable } from '../Tables/SellerTable';
import PieChart3 from './../Charts/PieChart3';
import MainPieChart from '../Charts/MainPieChart';
import BarChart from '../Charts/BarChart';
import PieChart4 from '../Charts/PieChart4';
import PieChart2 from '../Charts/PieChart2';
import LineChart from '../Charts/LineChart';
import { Ways, API, Cook, getvalue } from '../Helpers';
import { createBrowserHistory } from 'history';
import TimeChart from './../Charts/TimeChart';
import { useSnackbar } from 'notistack';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { AllBeekeepers } from '../Tables/AllBeekeepers';
import moment from 'moment';



const useStyles = makeStyles((theme) => ({


  root: {

    marginTop: '-4%',
  },

  actionArea: {

    '&.MuiCardActionArea-root': {

      textAlign: 'center',


    },

  },

  media: {


  },

  mainHeading: {

    color: '#303030',
    fontSize: 36,
    fontWeight: 600,


  },


  descriptionStyle: {


    fontSize: '1rem',
    fontWeight: 500,
    color: '#726f6f',

  },

  Paper3: {
    backgroundColor: '#fff',
    height: '270px',
    boxShadow: 'none',


    [theme.breakpoints.down('sm')]: {
      height: 200,
    },

  },

  card: {
    maxWidth: 400,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingTop: 30,
    paddingBottom: 30,
    pointerEvents: 'none',




  },




  Paper: {

    boxShadow: 'none',
  },


}));




export const DashboardFeatures: FunctionComponent<{ allBeekeepers: any, AllProducts: any, currentData: any, totalData: { totalOrderValue?: any } }> = props => {

  const [data, setData] = useState<any>([])
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [quarter, setQuarter] = useState<number>(moment().subtract(6, 'months').quarter())
  const [year, setYear] = useState<number>(new Date().getFullYear())


  const getData = async (year: number, quarter: number) => {
    var x = await API.get('admin/charts/totals', { year: year, quarter: quarter });
    if (x.status == 200) {
      // window.location.reload();
      return x.data;
      enqueueSnackbar('data request has been successfully sent!');
    }
    else {
      enqueueSnackbar('data request failed!')
    }
  }


  useEffect(() => {


    getData(new Date().getFullYear(), moment().subtract(6, 'months').quarter()).then(x => {
      setData(x)
      // console.log(x)
    })


  }, [])




  return (

    <div style={{marginBottom:'8px'}} className={classes.root}>






      <Grid container direction="row" spacing={2}>
        <Grid item lg={4} xs={12}>
          <Paper style={{display:'flex',justifyContent:'center',alignItems:'center'}} className={classes.Paper3}>

            <Card className={classes.card}>
              <CardActionArea className={classes.actionArea}>
                <CardMedia
                  className={classes.media}>

                  <Storage style={{ fontSize: 50, color: '#f28f16' }} />
                </CardMedia>
                <CardContent>


                  <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                    {Number(data['remaining']?? 0).toFixed(2)}kg
                  </Typography>

                  <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                    Honey Stock On Hand
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Paper>
        </Grid>

        <Grid item lg={4} xs={12}>
          <Paper style={{display:'flex',justifyContent:'center',alignItems:'center'}} className={classes.Paper3}>

            <Card className={classes.card}>
              <CardActionArea className={classes.actionArea}>
                <CardMedia
                  className={classes.media}>

                  <LocalShipping style={{ fontSize: 50, color: '#f28f16' }} />
                </CardMedia>
                <CardContent>


                  <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                    {/* {props.AllProducts.length} */}
                    {Number(data['sold']??0).toFixed(2)}kg
                  </Typography>

                  <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                    Honey Sold
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Paper>
        </Grid>


        <Grid item lg={4} xs={12}>
          <Paper style={{display:'flex',justifyContent:'center',alignItems:'center'}} className={classes.Paper3}>

            <Card className={classes.card}>
              <CardActionArea className={classes.actionArea}>
                <CardMedia
                  className={classes.media}>

                  <Receipt style={{ fontSize: 50, color: '#f28f16' }} />
                </CardMedia>
                <CardContent>


                  <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
                    ${Number(Number((props.totalData.totalOrderValue)??0).toFixed(2))}
                  </Typography>

                  <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
                    Total Paid To Beekeepers
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Paper>
        </Grid>

      </Grid>
    </div>




  )
}