import React, { useState, FunctionComponent, useEffect, } from 'react';
import { Grid, Button, FormControl, MenuItem, Select, InputLabel, Typography, List, ListItem, ListItemSecondaryAction, IconButton, ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Cancel, Add } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { HoneyPopupForm } from './HoneyPopupForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { API, Ways } from '../Helpers';
import { Edit } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { SetTimer } from './../Quote/SetTimer';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    content: {

      maxHeight: 800,
    },
  }),
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



export const PopupHoneyEditableForm: FunctionComponent<{ onAdded?: () => void, currentHolding?: any }> = props => {

  const [form, setForm] = useState<any>({});
  const [focused, setFocused] = useState(false);
  const set = (e: React.FormEvent) => {
    var n = e.target['name'];
    var v = e.target['value'];
    form[n] = v;
    setForm({ ...form })
    // console.log(form);
  }


  useEffect(() => {

    if (props.currentHolding != null) {
      setForm(props.currentHolding)
    }
  }, [])





  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [adding, setAdding] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const handleClickOpen = () => {
    setOpen(true);
    if (props.currentHolding != null) {
      setForm(props.currentHolding)
      // console.log(props.currentHolding.length)
    }

  };

  const handleClose = () => {
    setOpen(false);
    setForm({})

  };

  const [loading, setLoading] = useState(false);


  // function refreshPage() {
  //   window.location.reload(false);
  // }

  const { enqueueSnackbar } = useSnackbar();

  // style={{ marginTop: '4%' }}
  return (
    <div  style={{ marginTop: '5.3%' }} >
      
      <Grid container spacing={0}  >

        <Grid item lg={12} xs={12}>

          {
            (props.currentHolding != null)

              ?

              // <IconButton style={{ marginBottom: -70, marginTop: -70 }} onClick={handleClickOpen}>
              //   <Edit />
              // </IconButton>
              <Button
                size='small'
                variant="contained"
                style={{ backgroundColor: '#56A33B', color: '#fff',padding:'3.9px',marginTop:'-5.3%',height:'30px' }} onClick={handleClickOpen}

              >
                edit
              </Button>

              :

              <Button

                variant="contained"
                style={{ backgroundColor: '#f28f16', color: '#fff', }} onClick={handleClickOpen}
                startIcon={<Add />}
              >
                Add new holding category
              </Button>

          }


          <Dialog
            fullScreen={fullScreen}
            open={open}
            maxWidth="lg"
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            style={{ height: 800 }}
          >

            <ListItem>
              <ListItemText>
                <DialogTitle id="form-dialog-title"> {props.currentHolding != null ? 'Update Holding Category' : 'Add New Holding Category'} </DialogTitle>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton>
                  <Cancel onClick={handleClose} />

                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <DialogContent className={classes.content}>


              <ValidatorForm onSubmit={async () => {

                // console.log(props.currentHolding)
                // return ;
                //  setLoading(true);
                // console.log(form)


                if (props.currentHolding != null) {

                  delete form['availableStock']
                  delete form['contributors']
                  delete form['ibcs']
                  delete form['tableData']
                  delete form['__proto__']
                  API.put('holding', form, { holdingid: form['id'] }).then(x => {
                    if (x.status == 200) {
                      window.location.reload();

                      enqueueSnackbar('Holding category is updated');

                    }
                  })
                }


                else {
                  API.post('holding', form).then(x => {
                    if (x.status == 200) {
                      window.location.reload();
                      enqueueSnackbar('New holding category is added');
                      // console.log('nulll')
                    }


                  })
                }



                // API.post('holding', form);





                //  API.post('holding',  form ).then(()=>{
                //    if(prop.onAdded){
                //    props.onAdded();

                //    }
                //  })


                //          fetch('/honeyform', {
                //   method: 'POST',
                //   headers: {

                //   },
                //   body: JSON.stringify({
                //     firstParam: 'form',
                //     secondParam: '',
                //   })
                // })


              }}>

                <DialogContentText>

                </DialogContentText>




                <Grid container direction="row" spacing={2}>

                  <Grid item lg={6}>
                    <Typography variant="subtitle2">Holding Category Details:</Typography>
                    <br />


                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>


                    <SelectValidator name={'honeyType'} variant="outlined" size="small" value={form['honeyType']} style={{ width: '100%' }}
                      onChange={set}
                      label="Dominent Variety"
                      validators={['required']}
                      errorMessages={['this field is required']}

                    >
                      <MenuItem value={'Blackbutt'}>Blackbutt</MenuItem>
                      <MenuItem value={'Red Gum'}>Red Gum</MenuItem>
                      <MenuItem value={'Jarrah'}>Jarrah</MenuItem>
                      <MenuItem value={'Red Bell'}>Red Bell </MenuItem>
                      <MenuItem value={'Wildflower'}>Wildflower</MenuItem>
                      <MenuItem value={'Whitegum'}>Whitegum</MenuItem>
                      <MenuItem value={'Banksia'}>Banksia</MenuItem>

                      {/* {[<MenuItem key={-1} value={0}>None</MenuItem>].concat(form.map((x, i) => <MenuItem key={i} value={x}>{x['honeyType']}</MenuItem>))} */}



                    </SelectValidator>
                    {/* </FormControl> */}
                  </Grid>
                  <Grid item lg={6}>
                    <Typography variant="subtitle2">Second variety:</Typography>
                    <br />


                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>


                    <SelectValidator name={'secondaryVariety'} variant="outlined" size="small" value={form['secondaryVariety']} style={{ width: '100%' }}
                      onChange={set}
                      label="Second Variety"
                      validators={['required']}
                      errorMessages={['this field is required']}

                    >
                      <MenuItem value={'Blackbutt'}>Blackbutt</MenuItem>
                      <MenuItem value={'Red Gum'}>Red Gum</MenuItem>
                      <MenuItem value={'Jarrah'}>Jarrah</MenuItem>
                      <MenuItem value={'Red Bell'}>Red Bell </MenuItem>
                      <MenuItem value={'Wildflower'}>Wildflower</MenuItem>
                      <MenuItem value={'Whitegum'}>Whitegum</MenuItem>
                      <MenuItem value={'Banksia'}>Banksia</MenuItem>

                      {/* {[<MenuItem key={-1} value={0}>None</MenuItem>].concat(form.map((x, i) => <MenuItem key={i} value={x}>{x['honeyType']}</MenuItem>))} */}



                    </SelectValidator>
                    {/* </FormControl> */}
                  </Grid>
                  <Grid item lg={12} style={{ paddingTop: '10px', marginBottom: '-20px' }} >
                    <Typography variant="subtitle2">Honey Chractristics:</Typography>
                  </Grid>

                  {/* <Grid item lg={4}>

                

                    <SelectValidator id='floralType' name='floralType' value={form['floralType']} variant="outlined" size="small" style={{ width: '100%' }}
                      onChange={set}
                      label="Mono Floral/Multi Floral"
                      validators={['required']}
                      errorMessages={['this field is required']}

                    >
                      <MenuItem value={'Mono-Floral'}>Mono Floral</MenuItem>
                      <MenuItem value={'Multi-Floral'}>Multi Floral</MenuItem>



                    </SelectValidator>
              
                </Grid> */}

                  {/* <Grid item lg={4}>


                  <SelectValidator id='organicnonorganic' name='organicnonorganic' value={form['organicnonorganic']} variant="outlined" size="small" style={{ width: '100%', marginTop: 7 }}
                    onChange={set}
                    label="Organic/Non Organic"
                    validators={['required']}
                    errorMessages={['this field is required']}

                  >
                    <MenuItem value={'organic'}>organic</MenuItem>
                    <MenuItem value={'non-organic'}>non-organic</MenuItem>



                  </SelectValidator>
                  

                </Grid> */}

                  {/* <Grid item lg={4}>

              
                    <SelectValidator id='rawPastureized' name='rawPastureized' value={form['rawPastureized']} variant="outlined" size="small" style={{ width: '100%' }}
                      onChange={set}
                      label="Raw/Pastureized"
                      validators={['required']}
                      errorMessages={['this field is required']}

                    >
                      <MenuItem value={'raw'}>raw</MenuItem>
                      <MenuItem value={'pastureized'}>pastureized</MenuItem>



                    </SelectValidator>
              
                </Grid> */}

                  {/* <Grid item lg={4}>

               
                    <TextValidator


                      variant="outlined"

                   
                      
                      size="small"
                      fullWidth
                      value={form['countryOfOrigin']}
                      onChange={set}
                      label="Country Of Origin"
                      name="countryOfOrigin"
                      validators={['required', 'matchRegexp:^[a-zA-Z ,.]+$']}
                      errorMessages={['This field is required', 'Invalid Format']}
                  
                    >

                    </TextValidator>
              
                </Grid> */}


                  {/* <Grid item lg={4}>

              

                    <TextValidator


                      variant="outlined"

                
                      
                      size="small"
                      fullWidth
                      value={form['countryOfPackaged']}
                      onChange={set}
                      label="Country Of Packaged"
                      name="countryOfPackaged"
                      validators={['required', 'matchRegexp:^[a-zA-Z ,.]+$']}
                      errorMessages={['This field is required', 'Invalid Format']}
                 
                    >

                    </TextValidator>
            
                </Grid> */}


                  {/* 
                <Grid item lg={4}>

              
                    <SelectValidator id='floralType' name='containsImportedProducts' value={form['containsImportedProducts']} variant="outlined" size="small" style={{ width: '100%' }}
                      onChange={set}
                      label="Contains Imported Products"
                      validators={['required']}
                      errorMessages={['this field is required']}


                    >
                      <MenuItem value={'true'}>Yes</MenuItem>
                      <MenuItem value={'false'}>No</MenuItem>



                    </SelectValidator>
             
                </Grid> */}

                  {/* 
                <Grid item lg={4}>

               

                    <TextValidator
                      name={'originofimportedproducts'}
                      margin="dense"
                      
                      value={form['originofimportedproducts']}
                      id="amount"
                      label="Origin Of Imported Products"
                      onChange={set}
                      validators={['required', 'matchRegexp:^[a-zA-Z ,.]+$']}
                      errorMessages={['This field is required', 'Invalid Format']}
                     
                      type="amount"
                      fullWidth
                      variant="outlined"
                     
                    />


                </Grid> */}

















                </Grid>


                <Grid container direction="row" spacing={6} style={{ backgroundColor: '#fbfbfb', marginTop: '3%' }}>




                  <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Total Activity:</Typography>
                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>
                      <ListItem>
                        <Typography variant="body2">From</Typography>
                      </ListItem>

                      <ListItem>



                        <TextValidator
                          name={'activityfrom'}
                          margin="dense"
                          value={form['activityfrom']}
                          id="amount"
                          label=""
                          onChange={set}
                          // validators={['required', 'isNumber', 'maxNumber:' + form['activityto'] ?? 100]}
                          // errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}
                          validators={['required', 'isNumber']}
                          errorMessages={['this field is required', 'Minimum value is in an invalid format',]}
                          type="number"
                          fullWidth
                          variant="outlined"

                        />
                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">To</Typography>
                      </ListItem>

                      <ListItem>
                        <TextValidator
                          name={'activityto'}
                          margin="dense"
                          value={form['activityto']}
                          id="amount"
                          label=""
                          onChange={set}
                          validators={['required', 'isNumber', 'minNumber:' + form['activityfrom'] ?? 0]}
                          errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}
                          type="number"
                          fullWidth
                          variant="outlined"

                        />
                      </ListItem>


                    </List>
                  </Grid>



                  {/* 
                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">MGO:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>
                    <ListItem>
                      <Typography variant="body2">From</Typography>
                    </ListItem>

                    <ListItem>
                      <TextValidator
                        name={'mgofrom'}
                        margin="dense"
                        value={form['mgofrom']}
                        id="amount"
                        label=""
                        onChange={set}
                        validators={['required', 'isNumber']}
                        errorMessages={['this field is required', 'Minimum value is in an invalid format',]}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>

                    <ListItem>
                      <Typography variant="body2">To</Typography>
                    </ListItem>

                    <ListItem>
                      <TextValidator
                        name={'mgoto'}
                        margin="dense"
                        value={form['mgoto']}
                        id="amount"
                        label=""
                        onChange={set}
                        validators={['required', 'isNumber', 'minNumber:' + form['mgofrom'] ?? 0]}
                        errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>


                  </List>
                </Grid> */}



                  {/* <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Percentage Of Imported Products:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>
                    <ListItem>
                      <Typography variant="body2">From</Typography>
                    </ListItem>

                    <ListItem>
                    
  
<TextValidator
                        name={'productspercentagefrom'}
                        margin="dense"
                        value={form['productspercentagefrom']}
                        id="amount"
                        label=""
                        onChange={set}
                        validators={['required', 'isNumber']}
                        errorMessages={['this field is required', 'Minimum value is in an invalid format']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>

                    <ListItem>
                      <Typography variant="body2">To</Typography>
                    </ListItem>

                    <ListItem>
                   

<TextValidator
                        name={'productspercentageto'}
                        margin="dense"
                        value={form['productspercentageto']}
                        id="amount"
                        label=""
                        onChange={set}
                        validators={['required', 'isNumber', 'minNumber:' + form['productspercentagefrom'] ?? 0]}
                        errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>


                  </List>
                </Grid> */}



                  <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Moisture:</Typography>
                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>
                      <ListItem>
                        <Typography variant="body2">From</Typography>
                      </ListItem>

                      <ListItem>
                        <TextValidator
                          name={'moisturefrom'}
                          margin="dense"
                          value={form['moisturefrom']}
                          id="amount"
                          label=""
                          onChange={set}
                          validators={['required', 'isNumber']}
                          errorMessages={['this field is required', 'Minimum value is in an invalid format']}
                          type="number"
                          fullWidth
                          variant="outlined"

                        />
                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">To</Typography>
                      </ListItem>

                      <ListItem>
                        <TextValidator
                          name={'moistureto'}
                          margin="dense"
                          value={form['moistureto']}
                          id="amount"
                          label=""
                          onChange={set}
                          validators={['required', 'isNumber', 'minNumber:' + form['moisturefrom'] ?? 0]}
                          errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}
                          type="number"
                          fullWidth
                          variant="outlined"

                        />
                      </ListItem>


                    </List>
                  </Grid>

                  {/* 

                <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Energy/Carbs:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>
                    <ListItem>
                      <Typography variant="body2">From</Typography>
                    </ListItem>

                    <ListItem>
                      <TextValidator
                        name={'energycarbsfrom'}
                        margin="dense"
                        value={form['energycarbsfrom']}
                        id="amount"
                        label=""
                        onChange={set}
                        validators={['required', 'isNumber']}
                        errorMessages={['this field is required', 'Minimum value is in an invalid format',]}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>

                    <ListItem>
                      <Typography variant="body2">To</Typography>
                    </ListItem>

                    <ListItem>
                      <TextValidator
                        name={'energycarbsto'}
                        margin="dense"
                        value={form['energycarbsto']}
                        id="amount"
                        label=""
                        onChange={set}
                        validators={['required', 'isNumber', 'minNumber:' + form['energycarbsfrom'] ?? 0]}
                        errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}
                        type="amount"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>


                  </List>
                </Grid> */}


                  {/* <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                  <Typography style={{ marginLeft: '2%' }} variant="subtitle2">Glucose Index GI:</Typography>
                  <List dense style={{ display: 'flex', flexDirection: 'row' }}>
                    <ListItem>
                      <Typography variant="body2">From</Typography>
                    </ListItem>

                    <ListItem>
                      <TextValidator
                        name={'glucoseindexfrom'}
                        margin="dense"
                        value={form['glucoseindexfrom']}
                        id="amount"
                        label=""
                        onChange={set}
                        validators={['required', 'isNumber']}
                        errorMessages={['this field is required', 'Minimum value is in an invalid format']}
                        type="number"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>

                    <ListItem>
                      <Typography variant="body2">To</Typography>
                    </ListItem>

                    <ListItem>
                      <TextValidator
                        name={'glucoseindexto'}
                        margin="dense"
                        value={form['glucoseindexto']}
                        id="amount"
                        label=""
                        onChange={set}
                        validators={['required', 'isNumber', 'minNumber:' + form['glucoseindexfrom'] ?? 0]}
                        errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}
                        type="number"
                        fullWidth
                        variant="outlined"

                      />
                    </ListItem>


                  </List>
                </Grid> */}


                  <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">HMF:</Typography>
                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>
                      <ListItem>
                        <Typography variant="body2">From</Typography>
                      </ListItem>

                      <ListItem>

                        <TextValidator
                          onChange={set}

                          name="hmffrom"
                          variant="outlined"
                          required
                          size="small"
                          value={form['hmffrom']}
                          validators={['required', 'isNumber']}
                          errorMessages={['this field is required', 'Minimum value is in an invalid format']}
                          fullWidth
                          type='number'
                          id="minValue"
                          label=""
                        />



                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">To</Typography>
                      </ListItem>

                      <ListItem>



                        <TextValidator
                          value={form['hmfto']}
                          validators={['required', 'isNumber', 'minNumber:' + form['hmffrom'] ?? 0]}
                          errorMessages={['this field is required', 'Maximum value is in an invalid format', 'Maximum value cannot be less than Minmum value']}
                          onChange={set}

                          variant="outlined"
                          required
                          fullWidth
                          size="small"
                          id="maxValue"
                          label=""
                          name="hmfto"
                          autoComplete="lname"
                          type='number'
                        />
                      </ListItem>





                    </List>
                  </Grid>

                  {/* MGO field */}

                  <Grid item lg={4} style={{ border: '4px solid #fff' }}>
                    <Typography style={{ marginLeft: '2%' }} variant="subtitle2">MGO:</Typography>
                    <List dense style={{ display: 'flex', flexDirection: 'row' }}>
                      <ListItem>
                        <Typography variant="body2">From</Typography>
                      </ListItem>

                      <ListItem>

                        <TextValidator
                          onChange={set}

                          name="mgoFrom"
                          variant="outlined"
                          required
                          size="small"
                          value={form['mgoFrom']}
                          validators={['required', 'isNumber']}
                          errorMessages={['this field is required', 'Minimum value is in an invalid format']}
                          fullWidth
                          type='number'
                          id="minValue"
                          label=""
                        />



                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">To</Typography>
                      </ListItem>

                      <ListItem>



                        <TextValidator
                          name={'mgoTO'}
                          margin="dense"
                          value={form['mgoTO']}
                          id="amount"
                          label=""
                          onChange={set}
                          validators={['required', 'isNumber', 'minNumber:' + form['mgoFrom'] ?? 0]}
                          errorMessages={['this field is required', 'Minimum value is in an invalid format', 'Min value cannot be higher than']}
                          type="number"
                          fullWidth
                          variant="outlined"

                        />
                      </ListItem>





                    </List>
                  </Grid>

                  <Grid item lg={4}></Grid>


                </Grid>


                <Grid item lg={3} >
                  {
                    (props.currentHolding != null)
                      ?

                      <Button type='submit' fullWidth
                        variant="contained" size="large" style={{ backgroundColor: '#f28f16', color: '#fff', marginTop: 75, marginBottom: 15, paddingLeft: 20, paddingRight: 20, }} autoFocus>
                        Update
                      </Button>

                      :

                      <Button type='submit' fullWidth
                        variant="contained" size="large" style={{ backgroundColor: '#f28f16', color: '#fff', marginTop: 75, marginBottom: 15, paddingLeft: 20, paddingRight: 20, }} autoFocus>
                        Add
                      </Button>

                  }

                </Grid>

                <Grid item lg={9}></Grid>


              </ValidatorForm>
            </DialogContent>
            <DialogActions>

              <Grid item lg={3} xs={12}>





              </Grid>

              <Grid item lg={8}>

              </Grid>

              <Grid item lg={1}>

              </Grid>


            </DialogActions>

          </Dialog>
        </Grid>
      </Grid>



      


    </div>
  )
}